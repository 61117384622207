import React, { useState, useEffect } from "react";
import Practices_ChooseAnswer from "../Pratices/Practices_ChooseAnser";

import Practices_MultipleChoice from "../Pratices/Practices_MultipleChoice";
import _ from "lodash";

import "./Praticess.css";
import { useParams } from "react-router-dom";
import { Popconfirm } from "antd";
import history from "../../../utilities/history";



export default function TracNghiem_StartV2({ indexQuiz, allQuestions, setIndexQuiz, finishQuiz }) {
  const { quizId } = useParams()


  let [isShowResult, setIsShowResult] = useState(false);

  let checkQuizAWS = localStorage.getItem("quiz")
  if (checkQuizAWS) checkQuizAWS = JSON.parse(checkQuizAWS)


  let handle_CheckSingleChoice = (id, userAnsers) => {

    let quiz = checkQuizAWS.find(n => n.quizId == quizId)

    if (quiz) {

      let dataTemp = [...quiz.userChoice]

      let cauHoi = dataTemp.find(n => n.id == id)
      if (cauHoi) {
        cauHoi.choice = userAnsers

      } else {
        dataTemp.push({ id, choice: userAnsers })
      }

      quiz.userChoice = dataTemp

      localStorage.setItem("quiz", JSON.stringify(checkQuizAWS))

    }

  };


  let handle_CheckMultipleChoice = (id, userAnsers) => {
    let quiz = checkQuizAWS.find(n => n.quizId == quizId)

    if (quiz) {

      let dataTemp = [...quiz.userChoice]

      let cauHoi = dataTemp.find(n => n.id == id)
      if (cauHoi) {
        cauHoi.choice = userAnsers

      } else {
        dataTemp.push({ id, choice: userAnsers })
      }

      quiz.userChoice = dataTemp

      localStorage.setItem("quiz", JSON.stringify(checkQuizAWS))

    }


  };



  


  let arrRenderQuestion = [];
  arrRenderQuestion = allQuestions.map((question, index) => {
    switch (question?.maLoaiBaiTap) {
      case "SINGLE":
        return (
          <Practices_ChooseAnswer
            key={index}
            question={question}
            handle_CheckSingleChoice={handle_CheckSingleChoice}

          />
        );
      case "MULTIPLE_CHOICE":
        return (
          <Practices_MultipleChoice
            key={index}
            handle_CheckMultipleChoice={handle_CheckMultipleChoice}
            question={question}
          />
        );
      

      default:
        break;
    }
  });
  let isDisableNextBtn;

  let typeQuestion = allQuestions[indexQuiz]?.maLoaiBaiTap;
  if (typeQuestion === "MULTIPLE_CHOICE") {
    isDisableNextBtn =
      allQuestions[indexQuiz]?.userAnsers?.length === 0;
  }
  if (typeQuestion === "SINGLE") {
    isDisableNextBtn =
      allQuestions[indexQuiz]?.userAnsers == undefined || allQuestions[indexQuiz]?.userAnsers === null;
  }

  return (
    <div className="w-full  flex-grow h-full flex flex-col ">

      <div className="w-full h-full  flex-grow flex flex-col  p-3 relative overflow-y-scroll">
        <div className="w-full  ">
          {arrRenderQuestion[indexQuiz]}
        </div>
        <div className="w-full flex  justify-end">

          {indexQuiz != 0 && <button className="btn btn-sm btn-outline-primary mx-2" onClick={() => {
            setIndexQuiz(indexQuiz - 1)
          }}>Quay lại</button>}
          {indexQuiz + 1 != allQuestions.length ? <button className="btn btn-sm btn-primary mx-2" onClick={() => {
            setIndexQuiz(indexQuiz + 1)
          }}>Kế tiếp</button>

            :

            <Popconfirm
              title="Bạn chắc có muốn tiếp tục ?"
              onConfirm={() => {

                finishQuiz()

              }}
            >
              <button className="btn btn-sm btn-danger mx-2">Kết thúc</button>
            </Popconfirm>
          }
    
        </div>

      </div>


    </div>
  );
}
