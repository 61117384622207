import * as types from "../types";
import { LopHocSevice } from "../../service/lopHocService";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "./spinnerAction";

// lay data LopHoc
export const layDanhSachLopHoc = (id) => {
  return (dispatch) => {
    dispatch(set_request_spinner_started())
    LopHocSevice.layDanhSachLopHocService(id)
      .then((res) => {
        dispatch(layDanhSachLopHocThanhCong(res.data.content))
        dispatch(set_request_spinner_ended());
      })
      .catch((err) => {
        dispatch(set_request_spinner_ended());
        console.log(err.response)
      });
  };
};
// lay data bộ đề
export const layDanhSachBoDe = (id) => {
  return (dispatch) => {
    dispatch(set_request_spinner_started())
    LopHocSevice.layDanhSachBoDeService(id)
      .then((res) => {
        dispatch(layDanhSachBoDeThanhCong(res.data.content))
        dispatch(set_request_spinner_ended());
      })
      .catch((err) => {
        dispatch(set_request_spinner_ended());
        console.log(err.response)
      });
  };
};
export const layDanhSachBoDeThanhCong = (data) => {
  return {
    type: types.LAY_DANH_SACH_BO_DE_THANH_CONG,
    payload: data,
  };
};

export const layDanhSachLopHocThanhCong = (data) => {
  return {
    type: types.LAY_DANH_SACH_LOP_HOC_THANH_CONG,
    payload: data,
  };
};

// lay data video buoi hoc
export const layDanhSachXemLaiBuoiHocTheoLop = (id) => {
  return (dispatch) => {
    LopHocSevice.layDanhSachXemLaiTheoLopService(id)
      .then((res) =>
        dispatch(layDanhSachXemLaiBuoiHocTheoLopThanhCong(res.data.content))
      )
      .catch((err) => console.log(err.response));
  };
};

export const layDanhSachXemLaiBuoiHocTheoLopThanhCong = (data) => {
  return {
    type: types.LAY_DANH_SACH_XEM_LAI_BUOI_HOC_THANH_CONG,
    payload: data,
  };
};
