import { Tag, Tooltip } from "antd";
import moment from "moment";
import reactStringReplace from "react-string-replace";

export const GetTagStatusBaiTap = (data) => {
  if (data.diemBaiTap > 0) {
    return (
      <Tag className="w-20 text-center" color="blue">
        <i className="fa fa-check mr-1"></i> Đã chấm
      </Tag>
    );
  }
  if (data.diemBaiTap == -1) {
    return (
      <Tag className="w-20 text-center" color="purple">
        Đang chấm
      </Tag>
    );
  }

  if (data.diemBaiTap == 0 && data.isHetHan) {
    return (
      <Tag className="w-20 text-center" color="volcano">
        Hết hạn
      </Tag>
    );
  }
  if (data.diemBaiTap == 0) {
    return (
      <Tag className="w-20 text-center" color="blue">
        Chưa làm
      </Tag>
    );
  }
};

export const initBaiTapNop = {
  noiDung: "",
  maBuoiHoc: 0,
  maNguoiDung: "",
  maBaiHoc: 0,
  loaiBaiTap: "QUIZ_WRITE",
  maLop: 0,
};
export const initBaiTapTracNghiem = {
  maBuoiHoc: 0,
  maNguoiDung: "",
  maBaiHoc: 0,
  loaiBaiTap: "QUIZ",

  maLop: 0,
  baiLam: [],
};
export const initBaiTapCapstone = {
  noiDung: {
    linkBai: "string",
    linkYoutube: "string",
    linkDeploy: "string",
  },
  maBuoiHoc: 0,
  maNguoiDung: "string",
  maBaiHoc: 0,
  loaiBaiTap: "CAPSTONE",
  maLop: 0,
};
export const ConvertNameUser = (string) => {
  let arrName = string.split(" ");
  if (arrName.length > 2) {
    arrName = [
      arrName[0],
      arrName[arrName.length - 2],
      arrName[arrName.length - 1],
    ];
  }
  return arrName.join(" ");
};

export const gerateDataSetChart = (arrData, canvas, userInfor) => {
  const ctx = canvas.getContext("2d");

  const gradient_bg_1 = ctx.createLinearGradient(0, 0, 0, 250);
  gradient_bg_1.addColorStop(0, "rgba(251, 128, 133,0.3)");
  gradient_bg_1.addColorStop(1, "rgba(251, 128, 133,0)");

  const arrColor = [
    gradient_bg_1,
    "rgb( 117, 95, 211,0.8)",
    "rgb( 70, 220, 216)",
    "rgb(106, 201, 119,0.9)",
    "rgb(253, 206, 0,0.8)",
  ];

  let userIndex = arrData.findIndex((item) => {
    return item?.hoTen === userInfor?.hoTen;
  });
  if (userIndex !== -1) {
    let temp = arrData[0];
    arrData[0] = arrData[userIndex];
    arrData[userIndex] = temp;
  }

  return arrData.map((dataUser, index) => {
    let newDataUser = { ...dataUser };
    let dataShow = [...newDataUser.lstSoPhut];

    let nameUser =
      dataUser?.hoTen === userInfor?.hoTen
        ? "Của bạn"
        : ConvertNameUser(dataUser?.hoTen);
    return {
      label: arrData.length === 1 ? "Tốc độ xem video (phút)" : nameUser,
      fill: "start",
      backgroundColor: index === 0 ? arrColor[index] : "transparent",
      borderColor: index === 0 ? "#FF6384" : arrColor[index],
      pointBackgroundColor: index === 0 ? "#FF6384" : arrColor[index],
      lineTension: 0.4,
      cubicInterpolationMode: "monotone",
      pointStyle: "circle",
      pointRadius: 0,
      pointHoverRadius: 6,
      borderWidth: 4,
      data: dataShow,
      fontColor: "#ffffff",
    };
  });
};

export let renderTitleBaiTapNop = (listBaiTap) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="">
        Bài tập nộp (<span className="text-xs">{listBaiTap.length}</span>)
      </span>
      <div
        className="flex flex-col text-sm
      "
      >
        {listBaiTap.map((data) => {
          return (
            <Tooltip
              key={data.id}
              title={
                <div className="text-black text-center text-xs">
                  {data.tieuDe}
                </div>
              }
            >
              <span className="text-sm">
                {moment(data.hanNop).format("DD/MM/YYYY")}
              </span>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

// Thông báo

export const typeThongBao = {
  BaiTapDaCham: {
    value: "BaiTapDaCham",
    icon: (
      <i className=" mr-2 text-blue-500 font-medium fa fa-check-circle"></i>
    ),
  },
  BaiTapSapHetHan: {
    value: "BaiTapSapHetHan",
    icon: <i className=" mr-2 text-red-400 font-medium fa fa-exclamation"></i>,
  },
  TaoBaiTap: {
    value: "TaoBaiTap",
    icon: <i className=" text-red-500 mr-2 font-medium fa fa-bullhorn"></i>,
  },
  BaiTapDiemThap: {
    value: "BaiTapDiemThap",
    icon: <i className=" text-red-300 mr-2 font-medium fa fa-exclamation "></i>,
  },
  ChuaNopBai: {
    value: "ChuaNopBai",
    icon: <i className=" text-red-300 mr-2 font-medium fa fa-exclamation"></i>,
  },
  VideoDaDuocDangTai: {
    value: "VideoDaDuocDangTai",
    // icon: <i className=" mr-2 font-medium fa fa-video text-blue-theme"></i>,
    icon: <i className=" mr-2 text-blue-800 font-medium fa fa-child"></i>,
  },
  VideoSapHetHan: {
    value: "VideoSapHetHan",
    icon: <i className=" mr-2 font-medium fa fa-video text-red-800"></i>,
  },
  XemVideoXemTruoc: {
    value: "XemVideoXemTruoc",
    icon: <i className=" mr-2 font-medium fa fa-video text-blue-500"></i>,
  },
  FeedBack: {
    value: "FeedBack",
    icon: "",
  },
};
const renderTooltipThongBao = (content, desc) => {
  if (!desc) {
    return content;
  }
  return (
    <Tooltip title={<div className="text-black text-center">{desc}</div>}>
      {content}
    </Tooltip>
  );
};

export const renderThongBao = (thongBao) => {
  let contentRaw = thongBao.cauThongBao;
  let content = "";
  content = reactStringReplace(contentRaw, "[0]", () => {
    return renderTooltipThongBao(
      <span className="text-blue-theme font-medium">
        {thongBao.tenGiangVien}
      </span>,
      "Trợ lý hệ thống"
    );
  });
  content = reactStringReplace(content, "[1]", () => {
    return renderTooltipThongBao(
      <span className="text-blue-600 font-medium">{thongBao.tenHocVien}</span>,
      ""
    );
  });
  content = reactStringReplace(content, "[2]", () => {
    return renderTooltipThongBao(
      <span className="text-yellow-600 font-medium">{thongBao.tenBaiTap}</span>,
      "Tên bài tập bạn cần nộp"
    );
  });
  content = reactStringReplace(content, "[3]", () => {
    return renderTooltipThongBao(
      <span className="text-red-600 font-medium">{thongBao.soNgayConLai}</span>,
      "Thời gian còn lại trước khi hết deadline"
    );
  });
  content = reactStringReplace(content, "[4]", () => {
    return renderTooltipThongBao(
      <span className="text-red-600 font-medium">{thongBao.thuTuBuoiHoc}</span>,
      "Thứ tự buổi học trong quá trình học"
    );
  });

  content = reactStringReplace(content, "[5]", () => {
    return renderTooltipThongBao(
      <span className="text-red-600 font-medium">{moment(thongBao.ngayHetHan).format("DD/MM/YYYY") }</span>,
      "Thứ tự buổi học trong quá trình học"
    );
  });
  let result = [typeThongBao[thongBao.loaiThongBao].icon, <div>{content}</div>];
  return result;
};

export let testMode_Cyber = () => {
  if (!localStorage.getItem("LOGIN_LOCASTORE")) {
    localStorage.setItem(
      "LOGIN_LOCASTORE",
      JSON.stringify({
        id: "d9699b77-f003-42c4-b050-26607079a789",
        email: "tranquangsigl@gmail.com",
        hoTen: "Trần Quang Sĩ",
        biDanh: "tran-quang-si",
        soDT: "0386462582",
        facebookId: "706889296629670",
        avatar: "/static/user-icon.png",
        urls: "$2b$10$FZQ4uQdpF4w4Bv.taI23perEz066UDwAtqwZTMGADSwgepp.p9FlW",
        token:
          "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL2V4cGlyZWQiOiIyLzcvMjA0NSAyOjAwOjAzIFBNIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6ImQ5Njk5Yjc3LWYwMDMtNDJjNC1iMDUwLTI2NjA3MDc5YTc4OSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6InRyYW5xdWFuZ3NpZ2xAZ21haWwuY29tIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiREFBTEwiLCJuYmYiOjE2NDQyMTcyMDMsImV4cCI6MTY0NDIyMDgwM30.ymS6h8M8Winuc6umQjmUn7vzw0Y9n7mUwjVIMHTlbb4",
        thongTinMoRong: "",
        danhSachLopHoc:
          '["8","46","28","52","55","64","60","79","198","98","83","77","100","105","99","228","229","230",100]',
        thongTinLopHoc: null,
        maNhomQuyen: "ADMIN",
        ngayTao: "10/21/2020 2:39:00 PM",
        color: "",
        nuocNgoai: false,
      })
    );
  }
};

export let checkIsHocVienXuatSac = (idUser, list = []) => {
  let index = list.findIndex((user) => {
    return user.maNguoiDung == idUser;
  });
  if (index == -1) {
    return null;
  } else return list[index].thuHangTrongLop;
};
