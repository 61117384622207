import axios from "axios";
import { DOMAIN_API } from "../config/urlConfig";
import store from "../redux";
import history from "../utilities/history";

let token = "";

const setToken = () => {
  const user = localStorage.getItem("LOGIN_LOCASTORE");
  if (user) {
    token = JSON.parse(user).token;
  }
};

const handleUnauthorized = (err) => {
  if (err.response && err.response.status === 401) {
    history.push("/login");
  }
  return Promise.reject(err);
};

export const get = (url, params) => {
  setToken();
  return axios({
    baseURL: DOMAIN_API,
    url: url,
    params: params,
    method: "GET",
    responseType: "json",
    responseEncoding: "utf8",
    headers: {
      apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).catch(handleUnauthorized);
};

export const post = (url, data) => {
  setToken();
  return axios({
    baseURL: DOMAIN_API,
    url: url,
    method: "POST",
    responseType: "json",
    responseEncoding: "utf8",
    headers: {
      apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }).catch(handleUnauthorized);
};

export const put = (url, data) => {
  setToken();
  return axios({
    baseURL: DOMAIN_API,
    url: url,
    method: "PUT",
    responseType: "json",
    responseEncoding: "utf8",
    headers: {
      apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }).catch(handleUnauthorized);
};

export const remove = (url, data) => {
  setToken();
  return axios({
    baseURL: DOMAIN_API,
    url: url,
    method: "DELETE",
    responseType: "json",
    responseEncoding: "utf8",
    headers: {
      apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  }).catch(handleUnauthorized);
};

export const BaseService = {
  get,
  post,
  put,
  remove,
};
