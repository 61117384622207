import { DatePicker, message } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { layUserCV } from '../../../redux/actions/nguoiDungAction';
import { NguoiDungSevice } from '../../../service/nguoiDungService';
import { LocalStorageUtil } from '../../../utilities/localStorage';
import * as types from '../../../redux/types'
import moment from 'moment';

const { MonthPicker } = DatePicker;

export const Certification = ({ thongTinMoRong }) => {
   
    let oContent = {
        tenChungChi: "",
        noiCap: "",
        ngayCapChungChi: "",
        moTaNgan: "",
    }
    

    const FORMAT_DATE = "MM/yyyy"

    let [data, setData] = useState([]);
    let changeData = (name, value) => {
        let newData = data[0];

        newData[name] = value

        setData([newData]);
    }

    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    const dispatch = useDispatch();


    function handleXoaThongTin(index) {
        let {chungNhan} = thongTinMoRong

        // if (chungNhan.length === 1) {
        //     message.error("CV phải có ít nhất một trình độ học vấn, bạn hãy thêm rồi hãy xóa nhé !!")
        //     return
        // }

        if (chungNhan[index]?.noiCap === "CyberSoft Academy") {
            message.error("Xoá thất bại")
            return
        }

        chungNhan.splice(index,1)

        let newThongTinMoRong = {...thongTinMoRong, chungNhan}
        message.info("Xóa thành công")
        dispatch({type: types.CAP_NHAT_CV_LOCAL, payload: newThongTinMoRong})
    }

    const saveThongTin = (index = -1) => {
      
        let newData = data[0];
        for (let item in newData) {
            if (item === "ngayCapChungChi") continue
            if (newData[item].trim() == "") {
                message.info("Hãy nhập đầy đủ thông tin hoặc tắt thêm");
                return;
            }
        }


        let { chungNhan } = thongTinMoRong;

        chungNhan.push(newData)

        thongTinMoRong = { ...thongTinMoRong, chungNhan };
        setData([])
        dispatch({type: types.CAP_NHAT_CV_LOCAL, payload: thongTinMoRong})

     
    }




    //kiem tra data cuoi de cho phep them moi
    let checkData = data;

    return (

        < div className="resume_item resume_certification" >
            <div className="title d-flex justify-content-between">
            <p className="bold">Certification</p>
                <button className="btn btn-primary"
                onClick={() => {

                    if (checkData && checkData.length > 0) {
                        message.info("Bạn hãy lưu thông tin hiện tại");
                    } else
                        setData([...data, oContent])
                }}
                >Add</button>
            </div>
            {
                thongTinMoRong.chungNhan?.length > 0 && <ul>

                    {thongTinMoRong.chungNhan?.map((item, index) => {
                        return <li key={`chungChi_${index}`}>
                            <div className="info">
                                <p className="nameTitle">
                                    {item.tenChungChi} <span className="date">{moment(item.ngayCapChungChi).format(FORMAT_DATE)}
                                    {
                                        item?.noiCap !== "CyberSoft Academy" ? (<button className='delete ml-3 btn btn-sm btn-danger' onClick={() => handleXoaThongTin(index)}> <i className=' fa fa-trash'></i></button>) : ""
                                    }
                                    </span>
                                </p>
                                <p className="semi-bold">{item.noiCap}</p>

                                <p>{item.moTaNgan}</p>
                            </div>
                        </li>
                    })}

                </ul>
            }

            {
                checkData ?
                    checkData.length > 0 && <div className=' row flex justify-between'>

                        <div className="form-group col-12">
                            <label >Certification</label>
                            <input onChange={(e) => changeData("tenChungChi", e.target.value)} className="form-control form-control-sm" />
                        </div>
                        <div className="form-group col-5">
                            <label >Issued by</label>
                            <input onChange={(e) => changeData("noiCap", e.target.value)} className="form-control form-control-sm" />
                        </div>
                        <div className="form-group col-6 row flex justify-between">
                            <label className='col-12 mb-0'>Year issued</label>
                            <MonthPicker onChange={(date, dString) => changeData("ngayCapChungChi", date)} placeholder="start" format="MM/yyyy" className="form-control form-control-sm col-6" />
                        </div>
                      
                        <div className="form-group col-12">
                            <label >Description</label>
                            <textarea className="form-control" onChange={(e) => changeData("moTaNgan", e.target.value)} rows={3} />
                        </div>



                        <div className='col-12'>
                            <button className=' btn btn-sm btn-success mr-3'>
                                <i className='fa fa-check' onClick={() => saveThongTin()}></i>
                            </button>
                            <button className=' btn btn-sm btn-danger' onClick={() => {
                                setData([]);

                            }}>
                                <i className='fa fa-times'></i>
                            </button>
                        </div>

                    </div>
                    : ""
            }
        </div >
    )
}
