import React, { Component } from 'react';
import { Breadcrumb } from 'antd';
import { connect } from 'react-redux';
import history from '../../utilities/history';
import * as actions from '../../redux/actions/khoaHocAction';
import { LocalStorageUtil } from '../../utilities/localStorage';
import { DOMAIN_API } from '../../config/urlConfig';
import "./BaiHocPage.scss";

class BaiHocPage extends Component {

    state = {
        khoaHoc: {}
    }

    componentDidMount = () => {
        if (this.props.location !== null && this.props.location.state) {
            const { maKhoaHoc } = this.props.location.state;
            this.props.dispatch(actions.layChiTietKhoaHoc(maKhoaHoc));
        }
        else {
            history.push('/');
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { current } = props.khoaHocProps;
        if (current != null) {
            return {
                ...state,
                khoaHoc: current
            }
        }
        return null;
    }

    onGoToPlayVideo = (baiHoc) => {
        const { khoaHoc } = this.state;
        LocalStorageUtil.setCourseLocalStorage({ khoaHoc, baiHoc });
        history.push('/video');
    }

    renderChuongHoc = (dsChuongHoc) => {
        return dsChuongHoc.map((item, index) => {
            return <div className="lesson-item" key={index}>
                <div className="list-header">
                    {item.tenChuong}
                </div>
                <div className="list-content">
                    {item.thongTinBaiHoc ? this.renderBaiHoc(item.thongTinBaiHoc) : null}
                </div>
            </div>
        });
    }

    renderBaiHoc = (dsBaiHoc) => {
        return dsBaiHoc.map((item, index) => {
            return <a onClick={() => this.onGoToPlayVideo(item)} key={index}>
                {
                    item.maLoaiBaiHoc === 'VIDEO_FPT' ? <i className="fa fa-play-circle"></i> : <i className="fa fa-question-circle"></i>
                }
                {item.tenBaiHoc}
            </a>
        });
    }

    render() {
        const { khoaHoc } = this.state;
        return (
            <div className="lesson row">
                <div className="col-12 introduce">
                    <div className="icon-box">
                        <i className="fa fa-book"></i>
                    </div>
                    <div className="info">
                        <div className="left">
                            <h5>{khoaHoc.tenKhoaHoc}</h5>
                            <a className="card-text" href={`${DOMAIN_API}${khoaHoc.taiLieu}`} target="_blank" download>
                                <i className="fa fa-download"></i>
                                Tài liệu
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12 lesson-content">
                    <h5>DANH SÁCH BÀI HỌC</h5>
                    <div className="lesson-list">
                        {khoaHoc.thongTinChuongHoc ? this.renderChuongHoc(khoaHoc.thongTinChuongHoc) : null}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        khoaHocProps: state.khoaHocReducer
    }
}

export default connect(mapStateToProps, null)(BaiHocPage);