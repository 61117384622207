import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux";
import history from "../utilities/history";
import { Template } from "../template/Template";
import KhoaHocPage from "./khoa-hoc/KhoaHocPage";
import BaiHocPage from "./bai-hoc/BaiHocPage";
import VideoPage from "./video/VideoPage";
import TrangChuPage from "./trang-chu/TrangChuPage";
import DangNhap from "./dang-nhap/DangNhap";
import "video-react/dist/video-react.css";
import RoadmapPage from "./roadmapPage/RoadmapPage";
import "../index.css";
import RoadmapTaiLieuPage from "./roadmapTaiLieuPage/roadmapTaiLieuPage";
import RoadmapDiemChungNhanPage from "./roadmapDiemChungNhanPage/roadmapDiemChungNhanPage";
import RoadmapThongBaoPage from "./roadmapThongBaoPage/roadmapThongBaoPage";
import RoadmapQAPage from "./roadmapQAPage/RoadmapQAPage";
import RoadmapResponsive from "../template/roadmapResponsive/RoadmapResponsive";
import ResumePage from "./ResumePage/ResumePage";
import RoadMapReportPage from "./roadMapReport/RoadMapReportPage";
import RoadmapLoTrinhChiTiet from "./roadmapLoTrinhChiTiet/roadmapLoTrinhChiTiet";
import ContentChungNhan from "./roadmapPage/UserDashboard/ChungNhanPDF/ContentChungNhan";
import LinkChungNhan from "./roadmapPage/UserDashboard/ChungNhanPDF/linkChungNhan/LinkChungNhan";
import VoucherVinhDanh from "./roadmapPage/UserDashboard/VoucherVinhDanhTop1/VoucherVinhDanh";
import RoadMapMobile from "./roadmapMobile/RoadMapMobile";
import UserMobile from "./roadmapMobile/userMobile/UserMobile";
import XemVideoMobile from "./roadmapMobile/xemVideoMobile/VideoXemTruoc/XemVideoMobile";
import ChiTietLopMobile from "./roadmapMobile/chiTietLopMobile/ChiTietLopMobile";
import ViewXemChungNhan from "./roadmapPage/UserDashboard/ViewXemChungNhan/ViewXemChungNhan";
import XemRecordMobile from "./roadmapMobile/xemVideoMobile/VideoXemRecord/XemRecordMobile";
import ViewXemVinhDanh from "./roadmapPage/UserDashboard/ViewXemChungNhan/ViewXemVinhDanh";
import ViewVideoButton from "./roadmapMobile/xemVideoMobile/ViewVideoBottom";
import { ListBlog } from "./blog/ListBlog";
import Runcode from "../component/Runcode/Runcode";
import TemplateCV from "./ResumePage/TemplateCV/TemplateCV";
import DemoVideo from "./video/DemoVideo";
import UpdateResumePage from "./ResumePage/UpdateResumePage";
import QuanLyCv from "../features/quan-ly-cv/QuanLyCv";
import KhoaHocFreePage from "./khoa-hoc/khoa-hoc-free-page/KhoaHocFreePage";
import { GioiThieu } from "./gioithieu/GioiThieu";
import { ThongKeGioiThieu } from "./gioithieu/ThongKeGioiThieu";
import NhapHoc from "./nhap-hoc/NhapHoc";
import TaoForm from "./nhap-hoc/TaoForm";
import DangNhapMail from "./dang-nhap/DangNhapMail";
import { QuizTestV2 } from "./quiz-test/QuizTestV2";
import StudentProfile from "./hocvien-profile/StudentProfile";
import ResponsiveQR from "./hocvien-profile/ResponseQR";
import DangNhapEmail from "./dang-nhap/DangNhapEmail";
import ProfileNguoiDung from "./Profile/ProfileNguoiDung";
import Challenge from "./challenges/Challenge";

function App() {
  // testMode_Cyber()
  useEffect(() => { }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          
          <Route path="/infor-profile" exact component={ResponsiveQR} />
          <Template path="/course" exact Component={KhoaHocPage} />
          <Template path="/lesson" exact Component={BaiHocPage} />
          <Route path="/video" exact component={VideoPage} />
          <Template path="/home" exact component={TrangChuPage} />
          <Template path="/" exact Component={TrangChuPage} />
          <Route path="/login" exact component={DangNhap} />
          <Route path="/login-email" exact component={DangNhapEmail} />
          <Route path="/login-mail" exact component={DangNhapMail} />
          <Route path="/certification" component={ViewXemChungNhan} />
          <Route path="/honors" component={ViewXemVinhDanh} />
          <Route path="/demo" exact component={DemoVideo} />

          <Route path="/quizizz/:maNhom/:quizId" component={QuizTestV2} />
          <Route path="/quizizz/:maNhom" component={QuizTestV2} />

          <Route path="/referral" component={GioiThieu} />
          <Route path="/thongtin" exact component={NhapHoc} />
          <Route path="/nhaphoc" exact component={TaoForm} />
          <Route path="/refer-chart" exact component={ThongKeGioiThieu} />
          <Route path="/profile/:userId" exact component={ProfileNguoiDung} />
          <Route path="/challenges" exact component={Challenge} />

          <Route
            path="/blog/:page"
            render={() => <RoadmapResponsive Component={ListBlog} />}
          />



          <Route
            path="/user-cv"
            render={() => <RoadmapResponsive Component={ResumePage} />}
          />

          <Route
            path="/quan-ly-cv"
            render={() => <RoadmapResponsive Component={QuanLyCv} />}
          />

          <Route
            path="/run-code/:idLop/:idBuoiHoc/:chuongHoc"
            exact
            component={Runcode}
          />
          <Route
            path="/course-roadmap/:id"
            render={() => (
              <RoadmapResponsive
                Component={RoadmapPage}
                MobileComponent={RoadMapMobile}
              />
            )}
          />
          <Route
            path="/course-detail/:id"
            render={() => (
              <RoadmapResponsive
                Component={RoadmapLoTrinhChiTiet}
                MobileComponent={ChiTietLopMobile}
              />
            )}
          />
          <Route
            path="/course-roadmap-report/:id"
            render={() => <RoadmapResponsive Component={RoadMapReportPage} />}
          />
          <Route
            path="/cau-hoi-thao-luan/:id"
            render={() => <RoadmapResponsive Component={RoadmapQAPage} />}
          />

          <Route
            path="/course-roadmap-diem/:id"
            render={() => (
              <RoadmapResponsive Component={RoadmapDiemChungNhanPage} />
            )}
          />
          <Route
            path="/course-roadmap-tai-lieu/:id"
            render={() => <RoadmapResponsive Component={RoadmapTaiLieuPage} />}
          />
          <Route
            path="/course-free/:id"
            render={() => <RoadmapResponsive Component={KhoaHocFreePage} />}
          />
          <Route
            path="/course-roadmap-thong-bao/:id"
            render={() => <RoadmapResponsive Component={RoadmapThongBaoPage} />}
          />
          <Route path="/voucher-vinh-danh" component={VoucherVinhDanh} />
          <Route
            exact
            path="/home-mobile"
            render={() => (
              <RoadmapResponsive
                Component={RoadmapLoTrinhChiTiet}
                MobileComponent={RoadMapMobile}
              />
            )}
          // component={RoadMapMobile}
          />
          <Route
            path="/user-mobile"
            render={() => (
              <RoadmapResponsive
                Component={RoadmapLoTrinhChiTiet}
                MobileComponent={UserMobile}
              />
            )}
            exact
          />
          <Route
            index
            path="/video-mobile/:id/:buoiHoc/:chuongHoc"
            component={XemVideoMobile}
          />
          <Route
            path="/video-record-mobile/:id/:buoiHoc/:chuongHoc"
            component={XemRecordMobile}
          />
          <Route path="/video-mobile" component={ViewVideoButton} />
          <Route path="/template-cv" component={TemplateCV} />
          <Route path="/update-cv" component={UpdateResumePage} />
          <Template path="*" exact Component={TrangChuPage} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;