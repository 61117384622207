import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import lopHocReducer from "./lopHocReducer";
import khoaHocReducer from "./khoaHocReducer";
import baiTapReducer from "./baiTapReducer";
import { spinnerReducer } from "./spinnerReducer";
import { roadmapReducer } from "./roadMapReducer";
import { nguoiDungReducer } from "./nguoiDungReducer";

export default combineReducers({
  loginReducer,
  lopHocReducer,
  khoaHocReducer,
  baiTapReducer,
  spinner: spinnerReducer,
  roadmapReducer,
  nguoiDungReducer
});
