import React from "react";
import { NavLink } from "react-router-dom";

export default function ListHocVienXuatSac({ listThuHangHocVienTrongLop }) {
  return (
    <div className="overflow-x-auto relative shadow-md rounded-lg flex-grow  h-full">
      <table className="w-full text-base text-gray-500 dark:text-gray-400 h-full text-center card_theme ">
        <thead className="text-base font-thin text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="py-3 font-thin px-6">
              Thứ hạng
            </th>
            <th scope="col" className="py-3 font-thin px-6">
              Tên học viên
            </th>
            {/* <th scope="col" className="py-3 font-thin px-6">
              Điểm trong tháng
            </th> */}
            <th scope="col" className="py-3 font-thin px-6">
              Trang cá nhân
            </th>
          </tr>
        </thead>
        <tbody>
          {listThuHangHocVienTrongLop.map((hocVien) => {
            console.log('hocVien: ', hocVien);
            return (
              <tr className=" border-b " key={hocVien.hoTen}>
                <td className="py-4 px-6 font-medium text-gray-800">
                  {hocVien.thuHangTrongLop}
                </td>
                <th scope="row" className="py-4 px-6 text-gray-800 font-thin ">
                  {hocVien.hoTen}
                </th>
                {/* <td className="py-4 px-6 text-gray-800">
                  {hocVien.diemTrongThang.toFixed(2)}
                </td> */}
                <td className="py-4 px-6 text-gray-800">
                  <NavLink to={`/profile/${hocVien.maNguoiDung}`}>
                  Xem
                  </NavLink>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={2}>
              {listThuHangHocVienTrongLop?.length == 0 && (
                <p className="text-center mt-5">Dữ liệu đang được cập nhật</p>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
