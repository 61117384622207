import React, { useEffect, useRef, useState } from "react";
import RuncodeEditor from "./components/RuncodeEditor/RuncodeEditor";
import ProblemAndSolution from "./components/ProblemAndSolution/ProblemAndSolution";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getDataRuncodeAction } from "../../redux/actions/roadMapAction";
import axios from "axios";
import SpinnerComponent from "../Spinner";
import { set_request_spinner_ended } from "../../redux/actions/spinnerAction";
import "./runcode.css";
import { RUNCODE_SUBMIT } from "../../redux/types";
import SplitPane, { Pane } from "react-split-pane";
import { Tag } from "antd";
import UserGuilde from "./components/UserGuilde/UserGuilde";

export default function Runcode({}) {
  const { dataRuncode } = useSelector((state) => state.roadmapReducer);
  const dispatch = useDispatch();
  const { idLop, idBuoiHoc, chuongHoc } = useParams();
  const [questionNumber, setQuestionNumber] = useState(0);
  const [question, setQuestion] = useState(null);
  const [disabledButton, setDisabledButton] = useState(true);
  const thoiGianBatDau = useRef(null);
  const levelClassName = {
    easy: "bg-green-700 text-green-200 border-none px-3 py-1 text-xs rounded-2xl capitalize",
    medium:
      "bg-yellow-700 text-yellow-200 border-none px-3 py-1 text-xs rounded-2xl capitalize",
    hard: "bg-red-700 text-red-200 border-none px-3 py-1 text-xs rounded-2xl capitalize",
  };
  const increaseQuestionNumber = () => {
    // console.log("first");
    if (questionNumber === dataRuncode.length) return;
    setQuestionNumber(questionNumber + 1);
    dispatch({
      type: RUNCODE_SUBMIT,
      payload: [],
    });
  };
  const decreaseQuestionNumber = () => {
    if (questionNumber === 0) return;
    setQuestionNumber(questionNumber - 1);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);
  // console.log(question);
  useEffect(() => {
    dispatch(getDataRuncodeAction(idLop, idBuoiHoc, chuongHoc));
  }, [idLop, idBuoiHoc]);

  useEffect(() => {
    // dispatch({
    //   type: RUNCODE_SUBMIT,
    //   payload: [],
    // });
    if (dataRuncode.length > 0) {
      const getQuestion = async () => {
        try {
          const question = await axios({
            method: "GET",
            url: `https://runcode.cyberlearn.vn/getQuestionID/${dataRuncode[questionNumber].noiDung}`,
            // url: `https://runcode.cyberlearn.vn/getQuestionID/421`,
          });
          thoiGianBatDau.current = new Date();
          // console.log(thoiGianBatDau.current);
          setQuestion(question.data);
        } catch (error) {
          dispatch(set_request_spinner_ended());
        }
      };
      getQuestion();
      setDisabledButton(true);
      dispatch(set_request_spinner_ended());
    }
    if (dataRuncode[questionNumber]?.diem === 100) {
      setDisabledButton(false);
    }
  }, [questionNumber, dataRuncode]);

  return (
    <>
      <SpinnerComponent />
      <div
        className="bg-theme-runcode h-screen min-h-screen pb-10 space-y-5 px-4"
        id="layoutRuncode"
      >
        <div className="shadow h-16 flex items-center justify-between border-dark_mode_light border-b">
          <div>
            <Link to={`/course-detail/${idLop}`} className="text-white">
              <i className="fa-solid fa-arrow-left-long mr-1"></i> Dashboard
            </Link>
          </div>
          <div className="space-x-4 flex justify-center items-center">
            <button
              onClick={decreaseQuestionNumber}
              disabled={questionNumber === 0}
              className="bg-dark-fill-3 rounded px-2 py-1 text-white h-7 w-7"
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <h2 className="text-center text-white text-xl mb-0">
              {question?.vi?.question_title}
            </h2>
            {dataRuncode[questionNumber]?.diem === 100 ? (
              <Tag className={levelClassName["easy"]}>Đã hoàn thành</Tag>
            ) : (
              <Tag className={levelClassName["hard"]}>Chưa hoàn thành</Tag>
            )}
            {dataRuncode.length !== questionNumber + 1 && (
              <button
                disabled={disabledButton}
                onClick={increaseQuestionNumber}
                className={`bg-dark-fill-3 rounded px-2 py-1 text-white h-7 w-7 ${
                  disabledButton ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            )}
          </div>
          <div className="flex justify-center items-center space-x-4">
            {/* <Link to="/course-detail/:id">Quay trở về Dashboard</Link> */}
            <p className="text-white mb-0 text-lg">
              Câu số {questionNumber + 1}/{dataRuncode.length}
            </p>
            <UserGuilde />
          </div>
        </div>
        {/* <div className="content flex gap-5 pb-10"></div> */}
        <div className="content">
          <SplitPane
            split="vertical"
            defaultSize="50%"
            minSize={500}
            maxSize={1000}
          >
            <div className="h-full overflow-y-scroll">
              {question && (
                <RuncodeEditor
                  increaseQuestionNumber={increaseQuestionNumber}
                  disabledButton={disabledButton}
                  setDisabledButton={setDisabledButton}
                  idCauHoi={dataRuncode[questionNumber]}
                  question={question}
                  thoiGianBatDau={thoiGianBatDau.current}
                  idLop={Number(idLop)}
                  idBuoiHoc={idBuoiHoc}
                  idBaiHoc={dataRuncode[questionNumber].id}
                  chuongHoc={chuongHoc}
                  isShowNextQuestion={dataRuncode.length !== questionNumber + 1}
                />
              )}
            </div>
            <div className="h-full">
              {question && <ProblemAndSolution question={question} />}
            </div>
          </SplitPane>
        </div>
      </div>
    </>
  );
}
