import React, { useEffect, useState } from "react";
import vinhDanhTheme from "./../../../../assets/images/vinhDanhXuatXac.jpg";
import "./VinhDanh.css";
import moment from "moment";
export default function VinhDanh({ chungNhanId }) {
  const [chungNhan, setChungNhan] = useState();

  function decodeObject(encodedString) {

    let newEncodedString = encodedString.replace(" ", "+");


    // Decode the base64-encoded string to a UTF-8 string
    const decoded = atob(newEncodedString);
    // Decode the UTF-8 string to an object
    return JSON.parse(decodeURIComponent(escape(decoded)));
  }
  useEffect(() => {
    setChungNhan(decodeObject(chungNhanId));
  }, []);
  return (
    <div className="relative w-full">
      <img className="mx-auto" width="100%" src={vinhDanhTheme} alt="" />
      <h2 className="vinhDanh_username absolute">{chungNhan?.hoTen}</h2>
      <h3 className="vinhDanh_course">{chungNhan?.tenLoTrinh}</h3>
      <h3 className="vinhDanh_month w-max">
        {moment(chungNhan?.ngayCap).format("MM")}
      </h3>
      <h3 className="vinhDanh_year w-max">
        {moment(chungNhan?.ngayCap).format("YYYY")}
      </h3>
    </div>
  );
}
