import { BaseService } from "./baseService";
import axios from "axios";
var token = "";

const setToken = () => {
  if (localStorage.getItem("LOGIN_LOCASTORE")) {
    let user = JSON.parse(localStorage.getItem("LOGIN_LOCASTORE"));

    token = user.token;
  }
};
const getDetailRoadMap = (id) => {
  return BaseService.get(`api/buoihoc/lay-danh-sach-buoi-hoc-theo-lop/${id}`);
};
const getListTracNghiemAdmin = (id) => {
  return BaseService.get(`api/baihocnew/lay-du-lieu-trac-nghiem`);
};

const postQuizzWrite = (data) => {
  return BaseService.post(`api/buoihoc-nguoidung/nop-bai-tap`, data);
};
const postQuiz = (data) => {
  return BaseService.post(`api/buoihoc-nguoidung/nop-trac-nghiem`, data);
};
const postCapstone = (data) => {
  return BaseService.post(`api/buoihoc-nguoidung/nop-capstone`, data);
};
const themDuAn = (data) => {
  return BaseService.post(`api/buoihoc-nguoidung/them-du-an`, data);
};
const getCaps = () => {
  return BaseService.get(`api/baihocnew/capstone`);
};

const postTrackingXemTaiLieu = (data) => {
  return BaseService.post(
    `/api/tracking-nguoi-dung/tracking-xem-tai-lieu`,
    data
  );
};

const postTrackingXemTaiLieuExtra = (data) => {
  return BaseService.post(
    `/api/tracking-nguoi-dung/tracking-xem-tai-lieu-extra`,
    data
  );
};

const postTrackingXemBaiTapExtra = (data) => {
  return BaseService.post(
    `/api/tracking-nguoi-dung/tracking-xem-bai-tap-extra`,
    data
  );
};

const postTrackingXemVideoXemTruoc = (data) => {
  return BaseService.post(
    `/api/tracking-nguoi-dung/tracking-xem-video-xem-truoc`,
    data
  );
};

const postTrackingXemVideoXemLai = (data) => {
  return BaseService.post(
    `/api/tracking-nguoi-dung/tracking-xem-video-xem-lai`,
    data
  );
};

const getThongBaoNo = (maLop) => {
  return BaseService.get(`/api/hocphi/lay-no-hoc-phi/${maLop}`);
};

const postKhoKhanHocVien = (maLop, data) => {
  return BaseService.post(
    `/api/thongtinhoctaphocvien/cap-nhat-kho-khan/${maLop}`,
    data
  );
};

const getTaskRoadMap = (id) => {
  // setToken();
  return BaseService.get(`/api/crmtask/get-task-hoc-vien/${id}`);
  // return axios({
  //   method: "GET",
  //   url: `https://backtest.cyberlearn.vn/api/crmtask/get-task-hoc-vien/${id}`,
  //   responseType: "json",
  //   responseEncoding: "utf8",
  //   headers: {
  //     apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
  //     Authorization: "Bearer " + token,
  //     "Content-Type": "application/json",
  //     token: token,
  //   },
  // });

  // return BaseService.get(`api/crmtask/get-task-hoc-vien/${id}`);
};

const postCheckHoanThanhTaskRoadMap = (data) => {
  // setToken();
  // return axios({
  //   method: "POST",
  //   url: `https://backtest.cyberlearn.vn/api/crmtask/check-task-hoc-vien`,
  //   data: data,
  //   responseType: "json",
  //   responseEncoding: "utf8",
  //   headers: {
  //     apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
  //     Authorization: "Bearer " + token,
  //     "Content-Type": "application/json",
  //     token: token,
  //   },
  // });
  return BaseService.post(`/api/crmtask/check-task-hoc-vien`, data);
};

const upDiemRuncodeHocVien = (data) => {
  return BaseService.post(`/api/buoihoc-nguoidung/check-hoc-phi`, data);
};

export const RoadmapService = {
  getThongBaoNo,
  getDetailRoadMap,
  getTaskRoadMap,
  postCheckHoanThanhTaskRoadMap,
  postQuizzWrite,
  postQuiz,
  postCapstone,
  themDuAn,
  getCaps,
  getListTracNghiemAdmin,
  postTrackingXemTaiLieu,
  postTrackingXemTaiLieuExtra,
  postTrackingXemBaiTapExtra,
  postTrackingXemVideoXemTruoc,
  postTrackingXemVideoXemLai,
  postKhoKhanHocVien,
  upDiemRuncodeHocVien,
};
/**
 * /api/hocphi/lay-no-hoc-phi/{maLop}/{Authorization}

 */
