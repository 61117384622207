import React from "react";
import SubSider from "./SubSider";

export default function SiderKhoaHocLienQuan({ data = [] }) {
  return (
    <div className="h-full overflow-y-scroll w-96 flex-shrink-0 bg-gray-100">
      {/* <p className="text-black font-normal px-4 text-base">
        Danh sách khoá học liên quan
      </p> */}
      {data?.map((course) => {
        return <SubSider key={course.id} course={course} />;
      })}
    </div>
  );
}
