import { Button, message, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getDataRoadMapServAction,
  getTaskRoadMapServAction,
} from "../../../../../../../redux/actions/roadMapAction";
import { RoadmapService } from "../../../../../../../service/roadmapService";
import { LocalStorageUtil } from "../../../../../../../utilities/localStorage";
import { initBaiTapTracNghiem } from "../../../../../../../utilities/roadmap";
import { LOAI_TASK_TRACNGHIEM } from "../../../../../../../redux/types";

export default function Navigate_Footer_Pratices({
  current,
  total,
  handleClickNextQuestion,
  isDisableBtn,
  listQuestion,
  dataKhoaHoc,
  setIsShowResult,
  forceRerender,
}) {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  const { id } = useParams();
  const dispatch = useDispatch();

  let { dataTaskRoadMap } = useSelector((state) => state.roadmapReducer);

  // const [camCo, setCamCo] = useState(false);
  // const countTaskTrue = (data) => {
  //   let count = 0;
  //   for (let key in data) {
  //     if (data[key].isHoanThanh === true) {
  //       count++;
  //     }
  //   }
  //   if (count === Object.keys(data).length && Object.keys(data).length !== 0) {
  //     dispatch({
  //       type: "SET_CHUCMUNG_XONGTASK",
  //       payload: true,
  //       titleChucMungXongTask: " Chúc mừng bạn đã hoàn thành task trắc nghiệm",
  //     });
  //   }
  // };
  // useEffect(() => {
  //   if (dataKhoaHoc && camCo && dataTaskRoadMap.size !== 0) {
  //     countTaskTrue(
  //       dataTaskRoadMap.get(dataKhoaHoc.maBuoiHoc.toString()).danhSachTracNghiem
  //     );
  //   }
  // }, [camCo, dataTaskRoadMap]);

  // console.log(dataKhoaHoc);
  const handle_PostKetQua = () => {
    let baiTap = initBaiTapTracNghiem;
    baiTap.maBuoiHoc = dataKhoaHoc.maBuoiHoc;
    baiTap.maBaiHoc = dataKhoaHoc.id;
    baiTap.maLop = id * 1;
    baiTap.maNguoiDung = nguoiDung.id;
    baiTap.baiLam = listQuestion.map((item) => {
      if (item.maLoaiBaiTap === "SINGLE") {
        return {
          cauHoiId: item.id,
          traLoi: item.userAnsers,
          maLoaiBaiTap: item.maLoaiBaiTap,
        };
      } else if (item.maLoaiBaiTap == "fill_input") {
        return {
          cauHoiId: item.id,
          traLoi: item.userAnsers.length > 0 ? item.userAnsers[0] : "",
          maLoaiBaiTap: item.maLoaiBaiTap,
        };
      } else {
        let newArrayAnser = item.userAnsers.map((item) => item * 1);
        let stringArray = `[${newArrayAnser.join(",")}]`;
        return {
          cauHoiId: item.id,
          traLoi: stringArray,
          maLoaiBaiTap: item.maLoaiBaiTap,
        };
      }
    });

    RoadmapService.postQuiz(baiTap)
      .then((res) => {
        //
        message.success("Nộp bài tập thành công");
        // setScoreQuiz(diemQuizz);
        dispatch(getDataRoadMapServAction(id));
        // forceRerender();
        // setCamCo(!camCo);
        setIsShowResult(true);
      })
      .catch((err) => {});
  };
  // build

  const handleClickNext = () => {
    return !isDisableBtn ? handleClickNextQuestion() : null;
  };
  const percent = Math.floor((current / total) * 100);
  const nextBtnCss = isDisableBtn
    ? " text-gray-600 bg-gray-300 cursor-not-allowed"
    : "  bg-blue-theme text-white ";
  return (
    <div className="  flex items-center h-16 w-full justify-center space-x-10 px-16 border-none rounded-2xl flex-shrink-0 bg-gray-100">
      <div className="flex items-cente space-x-5 justify-center  w-full">
        <Progress
          step={total}
          percent={percent}
          className="w-full"
          showInfo={false}
          strokeWidth={15}
          strokeColor={{
            "0%": "#4A00E0",
            "100%": "#8E2DE2",
          }}
          trailColor={"rgba(68, 66, 178, 0.1)"}
        />
        <span className="font-bold flex-shrink-0 text-color-title">
          {current}/{total} câu
        </span>
      </div>

      <Button
        onClick={() => {
          return current === total ? handle_PostKetQua() : handleClickNext();
        }}
        className={
          "  text-white duration-150 font-bold px-8 rounded  flex items-center h-10 flex-shrink-0 border-none  focus:border-blue-theme hover:border-transparent hover:shadow-lg" +
          nextBtnCss
        }
        // loading={loading}
      >
        {current == total ? "Hoàn thành" : " Câu tiếp theo"}
      </Button>
    </div>
  );
}
