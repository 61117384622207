import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton
} from "react-share";
import VinhDanh from "./VinhDanh";
import { Tooltip, Result, Modal } from "antd";
import { DOMAIN_MAIN } from "../../../../config/urlConfig";
import bgImage from '../../../../assets/images/background.png';
import { VoucherUser } from "./VoucherUser";
import { LocalStorageUtil } from '../../../../utilities/localStorage';

export default function ViewXemVinhDanh() {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  const [mask, setMask] = useState({
    maskDark: {

      background: 'rgba(0, 0, 0, 0.8)',
      width: '100%',
      height: '100%',
      position: 'fixed',
      zIndex: '49',

    },
    shareDark: {

      background: 'rgb(255,255,255,0.5)',
      padding: '5px',
      borderRadius: '10px'
    },
    visibleTooltip: true
  })

  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let id = params.get('inf');

  let urlShare = `${DOMAIN_MAIN}/honors/?inf=${id}`;

  return (
    <>
      <div style={mask.maskDark} onClick={() => setMask({
        maskDark: null,
        shareDark: null,
        visibleTooltip: false,
      })}></div>
      <div
        className="w-full"
        style={{
          background: `url(${bgImage}) `,
          backgroundSize: 'contain',
        }}
      >

        <div className="social_share fixed bottom-5 right-4 z-50 w-max " style={mask.shareDark}>
          <div className="share mb-3 gap-3 flex flex-col ">
            <Tooltip placement="topLeft" title="Khoe ngay phần thưởng với bạn bè bạn nhé." visible={mask.visibleTooltip}>
              <FacebookShareButton
                url={urlShare}
                quote="Chia sẻ lên Facebook "
              >
                <div className="rounded-full bg-blue-600 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-700 cursor-pointer">
                  <i className="fa-brands fa-facebook text-white text-2xl"></i>
                </div>
              </FacebookShareButton>
            </Tooltip>
            <FacebookMessengerShareButton
              url={urlShare}
              quote="Chia sẻ lên Messenger"
            >
              <div className="rounded-full bg-blue-600 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-700 cursor-pointer">
                <i className="fa-brands fa-facebook-messenger text-white text-2xl"></i>
              </div>
            </FacebookMessengerShareButton>
            <TwitterShareButton
              url={urlShare}
            >
              <div className="rounded-full bg-blue-500 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-600 cursor-pointer">
                <i className="fa-brands fa-twitter text-white text-2xl"></i>
              </div>
            </TwitterShareButton>
            <TelegramShareButton url={urlShare} quote="Chia sẻ lên Telegram">
              <div className="rounded-md bg-blue-700 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-800 cursor-pointer">
                <i className="fa-brands fa-telegram text-white text-xl"></i>
              </div>
            </TelegramShareButton>

            {nguoiDung && <VoucherUser id={id} />}
            
          </div>
        </div>
        <VinhDanh chungNhanId={id} />
      </div>
    </>

  );
}
