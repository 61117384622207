import React, { useState } from 'react';
import { Upload, Button, Icon } from 'antd';
import './UploadField.css';
import { URL_API_BASE } from '../utilities/UrlConfig';

const URL_UPLOAD = `${URL_API_BASE}/api/file/cmnd`;

export default function UploadField({ required, onChange, label, value, ...props }) {

    const { name } = props;

    const [fileList, setFileList] = useState([]);
    const [message, setMessage] = useState('');

    const onChangeHandle = ({ file, fileList }) => {
        if (file.status !== 'uploading') {
            if (fileList.length > 0) {
                onChange(name, file.response, true);
                setMessage('');
            }
            else {
                onChange(name, '', false);
                setMessage('Tệp tin chưa được upload!');
            }
            setFileList(fileList);
        }
    }

    return (
        <div className="upload-field">
            <div className="title">
                <label>{label}</label>
                <span>{required ? '*' : ''}</span>
            </div>
            <div className="clearfix">
                <Upload
                    accept='.jpg,.jpeg,.png'
                    {...props}
                    listType="picture-card"
                    action={URL_UPLOAD}

                    defaultFileList={[...fileList]}
                    onChange={onChangeHandle}
                >

                    {fileList.length > 0 ? null : <> <Icon type="plus" /> Upload</>}

                </Upload>

            </div>
            {
                message.length > 0 ? <small>{message}</small> : null
            }
        </div >
    )
}
