import React from "react";
import { QRCodeCanvas } from 'qrcode.react';
import { LocalStorageUtil } from "../../utilities/localStorage";
import { DOMAIN_MAIN } from "../../config/urlConfig";
import logo from '../../assets/images/logoCyber.png'; // Đường dẫn đến logo
import { useLocation } from "react-router-dom";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import { CodeSandboxOutlined } from "@ant-design/icons";


const QRcodeMini = ({width,code}) => {
  const url = `${DOMAIN_MAIN}/infor-profile?code=${code}`;
  console.log('url: ', url);


  return (
    <div>
      <QRCodeCanvas
        value={url}
        size={width}
      />
     
    </div>
  );
};

export default QRcodeMini;
