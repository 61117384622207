import React, { Component } from 'react';
import chuky from '../../../assets/images/chuky.png';
import logo from '../../../assets/images/logo_opcacity.png';

import { urlMainPage, urlMainPageOffline } from '../../../config/urlConfig';
import { tinhSoThang } from '../../../utilities/dateTimeFormat';


export default class InBangDiem extends Component {
    layTenBaiTap = (item) => {
        const { nguoiDung, dsLopBaiTap, dsBaiTap } = this.props;

        let lopBaiTap = dsLopBaiTap.find(n => n.id == item.maBaiTap);

        if (lopBaiTap) {
            if (lopBaiTap.maBaiTap == 0) {
                let dUrl = JSON.parse(lopBaiTap.duongDan);
                return dUrl[0];
            } else {
                let baiTap = dsBaiTap.find(n => n.id == lopBaiTap.maBaiTap);
                return baiTap.tenBaiTap;
            }
        }

    }

    render() {
        const { lopHoc, nguoiDung, dsBaiTapNopHocVien } = this.props;
        let dNow = new Date();

        let dtb = 0;
        dsBaiTapNopHocVien.forEach((item, i) => {
            dtb += Number(item.diem);
        })

        dtb = dtb / dsBaiTapNopHocVien.length / 10;

        let xepLoai = "Dưới Trung Bình";

        if (dtb >= 5)
            xepLoai = "Trung Bình";
        if (dtb >= 6)
            xepLoai = "Trung Bình Khá";
        if (dtb >= 7)
            xepLoai = "Khá";
        if (dtb >= 8)
            xepLoai = "Giỏi";
        if (dtb >= 9)
            xepLoai = "Xuất sắc";

        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: "space-between", height: "100%" }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', background: `url(${logo}) center center / 70% no-repeat` }}>
                    <b style={{ fontSize: '20pt', textAlign: 'center', width: '100%' }}>
                        CyberSoft - Đào tạo chuyên gia lập trình
                    <br />
                    BẢNG ĐIỂM
                </b>
                    <table style={{ margin: '0 20px' }}>
                        <tbody><tr >
                            <td ><u>Họ tên:</u> <b> {nguoiDung.hoTen}</b></td>
                            <td ><u>Thời gian học:</u> <b>
                                {lopHoc && tinhSoThang(new Date(lopHoc.ngayBatDau), new Date(lopHoc.ngayKetThuc))} tháng
                            </b></td>
                        </tr>
                            <tr>
                                <td><u>Lớp:</u><b> {lopHoc.tenLopHoc}</b> </td>
                                <td><u>Hình thức học:</u><b> Offline trực tiếp</b> </td>
                            </tr>
                        </tbody></table>
                    <table border={1} style={{ margin: '20px' }}>
                        <tbody><tr style={{ textAlign: "center", fontWeight: "bold" }}>
                            <td style={{ padding: 10 }}>STT</td>
                            <td style={{ padding: 10 }}>Tên bài tập/dự án </td>
                            <td style={{ padding: 10 }}>Điểm</td>
                            <td style={{ padding: 10 }}>Hệ 10</td>
                        </tr>
                            {
                                dsBaiTapNopHocVien.map((item, index) =>
                                (<tr >
                                    <td style={{ padding: 10, textAlign: "center" }}>
                                        {index + 1}
                                    </td>
                                    <td style={{ padding: 10 }}>
                                        {this.layTenBaiTap(item)}
                                    </td>
                                    <td style={{ padding: 10, textAlign: "center" }}>{Number(item.diem)}</td>
                                    <td style={{ padding: 10, textAlign: "center" }}>{Number(item.diem) / 10}</td>
                                </tr>
                                ))
                            }
                        </tbody></table >
                    <table style={{ margin: '20px ' }}>
                        <tbody><tr >
                            <td style={{ fontSize: '20pt', fontWeight: 'bold' }} width="35%">Điểm trung bình toàn khóa: {dtb.toFixed(1)}</td>
                        </tr>
                            <tr>
                                <td style={{ fontSize: '20pt', fontWeight: 'bold' }}> Xếp loại: {xepLoai}</td>
                            </tr>
                        </tbody></table>
                    <table style={{ textAlign: 'center' }}>
                        <tbody><tr>
                            <td style={{ width: '50%' }}> </td>
                            <td style={{ fontSize: '20pt' }}>
                                Ngày {dNow.getDate()} tháng {dNow.getMonth()+1} năm {dNow.getFullYear()}
                                <br />
                                <br />
                                <b style={{ fontSize: '20pt', padding: "0 20px" }}>GIÁM ĐỐC ĐIỀU HÀNH</b>
                                <br />
                                <br />
                                <img src={chuky} width="30%" />
                                <br />
                                <b style={{ fontSize: '20pt' }}> Lê Quang Song</b>
                            </td>
                        </tr>
                        </tbody></table>


                </div >

                <div style={{ padding: 20, display: "flex", justifyContent: "space-around" }}>
                    <span>
                        {urlMainPageOffline}
                    </span>
                    <span>
                        {urlMainPage}
                    </span>


                </div>
            </div>

        )
    }
}
