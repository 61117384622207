import React, { useEffect } from "react";
import Navbar from "../../template/navbar/Navbar";
import SidebarNew from "../../template/SidebarNew/sidebarNew";
import backGrountTemplate from "../../assets/images/background.png";
import ContentResume from "./ContentResume";
import ContentResumeV2 from "./ContentResumeV2";
import { LocalStorageUtil } from "../../utilities/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { layUserCV } from "../../redux/actions/nguoiDungAction";
import { UpdateInfo } from "./UpdateInfo";
import { UpdateInfoV2 } from "./UpdateInfoV2";
import { message } from "antd";
import history from "../../utilities/history";
import * as types  from '../../redux/types'

export default function UpdateResumePage() {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  

  return (
    <div
      style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
      className="h-max-content min-h-screen w-full  flex"
    >
      <div className="w-60">
        <SidebarNew />
      </div>
      <div
        style={{ flexGrow: "1" }}
        className="h-max-cotent flex flex-col  min-h-screen "
      >
        <Navbar />
        <div className=" w-full bg-cover  space-y-2 chung-nhan h-{100vh}">
        <UpdateInfoV2 nguoiDung={nguoiDung} />
        </div>
      </div>
    </div>
  );
}
