import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./pages/App";
import "font-awesome/css/font-awesome.min.css";
import "antd/dist/antd.css";
import { version } from "../package.json"
import * as serviceWorker from "./serviceWorker";


//Kiểm tra version lưu ở local
const versionLocalStorage = localStorage.getItem('version');

//Nếu có lưu version
//Nếu không có lưu thì cũng load lại 1 lần để đảm bảo clear cache trang cũ

if (!versionLocalStorage || versionLocalStorage !== version) {
    localStorage.setItem('version',version);
    window.location.reload();
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
