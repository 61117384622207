import React, { useState } from "react";
import { DOMAIN_API_FILE } from "../../../config/urlConfig";
import { Modal } from "antd";

export default function TaiLieuTheoSkill({ data }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState();

  const handleButtonClick = (item) => {
    setItem(item)
    if (!item.noiDung) {
      setIsModalOpen(true); // Mở modal nếu nội dung rỗng
    } else {
      window.open(`${DOMAIN_API_FILE}/${item.noiDung}`, '_blank'); // Mở tài liệu nếu có
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=" w-full rounded-lg card_theme p-3">
      <p className="p-0 text-black">{data.tenSkill}</p>
      <div className="space-y-4">
        {data.danhSachBaiHoc.map((item, index) => {
          return (
            <div
              key={index}
              className=" w-full rounded card_theme p-3 space-x-5 flex items-center justify-between"
            >
              <span className="text-black">{item.tieuDe}</span>
              <button
        className="flex items-center h-max-content rounded p-1 border-gray-600 w-max flex-shrink-0 border-1 text-color-title cursor-pointer hover:text-gray-900 px-2 transform duration-300 hover:border-gray-500"
        onClick={() => { 
          handleButtonClick(item)
         }}
      >
        <i className="fa fa-folder-open"></i>
        <span>Tài nguyên</span>
        <i className="fa fa-download"></i>
      </button>


            </div>
          );
        })}
      </div>
      {isModalOpen &&       <Modal
      title="Thông tin tài  liệu "
        visible={isModalOpen}
        onCancel={closeModal}
        width={'80%'}
        footer={null}
        className="modelExtra"
        style={{width:'50%'}}
      >
        {item.ghiChu&& <p><strong>Ghi chú:</strong ><span dangerouslySetInnerHTML={{__html:item.ghiChu}}></span> </p>}
        {item.moTa&&<p><strong>Mô tả:</strong> <span dangerouslySetInnerHTML={{__html:item.moTa}}></span></p>}
      </Modal>}
          </div>
  );
}
