import React, { useEffect, useState } from 'react'
import Navbar from '../../template/navbar/Navbar'
import SidebarNew from '../../template/SidebarNew/sidebarNew'
import backGrountTemplate from "../../assets/images/background.png";
import { useMeasure } from 'react-use';
import Axios from 'axios';
import HtmlParser from "react-html-parser";
import './gioithieu.css'
import { Badge, Divider, Pagination, Tabs, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import history from '../../utilities/history';
import { NguoiDungSevice } from '../../service/nguoiDungService';
import { LocalStorageUtil } from '../../utilities/localStorage';
import { FacebookMessengerShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton } from 'react-share';

export const GioiThieu = () => {

    let [noiDung, setNoiDung] = useState("");
    let [dsGioiThieu, setDsGioiThieu] = useState("");
    let param = useParams();
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    const currentUrl = new URL(window.location.href);
    const origin = currentUrl.origin;
    useEffect(() => {

        NguoiDungSevice.laySanPhamBanService().then(res => {


            setNoiDung(res.data.content)


        })
        let codeRefer = splitEmail(nguoiDung.email)
        NguoiDungSevice.layGioiThieuService(codeRefer).then(res => {

            setDsGioiThieu(res.data.content)

        })

    }, [])

    const splitEmail = (email) => {
        const atIndex = email.indexOf('@');
        if (atIndex === -1) return ''; // Nếu không tìm thấy '@', trả về chuỗi rỗng

        const partBeforeAt = email.slice(0, atIndex);
        const dotIndex = email.indexOf('.', atIndex);

        if (dotIndex !== -1) {
            return email.slice(0, dotIndex);
        } else {
            return partBeforeAt;
        }
    }

    const demGioiThieu = (sanPhamId, chot) => {
        let dem = [];

        if (dsGioiThieu)
            dem = dsGioiThieu?.filter(n => n.sanPhamId == sanPhamId);

        if (chot) {
            dem = dem.filter(n => n.daChot == true)
        }


        return dem.length
    }

    const tinhTien = (sanPhamId, tongTien, chietKhau, chot) => {

        let dem = demGioiThieu(sanPhamId, chot)

        let tongCong = dem * (tongTien * chietKhau / 100)

        return tongCong.toLocaleString();

    }

    const thongKeTong = (getTien, chot) => {
        let dem = 0;
        let tongCong = 0;
        if (noiDung) {
            noiDung.map((item, index) => {
                let demRieng = demGioiThieu(item.id, chot)
                dem += demRieng;
                tongCong += demRieng * (item.tongTien * item.phanTramChietKhau / 100)

            })
        }

        return getTien ? tongCong.toLocaleString() : dem
    }
    const items = [
        {
            key: '1',
            label: 'Tab 1',
            children: 'Content of Tab Pane 1',
        },
        {
            key: '2',
            label: 'Tab 2',
            children: 'Content of Tab Pane 2',
        },
        {
            key: '3',
            label: 'Tab 3',
            children: 'Content of Tab Pane 3',
        },
    ];
    return (
        <div
            style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
            className="h-max-content min-h-screen w-full  flex gioiThieu"
        >

            <div className="w-60">
                <SidebarNew />
            </div>
            <div
                style={{ flexGrow: "1" }}
                className="h-max-cotent flex flex-col  min-h-screen "
            >
                <Navbar />

                <div className=' p-3'>


                    <Divider />
                    <b className='h5'> Sản phẩm giới thiệu
                        <Tooltip trigger="click" title={
                            <span className='font-thin'>

                                Chào {nguoiDung.hoTen}! CyberSoft vui mừng mời bạn tham gia vào chương trình đặc biệt với mã giới thiệu độc quyền.
                                <br />
                                Khi bạn đưa mã <b>MÃ GIỚI THIỆU</b> cho bạn bè đăng ký khóa học mới, bạn sẽ được hưởng lợi % chiết khấu trên mỗi sản phẩm bạn giới thiệu thành công của mình.
                                <br />
                                Đây là lời cảm ơn của chúng tôi dành cho bạn vì đã quyết định tham gia và trải nghiệm những gì tốt nhất mà chúng tôi có thể cung cấp.
                            </span>
                        }>
                            : <i className='fa fa-question-circle cursor-pointer'></i>
                        </Tooltip>
                    </b>

                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Cybersoft" key="1">
                            <div className='row gap-10'>


                                {noiDung && noiDung.map((item, index) => {

                                    if (item.metaData == "Cybersoft") {
                                        return <div key={index} className="col-2 col-lg-2 col-md-4 col-sm-6 card_theme p-2 mt-3 flex flex-col justify-between"  >
                                            <a className='text-black' href={item.linkMoTa} target='_blank'>
                                                <Badge count="NEW">
                                                    <img src={item.hinhAnh} />
                                                </Badge>
                                                <span className='text-pink-700 font-bold'>Kiếm {item.phanTramChietKhau == 0 ? (item.tienThuong * 1).toLocaleString() : (item.tongTien * item.phanTramChietKhau / 100).toLocaleString()} đ</span>
                                                <br />

                                                <b>{item.tongTien.toLocaleString()} đ</b>
                                                <br />

                                                <span>{item.tenSanPham}</span>

                                            </a>

                                            <div className='flex justify-between'>
                                                <Tooltip trigger="click" title="Đã copy">
                                                    <button className='btn btn-primary btn-sm' onClick={() => {
                                                        navigator.clipboard.writeText(`${item.metaData == "Cybersoft" ? 'https://login.cybersoft.edu.vn/thongtin' : 'https://login.codezuni.com/signup-user'}?code=${splitEmail(nguoiDung?.email)}&linkMoTa=${encodeURIComponent(item.linkMoTa)}`);
                                                    }}> Lấy mã giới thiệu</button>
                                                </Tooltip>

                                                <Tooltip title={<div>
                                                    <p className='text-center'>Chia sẽ đến</p>



                                                    <FacebookShareButton

                                                        url={`${item.metaData == "Cybersoft" ? 'https://login.cybersoft.edu.vn/thongtin' : 'https://login.codezuni.com/signup-user'}?code=${splitEmail(nguoiDung?.email)}&linkMoTa=${encodeURIComponent(item.linkMoTa)}`}

                                                        quote="Chia sẻ lên Facebook "
                                                        hashtag="#Cybersoft"

                                                    >
                                                        <div className="rounded-full bg-blue-500 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-600 cursor-pointer">
                                                            <i className="fa-brands fa-facebook text-white text-2xl"></i>
                                                        </div>
                                                    </FacebookShareButton>
                                                    <span className='mx-2'>
                                                        <TwitterShareButton
                                                            url={`${item.metaData == "Cybersoft" ? 'https://login.cybersoft.edu.vn/thongtin' : 'https://login.codezuni.com/signup-user'}?code=${splitEmail(nguoiDung?.email)}&linkMoTa=${encodeURIComponent(item.linkMoTa)}`}
                                                            hashtags='#Cybersoft'
                                                        >
                                                            <div className="rounded-full bg-blue-500 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-600 cursor-pointer">
                                                                <i className="fa-brands fa-twitter text-white text-2xl"></i>
                                                            </div>
                                                        </TwitterShareButton>
                                                    </span>

                                                    <TelegramShareButton url={`${item.metaData == "Cybersoft" ? 'https://login.cybersoft.edu.vn/thongtin' : 'https://login.codezuni.com/signup-user'}?code=${splitEmail(nguoiDung?.email)}&linkMoTa=${encodeURIComponent(item.linkMoTa)}`} quote="Chia sẻ lên Telegram" >
                                                        <div className="rounded-md bg-blue-500 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-600 cursor-pointer">
                                                            <i className="fa-brands fa-telegram text-white text-xl"></i>
                                                        </div>
                                                    </TelegramShareButton>

                                                </div>} trigger="click">
                                                    <button className='btn btn-outline-primary btn-sm' onClick={() => {
                                                        navigator.clipboard.writeText(splitEmail(nguoiDung?.email));
                                                    }}> <i className='fa fa-share'></i></button>
                                                </Tooltip>
                                            </div>

                                        </div>
                                    }



                                })}

                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Codezuni" key="2">
                            <div className="row gap-10">
                                {noiDung && noiDung.map((item, index) => {

                                    if (item.metaData == "Mentor11") {
                                        return <div key={index} className="col-2 col-lg-2 col-md-4 col-sm-6 card_theme p-2 mt-3 flex flex-col justify-between"  >
                                            <a className='text-black' href={item.linkMoTa} target='_blank'>
                                                <Badge count="NEW">
                                                    <img src={item.hinhAnh} />
                                                </Badge>
                                                <span className='text-pink-700 font-bold'>Kiếm {item.phanTramChietKhau == 0 ? (item.tienThuong * 1).toLocaleString() : (item.tongTien * item.phanTramChietKhau / 100).toLocaleString()} đ</span>
                                                <br />

                                                <b>{item.tongTien.toLocaleString()} đ</b>
                                                <br />

                                                <span>{item.tenSanPham}</span>

                                            </a>

                                            <div className='flex justify-between'>
                                                <Tooltip trigger="click" title="Đã copy">
                                                    <button className='btn btn-primary btn-sm' onClick={() => {
                                                        navigator.clipboard.writeText(`${item.metaData == "Cybersoft" ? 'https://login.cybersoft.edu.vn/thongtin' : 'https://login.codezuni.com/signup-user'}?code=${splitEmail(nguoiDung?.email)}&linkMoTa=${encodeURIComponent(item.linkMoTa)}`);
                                                    }}> Lấy mã giới thiệu</button>
                                                </Tooltip>

                                                <Tooltip title={<div>
                                                    <p className='text-center'>Chia sẽ đến</p>



                                                    <FacebookShareButton

                                                        url={`${item.metaData == "Cybersoft" ? 'https://login.cybersoft.edu.vn/thongtin' : 'https://login.codezuni.com/signup-user'}?code=${splitEmail(nguoiDung?.email)}&linkMoTa=${encodeURIComponent(item.linkMoTa)}`}

                                                        quote="Chia sẻ lên Facebook "
                                                        hashtag="#Cybersoft"

                                                    >
                                                        <div className="rounded-full bg-blue-500 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-600 cursor-pointer">
                                                            <i className="fa-brands fa-facebook text-white text-2xl"></i>
                                                        </div>
                                                    </FacebookShareButton>
                                                    <span className='mx-2'>
                                                        <TwitterShareButton
                                                            url={`${item.metaData == "Cybersoft" ? 'https://login.cybersoft.edu.vn/thongtin' : 'https://login.codezuni.com/signup-user'}?code=${splitEmail(nguoiDung?.email)}&linkMoTa=${encodeURIComponent(item.linkMoTa)}`}
                                                        >
                                                            <div className="rounded-full bg-blue-500 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-600 cursor-pointer">
                                                                <i className="fa-brands fa-twitter text-white text-2xl"></i>
                                                            </div>
                                                        </TwitterShareButton>
                                                    </span>

                                                    <TelegramShareButton url={`${item.metaData == "Cybersoft" ? 'https://login.cybersoft.edu.vn/thongtin' : 'https://login.codezuni.com/signup-user'}?code=${splitEmail(nguoiDung?.email)}&linkMoTa=${encodeURIComponent(item.linkMoTa)}`} quote="Chia sẻ lên Telegram">
                                                        <div className="rounded-md bg-blue-500 w-10 h-10 flex items-center justify-center p-1 hover:bg-blue-600 cursor-pointer">
                                                            <i className="fa-brands fa-telegram text-white text-xl"></i>
                                                        </div>
                                                    </TelegramShareButton>

                                                </div>} trigger="click">
                                                    <button className='btn btn-outline-primary btn-sm' onClick={() => {
                                                        navigator.clipboard.writeText(splitEmail(nguoiDung?.email));
                                                    }}> <i className='fa fa-share'></i></button>
                                                </Tooltip>
                                            </div>

                                        </div>
                                    }



                                })}
                            </div>
                        </Tabs.TabPane>
                    </Tabs>

                </div>


            </div >


        </div >
    )
}
