import { SPINNER_ENDED, SPINNER_STARTED } from "../types";

let initialState = {
  count: 0,
  isLoading: false,
};

export const spinnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER_STARTED: {
      state.isLoading = true;
      state.count++;
      return { ...state };
    }

    case SPINNER_ENDED: {
      if (--state.count <= 0) {
        state.isLoading = false;
      }
      return { ...state };
    }
    default:
      return { ...state };
  }
};
