import React, { useEffect, useRef } from 'react'
import { colors } from '../roadmapLoTrinhChiTiet/constant';
import { initBaiTapCapstone } from '../../utilities/roadmap';
import { LocalStorageUtil } from '../../utilities/localStorage';
import { RoadmapService } from '../../service/roadmapService';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

const ModalDuAn = ({ item, close,caps,fecthData }) => {
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    const dispatch = useDispatch()
    let color = colors[0];
    const inputRefSource = useRef();
    const inputRefDeploy = useRef();
    const inputRefYoutube = useRef();
    const inputRefMaBaiHoc= useRef();
    const handleNopBaiTap = (data) => {
        let baiTap = initBaiTapCapstone;
        baiTap.maBuoiHoc = item?item.maBuoiHoc:0;
        baiTap.maBaiHoc = item?item.maBaiHoc:inputRefMaBaiHoc.current.value;
        baiTap.maLop = item?item.maLop:0;
        baiTap.maNguoiDung = nguoiDung.id;
        baiTap.noiDung.linkBai = inputRefSource.current.value;
        baiTap.noiDung.linkDeploy = inputRefDeploy.current.value;
        baiTap.noiDung.linkYoutube = inputRefYoutube.current.value;

        if (
            !inputRefSource.current.value ||
            !inputRefDeploy.current.value ||
            !inputRefYoutube.current.value
        ) {
            message.error("Nội dung nộp không được để rỗng");
            return;
        }
            RoadmapService.themDuAn(baiTap)
                .then((res) => {
                    message.success(item?"Cập nhật thành công":"Thêm dự án thành công");
                    fecthData()
                    close()
                })
                .catch((err) => {
                    console.log(err);
                });
        


    };
    // Sử dụng useEffect để cập nhật giá trị khi item thay đổi
    useEffect(() => {
        if (item) {
            inputRefSource.current.value = item.linkGit;
            inputRefDeploy.current.value = item.linkDeploy;
            inputRefYoutube.current.value = item.linkYoutube;
            inputRefMaBaiHoc.current.value = item.maBaiHoc; // Giả sử item có thuộc tính maBaiHoc
        }
    }, [item]);
    return (
        <div style={{ backgroundColor: color.bg, width: "800px" }}
            className=" p-2 px-3  min-h-22 justify-between flex items-center relative rounded-lg shadow-lg">
            <div className="w-full pl-6 h-full flex flex-col  relative  space-y-3">
                <div className="flex flex-col space-y-2 items-center rounded-lg h-max-content overflow-hidden">
                   <select
                        ref={inputRefMaBaiHoc}
                        type="text"
                        placeholder="Link nộp source"
                        className="rounded w-full h-12  px-3"
                        style={{display:`${item?"none":"block"}`}}
                    >
                        {caps?.map(item=><option key={item.id} value={item.id}>{item.tieuDe}</option>)}
                    </select>
                    <input
                        ref={inputRefSource}
                        type="text"
                        placeholder="Link nộp source"
                        className="rounded w-full h-12  px-3"
                    />
                    <input
                        ref={inputRefDeploy}
                        type="text"
                        placeholder="Link deploy"
                        className="rounded w-full h-12  px-3"
                    />
                    <input
                        ref={inputRefYoutube}
                        type="text"
                        placeholder="Link youtube"
                        className="rounded w-full h-12  px-3"
                    />
                    <button
                        onClick={() => {
                            handleNopBaiTap(item);
                        }}
                        className=" w-24 p-2 rounded bg-blue-theme text-white cursor-pointer"
                    >
                        {item ? "Cập nhật" : "Thêm"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalDuAn