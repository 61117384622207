import { Portal } from "react-portal";

import React, { useEffect, useState } from "react";
import NavHeaderStiky from "./NavHeaderStiky";
import SkillDown from "./SkillDown";
import SkillUp from "./SkillUp";
import "./style.css";
export default function SkillTimeLine({ dataRoadMap }) {
  const [widthContainer, setWidthContainer] = useState(0);
  useEffect(() => {
    var navbar = document.getElementById("skillNav");
    var sticky = navbar.offsetTop;
    function myFunction() {
      if (window.pageYOffset >= sticky + 1100) {
        navbar.classList.add("sticky_container");
        navbar.classList.remove("display-none");
      } else {
        navbar.classList.remove("sticky_container");
        navbar.classList.add("display-none");
      }
    }
    myFunction();
    window.addEventListener("scroll", myFunction);
  }, []);

  useEffect(() => {
    let containerTracNhiem = document.getElementById("containerSkilllNav");
    const handleChangeWidth = () => {
      if (containerTracNhiem) {
        let width = containerTracNhiem.clientWidth;
        setWidthContainer(width);
        let skillNav = document.getElementById("skillNav");
        if (skillNav) {
          skillNav.style.width = `${width}px`;
        }
      }
    };
    setTimeout(() => {
      handleChangeWidth();
    }, 1000);

    window.addEventListener("resize", handleChangeWidth);
    return () => {
      window.removeEventListener("resize", handleChangeWidth);
    };
  }, []);
  //

  return (
    <div id="containerSkilllNav" className="h-max-content w-full card_theme  ">
      <div className=" w-full h-max-content xl:m-auto">
        <p className=" text-center pt-3 font-medium m-0">
          LỘ TRÌNH HỌC THEO SKILL
        </p>

        <Portal>
          <div
            id="skillNav"
            className=" h-max-content  flex-shrink-0  bg-transparent fixed top-0 card_theme  border-none shadow-lg hover:shadow-xl transition duration-200 cursor-pointer right-3  border-0 "
          >
            <div
              // style={{ maxWidth: "1300px" }}
              className=" w-full h-full m-auto flex"
            >
              <NavHeaderStiky
                width={
                  widthContainer /
                  (dataRoadMap.danhSachBuoiHocTheoSkill?.length + 1)
                }
                data={dataRoadMap.danhSachBuoiHocTheoSkill}
              />
            </div>
          </div>
        </Portal>
        <div
          // style={{ maxWidth: "1300px" }}
          className="h-72 xl:h-84 w-full pb-4 flex  m-auto justify-between p-3 "
          // id="navbar"
        >
          {dataRoadMap.danhSachBuoiHocTheoSkill?.map((item, index) => {
            return index % 2 == 0 ? (
              <SkillDown
                width={
                  widthContainer /
                  (dataRoadMap.danhSachBuoiHocTheoSkill?.length + 1)
                }
                data={item}
                key={item.tenSkill}
                total={dataRoadMap.danhSachBuoiHocTheoSkill.length}
              />
            ) : (
              <SkillUp
                // width={
                //   widthContainer /
                //     dataRoadMap.danhSachBuoiHocTheoSkill?.length -
                //   5
                // }
                width={
                  widthContainer /
                  (dataRoadMap.danhSachBuoiHocTheoSkill?.length + 1)
                }
                data={item}
                key={item.tenSkill}
                total={dataRoadMap.danhSachBuoiHocTheoSkill.length}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
