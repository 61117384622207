import { Tag, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import "./record_andt.css";
import ViewXemLaiBuoiHoc from "../../roadmapLoTrinhChiTiet/RoadMapToogleList/CollapseRoadMap/NewItemRoadMap/ViewXemLaiBuoiHoc/ViewXemLaiBuoiHoc";
import { useParams } from "react-router-dom";
import ViewXemRecordThongTin from "../../roadmapLoTrinhChiTiet/RoadMapToogleList/CollapseRoadMap/NewItemRoadMap/ViewXemRecordThongTin/ViewXemRecordThongTin";
export default function ThongTinRecord({
  danhSachTatCaVideoXemLai,
  setTitleModal,
  setContentModal,
  setIsModalVisible,
}) {
  const { id } = useParams();
  // console.log(danhSachTatCaVideoXemLai);

  return (
    <div className="space-y-4 h-full w-full  ">
      {danhSachTatCaVideoXemLai.map((item) => {
        return (
          <div className="card_theme p-3 text-black space-y-3">
            <div className="flex justify-between">
              <div>
                <p className="text-base m-0 ">
                  Buổi {item.soThuTuBuoi} - Tên video : {item.tieuDe}{" "}
                </p>
                <div className="  w-full text-left flex items-center space-x-5  justify-between">
                  <div>
                    <p className="m-0 p-0">
                      Ngày tạo : {moment(item.ngayTao).format("DD/MM/yyyy")}
                    </p>
                    <p className="m-0 p-0">
                      Ngày xoá : {moment(item.ngayXoa).format("DD/MM/yyyy")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center space-y-3">
                {!item.isDaXoa ? (
                  <Tooltip
                    title={
                      <div>
                        <span className="text-black">Video Xem Lại</span>
                      </div>
                    }
                  >
                    <p
                      className="mb-0 w-10 h-10 flex items-center justify-center cursor-pointer bg-blue-theme bg-opacity-80 text-white rounded-md text-lg"
                      onClick={() => {
                        setTitleModal("Xem lại video");
                        setContentModal(
                          <div style={{ height: "80vh", width: "90vw" }}>
                            <ViewXemRecordThongTin
                              maBuoiHoc={item.id}
                              dsXemLai={danhSachTatCaVideoXemLai.find(
                                (itemVideo) => itemVideo.id == item.id
                              )}
                              maLop={id}
                            />
                          </div>
                        );
                        setIsModalVisible(true);
                      }}
                    >
                      <i className="fa-regular fa-closed-captioning"></i>
                    </p>
                  </Tooltip>
                ) : (
                  ""
                )}
                <Tag
                  className="mr-0 inline-block"
                  color={item.isDaXoa ? "red" : "blue"}
                >
                  {item.isDaXoa ? "Đã xoá" : "Vẫn còn"}
                </Tag>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
// {
//     "id": 3367,
//     "tieuDe": "Form",
//     "noiDung": "25-08-2022-08-16-00-2022-08-25-08-48-06.mp4",
//     "maLop": 247,
//     "ngayTao": "2022-08-25T20:47:36",
//     "ngayXoa": "2022-09-08T20:47:36",
//     "soThuTuBuoi": 36,
//     "isDaXoa": false
// }
