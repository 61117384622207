import React, { useEffect, useState } from 'react'
import { Drawer,Tag,Tooltip,message} from "antd";
import { NguoiDungSevice } from '../../service/nguoiDungService';
import moment from 'moment';

const LstGioiThieu = ({id,onClose, openDrawer, code,ngayBd,ngaykt}) => {

    const [lstData,setLstData] = useState([])
    const [all,setAll] = useState(false)
    console.log('all: ', all);
    useEffect(()=>{
        const obj={
            "ngayBatDau": ngayBd.toISOString(),
            "ngayKetThuc": ngaykt.toISOString(),
            "sanPhamBan": id?.id
        }
        if(all){
            NguoiDungSevice.getLstGTById(id?.id,code).then(res=>{
                setLstData(res.data.content) })
            } else{
                NguoiDungSevice.getLstGTByIdTime(code,obj).then(res=>{
                    setLstData(res.data.content)
            })
          }
          return()=>{
            setLstData([])
        }
    },[id,all])
  return (
    <Drawer width={'80%'}
    title="Danh sách giới thiệu"
    placement="right"
    onClose={onClose}
    visible={openDrawer}>
        <h1>{id?.tenSanPham}</h1> <br />
        <p>{all?"* Tất cả giới thiệu":`* Giới thiệu theo thời gian ${moment(ngayBd).format("DD-MM-YYYY")} đến ${moment(ngaykt).format("DD-MM-YYYY")}`}</p>
        <div className="d-flex">
                <button className="btn btn-info" onClick={() => { 
                    setAll(false)
                 }}>Theo thời gian </button>
                <button className='ml-3 btn btn-success' onClick={() => { 
                    setAll(true)
                 }}>All</button>
            </div>
         <div className="row m-3">
            
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Code</th>
                            {/* <th scope="col">Email người giới thiệu</th> */}
                            <th scope="col">Email đăng ký</th>
                            <th scope="col">Trạng thái</th>
                            <th scope="col">Ngày đăng ký</th>
                            <th scope="col">Tiền thưởng</th>
                            <th scope="col"><Tooltip title="Đã đóng học phí lần đầu và lớp đã khai giảng!">
                                    Đã chốt  <i className="fa fa-question-circle-o"></i>
                                </Tooltip></th>
                            <th scope="col">Khai giảng</th>
                            <th scope="col">Thưởng</th>

                        </tr>
                    </thead>
                    <tbody >
                        {lstData && lstData.map((item, index) => {

                            return <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td  >{item.codeRefer}</td>
                                {/* <td  >{item.emailRefer}</td> */}
                                <td className='h5'>{item.emailDangKy}</td>
                                <td className='h5'>{item.daChot?<Tag color='green'  style={{  borderRadius: "10px"}}>Đã hoàn thành</Tag>:<Tag color='red'  style={{  borderRadius: "10px"}}>Chưa hoàn thành</Tag>}</td>
                                
                                <td className=' '>{moment(item.ngayTao).format("DD-MM-YYYY")}</td>
                                <td className=' '>{(item.hocPhiDong*item.chietKhau/100).toLocaleString()}</td>
                                <td className='h5'>{item.daChot?<Tag color='green'  style={{  borderRadius: "10px"}}><i className="fa fa-check"></i></Tag>:<Tag color='red'  style={{  borderRadius: "10px"}}><i className="fa fa-times"></i></Tag>}</td>
                                <td className='h5'>{item.khaiGiang?<Tag color='cyan'  style={{  borderRadius: "10px"}}><i className="fa fa-check"></i></Tag>:<Tag color='red'  style={{  borderRadius: "10px"}}><i className="fa fa-times"></i></Tag>}</td>
                                <td className='h5'>{item.traThuong?<Tag color='blue'  style={{  borderRadius: "10px"}}>Đã nhận</Tag>:<Tag color='red'  style={{  borderRadius: "10px"}}>Chưa nhận</Tag>}</td>
                               
                                </tr>
                                }
                        )}

                    </tbody>
                </table>
                
            </div>
        </Drawer>
  )
}

export default LstGioiThieu