import { message } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { layUserCV } from '../../../redux/actions/nguoiDungAction';
import { NguoiDungSevice } from '../../../service/nguoiDungService';
import { LocalStorageUtil } from '../../../utilities/localStorage';

export const About = ({ thongTinMoRong, dispatchLocal,CONSTANTS }) => {

    let [showInfo, setShowInfo] = useState(false);
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    const dispatch = useDispatch();


    const saveThongTin = () => {
        let gioiThieu = document.querySelector("#gioiThieu").value;
       Promise.all([dispatchLocal({type: CONSTANTS.UPDATE_THONG_TIN_ABOUT, payload: gioiThieu})])
       .then((res) =>{
        setShowInfo(false)
       })
    }

    return (
        <div className="resume_item resume_about">
            <div className="title d-flex justify-content-between">
                <p className="bold"> Summary </p>
                <button className="btn btn-primary" onClick={() => setShowInfo(true)}>Edit</button> 
            </div>
            {showInfo == false ?
                <p>
                    {thongTinMoRong.gioiThieu}
                </p>
                :
                <p>
                    <textarea className="form-control mb-3" id="gioiThieu" rows={5} defaultValue={thongTinMoRong.gioiThieu} />
                    <button className='btn btn-sm btn-success mr-3' onClick={() => saveThongTin()}>Lưu</button>
                    <button className='btn btn-sm btn-outline-secondary' onClick={() => setShowInfo(false)}>Hủy</button>
                </p>
            }
        </div>
    )
}
