import React, { useState, Component, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../template/navbar/Navbar";
import backGrountTemplate from "../../assets/images/background.png";
import SpinnerComponent from "../../component/Spinner";
import { useDispatch, useSelector } from "react-redux";
import SidebarNew from "../../template/SidebarNew/sidebarNew";
import { Collapse, Select } from "antd";
import { baseServiceNew } from "../../service/baseServiceNew";
import americanFlag from "../../assets/images/americanFlag.png";
import vietnamFlag from "../../assets/images/vietnamFlag.png";
const { Option } = Select;
const { Panel } = Collapse;

export default function RoadMapReportPage() {
  const { id } = useParams();
  const API_URL = "/api/tracking-nguoi-dung";
  const [dsCauHoi, setDsCauHoi] = useState([]);
  const [dataReport, setDataReport] = useState([]);
  const [loaiNgonNgu, setLoaiNgonNgu] = useState("VN");

  useEffect(() => {
    baseServiceNew
      .get(`${API_URL}/lay-report-nguoi-dung/${id}`)
      .then((res) => {
        setDataReport(res.data.content);
        // console.log(res.data.content)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div
      style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
      className="h-max-content min-h-screen w-full  flex  bg-cover"
    >
      <SpinnerComponent />
      {/* <SideBar /> */}
      <SidebarNew />
      <div
        style={{ flexGrow: "1" }}
        className="flex flex-col  min-h-screen w-full"
      >
        <Navbar />
        <div className="d-flex ml-auto">
          <button>
            <img
              src={americanFlag}
              className="mr-2"
              alt="americanFlag"
              width={"40px"}
              onClick={() => {
                setLoaiNgonNgu("US");
              }}
            />
          </button>

          <button>
            <img
              src={vietnamFlag}
              className="mr-2"
              alt="vietnamFlag"
              width={"40px"}
              onClick={() => {
                setLoaiNgonNgu("VN");
              }}
            />
          </button>
        </div>

        <div className="w-full space-y-5 p-3 overflow-hidden">
          {dataReport?.map((skill) => {
            if (skill.isActive) {
              return SkillTable(skill);
            }
          })}
        </div>
      </div>
    </div>
  );
}

function SkillTable(skill) {
  return (
    <div>
      <div>
        <span className="mr-3">{skill.tenSkill}</span>
        <span className="mr-3">
          Tổng thời gian học: {skill.thoiGianHocTheoPhut}
        </span>
        <span className="mr-3">
          Tiến độ hoàn thành: {skill.tienDoHoanThanh * 100} %
        </span>
        <span>Lần cuối cùng học: {skill.ngayCuoiCungHoc}</span>
      </div>
      <table>
        <tr>
          <th>Buổi học</th>
          <th>Thời gian học</th>
          <th>Tiến độ hoàn thành</th>
          <th>Tỷ lệ xem tài liệu</th>
          <th>Tỷ lệ làm bài tập tuần</th>
          <th>Điểm trung bình bài tập tuần</th>
          <th>Tổng thời gian làm bài</th>
          <th>Điểm trắc nghiệm</th>
          <th>Thời gian làm trắc nghiệm</th>
          <th>Điểm trắc nghiệm extra</th>
          <th>Thời gian làm bài trắc nghiệm extra</th>
          <th>Thời gian xem video xem trước</th>
          <th>Thời gian xem video xem lại</th>
          <th>Tỷ lệ xem tài liệu đọc thêm</th>
          <th>Tỷ lệ xem bài tập extra</th>
        </tr>
        {skill.danhSachReportBuoiHoc?.map((buoiHoc) => {
          return SkillCell(buoiHoc);
        })}
      </table>
    </div>
  );
}

function SkillCell(buoiHoc) {
  return (
    <tr>
      <td>{buoiHoc.thuTuBuoiHoc}</td>
      <td>{buoiHoc.thoiGianHocTheoPhut}</td>
      <td>{buoiHoc.tienDoHoanThanh * 100} %</td>
      <td>{buoiHoc.tyLeXemTaiLieu * 100} %</td>
      <td>{buoiHoc.tyLeLamBaiTapTuan}</td>
      <td>{buoiHoc.diemTrungBinhBaiTapTuan}</td>
      <td>{buoiHoc.tongThoiGianLamBaiTheoGio}</td>
      <td>{buoiHoc.diemTracNghiem}</td>
      <td>{buoiHoc.thoiGianLamTracNghiem}</td>
      <td>{buoiHoc.diemTracNghiemExtra}</td>
      <td>{buoiHoc.thoiGianLamBaiTracNghiemExtra}</td>
      <td>{buoiHoc.thoiGianXemVideoXemTruocTheoPhut}</td>
      <td>{buoiHoc.thoiGianXemVideoXemLaiTheoPhut}</td>
      <td>{buoiHoc.tyLeXemTaiLieuDocThem * 100} %</td>
      <td>{buoiHoc.tyLeXemBaiTapExtra * 100} %</td>
    </tr>
  );
}

// import React from "react";
// import "./ChungNhan.css";
// import bground from "../../assets/img/bgChungNhan.jpg";
// import iCielNovecentosans from "../../assets/font/iCielNovecentosans-UltraBold.ttf";
// import iCielNovecentosansRegular from "../../assets/font/iCielNovecentosans-DemiBold.ttf";
// import Lobster from "../../assets/font/Lobster-Regular.ttf";
// import Neutraface from "../../assets/font/Neutraface_Display_Bold.ttf";
// import {
//   Document,
//   Page,
//   Text,
//   View,
//   StyleSheet,
//   Image,
//   Font,
// } from "@react-pdf/renderer";
// export default function ContentChungNhan({ chungNhan, userInfor }) {
//   Font.register({ family: "iCielNovecentosans", src: iCielNovecentosans });
//   Font.register({
//     family: "iCielNovecentosansRegular",
//     src: iCielNovecentosansRegular,
//   });
//   Font.register({ family: "Lobster", src: Lobster });
//   Font.register({
//     family: "Neutraface",
//     src: Neutraface,
//   });
//   const styles = StyleSheet.create({
//     page: {},
//     username: {
//       fontFamily: "Lobster",
//       color: "rgb(70, 24, 21)",
//       fontSize: "1.5cm",
//       position: "absolute",
//       textAlign: "center",
//       width: "100%",

//       top: "25%",
//     },
//     tenChungNhan: {
//       fontFamily: "iCielNovecentosans",
//       color: "rgb(70, 24, 21)",
//       fontSize: "0.8cm",
//       position: "absolute",
//       textAlign: "center",
//       width: "100%",
//       top: "60%",
//       padding: " 0cm 1.3cm",
//     },
//     thoiGianDaoTao: {
//       fontFamily: "Neutraface",
//       color: "rgb(70, 24, 21)",
//       fontSize: "0.5cm",
//       position: "absolute",
//       textAlign: "left",
//       marginLeft: "45%",

//       width: "100%",
//       bottom: "11%",
//     },
//     duration: {
//       textAlign: "left",
//       marginLeft: "45%",
//       fontFamily: "Neutraface",
//       color: "rgb(70, 24, 21)",
//       fontSize: "0.5cm",
//       position: "absolute",
//       width: "100%",
//       bottom: "17%",
//     },
//     soChungNhan: {
//       fontFamily: "iCielNovecentosans",
//       color: "rgb(70, 24, 21)",
//       fontSize: "0.5cm",
//       position: "absolute",
//       textAlign: "left",
//       marginLeft: "40%",
//       width: "100%",
//       bottom: "-45%",
//     },
//     ngayCap: {
//       fontFamily: "iCielNovecentosansRegular",
//       color: "rgb(70, 24, 21)",
//       fontSize: "0.5cm",
//       position: "absolute",
//       textAlign: "left",
//       marginLeft: "34%",
//       width: "100%",
//       bottom: "-49%",
//     },
//   });
//   return (
//     <Document>
//       <Page size="A4" style={styles.page} orientation="portrait">
//         <Image
//           style={{
//             position: "absolute",
//             top: "0%",
//             bottom: "0",
//             left: "0%",
//             right: "0",
//           }}
//           src={bground}
//         />
//         <View
//           style={{
//             position: "absolute",
//             top: "20%",
//             bottom: "30%",
//             left: "0",
//             right: "0",
//           }}
//         >
//           <Text style={styles.username}>{userInfor.hoTen}</Text>
//           <Text style={styles.tenChungNhan}>{chungNhan.tenChungNhan}</Text>
//           <Text style={styles.duration}>{chungNhan.thoiGianDaoTao} months</Text>
//           <Text style={styles.thoiGianDaoTao}>
//             {chungNhan.thoiGianDaoTao} tháng
//           </Text>
//           <Text style={styles.soChungNhan}>{chungNhan.soChungNhan}</Text>
//           <Text style={styles.ngayCap}>{chungNhan.ngayCap}</Text>
//         </View>
//       </Page>
//     </Document>
//   );
// }
