import * as types from '../types';

const initialState = {
    dsKhoaHoc: [],
    dsBaiTap: [],
    current: null,
    dsKhoaHocFree: [],
    chiTietKhoaHocFree: null
}

export const khoaHocReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LAY_DANH_SACH_KHOA_HOC_THANH_CONG:
            const { danhSachKhoaHoc, danhSachBaiTap } = action.payload;
            return {
                ...state,
                dsKhoaHoc: danhSachKhoaHoc,
                dsBaiTap: danhSachBaiTap
            }
            case types.LAY_DANH_SACH_KHOA_HOC_FREE_THANH_CONG:
            return {
                ...state,
                dsKhoaHocFree: action.payload
            }
            case types.LAY_CHI_TIET_KHOA_HOC_FREE_THANH_CONG:
                return {
                    ...state,
                    chiTietKhoaHocFree: action.payload
                }
        case types.LAY_CHI_TIET_KHOA_HOC_THANH_CONG:
            return {
                ...state,
                current: action.payload
            }
        case types.CAP_NHAT_CHI_TIET_KHOA_HOC:
            return {
                ...state,
                current: action.payload
            }
        default:
            return state;
    }
}


export default khoaHocReducer;