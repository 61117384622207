import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../utilities/history";
import { FileSevice } from "../../service/fileService";
import VideoPlayer from "../../component/VideoPlayer";
import { LocalStorageUtil } from "../../utilities/localStorage";
import "./VideoPage.scss";
import QuizList from "./quiz/QuizList";
import { Popover } from "antd";

import Vimeo from "@u-wave/react-vimeo";

var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();

const videoJsOptions = {
  autoplay: false,
  playbackRates: [0.5, 1, 1.25, 1.5, 2],
  width: "100%",
  height: "100%",
  controls: true,
};

class VideoPage extends Component {
  state = {
    source: "",
    baiHoc: {},
    khoaHoc: {},
    dsBaiHoc: [],
    visiblePopup: false,
  };

  hide = () => {
    this.setState({
      visiblePopup: false,
    });
  };

  handleVisibleChange = (visiblePopup) => {
    this.setState({ visiblePopup });
  };

  componentDidMount = () => {
    const { khoaHoc, baiHoc } = LocalStorageUtil.getCourseLocalStorage();
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    // LƯU TẤT CẢ CÁC BÀI HỌC VÀO 1 MẢNG
    const dsBaiHoc = [];
    khoaHoc.thongTinChuongHoc.forEach((chuongHoc) => {
      chuongHoc.thongTinBaiHoc.forEach((item) => dsBaiHoc.push(item));
    });
    this.setState({ baiHoc, khoaHoc, dsBaiHoc });
    if (nguoiDung.nuocNgoai) {
      FileSevice.layDuongDanVideoDigitalService(baiHoc.noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));
      return
    }
    if (baiHoc.online) {
      FileSevice.layDuongDanVideoOnlineService(baiHoc.noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));

    } else {
      FileSevice.layDuongDanVideoService(baiHoc.noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));
    }


  };

  onChangeVideo = (baiHoc) => {
    const { khoaHoc } = this.state;
    LocalStorageUtil.setCourseLocalStorage({ khoaHoc, baiHoc });
    window.location.href = window.location.href;
  };

  onXemVideoTruoc = () => {
    const { baiHoc, dsBaiHoc } = this.state;
    const viTri = dsBaiHoc.findIndex((x) => x.id === baiHoc.id);
    if (viTri + 1 > 0) {
      this.onChangeVideo(dsBaiHoc[viTri - 1]);
    }
  };

  onXemVideoKeTiep = () => {
    const { baiHoc, dsBaiHoc } = this.state;
    const viTri = dsBaiHoc.findIndex((x) => x.id === baiHoc.id);
    if (viTri + 1 < dsBaiHoc.length) {
      this.onChangeVideo(dsBaiHoc[viTri + 1]);
    }
  };

  onQuayLai = () => {
    const { khoaHoc } = this.state;
    history.push("/lesson", { maKhoaHoc: khoaHoc.id });
  };

  renderChuongHoc = (dsChuongHoc) => {
    return dsChuongHoc.map((item, index) => {
      return (
        <div className="chapter-item" key={index}>
          <div className="chapter-title">
            <i className="fa fa-bars"></i>
            <b>{item.tenChuong}</b>
          </div>
          <div>
            {item.thongTinBaiHoc
              ? this.renderBaiHoc(item.thongTinBaiHoc)
              : null}
          </div>
        </div>
      );
    });
  };

  renderIcon = (maLoaiBaiHoc) => {
    switch (maLoaiBaiHoc) {
      case "VIDEO_FPT":
        return <i className="fa fa-film"></i>;
      case "QUIZ":
        return <i className="fa fa-question-circle"></i>;
      default:
        break;
    }
  };

  renderBaiHoc = (dsBaiHoc) => {
    const { baiHoc } = this.state;

    const viTri = dsBaiHoc.findIndex((x) => x.id === baiHoc.id);
    return dsBaiHoc.map((item, index) => {
      if (index === viTri) {
        return (
          <div className="video-item active" key={index}>
            <div className="video-icon">
              <i className="fa fa-play"></i>
            </div>
            <div className="video-title">
              <span>{item.tenBaiHoc}</span>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="video-item"
            key={index}
            onClick={() => this.onChangeVideo(item)}
          >
            <div className="video-icon">
              {this.renderIcon(item.maLoaiBaiHoc)}
            </div>
            <div className="video-title">
              <span>{item.tenBaiHoc}</span>
            </div>
          </div>
        );
      }
    });
  };

  renderIcon = (maLoaiBaiHoc) => {
    switch (maLoaiBaiHoc) {
      case "VIDEO_FPT":
        return <i className="fa fa-film"></i>;
      case "QUIZ":
        return <i className="fa fa-question-circle"></i>;
      default:
        break;
    }
  };

  renderNoiDung = (source, baiHoc) => {
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    switch (baiHoc.maLoaiBaiHoc) {
      case "VIDEO_FPT":
        return (
          <div className="video-player">

            {
              source && <VideoPlayer {...videoJsOptions} />
            }
          </div>
        );
      case "QUIZ":
        return (
          <div className="video-player" style={{ background: "#fff" }}>
            <QuizList noiDung={baiHoc.noiDung} />
          </div>
        );
      case "ARTICLE": {
        let reactElement = htmlToReactParser.parse(baiHoc.noiDung);
        return <div>{reactElement}</div>;
      }
      default:
        break;
    }
  };


  render() {
    const { source, khoaHoc, baiHoc } = this.state;
    videoJsOptions.sources = [
      {
        src: source,
        type: "application/x-mpegURL",
      },
    ];

    return (
      <div className="video row">
        <div className="col-md-3 video-left">
          <div className="video-list">
            {khoaHoc.thongTinChuongHoc
              ? this.renderChuongHoc(khoaHoc.thongTinChuongHoc)
              : null}
          </div>
        </div>
        <div className="col-md-9 video-right">
          <div className="header">
            <i className="fa fa-indent"></i>
            <span>{baiHoc.tenBaiHoc}</span>
          </div>
          <div className="video-player">
            {this.renderNoiDung(source, baiHoc)}
          </div>
          <div className="video-control">
            <div className="control-item">
              <a onClick={this.onQuayLai} title="Quay lại trang trước">
                <i className="fa fa-reply"></i>
              </a>
              {
                //new code
                baiHoc.noiDungMoTa && (
                  <Popover
                    content={htmlToReactParser.parse(baiHoc.noiDungMoTa)}
                    title="Ghi chú"
                    trigger="click"
                    visible={this.state.visiblePopup}
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <button className="btn btn-outline-light ml-4">
                      Ghi chú
                    </button>
                  </Popover>
                )
              }
            </div>
            <div className="control-item">
              <a onClick={this.onXemVideoTruoc} title="Bài trước">
                <i className="fa fa-angle-double-left"></i>
              </a>
              <a onClick={this.onXemVideoKeTiep} title="Bài kế tiếp">
                <i className="fa fa-angle-double-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    khoaHocProps: state.khoaHocReducer,
  };
}

export default connect(mapStateToProps, null)(VideoPage);
