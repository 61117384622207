import React from "react";
import { Collapse } from "antd";
import ItemRoadMap from "./ItemRoadMap";
import "./collapse.css";
import { CaretRightOutlined } from "@ant-design/icons";
import HeaderCollapse from "./HeaderCollapse";
import KhoaHocLienQuan from "./KhoaHocLienQuan";
import TracNghiemCuoiKhoa from "./TracNghiemCuoiKhoa";
import HeaderCollapseDisable from "./HeaderCollapseDisable/HeaderCollapseDisable";
const { Panel } = Collapse;

export default function CollapseRoadMap({ data }) {
  return (
    <div className="trac-nghiem " id={`skill-${data.tenSkill}`}>
      {data.isActive ? (
        <Collapse
          expandIcon={({ isActive }) => {
            return (
              <div
                style={{
                  backgroundImage:
                    "linear-gradient(to left, rgb(34, 125, 249) 0%, rgb(116, 98, 249) 25%, rgb(223, 62, 248) 50%, rgb(116, 98, 249) 75%, rgb(34, 125, 249) 100%)",
                }}
                className=" w-12 h-12 rounded-full flex items-center justify-center"
              >
                <div className=" h-11/12 w-11/12   absolute rounded-full bg-blue-theme flex justify-center items-center">
                  <CaretRightOutlined
                    style={{ fontSize: "150%" }}
                    className="transform  text-white"
                    rotate={isActive ? 270 : 90}
                  />
                </div>
              </div>
            );
          }}
        >
          <Panel
            header={<HeaderCollapse data={data} />}
            key="1"
            className="bg-transparent"
          >
            <div className=" flex flex-col  space-y-3 w-full pt-8">
              <div className="w-full space-y-14  ">
                <KhoaHocLienQuan data={data} />
                {data.danhSachBuoiHoc?.map((item, index) => {
                  return <ItemRoadMap data={item} key={index} index={index} />;
                })}
                {data.danhSachTracNghiemTheoSkill.length > 0 ||
                data.danhSachCapstoneTheoSkill.length > 0 ? (
                  <TracNghiemCuoiKhoa data={data} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Panel>
        </Collapse>
      ) : (
        <HeaderCollapseDisable data={data} />
      )}
    </div>
  );
}
