import React, { Component } from 'react';
import { Checkbox, Button } from 'antd';

export default class QuizList extends Component {

    state = {
        dsCauHoi: [],
        cauTraLoi: {}
    }

    componentDidMount = () => {
        const { noiDung } = this.props;
        if (noiDung) {
            const dsCauHoi = JSON.parse(noiDung);
            this.setState({ dsCauHoi });
        }
    }

    onChange = (e, index) => {
        const { value } = e.target;
        this.setState({
            [index]: value
        })
    }

    onNopBai = () => {

    }

    renderKyTu = (index) => {
        switch (index) {
            case 0:
                return <b>A.</b>
            case 1:
                return <b>B.</b>
            case 2:
                return <b>C.</b>
            case 3:
                return <b>D.</b>
            default:
                break;
        }
    }

    render() {
        const { dsCauHoi } = this.state;
        return (
            <div className="question-content">
                <div className="question-list">
                    {
                        dsCauHoi.map((item, index) => {
                            return <div className="question-item" key={index}>
                                <div className="quiz">
                                    <b>Câu {index + 1}: </b>
                                    <span>{item.cauHoi}</span>
                                </div>
                                <div className="answer-list">
                                    {
                                        item.cauTraLoi ? item.cauTraLoi.map((item2, index2) => {
                                            return <div className="answer-item">
                                                <span>{this.renderKyTu(index2)} {item2.dapAn}</span>
                                                <Checkbox onChange={(e) => this.onChange(e, index)} />
                                            </div>
                                        }) : null
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="quiz-action">
                    <Button onClick={this.onNopBai} type="primary">Nộp bài</Button>
                </div>
            </div>
        )
    }
}
