import React, { Component } from "react";
// import UserChart from "./UserChart";
import UserInfor from "./UserInfor";
import UserThongbao from "./UserThongbao";

export default class UserDashBoard extends Component {
  render() {
    return (
      <div className="h-64 2xl:h-80 flex space-x-3 w-full flex-shrink-0 items-stretch">
        <UserInfor dataRoadMap={this.props.dataRoadMap} />
        {/* <UserChart /> */}
        {/* <div className=" flex-grow h-full card_theme"></div> */}
        <UserThongbao dataRoadMap={this.props.dataRoadMap} />
      </div>
    );
  }
}
