import React, { useEffect, useState } from "react";
import ItemClassHomeMobile from "./contentHomeMobile/ItemClassHomeMobile";
import { LocalStorageUtil } from "../../../utilities/localStorage";
import { useDispatch, useSelector } from "react-redux";
import * as action from "./../../../redux/actions/lopHocAction";
import backgroundTemplate from "./../../../assets/images/background.png";
import SpinnerComponent from "../../../component/Spinner";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "../../../redux/actions/spinnerAction";
import AnimateHeight from "react-animate-height";

export default function HomeMobile() {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);

  const { dsLopHoc } = useSelector((state) => state.lopHocReducer);
  useEffect(() => {

    dispatch(action.layDanhSachLopHoc(nguoiDung.id));
  
  }, []);
  const renderLopDangHoc = () => {
    return dsLopHoc
      ? dsLopHoc
          .filter((item) => {
            return item.maTrangThai == 2;
          })
          .map((item, index) => {
            return (
              <div className="w-1/2 p-2 box-border" key={index}>
                <ItemClassHomeMobile
                  maTrangThai={item.maTrangThai}
                  idLop={item.id}
                  data={item}
                />
              </div>
            );
          })
      : null;
  };
  const renderLopKetThuc = () => {
    return dsLopHoc
      ? dsLopHoc
          .filter((item) => {
            return item.maTrangThai == 3;
          })
          .map((item, index) => {
            return (
              <div className="w-1/2 p-2 box-border" key={index}>
                <ItemClassHomeMobile
                  maTrangThai={item.maTrangThai}
                  idLop={item.id}
                  data={item}
                />
              </div>
            );
          })
      : null;
  };

  return (
    <div>
      <SpinnerComponent />
      <div
        style={{
          background: `url(${backgroundTemplate})`,
          backgroundSize: "cover",
        }}
        className="p-2 h-full pb-20"
      >
        <div>
          <h3 className="font-bold text-lg">Danh sách các lớp học</h3>
        </div>
        <div className="flex flex-wrap w-full pb-5">{renderLopDangHoc()}</div>
        <div>
          <div
            className="bg-white m-2 cursor-pointer text-black font-bold text-xl rounded shadow p-2 py-3 flex justify-between items-center"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <p className="mb-0">Danh sách các lớp đã kết thúc</p>
            <i
              style={{
                transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
              }}
              className=" fa fa-angle-down text-color-title  transition  duration-100 text-base flex-shrink-0 font-medium"
            ></i>
          </div>
          <AnimateHeight
            id="example-panel"
            duration={500}
            height={collapsed ? 0 : "auto"} // see props documentation below
          >
            <div className="flex flex-wrap w-full pb-5">
              {renderLopKetThuc()}
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
}
