import { message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import HtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DOMAIN_API_FILE } from "../../../../../../../config/urlConfig";
import {
  getDataRoadMapServAction,
  getTaskRoadMapServAction,
} from "../../../../../../../redux/actions/roadMapAction";
import { RoadmapService } from "../../../../../../../service/roadmapService";
import { LocalStorageUtil } from "../../../../../../../utilities/localStorage";
import { initBaiTapCapstone } from "../../../../../../../utilities/roadmap";

import { colors } from "../../../../../constant";
import { LOAI_TASK_CAPSTONE } from "../../../../../../../redux/types";

export default function ContentCapstone({ data, dataHash, dataRoadMapItem }) {
  let params = useParams();
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  const inputRefSource = useRef();
  const inputRefDeploy = useRef();
  const inputRefYoutube = useRef();
  const { id } = useParams();
  let color = colors[0];
  let { dataTaskRoadMap } = useSelector((state) => state.roadmapReducer);
  let dispatch = useDispatch();
  const [camCo, setCamCo] = useState(false);
  const countTaskTrue = (dataTask, dataRoadMapItem) => {
    let count = 0;
    for (let key in dataRoadMapItem) {
      if (dataTask[dataRoadMapItem[key]?.id].isHoanThanh === true) {
        count++;
      }
    }
    if (count === dataRoadMapItem.length && count !== 0) {
      dispatch({
        type: "SET_CHUCMUNG_XONGTASK",
        payload: true,
        titleChucMungXongTask: " Chúc mừng bạn đã hoàn thành bài tập capstone",
      });
    }
  };

  useEffect(() => {
    if (data && camCo && dataTaskRoadMap.size !== 0) {
      countTaskTrue(
        dataTaskRoadMap.get(data.maBuoiHoc.toString()).danhSachCapstone,
        dataRoadMapItem
      );
    }
  }, [camCo, dataTaskRoadMap]);

  const handleNopBaiTap = (data) => {
    let baiTap = initBaiTapCapstone;
    baiTap.maBuoiHoc = data.maBuoiHoc;
    baiTap.maBaiHoc = data.id;
    baiTap.maLop = params.id * 1;
    baiTap.maNguoiDung = nguoiDung.id;
    baiTap.noiDung.linkBai = inputRefSource.current.value;
    baiTap.noiDung.linkDeploy = inputRefDeploy.current.value;
    baiTap.noiDung.linkYoutube = inputRefYoutube.current.value;

    if (
      !inputRefSource.current.value ||
      !inputRefDeploy.current.value ||
      !inputRefYoutube.current.value
    ) {
      message.error("Nội dung nộp không được để rỗng");
      return;
    }
    RoadmapService.postCapstone(baiTap)
      .then((res) => {
        message.success("Nộp bài Capstone thành công");
        dispatch(getDataRoadMapServAction(id));
        setCamCo(!camCo);
      })
      .catch((err) => {
        console.log(err);
      });
    if (dataHash) {
      let submitHoanThanh = {
        maBuoiHoc: data.maBuoiHoc,
        maBaiHoc: data.id,
        maLop: +id,
        loaiTask: LOAI_TASK_CAPSTONE,
      };
      RoadmapService.postCheckHoanThanhTaskRoadMap(submitHoanThanh)
        .then(() => {
          dispatch(getTaskRoadMapServAction(id));
        })
        .catch((err) => {
          // message.error("đã có lỗi xảy ra");
          console.log(err);
        });
    }
  };
  let renderContent = () => {
    if (data.diem == -1) {
      return (
        <>
          <p className="text-red-500 text-center">
            Bài tập của bạn đang được đợi chấm
          </p>
          <div className="flex flex-col space-y-2 items-center rounded-lg h-max-content overflow-hidden">
            <input
              ref={inputRefSource}
              type="text"
              placeholder="Link nộp source"
              className="rounded w-full h-12  px-3"
            />
            <input
              ref={inputRefDeploy}
              type="text"
              placeholder="Link deploy"
              className="rounded w-full h-12  px-3"
            />
            <input
              ref={inputRefYoutube}
              type="text"
              placeholder="Link youtube"
              className="rounded w-full h-12  px-3"
            />
            <button
              onClick={() => {
                handleNopBaiTap(data);
              }}
              className=" w-24 p-2 rounded bg-blue-theme text-white cursor-pointer"
            >
              Nộp bài
            </button>
          </div>
        </>
      );
    } else if (data.diem > 0) {
      return (
        <button className="round px-4 py-2 bg-blue-theme w-max mx-auto rounded text-white ">
          {data.diem} điểm
        </button>
      );
    } else if (!data.isHetHan && data.diem == 0) {
      return (
        <div className="flex flex-col space-y-2 items-center rounded-lg h-max-content overflow-hidden">
          <input
            ref={inputRefSource}
            type="text"
            placeholder="Link nộp source"
            className="rounded w-full h-12  px-3"
          />
          <input
            ref={inputRefDeploy}
            type="text"
            placeholder="Link deploy"
            className="rounded w-full h-12  px-3"
          />
          <input
            ref={inputRefYoutube}
            type="text"
            placeholder="Link youtube"
            className="rounded w-full h-12  px-3"
          />
          <button
            onClick={() => {
              handleNopBaiTap(data);
            }}
            className=" w-24 p-2 rounded bg-blue-theme text-white cursor-pointer"
          >
            Nộp bài
          </button>
        </div>
      );
    } else if (data.isHetHan && data.diem == 0) {
      return (
        <button className="round px-4 py-2 bg-red-600 w-22 mx-auto rounded text-white ">
          Hết hạn
        </button>
      );
    }
  };
  return (
    <div
      style={{ backgroundColor: color.bg, width: "800px" }}
      className=" p-2 px-3  min-h-22 justify-between flex items-center relative rounded-lg shadow-lg"
    >
      <div className="w-full pl-6 h-full flex flex-col  relative  space-y-3">
        <div className="flex space-x-2 justify-between space-x-5 items-center">
          <p className="text-gray-900">{data.tieuDe}</p>

          <p>
            <span className="text-black">Deadline</span>{" "}
            {moment(data.hanNop).format("DD/MM/YYYY")}
          </p>
        </div>
        <button
          className=" flex items-center h-max-content rounded p-1 border-gray-600 w-max flex-shrink-0 border-1 text-color-content cursor-pointer hover:text-gray-900  px-2 transform duration-300 hover:border-gray-500
            "
        >
          <a
            className="flex justify-center items-center space-x-1 text-color-content"
            href={`${DOMAIN_API_FILE}/${data.noiDung}`}
            target="_blank"
          >
            <i className="fa fa-folder-open"></i>
            <span>Đề bài</span>
            <i className="fa fa-download"></i>
          </a>
        </button>{" "}
        {data.ghiChu && (
          <div className="p-3 bg-white rounded">
            <h2>Ghi chú:</h2>
            {HtmlParser(data.ghiChu)}
          </div>
        )}
        {renderContent()}
        <div
          style={{ backgroundColor: color.line }}
          className="h-4/5 w-1 left-2 top-1/2 transform absolute rounded-lg -translate-y-1/2"
        ></div>
      </div>
    </div>
  );
}
