import { DatePicker, message } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { layUserCV } from '../../../redux/actions/nguoiDungAction';
import { NguoiDungSevice } from '../../../service/nguoiDungService';
import { LocalStorageUtil } from '../../../utilities/localStorage';
import moment from 'moment';
import * as types from '../../../redux/types'
import {boDauTiengViet} from '../../../utilities/stringFormat'


const { MonthPicker } = DatePicker;

export const Expereience = ({ thongTinMoRong }) => {

    const formatDate = "MM/yyyy"

    let oContent = {
        chucVu: "",
        tenCongTy: "",
        namLamTu: "",
        namLamDen: "",
        diaChi: "",
        moTaNgan: ""
    }

    let [data, setData] = useState([]);
    let changeData = (name, value) => {
        let newData = data[0];

        newData[name] = boDauTiengViet(value)

        setData([newData]);
    }

    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    const dispatch = useDispatch();


    function handleXoaThongTin(index) {
        let {kinhNghiem} = thongTinMoRong

        // if (kinhNghiem.length === 1) {
        //     message.error("CV phải có ít nhất một trình độ học vấn, bạn hãy thêm rồi hãy xóa nhé !!")
        //     return
        // }

        if (kinhNghiem[index]?.tenCongTy === "CyberSoft Academy") {
            message.error("Xoá thất bại")
            return
        }

        kinhNghiem.splice(index,1)

        let newThongTinMoRong = {...thongTinMoRong, kinhNghiem}
        message.info("Xóa thành công")
        dispatch({type: types.CAP_NHAT_CV_LOCAL, payload: newThongTinMoRong})
    }

    const saveThongTin = (index = -1) => {
      
        let newData = data[0];
        for (let item in newData) {
            if (newData[item].trim() == "") {
                message.info("Hãy nhập đầy đủ thông tin hoặc tắt thêm");
                return;
            }
        }

        let { kinhNghiem } = thongTinMoRong;

        kinhNghiem.push(newData)

        thongTinMoRong = { ...thongTinMoRong, kinhNghiem };
        setData([])
        dispatch({type: types.CAP_NHAT_CV_LOCAL, payload: thongTinMoRong})

     
    }




    //kiem tra data cuoi de cho phep them moi
    let checkData = data;

    return (

        <div className="resume_item resume_experience">
            <div className="title d-flex justify-content-between">
                <p className="bold">Work Experience</p>
                <button className="btn btn-primary"
                onClick={() => {

                    if (checkData && checkData.length > 0) {
                        message.info("Bạn hãy lưu thông tin hiện tại");
                    } else
                        setData([...data, oContent])
                }}
                >Add</button>

                
            </div>
            {thongTinMoRong.kinhNghiem?.length > 0 && <ul>

                {thongTinMoRong.kinhNghiem?.map((item, index) => {
                    return <li key={`experience_${index}`}>
                        <div className="info">
                            <p className="nameTitle">

                                {item.tenCongTy} <span className="date">{item.namLamTu} - {item.namLamDen}
                                {
                                    item.tenCongTy !== "CyberSoft Academy" ? (<button className='delete ml-3 btn btn-sm btn-danger' onClick={() => handleXoaThongTin(index)}> <i className=' fa fa-trash'></i></button>) : null
                                }
                                    
                                </span>
                            </p>
                            <p className="semi-bold">{item.chucVu}</p>
                            <p> <i className="fa fa-map-marker"></i> {item.diaChi}</p>
                            <p>{item.moTaNgan}</p>
                        </div>
                    </li>
                })}

            </ul>
            }

            {checkData ?
                checkData.length > 0 && <div className=' row flex justify-between'>

                    <div className="form-group col-6">
                        <label >Company</label>
                        <input onChange={(e) => changeData("tenCongTy", e.target.value)} className="form-control form-control-sm" />
                    </div>
                    <div className="form-group col-5">
                        <label >Job title</label>
                        <input onChange={(e) => changeData("chucVu", e.target.value)} className="form-control form-control-sm" />
                    </div>
                    <div className="form-group col-6 row flex justify-between">
                        <label className='col-12 mb-0'>Start & End Date</label>
                        <MonthPicker onChange={(date, dString) => changeData("namLamTu", dString)} placeholder="start" format="MM/yyyy" className="form-control form-control-sm col-6" />
                        <MonthPicker onChange={(date, dString) => changeData("namLamDen", dString)} placeholder="end" format="MM/yyyy" className="form-control form-control-sm col-5" />


                    </div>
                    <div className="form-group col-5">
                        <label >Address</label>
                        <input onChange={(e) => changeData("diaChi", e.target.value)} className="form-control form-control-sm" />
                    </div>
                    <div className="form-group col-12">
                        <label >Description</label>
                        <textarea className="form-control" onChange={(e) => changeData("moTaNgan", e.target.value)} rows={3} />
                    </div>



                    <div className='col-12'>
                        <button className=' btn btn-sm btn-success mr-3'>
                            <i className='fa fa-check' onClick={() => saveThongTin()}></i>
                        </button>
                        <button className=' btn btn-sm btn-danger' onClick={() => {
                            setData([]);

                        }}>
                            <i className='fa fa-times'></i>
                        </button>
                    </div>

                </div>
                : ""
            }
        </div>
    )
}
