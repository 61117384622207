import { Progress, Tooltip } from "antd";
import React from "react";

export default function CycleImg({ data, width }) {
  return (
    <div
      style={{
        width: width,
        height: width,
        maxWidth: "90px",
        maxHeight: "90px",
        backgroundImage: `url("https://apicrm.cybersoft.edu.vn/images/${data.hinhAnh}")`,
        filter: `grayscale(${!data.isActive ? "100%" : "0%"})`,
      }}
      className=" relative rounded-full shadow-lg flex-shrink-0 bg-center bg-cover transform  "
    >
     
         <Progress
        type="circle"
        strokeColor={{
          "0%": "#fb8085",
          "100%": "#f53844",
        }}
        percent={data.diemBuoiHoc.phanTramCam}
        showInfo={false}
        className="absolute w-full h-full center-position "
        trailColor={"rgba(68, 66, 178, 0.1)"}
      />

     
      <Progress
        type="circle"
        strokeColor={{
          "0%": "#5B86E5",
          "100%": "#36D1DC",
        }}
        percent={data.diemBuoiHoc.phanTramTim}
        showInfo={false}
        className="absolute w-full h-full center-position scale-110 origin-center rotate-180"
        trailColor={"rgba(68, 66, 178, 0.1)"}
      />
    </div>
  );
}
