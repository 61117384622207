import React from "react";
import XemTruoc from "./ContentVideoListRoadMap/XemTruoc";

export default function ContentVideoXemTruoc({ data, maBuoiHoc,maLop }) {
  return (
    <div style={{ width: "90vw", height: "80vh" }} className="flex">
      <div className=" flex-grow h-full">
        <XemTruoc dsXemLai={data} maBuoiHoc={maBuoiHoc} maLop={maLop}/>
      </div>
    </div>
  );
}
