import React from "react";
import logoReact from "./../../../../assets/images/logoSquareReact.png";
import { Link } from "react-router-dom";
import moment from "moment";
import { Alert, Tag } from "antd";
import "./itemClassHomeMobile.css";
import imgReactMobile from "./../../../../assets/images/ReactMobileU.png";
import imgNodejsMobile from "./../../../../assets/images/NodejsMobile.png";
import imgJavaMobile from "./../../../../assets/images/javaMobile.png";
export default function ItemClassHomeMobile({ data, idLop, maTrangThai }) {
  // console.log(data);
  // console.log(data.tenLopHoc.includes("Nodejs"));
  // console.log(data);
  // console.log(maTrangThai);
  return (
    <Link
      to={`/course-detail/${idLop}`}
      className="rounded no-underline min-h-150 bg-item-mobile p-3 cursor-pointer h-full flex flex-col justify-between shadow"
    >
      {data.tenLopHoc.includes("Nodejs") ? (
        <img
          className="w-full h-48 object-contain"
          src={imgNodejsMobile}
          alt=""
        />
      ) : data.tenLopHoc.includes("Java") ? (
        <img
          className="w-full h-48 object-contain"
          src={imgJavaMobile}
          alt=""
        />
      ) : (
        <img
          className="w-full h-48 object-contain"
          src={imgReactMobile}
          alt=""
        />
      )}

      <h4 className="mt-3 font-semibold text-2xl mb-3">
        Lớp: {data?.tenLopHoc}
      </h4>
      <div className="space-y-2">
        <p className="mb-0 text-black text-opacity-70 font-normal">
          Ngày bắt đầu:
          <span className="ml-2 text-black font-bold">
            {moment(data.ngayBatDau).format("DD/MM/YYYY")}
          </span>
        </p>
        <p className="mb-0 text-black text-opacity-70 font-normal">
          Ngày kết thúc:
          <span className="ml-2 text-black font-bold">
            {moment(data.ngayKetThuc).format("DD/MM/YYYY")}
          </span>
        </p>
        {maTrangThai === 2 ? (
          <p className="text-black">
            Trạng thái lớp: <Tag color="#008000">Đang mở</Tag>
          </p>
        ) : (
          <p className="text-black">
            Trạng thái lớp: <Tag color="#f50">Kết thúc</Tag>
          </p>
        )}
      </div>
    </Link>
  );
}
