import React from "react";
import Center from "./Center";
import Left from "./Left";
import Right from "./Right";

export default function KhoaHocLienQuan({ data }) {
  return (
    <div className="w-full space-x-16 xl: space-x-12 xl:space-x-36 flex  justify-center items-center transform ">
      <Left data={data} /> <Center data={data} /> <Right data={data} />
    </div>
  );
}
