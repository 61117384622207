import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalStorageUtil } from "../../../../../../utilities/localStorage";
import { FileSevice } from "../../../../../../service/fileService";
import { setSourceVideoAction } from "../../../../../../redux/actions/roadMapAction";
import { CHECK_VIDEO_XEMTRUOC } from "../../../../../../redux/types";
import HtmlParser from "react-html-parser";
import { Popover } from "antd";

function ButtonChangeVideo({
  dataItem,
  checkHoanThanh,
  maBuoiHoc,
  lopHocActive,
  dataHash,
}) {
  // console.log(checkHoanThanh);
  const { dataTaskRoadMap } = useSelector((state) => state.roadmapReducer);
  // const [checkTaskRoadMap, setCheckTaskRoadMap] = useState(false);
  const dispatch = useDispatch();

  let handleChangVideo = (baiHoc) => {
    if (checkHoanThanh && Object.keys(checkHoanThanh).length !== 0) {
      const checkHoanThanhVideo = {
        type: CHECK_VIDEO_XEMTRUOC,
        payload: {
          hoanThanh: checkHoanThanh,
          item: dataItem,
        },
      };
      dispatch(checkHoanThanhVideo);
    }

    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    if (nguoiDung.nuocNgoai) {
      // dispatch(setSourceVideoAction(baiHoc.vimeo));

      FileSevice.layDuongDanVideoDigitalService(baiHoc.noiDung)
        .then((res) => {
          dispatch(setSourceVideoAction(res.data));
        })
        .catch((err) => console.log(err.response));
    } else {
      if (baiHoc.online) {
        FileSevice.layDuongDanVideoOnlineService(baiHoc.noiDung)
          .then((res) => {
            dispatch(setSourceVideoAction(res.data));
          })
          .catch((err) => console.log(err.response));
      } else {
        FileSevice.layDuongDanVideoService(baiHoc.noiDung)
          .then((res) => {
            dispatch(setSourceVideoAction(res.data));
          })
          .catch((err) => console.log(err.response));
      }
    }
  };
  // console.log(lopHocActive);
  const rederCheckTask = () => {
    if (lopHocActive && dataHash !== undefined) {
      if (
        dataTaskRoadMap?.get(maBuoiHoc.toString()).danhSachVideoXemTruoc[
          dataItem.id
        ]?.isHoanThanh
      ) {
        return (
          <i className="fa-solid ml-auto fa-check p1 w-5 flex items-center justify-center h-5 text-xs rounded-full bg-green-600 text-white"></i>
        );
      } else {
        return (
          <i className="fa-solid fa-triangle-exclamation flex items-center justify-center text-red-600 w-5 h-5 text-xl ml-auto"></i>
        );
      }
    }
  };

  return (
    
    <>
      <div
        className="bg-white cursor-pointer border-t-1  p-4  hover:bg-gray-300 transition duration-150 ease-out"
        onClick={() => {
          handleChangVideo(dataItem);
        }}
      >
        <div className="justify-between flex items-center">
          <div className="flex items-center w-4/5">
            <i className="fa-solid fa-film mr-3 text-base"></i>
            <p className="mb-0 text-sm">{dataItem.tieuDe}</p>
          </div>
          <div className="checkDone w-1/5">{rederCheckTask()}</div>
        </div>
      </div>
      <div className="text-right">
        {dataItem?.ghiChu && (
          <Popover
            content={
              <div className="ghiChu w-full">{HtmlParser(dataItem.ghiChu)}</div>
            }
            title="Tài nguyên"
            trigger="click"
          >
            <p className="px-2 py-1 border inline-block cursor-pointer rounded">
              <span>
                <i class="fa-solid fa-folder-open"></i>
              </span>{" "}
              Tài nguyên
            </p>
          </Popover>
        )}
      </div>
    </>
  );
}

export default ButtonChangeVideo;
