import React, { useEffect, useState } from 'react'
import Navbar from '../../template/navbar/Navbar'
import SidebarNew from '../../template/SidebarNew/sidebarNew'
import backGrountTemplate from "../../assets/images/background.png";
import { useMeasure } from 'react-use';
import Axios from 'axios';
import HtmlParser from "react-html-parser";
import './quiztest.css'
import { Badge, Divider, message, Pagination, Popconfirm, Tabs, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import history from '../../utilities/history';
import { NguoiDungSevice } from '../../service/nguoiDungService';
import { LocalStorageUtil } from '../../utilities/localStorage';
import { FacebookMessengerShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton } from 'react-share';
import ContentStart from './ContentStart';
import { useDispatch, useSelector } from 'react-redux';
import { layLayoutAction, layQuizAction, layQuizAwsAction } from '../../redux/actions/baiTapActions';
import TracNghiem_Start from './ContentTracNghiemCuoiKhoa/TracNghiem_Start';
import QuizEnd from './QuizEnd';
import _ from 'lodash'
import TracNghiem_StartV2 from './ContentTracNghiemCuoiKhoa/TracNghiem_StartV2';


export const QuizTestV2 = () => {
    const { maNhom, quizId } = useParams()

    const dispatch = useDispatch();
    const { lstQuiz, layoutBoDe } = useSelector(state => state.baiTapReducer)

    let [dataQuiz, setDataQuiz] = useState(null)
    let [indexQuiz, setIndexQuiz] = useState(0)

    useEffect(() => {
        if (lstQuiz) {
          const quizItem = lstQuiz.data?.find(n => n.id == quizId);
          if (quizItem) {
            const parsedQuizItem = {
              ...quizItem,
              noiDung: quizItem.noiDung ? JSON.parse(quizItem.noiDung) : []
            };
            setDataQuiz(parsedQuizItem);
            setIndexQuiz(0);
          }
        }
      }, [lstQuiz, quizId]);
    


    useEffect(() => {
        dispatch(layQuizAction(maNhom))
        dispatch(layLayoutAction(maNhom))
    }, [maNhom])

    let finishQuiz = (trangThai = 1) => {

        let storeQuizAWS = localStorage.getItem("quiz")

        if (storeQuizAWS) {
            storeQuizAWS = JSON.parse(storeQuizAWS)

            let quiz = storeQuizAWS.find(n => n.quizId == quizId)

            if (quiz) {

                quiz.trangThai = trangThai

                localStorage.setItem("quiz", JSON.stringify(storeQuizAWS))
                history.push(`/quizizz/${maNhom}/${quizId}`)
            }
        }
    };

    let listQuizz = []
    if (lstQuiz) {
        listQuizz = lstQuiz.data
    }

    let checkQuizAWS = localStorage.getItem("quiz")

    if (checkQuizAWS) checkQuizAWS = JSON.parse(checkQuizAWS)
    let quizDetail = checkQuizAWS ? checkQuizAWS.find(n => n.quizId == quizId) : null

    const batDau = () => {
        let checkQuizAWS = localStorage.getItem("quiz")
        if (quizId) {
            if (checkQuizAWS) {

                checkQuizAWS = JSON.parse(checkQuizAWS)

                if (!checkQuizAWS.find(n => n.quizId == quizId)) {
                    checkQuizAWS.push({ quizId, userChoice: [], trangThai: 0 })

                    localStorage.setItem("quiz", JSON.stringify(checkQuizAWS))

                    history.push(`${quizId}`)
                }

            } else {

                // lần đầu vào
                localStorage.setItem("quiz", JSON.stringify([{ quizId, userChoice: [], trangThai: 0 }]))

                history.push(`${quizId}`)
            }
        } else {
            message.info("Chọn bồ đề trước khi bắt đầu làm bài!")
        }

    }


    return (
        <div
            style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
            className="h-max-content min-h-screen w-full  flex gioiThieu"
        >

            <div className="w-60">
                <SidebarNew />
            </div>
            <div
                style={{ flexGrow: "1", width: "85%" }}
                className="h-max-cotent flex flex-col  min-h-screen "
            >
                <Navbar />


                <div className=" flex row ">
                    {/* left */}
                    <div className="col-9 p-4">
                        {dataQuiz && quizDetail ?

                            quizDetail.trangThai == 1 ?
                                <QuizEnd finishQuiz={finishQuiz} quizDetail={quizDetail} dataQuiz={dataQuiz} />

                                :

                                < TracNghiem_StartV2 indexQuiz={indexQuiz} allQuestions={dataQuiz.noiDung} setIndexQuiz={setIndexQuiz} finishQuiz={finishQuiz} />

                            :

                            <div dangerouslySetInnerHTML={{ __html: layoutBoDe?.noiDung }}></div>
                        }
                        
                    </div>

                    {/* right */}
                    <div className="bg-gray-50  col-3 p-4" style={{ height: "100vh" }}>
                        <div className="border-b pb-4 mb-4">
                            <h2 className="text-lg font-semibold">Chọn bộ đề luyện thi</h2>
                        </div>
                        <div className="border-b mb-4">
                            {listQuizz.map(item => {
                                let cssActive = ""

                                if (item.id == quizId) cssActive = " bg-gray-200 "

                                return <a key={item.id} onClick={() => {

                                    history.push(`/quizizz/${maNhom}/${item.id}`)
                                }}>

                                    <p className={` py-2 hover:bg-gray-200 ${cssActive}`} >{item.tieuDe}</p>

                                </a>
                            })}


                        </div>
                        <div className=' mb-4'>
                            {quizId && !quizDetail && <button className="btn btn-sm btn-primary mr-2" onClick={batDau}>Bắt đầu</button>}
                           {quizDetail&&<>
                            <Popconfirm
                                title="Bạn chắc có muốn tiếp tục ?"
                                onConfirm={() => {

                                    let index = checkQuizAWS.findIndex(n => n.quizId == quizId)
                                    checkQuizAWS.splice(index, 1)

                                    localStorage.setItem("quiz", JSON.stringify(checkQuizAWS))

                                    history.push(`/quizizz/${maNhom}/${quizId}`)


                                }}
                            >

                                <button className="btn btn-sm btn-success mr-2">Làm lại</button>
                            </Popconfirm>


                            <Popconfirm
                                title="Bạn chắc có muốn tiếp tục ?"
                                onConfirm={() => {

                                    finishQuiz()


                                }}
                            >
                                <button className="btn btn-sm btn-danger mr-2">Kết thúc</button>
                            </Popconfirm></>}
                        </div>

                        <div class="row" style={{ gap: 10 }}>
                            {dataQuiz && (checkQuizAWS && checkQuizAWS.find(n => n.quizId == quizId))
                                &&
                                dataQuiz.noiDung.map((item, index) => {

                                    let cssResult = " bg-gray-400 "

                                    if (quizDetail.trangThai != 0) {
                                        let { userChoice } = quizDetail

                                        let userAnwser = userChoice.find(n => n.id == item.id);


                                        if (userAnwser && _.isEqual(_.sortBy(userAnwser.choice?.map(String)), _.sortBy(item.dapAn?.map(String)))) {
                                            cssResult = " bg-green-400 "
                                        } else {
                                            cssResult = " bg-red-400 "
                                        }
                                    }

                                    let cssActive = ""
                                    if (index == indexQuiz) cssActive = " inputCauBlue "

                                    return <a onClick={() => {
                                        setIndexQuiz(index)
                                    }}>
                                        <div class={`inputCau text-white font-bold w-12 h-12 flex items-center justify-center   ${cssActive} ${cssResult} `} >
                                            {index + 1}

                                        </div>
                                    </a>
                                })
                            }

                        </div>
                    </div>
                </div>


            </div >
        </div >
    )

}
