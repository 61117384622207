import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import Navbar from "../../template/navbar/Navbar";
import backGrountTemplate from "../../assets/images/background.png";
// import UserDashBoard from "./UserDashboard";
// import ThongKeLoTrinh from "./ThongKeLoTrinh";
import RoadMapToogleList from "./RoadMapToogleList";
import SpinnerComponent from "../../component/Spinner";
import { useDispatch, useSelector } from "react-redux";

import BtnBackToTop from "./BtnBackToTop/BtnBackToTop";
import SkillTimeLine from "./SkillTimeLine/SkillTimeLine";
import {
  getDataRoadMapServAction,
  getTaskRoadMapServAction,
} from "../../redux/actions/roadMapAction";
import SidebarNew from "../../template/SidebarNew/sidebarNew";
import PopupKhoKhanHocVien from "../../component/PopupKhoKhanHocVien/PopupKhoKhanHocVien";
// import PopupKhoKhanHocVien from "../../component/PopupKhoKhanHocVien/PopupKhoKhanHocVien";
import ChucMungXongTask from "../../component/ChucMungXongTask/ChucMungXongTask";

export default function RoadmapLoTrinhChiTiet() {
  const { id } = useParams();
  let { dataRoadMap, chucMungXongTask } = useSelector(
    (state) => state.roadmapReducer
  );
  // console.log(dataRoadMap);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataRoadMapServAction(id));
    dispatch(getTaskRoadMapServAction(id));
  }, [id]);
  // console.log(dataRoadMap);
  // console.log(chucMungXongTask);
  // console.log(titleChucMungXongTask);
  return (
    <>
      {chucMungXongTask && <ChucMungXongTask />}
      <div
        style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
        className="h-max-content min-h-screen w-full  flex  bg-cover"
      >
        <BtnBackToTop khoKhanHocVien={dataRoadMap.khoKhanHocVien} />
        <SpinnerComponent />
        <PopupKhoKhanHocVien khoKhanHocVien={dataRoadMap.khoKhanHocVien} />

        <SidebarNew />
        <div
          style={{ flexGrow: "1" }}
          className="flex flex-col  min-h-screen w-full"
        >
          <Navbar tenLop={dataRoadMap?.thongTinLopHoc?.tenLopHoc} />
          <div className=" w-full p-3 space-y-2  ">
            {/* <UserDashBoard dataRoadMap={dataRoadMap} />
          <ThongKeLoTrinh dataRoadMap={dataRoadMap} /> */}
            <SkillTimeLine dataRoadMap={dataRoadMap} />
            {
              dataRoadMap?.thongTinLopHoc?.isKetThuc ? 
              <h1 class="text-center" style={{fontSize:"27px"}}>Thông Báo <br/> Hiện tại bạn đã hết thời gian truy xuất tài nguyên theo quy định </h1>:  <RoadMapToogleList dataRoadMap={dataRoadMap} />
            }
           
          </div>
        </div>
      </div>
    </>
  );
}
