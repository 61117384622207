import React from "react";
import LinkIconComponent from "../Component/LinkIconComponent/LinkIconComponent";
import LinkIconEmailComponent from "../Component/LinkIconEmailComponent/LinkIconEmailComponent";
import { Tag } from "antd";
import { convertHoTenThanhTenHo } from "../../../../utilities/stringFormat";
import moment from "moment";
import "./index.scss";
import * as strUtilities from "../../../../utilities/stringFormat";
import { forLoopMap } from "../../../../utilities/utils";

export default function index(props) {
  const { thongTinMoRong } = props;

  function renderTen(thongTinMoRong) {
    return `${
      thongTinMoRong?.gioiTinh === "male" ? "Mr" : "Mrs"
    }.${convertHoTenThanhTenHo(thongTinMoRong?.hoTen).toUpperCase()}`;
  }

  function renderSkill(danhSachSkill, loaiSkill) {
    let arraySkill = forLoopMap(danhSachSkill, (item, index) => {
      return <li key={`${loaiSkill}_${index}`}>{item}</li>;
    });

    if (danhSachSkill.length === 0) return null

    return (
      <>
        <h4 className="skill__sub-title">{loaiSkill}</h4>
        <ul className="skill__items">{arraySkill}</ul>
      </>
    );
  }

  function renderButtonLinkOnProject(item, index) {
    return (
      <div className="row project__list-btn" key={`projectButton_${index}`}>
        <div className="col-4">
          {
            item.linkDeploy !== "" ? (<div className="project__btn project__btn-deploy">
            <a href={item.linkDeploy} target="_blank">
              <i className="fa fa-globe"></i>
            </a>
          </div>) : null
          }
          
        </div>
        <div className="col-4">
          {
            item.linkYoutube !== "" ? (<div className="project__btn project__btn-youtube">
            <a href={item.linkYoutube} target="_blank">
             <i className="fa fa-youtube-play"></i>
            </a>
          </div>) : null
          }
        </div>
        <div className="col-4">
          {item.linkGit !== "" ? (<div className="project__btn project__btn-git">
            <a href={item.linkGit} target="_blank">
            <i className="fa fa-github"></i>
            </a>
          </div>) : null}
        </div>
      </div>
    );
  }

  function renderEducation(dsEducation) {
    return forLoopMap(dsEducation, (item, index) => {
      return (
        <div className="col-12" key={`educationItem_${index}`}>
          <div className="item">
            <h5>
              {item.tenTruong} - {item.trinhDo}
            </h5>
            <h6>
              {item.namHocTu} - {item.namHocDen}
            </h6>
            <p className="education__item-moTa">{item.moTaNgan}</p>
          </div>
        </div>
      );
    });
  }

  function renderCertification(dsCertification) {
    if (dsCertification.length === 0) return "" 
    return  <section className="certification">
    <h3>Certification</h3>
    <div className="row">
      {forLoopMap(dsCertification, (item, index) => {
      return (
        <div className="col-12" key={`certification__${index}`}>
          <div className="item">
            <h5>{item.tenChungChi}</h5>
            <h5>{moment(item.ngayCapChungChi).format("MM/YYYY")}</h5>
            <h6>{item.noiCap}</h6>
            <p className="certification__item-moTa">{item.moTaNgan}</p>
          </div>
        </div>
      );
    })}
    </div>
  </section>

    
  }

  function renderExperience(dsExperience) {
    return forLoopMap(dsExperience, (item, index) => {
      return (
        <div className="col-12" key={`experienceItem_${index}`}>
          <div className="item">
            <h5>
              {item.tenCongTy} - {item.chucVu}
            </h5>
            <h6>
              {item.namLamTu} - {item.namLamDen}
            </h6>
            <p className="experience__item-moTa">{item.moTaNgan}</p>
          </div>
        </div>
      );
    });
  }

  function renderSkillOnProject(dsSkills) {
    return (
      <p className="project_skillOnProject">
        {JSON.parse(dsSkills).join(", ")}
      </p>
    );
  }

  function renderProject(danhSachDuAn) {
    return forLoopMap(danhSachDuAn, (item, index) => {
      return (
        <div className="col-xs-12 col-sm-6 project__dot" key={`projectItem_${index}`}>
          <div className="project__item">
            <h4>{item.tieuDe}</h4>
            {renderSkillOnProject(item.danhSachSkill)}
            {renderButtonLinkOnProject(item)}
            <p className="project__item-moTa">{item.moTa}</p>
          </div>
        </div>
      );
    });
  }
  
  function renderAvatarImage(thongTinMoRong) {
    let isHidden = thongTinMoRong?.isHiddenAvatar || thongTinMoRong?.anhAvatar === ""
    if (isHidden) return null
return  <div className="header__image">
      <img src={strUtilities.convertFileNameToUploadAvatarImage(thongTinMoRong.anhAvatar)} alt="image_avatar" />
    </div>
   
  }

  function renderFacebookLink(thongTinMoRong) {
    if (thongTinMoRong?.isHiddenFacebookLink) return null
    return <a href={thongTinMoRong?.linkFacebook} target="_blank" rel="noopener noreferrer"> <p>{thongTinMoRong?.linkFacebook}</p></a>
  }


  
  return (
    <>
      <div className="container basic2">
        <div className="row">
          <div className="col-xs-12 col-sm-4 basic2__sidebar">
            <section className="header">
              {renderAvatarImage(thongTinMoRong)}

              <h3>
                <b>{renderTen(thongTinMoRong)}</b>
              </h3>
              <h4>{thongTinMoRong?.chucDanh}</h4>
              <p>{thongTinMoRong?.diaChi}</p>
             <a href={`mailto:${thongTinMoRong?.email}`}><p>{thongTinMoRong?.email}</p></a> 
             <a href={thongTinMoRong?.linkGitHub} target="_blank" rel="noopener noreferrer"> <p>{thongTinMoRong?.linkGitHub}</p></a>
             <a href={`tel:+${thongTinMoRong?.soDienThoai}`}><p><span>Hotline: </span>{thongTinMoRong?.soDienThoai}</p></a>
             <a href={thongTinMoRong?.linkLinkedIn} target="_blank" rel="noopener noreferrer"> <p>{thongTinMoRong?.linkLinkedIn}</p></a>
             {renderFacebookLink(thongTinMoRong)}
            </section>
            <section className="skills">
              {renderSkill(thongTinMoRong?.lsSkillFE, "Front End")}

              {renderSkill(thongTinMoRong?.lsSkillBE, "Back End")}

              {renderSkill(thongTinMoRong?.lsSkillOther, "Other")}

              {renderSkill(thongTinMoRong?.listSoftSkill, "Soft Skill")}

              {renderSkill(thongTinMoRong?.ngoaiNgu, "Language")}
            </section>
          </div>
          <div className="col-xs-12 col-sm-8">
            <section className="summary">
              <h3>Profile</h3>
              <p>{thongTinMoRong?.gioiThieu}</p>
            </section>

            <section className="project">
              <h3>Projects</h3>
              <div className="row">
                {renderProject(thongTinMoRong?.listDuAn)}
              </div>
            </section>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <section className="experience">
                  <h3>Experience</h3>
                  <div className="row">
                    {renderExperience(thongTinMoRong?.kinhNghiem)}
                  </div>
                </section>
              </div>
              <div className="col-xs-12 col-sm-6">
                <section className="education">
                  <h3>Education</h3>
                  <div className="row">
                    {renderEducation(thongTinMoRong?.truongHoc)}
                  </div>
                </section>
                {renderCertification(thongTinMoRong?.chungNhan)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
