import React, { useEffect, useState } from "react";
import { message, Modal, Rate, Tabs, Input, Collapse } from "antd";
import { layDanhMucDanhGia, layDanhSachDanhGia } from '../../../../redux/actions/nguoiDungAction';
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useParams } from "react-router-dom";
import { LocalStorageUtil } from "../../../../utilities/localStorage";
import { NguoiDungSevice } from '../../../../service/nguoiDungService';
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Panel } = Collapse;
const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

export default function CauHoiKhoKhanDaHoi({ dataRoadMap }) {
    const [visible, setVisible] = useState(false);
    const [lstDanhGia, setLstDanhGia] = useState([]);
    const { id } = useParams();
    const danhMucDanhGia = useSelector(state => state.nguoiDungReducer.danhMucDanhGia);
    const dsDanhGia = useSelector(state => state.nguoiDungReducer.dsDanhGia);
    const thongTinDangNhap = LocalStorageUtil.getLoginLocalStorage();

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setTimeout(() => {
            setVisible(false);
        }, 3000);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>
            <span className=" text-sm" onClick={showModal}>
                Các câu hỏi khó khăn đã hỏi
            </span>
            <Modal
                visible={visible}
                title="Các câu hỏi khó khăn đã hỏi"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width="90vw"
                height="90vh"
                style={{ top: 10 }}
                bodyStyle ={{width: "90vw"}}
            >
                {dataRoadMap?.danhSachKhoKhanDaHoi?.map(cauHoiKhoKhan => {
                    return (
<Collapse
            // onChange={callback}
            bordered={false}
            // expandIcon={({ isActive }) => (
            //   <CaretRightOutlined rotate={isActive ? 90 : 0} />
            // )}
            ghost
            style={{width:"100%"}}
          >
            <Panel
              // header={ <span>Câu hỏi: </span> {data.tieuDe}}
              key="1"
              className="rounded-xl overflow-hidden"
              header={
                <div style={{paddingTop: "14px"}}>
                  <p>Câu hỏi ngày {moment(cauHoiKhoKhan.ngayHoi).format('DD/MM/YYYY')}</p>

                </div>
              }
            >
              <div className="space-y-2">
                <div className="text-red-500 w-100">
                    {cauHoiKhoKhan.noiDungTraLoi}
                </div>
                <div className="text-right text-green-500">
                  {ReactHtmlParser(cauHoiKhoKhan.noiDungXuLy)}
                </div>
              </div>
            </Panel>
          </Collapse>
                    )
                })}
                
            </Modal>
        </>
    );
}


