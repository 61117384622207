import React from "react";
import Confetti from "react-confetti";

export default function Overlay_ChucMung({ width = 0, height = 0 }) {
  return (
    <div className="absolute l-0 t-0" style={{ width, height }}>
      <Confetti opacity={0.7} gravity={0.05} width={width} height={height} />
    </div>
  );
}
