import React from 'react'

export default function LinkIconEmailComponent(props) {


    const { linkUrl, icon  } = props
  
  return <div className="col-12  mt-3">
    <a
              href={linkUrl}
              target={`mailto:${props.children}`}
              rel="nofollow noopener"
            className='link'
           
            >
          <span className="mt-4" style={{fontSize: "0.6rem"}} >
          <i className={icon} aria-hidden="true">
  
          </i>
               <span className="text-inherit ml-1">
                
                {props.children}
                
              
                </span> 
            
          </span>
          </a></div>
  }
  