import React from "react";
import CollapseRoadMap from "./CollapseRoadMap";

export default function RoadMapToogleList({ dataRoadMap }) {
  let renderContent = () => {
    return dataRoadMap.danhSachBuoiHocTheoSkill?.map((item, index) => {
      // console.log("item", item);
      return (
        <CollapseRoadMap
          data={item}
          key={index + item?.tenSkill}
          indexChuongHoc={index}
        />
      );
    });
  };
  return (
    <div className=" w-full  xl:p-5 pb-16 space-y-10    ">
      <p className="font-medium text-center">CHI TIẾT LỘ TRÌNH</p>
      <div className="w-full  space-y-28 pb-14">{renderContent()}</div>
    </div>
  );
}
