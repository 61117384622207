import React from "react";
import BuoiHoc from "../BuoiHoc";

export default function ChuongHocMobile({ tenChuong, data, indexBuoi }) {
  // console.log(data);
  return (
    <div>
      <h3 className="font-bold text-lg pb-3">
        Chương {indexBuoi + 1} :{tenChuong}
      </h3>
      <div className="space-y-4">
        {data.danhSachBuoiHoc.map((item, index) => {
          if (item.videoXemLai.length > 0 || item.videoExtra.length > 0) {
            return (
              <BuoiHoc
                chuongSo={indexBuoi}
                data={item}
                key={index}
                isShow={
                  item.videoExtra.length > 0 ||
                  // item.tracNghiem.length > 0 ||
                  item.videoXemLai.length > 0
                }
              />
            );
          }
        })}
      </div>
    </div>
  );
}
