import React, { Component } from "react";
import { Tabs, Result } from "antd";
import { connect } from "react-redux";
import TeamImage from "../../assets/images/team.jpg";
import history from "../../utilities/history";
import "./KhoaHocPage.scss";
import BaiTap from "./bai-tap/BaiTap";
import { DOMAIN_API } from "../../config/urlConfig";
import { dateFormat } from "../../utilities/dateTimeFormat";
import TaiLieu from "./tai-lieu/TaiLieu";
import * as baiTapActions from "../../redux/actions/baiTapActions";
import DiemBaiTap from "./bai-tap/DiemBaiTap";
import { LocalStorageUtil } from "../../utilities/localStorage";

import * as khoaHocActions from "../../redux/actions/khoaHocAction";
import XemLaiBuoiHoc from "./xem-lai/XemLaiBuoiHoc";
import { Tooltip } from "antd";

// import _ from 'lodash'
const { TabPane } = Tabs;

class KhoaHocPage extends Component {
  state = {
    lopHoc: {},
  };

  componentDidMount = () => {
    if (this.props.location !== null && this.props.location.state) {
      const { lopHoc } = this.props.location.state;
      this.setState({ lopHoc });
    } else {
      history.push("/login");
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { lopHoc } = props.location.state;
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    if (lopHoc.id != state.lopHoc.id) {
      return {
        ...state,
        lopHoc: lopHoc,
      };
    }
  }

  onGoToLesson = (id) => {
    history.push("/lesson", { maKhoaHoc: id });
  };

  renderKhoaHoc = (dsKhoaHoc) => {
    const { lopHoc } = this.props.location.state;

    return dsKhoaHoc
      ? dsKhoaHoc.map((item, index) => {
          if (lopHoc.id == 100 && (item.id == 12 || item.id == 15)) return;

          return (
            <div key={index} className="col-md-3 course-item">
              <div className="card">
                <img
                  className="card-img-top"
                  src={`${DOMAIN_API}${item.hinhAnh}`}
                  alt="Card image"
                />
                <div className="card-body">
                  <h5 className="card-title">{item.tenKhoaHoc}</h5>
                  <div className="card-text">
                    <i className="fa fa-server"></i>
                    <span>Số bài học: {item.danhSachChuongHoc.length} bài</span>
                  </div>
                  {/* <div className="card-text"> */}
                  {/* <i className="fa fa-hourglass-half"></i> */}
                  {/* <span>Thời gian mở: {item.soNgayKichHoat} ngày</span> */}
                  {/* </div> */}
                  {/* <a className="card-text" href={`${DOMAIN_API}/${item.taiLieu}`} target="_blank" download>
                            <i className="fa fa-download"></i>
                            Tài liệu
                        </a> */}
                  <a
                    onClick={() => this.onGoToLesson(item.id)}
                    className="btn btn-warning btn-detail"
                  >
                    Chi tiết
                  </a>
                </div>
              </div>
            </div>
          );
        })
      : null;
  };

  checkGiaHanHocVien = (checkDateEnd, dsHocVien) => {
    let nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    let lstHocVien = JSON.parse(dsHocVien);

    let hocVien = lstHocVien.find((n) => n.Id == nguoiDung.id);

    if (checkDateEnd > 60) {
      if (hocVien && hocVien.giaHan) {
        let dNow = new Date();
        let dGiaHan = new Date(hocVien.giaHan);

        if (
          Date.parse(dNow.toDateString()) < Date.parse(dGiaHan.toDateString())
        )
          return false;
      }

      return true;
    }

    return false;
  };

  copyToken = (lopHoc) => {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    // dummy.value = `http://nhaphoc.cybersoft.edu.vn/?${res.data.content}`;
    dummy.value = lopHoc.token;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  render() {
    const { dsKhoaHoc, dsBaiTap } = this.props.khoaHocProps;
    const { dsLopTaiLieu, dsBaiTapNop, dsXemLai } = this.props;

    const { lopHoc } = this.state;
    let nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    //tinh ngay ket thuc cach 2 thang = 60 day
    let checkDateEnd =
      (new Date() - Date.parse(lopHoc.ngayKetThuc)) / (1000 * 3600 * 24);

    let dGiaHan = "";

    let lstHocVien = JSON.parse(lopHoc.danhSachHocVienNew);

    let hocVien = lstHocVien.find((n) => n.Id == nguoiDung.id);

    if (hocVien) {
      if (hocVien.giaHan) {
        dGiaHan = dateFormat(hocVien.giaHan);
      }
    }

    return (
      <div className="course-list row">
        <div className="col-12 introduce">
          <div className="icon-box">
            <i className="fa fa-graduation-cap"></i>
          </div>
          <div className="info">
            <div className="left">
              <h5>{nguoiDung.hoTen}</h5>
              <p>
                <span>Email: </span> {nguoiDung.email}
              </p>
              <p>
                <span>Số ĐT:</span> {nguoiDung.soDT}
              </p>
              {this.checkGiaHanHocVien(
                checkDateEnd,
                lopHoc.danhSachHocVienNew
              ) == false && (
                <Tooltip title="Đã copy" trigger="click">
                  <button
                    className="btn btn-success"
                    onClick={() => this.copyToken(lopHoc)}
                  >
                    Token
                  </button>
                </Tooltip>
              )}
            </div>
            <div className="right">
              <h5>Lớp {lopHoc.tenLopHoc}</h5>
              <p>
                <span>Ngày bắt đầu:</span> {dateFormat(lopHoc.ngayBatDau)}
              </p>
              <p>
                <span>Ngày kết thúc:</span> {dateFormat(lopHoc.ngayKetThuc)}
              </p>
            </div>
            <div className="right">
              {dGiaHan != "" ? (
                <p>
                  <span>Ngày hết hạn:</span> {dGiaHan}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="col-12">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Danh sách khóa học" key="1">
              {this.checkGiaHanHocVien(
                checkDateEnd,
                lopHoc.danhSachHocVienNew
              ) == true ? (
                <Result title="Bạn đã hết thời gian truy cập vào tài nguyên này !" />
              ) : (
                <div className="row">{this.renderKhoaHoc(dsKhoaHoc)}</div>
              )}
            </TabPane>
            {/* <TabPane tab="Danh sách bài tập" key="2">
                            <BaiTap dsBaiTap={{dsBaiTap}} maLopHoc={lopHoc.id} />
                        </TabPane> */}
            <TabPane tab="Danh sách tài liệu / bài tập" key="3">
              {this.checkGiaHanHocVien(
                checkDateEnd,
                lopHoc.danhSachHocVienNew
              ) == true ? (
                <Result title="Bạn đã hết thời gian truy cập vào tài nguyên này !" />
              ) : (
                <div>
                  <h4 className="p-3">
                    <b> Chú ý:</b>
                    <br />
                    - Bài làm nộp qua link online (google drive, github,...)
                    <br />- File bài nộp đặt tên theo định dạng: [Tên lớp]_[Tên
                    bài tập]_[Tên của bạn]
                  </h4>
                  <TaiLieu
                    dsBaiTap={dsBaiTap}
                    dsLopTaiLieu={dsLopTaiLieu}
                    dsBaiTapNop={dsBaiTapNop}
                    maLopHoc={lopHoc.id}
                  />
                </div>
              )}
            </TabPane>

            <TabPane tab="Điểm bài tập" key="4">
              <DiemBaiTap
                dsBaiTap={dsBaiTap}
                dsLopTaiLieu={dsLopTaiLieu}
                dsBaiTapNop={dsBaiTapNop}
                lopHoc={lopHoc}
              />
            </TabPane>

            <TabPane tab="Xem lại buổi học" key="5">
              {this.checkGiaHanHocVien(
                checkDateEnd,
                lopHoc.danhSachHocVienNew
              ) == true ? (
                <Result title="Bạn đã hết thời gian truy cập vào tài nguyên này !" />
              ) : (
                <XemLaiBuoiHoc dsXemLai={dsXemLai} />
              )}
            </TabPane>
            {/* <TabPane tab={<p>Extra</p>} key="6"></TabPane> */}
          </Tabs>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    khoaHocProps: state.khoaHocReducer,
    dsLopTaiLieu: state.baiTapReducer.dataLopBaiTap,
    dsBaiTapNop: state.baiTapReducer.dsBaiTapNop,
    dsXemLai: state.lopHocReducer.dsXemLai,
  };
}

export default connect(mapStateToProps, null)(KhoaHocPage);
