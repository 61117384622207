import { Avatar, Button, Divider, Spin, Tabs, Tooltip, Tag, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import Navbar from '../../template/navbar/Navbar'
import SidebarNew from '../../template/SidebarNew/sidebarNew'
import backGrountTemplate from "../../assets/images/background.png";
import { useDispatch, useSelector } from 'react-redux';
import nguoiDungReducer from '../../redux/reducers/nguoiDungReducer';
import { layTimeTruyCapHeThong } from '../../redux/actions/nguoiDungAction';
import { Line } from 'react-chartjs-2';
import { getDayName, getHinhAnhFB, getHoVaTen, maskEmail } from '../../utilities/utils';
import { generateOption, generateOption2, gerateDataSetChart } from './GenerateDataSetChart';
import { LocalStorageUtil, setCourseLocalStorage } from '../../utilities/localStorage';
import circleBg from "./circleAvatar.png";
import { NguoiDungSevice } from '../../service/nguoiDungService';
import { getDataReportDSBuoiHocTheoSkill } from '../roadmapPage/UserDashboard/ReportBangDiem/report_utils';
import { BangDiem } from '../hocvien-profile/BangDiem';
import { useParams, us } from 'react-router-dom';
import { FollowSevice } from '../../service/followUserService';
import history from '../../utilities/history';
import { DeploymentUnitOutlined, GithubOutlined, YoutubeOutlined } from '@ant-design/icons';
import VideoPlayer from './VideoPlayer';
import ModalDuAn from './ModalDuAn';
import { RoadmapService } from '../../service/roadmapService';


const ProfileNguoiDung = () => {
  const { userId } = useParams()
  const userInfor = LocalStorageUtil.getLoginLocalStorage();
  const [profile, setProfile] = useState()
  const [loading, setLoading] = useState(false)
  const [labeArr, setLabeArr] = useState()
  const [diem, setDiem] = useState()
  const [follower, setFollower] = useState([])
  const [following, setFollowing] = useState([])
  const [isFollow, setIsFollow] = useState(false)
  const [open, setOpen] = useState(false)
  const [itemDuAn, setItemDuAn] = useState()
  const [lsCaps, setLsCaps] = useState([])

  const checkOwner = userInfor.id == userId
  let { lsThoiGian } = useSelector(state => state.nguoiDungReducer)
  const dispatch = useDispatch()
  const data = (canvas) => {
    if (lsThoiGian.length) {
      return {
        labels: labeArr,
        datasets: gerateDataSetChart(checkOwner ? lsThoiGian : [], canvas, userInfor),
      };
    } else {
      return { labels: labeArr, datasets: [] };
    }
  };
  const handleChangeFilter = () => {
    let labels = getDayName(7, 0);
    setLabeArr(labels)
  };
  const follow = () => {
    FollowSevice.follow(userId, !isFollow).then(res => {
      setIsFollow(!isFollow)
      fetchFollow()
    }).catch(err => { })
  }
  const isYouTubeLink = (url) => {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return pattern.test(url);
  };
  const editDuAn = (item) => {
    setItemDuAn(item)
    setOpen(true)
  }
  const openModal = () => {
    console.log('mở modal')
    setOpen(true)
  }
  const closeModal = () => {
    setItemDuAn()
    setOpen(false)
  }
  const fetchFollow = () => {
    //ds follow
    FollowSevice.dsFollow(userId, 0).then(res => {
      setFollower(res.data.content)
    }).catch(err => setFollower())

    FollowSevice.dsFollow(userId, 1).then(res => {
      setFollowing(res.data.content)
    }).catch(err => setFollowing())
  }
  const fetchData = () => {
    setLoading(true)
    NguoiDungSevice.getDataInforProfile(userId).then(res => {
      setProfile(res.data.content)
      setLoading(false)
    }).catch(err => setProfile(
      setLoading(false)
    ))
  }
  useEffect(() => {
    dispatch(layTimeTruyCapHeThong(0))
    //Get profile
    fetchData()
    //Get Điểm
    // if (checkOwner) {
      NguoiDungSevice.getDataInforProfilePoint(userId).then(res => {
        setDiem(res.data.content)
      }).catch(error => {
        setDiem()
      })
    // }
    //check follow
    FollowSevice.checkFollow(userId).then(res => {
      setIsFollow(res.data.content)
    }).catch(err => setIsFollow(false))
    //follow
    fetchFollow()

  }, [userId])
  useEffect(() => {
    handleChangeFilter()
  }, [lsThoiGian])
  useEffect(() => {
    RoadmapService.getCaps().then(res => {
      setLsCaps(res.data.content)
    }).catch(err => setLsCaps([]))
  }, [])

  return (
    <div
      style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
      className="h-max-content min-h-screen w-full  flex gioiThieu"
    >

      <div className="w-60">
        <SidebarNew />
      </div>
      <div
        style={{ flexGrow: "1" }}
        className="h-max-cotent flex flex-col  min-h-screen "
      >
        <Navbar />

        <div className=' p-3'>



          <div className='h-64 2xl:h-80 flex space-x-3 w-full flex-shrink-0 items-stretch'>
            <div className='info_card card_theme w-2/5 lg:w-2/5 md:w-full    h-full flex flex-col justify-center relative'>

              <div className="flex  flex-col">
                <Spin spinning={loading}><div className="flex p-5 pb-8">
                  <div className="  flex items-center justify-center relative">
                    <div
                      style={{
                        width: '120px',
                        height: '120px',
                      }}
                      className="relative flex-shrink-0"
                    >
                      <img src={circleBg} alt="" className="absolute transform" style={{ scale: '1.45' }} />

                      <Avatar style={{ width: '120px', height: '120px' }} src={getHinhAnhFB(profile?.facebookId, profile)} />
                      <div
                        style={{
                          transform: `translate(-50%,20%)`,
                        }}
                        className="absolute -bottom-3 left-1/2 z-20 "
                      ></div>
                    </div>

                    <div className="pl-10">
                      <p className="text-title "><span className='font-semibold capitalize'>{profile?.hoTen.length < 20 ? profile?.hoTen : getHoVaTen(profile?.hoTen)}</span></p>
                      <p className="text-title " title={checkOwner ? profile?.email : maskEmail(profile?.email)}>Email: <span className=''>{checkOwner ? profile?.email.length > 25 ? profile?.email.substring(0, 22) + "..." : profile?.email : maskEmail(profile?.email)}</span></p>
                      <p className="text-title ">SDT: <span className=''>{checkOwner ? profile?.soDT : "0*********"}</span></p>

                      <div className="flex space-x-2 items-center relative">
                        {/* <ModalDanhHieu danhHieu={userInfo.danhHieu} />

                  <p className="text-yellow name capitalize rank">
                    {userInfor.danhHieu?.tenDanhHieuEN}
                  </p> */}
                      </div>
                    </div>
                  </div>
                </div></Spin>

                <div className="flex-grow   ">
                  <div className="space-y-2 px-2">
                    {!checkOwner && <button className='text-white bg-blue-theme w-32 transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300' onClick={follow}>{isFollow ? "Đang follow" : "Follow"}</button>}
                  </div>
                </div>
              </div>
            </div>
            <div className="  info_card card_theme w-3/5 lg:w-3/5 md:w-full  h-full flex flex-col justify-center relative " style={{width:"60%"}} >
              <Line
                data={data}
                options={generateOption2(checkOwner ? lsThoiGian : [])}
                plugins={{}}
              />
            </div>

          </div>
          <div>
            <Divider />
            <div className="w-full info_card card_theme">
              <Tabs defaultActiveKey="1" onChange={(value) => {

              }}>

                <Tabs.TabPane tab="Tường nhà" key="1">
                  <Spin spinning={loading}>
                    {
                    // checkOwner ? 
                    diem?.map((item, idx) => {
                      let dataReport = getDataReportDSBuoiHocTheoSkill(item.danhSachPoint);
                      return (
                        <div key={idx} className="certificate-item" style={{ padding: '0px 10px' }}>
                          <BangDiem dataReport={dataReport} tenLop={item.tenLop} />
                        </div>
                      )
                    }) 
                    // : <p className='p-3'>Bạn không thể xem bảng điểm của người khác</p>
                    }
                  </Spin>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Dự án" key="2">
                  {checkOwner && <div className="text-right mb-3">
                    <button className='text-white bg-blue-theme w-32 transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300' onClick={openModal}>Thêm dự án</button>
                  </div>}
                  <div className="grid grid-cols-2 gap-5">

                    {profile?.danhSachDuAn.length == 0 && <p className='p-3'>Chưa có dự án</p>}
                    {profile?.danhSachDuAn?.map((item, idx) => {
                      const match = isYouTubeLink(item.linkYoutube)
                      let videoId;

                      if (item.linkYoutube.includes("youtube.com")) {
                        videoId = item.linkYoutube.split("v=")[1];
                        const ampersandPosition = videoId.indexOf("&");
                        if (ampersandPosition !== -1) {
                          videoId = videoId.substring(0, ampersandPosition);
                        }
                      } else if (item.linkYoutube.includes("youtu.be")) {
                        videoId = item.linkYoutube.split(".be/")[1];
                      }
                      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
                      return (
                        <div className="card cursor-pointer rounded-lg" >
                          <div className="cursor-pointer rounded-lg relative overflow-hidden">
                            <iframe
                              width="560"
                              height="315"
                              src={embedUrl}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="Embedded youtube"
                            />
                            <div className="footer space-y-3 p-3">
                              <div className="flex flex-wrap justify-content-between">

                                <a
                                  href={item.linkDeploy}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ background: 'rgb(219, 234, 254)' }}
                                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium text-blue-800 transition duration-300 mr-1"
                                >
                                  <DeploymentUnitOutlined style={{ fontSize: '20px', marginRight: '10px' }} /> Deploy
                                </a>

                                {checkOwner && <button onClick={() => { 
                                  editDuAn(item)
                                 }}>
                                  <i className='fa-solid fa-pen'></i>
                                </button>}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                      //{
                      //   return <div
                      //   key={idx}
                      //   className="item_caps bg-gray-50 m-2 rounded-lg p-4 transition duration-300"
                      // >
                      //   <h3 className="text-lg font-semibold text-gray-800 mb-2">

                      //     {item.noiDung}
                      //     <Tag className="ml-2"> Score: {item.diem / 10}/10</Tag>
                      //   </h3>

                      // </div>
                      //}
                    )}
                  </div>
                </Tabs.TabPane>

                {/* <Tabs.TabPane tab="Thành tích" key="3" className="relative">
                  <p className='p-3'>Thành tích</p>
                </Tabs.TabPane> */}
                <Tabs.TabPane tab="Người theo dõi" key="4" on>
                  {follower.length == 0 && <p className='p-3'>Chưa có người theo dõi</p>}
                  {follower?.map((item, idx) => {
                    return <div
                      onClick={() => {
                        history.push(`/profile/${item.id}`);
                      }}
                      className="flex items-center  space-x-3 text-content  py-1 cursor-pointer item_follow px-5 rounded"
                    >
                      <img
                        src={getHinhAnhFB(item?.facebookId, item)}
                        alt=""
                        className="w-16 h-16 object-cover rounded-full"
                      />
                      <div className=" space-y-1">
                        <p className="name capitalize text-2xl text-title mb-0">{item?.hoTen}</p>
                        {/* <p className="name capitalize rank">{item?.danhHieu?.tenDanhHieu}</p> */}
                        <p>
                          <span className="font-medium">{maskEmail(item?.email)}</span>
                        </p>
                      </div>
                    </div>
                  })}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Đang theo dõi" key="5">
                  {following.length == 0 && <p className='p-3'>Chưa có người theo dõi</p>}
                  {following?.map((item, idx) => {
                    return <div key={idx}
                      onClick={() => {
                        history.push(`/profile/${item.id}`);
                      }}
                      className="flex items-center  space-x-3 text-content  py-2 cursor-pointer item_follow px-5 rounded "
                    >
                      <img
                        src={getHinhAnhFB(item?.facebookId, item)}
                        alt=""
                        className="w-16 h-16 object-cover rounded-full"
                      />
                      <div className=" space-y-1">
                        <p className="name capitalize text-2xl text-title  mb-0">{item?.hoTen}</p>
                        {/* <p className="name capitalize rank">{item?.danhHieu?.tenDanhHieu}</p> */}
                        <p>
                          <span className="font-medium">{maskEmail(item?.email)}</span>
                        </p>
                      </div>
                    </div>
                  })}
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>


        </div>


      </div >

      {open && <Modal
        title={itemDuAn?"Chỉnh sửa dự án":"Thêm dự án"}
        visible={open}
        onCancel={closeModal}
        footer={null}
        width={'80%'}

      >
        <ModalDuAn close={closeModal} item={itemDuAn} caps={lsCaps} fecthData={fetchData} />
      </Modal>}
    </div >
  )
}

export default ProfileNguoiDung