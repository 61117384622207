import React from "react";

export default function OptionItem({
  title,
  contentModal,
  icon,
  setIsModalVisible,
  setTitleModal,
  setContentModal = () => {},
}) {
  return (
    <div
      className="w-full flex space-x-2 items-center cursor-pointer "
      onClick={() => {
        setIsModalVisible(true);
        setTitleModal(title);
        setContentModal(contentModal);
      }}
    >
      {icon} <span>{title}</span>
    </div>
  );
}
