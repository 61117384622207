import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonFacebook from "../../pages/dang-nhap/ButtonFacebook";
import { LocalStorageUtil } from "../../utilities/localStorage";
import history from "../../utilities/history";
import { DOMAIN_API } from "../../config/urlConfig";

export default function LoginEmail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isShow, setIsShow] = useState(true);
  useEffect(() => {
    axios
      .post(`${DOMAIN_API}/api/config/bat-login-temp`)
      .then((res) => {
        console.log(res);
        setIsShow(!res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let handleLogin = (value) => {
    axios
      .post(`${DOMAIN_API}/api/nguoidung/login-temp`, value)
      .then((res) => {
        console.log(res);
        message.success("Đăng nhập thành công");
        LocalStorageUtil.setLoginLocalStorage(res.data.content);
        // CHUYỂN HƯỚNG ĐẾN TRANG CHỦ
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.content);
      });
  };
  return !isShow ? (
    <ButtonFacebook />
  ) : (
    // <></>
    <div className="w-full flex items-center px-5">
      <form
        className="space-y-2 w-full "
        onSubmit={handleSubmit((data) => handleLogin(data))}
      >
        <div>
          <label
            htmlFor="first_name"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Gmail
          </label>
          <input
            {...register("email", {
              required: "Email không được bỏ trống",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Email không hợp lệ",
              },
            })}
            type="text"
            id="first_name"
            className=" border  text-gray-900 text-sm rounded   block w-full p-2.5  border-gray-600 placeholder-gray-400   "
            placeholder=""
          />
          {errors.email && (
            <p className="text-red-600 text-sm">{errors.email?.message}</p>
          )}
        </div>
        <div>
          <label
            htmlFor="last_name"
            className="block mb-2 text-sm font-medium text-gray-900 "
          >
            Số điện thoại
          </label>
          <input
            {...register("phone", {
              required: "Số điện thoại không được bỏ trống",
              pattern: {
                value: /^\d{10}$/,
                message: "Số điện thoại không hợp lệ",
              },
            })}
            type="text"
            id="last_name"
            className=" border  text-gray-900 text-sm rounded   block w-full p-2.5  border-gray-600 placeholder-gray-400   "
            placeholder=""
          />
          {errors.phone && (
            <p className="text-red-600 text-sm">{errors.phone?.message}</p>
          )}
        </div>
        <button type="submit" className="btn btn-success">
          Đăng nhập
        </button>
      </form>
    </div>
  );
}
