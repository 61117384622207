import React from "react";

function SpanAnswer({ children }) {
  return (
    <span className="px-3 py-1 m-2 rounded text-white bg-green-800">
      {children}
    </span>
  );
}

export default function UserAnswer({ question, userAnswers }) {
  if (!userAnswers || !question) {
    return <></>;
  }
  let { MaLoaiBaiTap, TraLoi } = userAnswers;
  let contentHTML = "SINGLE";
  if (MaLoaiBaiTap == "SINGLE" || MaLoaiBaiTap == "fill_input") {
    contentHTML = <SpanAnswer>{TraLoi}</SpanAnswer>;
  }
  if (
    MaLoaiBaiTap == "multiple_choice" ||
    MaLoaiBaiTap == "fill_inblank_css" ||
    MaLoaiBaiTap == "fill_inblank"
  ) {
    let arrID = JSON.parse(TraLoi);

    let arrContent = [];
    arrID.forEach((id, index) => {
      question.noiDung.cauTraLoi.forEach((item) => {
        if (id == item.ma) {
          arrContent.push(item);
        }
      });
    });
    contentHTML = arrContent.map((item) => {
      return <SpanAnswer>{item.noiDung}</SpanAnswer>;
    });
  }

  return (
    <div className="p-3">
      <div className="px-5 py-3 rounded border border-gray-400">
        <p className="text-dark text-lg">Câu trả lời của bạn: </p>
        <div className=" flex flew">{contentHTML}</div>
      </div>
    </div>
  );
}
