import React, { useState, useEffect } from "react";
import Practices_ChooseAnswer from "../Pratices/Practices_ChooseAnser";
import Practices_ChooseAnswerToFill from "../Pratices/Praticees_ChooseAnserToFill";
import Practices_HTML_CSS from "../Pratices/Practices_HTML_CSS";
import Practices_FillCodeToInput from "../Pratices/Practices_FillCodeToInput";
import Practices_MultipleChoice from "../Pratices/Practices_MultipleChoice";
import _ from "lodash";
import Navigate_Footer_Pratices from "./Navigate_Footer_Pratices";
import "./Praticess.css";
export default function TracNghiem_Start({ data }) {
  let [currentQuestionIndex, setCurrentQuestsionIndex] = useState(0);
  let [scoreQuiz, setScoreQuiz] = useState(null);
  const [allQuestions, setListQuestion] = useState([]);
  useEffect(() => {
    let listQuestionRaw = JSON.parse(data.noiDung).map((item, index) => {
      return {
        id: index,
        noiDung: item,
        isCorrect: false,
        userAnsers: [],
      };
    });
    let shuffled_question = _.shuffle(listQuestionRaw);
    let questionFinal = shuffled_question.slice(0, 10);
    setListQuestion(questionFinal);
  }, []);

  let handleClickNextQuestion = () => {
    setCurrentQuestsionIndex(currentQuestionIndex + 1);
  };

  let handle_CheckSingleChoice = (id, value, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (value) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }

    newCurrentQuestion.userAnsers = userAnsers;
    let newAllQuestion = [...allQuestions];

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckMultipleChoice = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    let arrDapAn = [...newCurrentQuestion.noiDung.dapAn];
    if (arrDapAn.sort().toString() == userAnsers.sort().toString()) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];
    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFillInput = (id, userAnsers = []) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };

    let arrDapAn = [...newCurrentQuestion.noiDung.dapAn];
    if (arrDapAn.sort().toString() == userAnsers.sort().toString()) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }

    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;
    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank_CSS = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    newCurrentQuestion.userAnsers = userAnsers;
    let newAllQuestion = [...allQuestions];
    newAllQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let arrRenderQuestion = [];
  arrRenderQuestion = allQuestions.map((question, index) => {
    // console.log(
    //   "🚀 ~ file: TracNghiem_Start.js ~ line 138 ~ arrRenderQuestion=allQuestions.map ~ question",
    //   question.noiDung.inPut
    // );

    let keyIndex = index + data?.id;
    switch (question?.noiDung.maLoaiBaiTap) {
      case "SINGLE":
        return (
          <Practices_ChooseAnswer
            key={keyIndex}
            question={question}
            handle_CheckSingleChoice={handle_CheckSingleChoice}
          />
        );
      case "multiple_choice":
        return (
          <Practices_MultipleChoice
            key={keyIndex}
            handle_CheckMultipleChoice={handle_CheckMultipleChoice}
            question={question}
          />
        );
      case "fill_inblank_css":
        return (
          <Practices_HTML_CSS
            key={keyIndex}
            handle_CheckFinll_IN_Blank_CSS={handle_CheckFinll_IN_Blank_CSS}
            question={question}
          />
        );
      case "fill_inblank":
        return (
          <Practices_ChooseAnswerToFill
            key={keyIndex}
            handle_CheckFinll_IN_Blank={handle_CheckFinll_IN_Blank}
            question={question}
          />
        );
      case "fill_input":
        return (
          <Practices_FillCodeToInput
            key={keyIndex}
            question={allQuestions[currentQuestionIndex]}
            handle_CheckFillInput={handle_CheckFillInput}
          />
        );

      default:
        break;
    }
  });
  let isDisableNextBtn;

  let typeQuestion = allQuestions[currentQuestionIndex]?.noiDung.maLoaiBaiTap;
  if (typeQuestion === "SINGLE" || typeQuestion === "multiple_choice") {
    isDisableNextBtn =
      allQuestions[currentQuestionIndex]?.userAnsers?.length === 0;
  }
  if (typeQuestion === "fill_inblank_css" || typeQuestion === "fill_inblank") {
    isDisableNextBtn = !allQuestions[currentQuestionIndex].userAnsers[0];
  }

  return (
    <div className="w-full  flex-grow h-full flex flex-col ">
      {scoreQuiz || data.diem ? (
        <div className="flex flex-col justify-center items-center h-full text-lg">
          <p>{data.tieuDe} </p>
          <div className="space-x-2">
            <i className="fa fa-check text-green-600"></i>
            <span> {scoreQuiz || data.diem} điểm</span>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full h-full  flex-grow flex flex-col  p-3 relative overflow-y-scroll">
            <div className="w-full  ">
              {arrRenderQuestion[currentQuestionIndex]}
            </div>
            <div className="h-22 w-full"></div>
          </div>
          <Navigate_Footer_Pratices
            current={currentQuestionIndex + 1}
            total={allQuestions.length}
            handleClickNextQuestion={handleClickNextQuestion}
            isDisableBtn={isDisableNextBtn}
            listQuestion={allQuestions}
            dataKhoaHoc={data}
            setScoreQuiz={setScoreQuiz}
          />{" "}
        </>
      )}
    </div>
  );
}
