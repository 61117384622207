import React, { useEffect, useState } from "react";
import MenuVideoXemTruoc from "../MenuNewKhoaHocLienQuan/MenuVideoXemTruoc/MenuVideoXemTruoc";
import ContentVideo from "../../ItemRoadMap/ContentModal/ContentKhoaHocLienQuan/ContentVideo";
import { useDispatch, useSelector } from "react-redux";
import { RoadmapService } from "../../../../../../service/roadmapService";
import { useParams } from "react-router-dom";
import { LOAI_TASK_VIDEO_XEMTRUOC } from "../../../../../../redux/types";
import { getTaskRoadMapServAction } from "../../../../../../redux/actions/roadMapAction";
import HtmlParser from "react-html-parser";

export default function VideoXemTruoc({
  data,
  dataHash,
  maBuoiHoc,
  lopHocActive,
}) {
  const dispatch = useDispatch();
  const { itemVideoXemTruoc, dataTaskRoadMap } = useSelector(
    (state) => state.roadmapReducer
  );
  const [checkTaskRoadMap, setCheckTaskRoadMap] = useState(true);
  const { id } = useParams();
  const [camCo, setCamCo] = useState(false);
  const countTaskTrue = (dataTask, dataRoadMapItem) => {
    let count = 0;
    for (let key in dataRoadMapItem) {
      if (dataTask[dataRoadMapItem[key]?.id].isHoanThanh === true) {
        count++;
      }
    }
    if (count === dataRoadMapItem.length && count !== 0) {
      dispatch({
        type: "SET_CHUCMUNG_XONGTASK",
        payload: true,
        titleChucMungXongTask:
          " Chúc mừng bạn đã hoàn thành task video xem trước",
      });
    }
  };

  useEffect(() => {
    if (data && camCo && dataTaskRoadMap.size !== 0) {
      countTaskTrue(
        dataTaskRoadMap.get(maBuoiHoc.toString()).danhSachVideoXemTruoc,
        data
      );
    }
  }, [camCo, dataTaskRoadMap]);

  const handleCheckHoanThanh = () => {
    let submitHoanThanh = {
      maBuoiHoc: maBuoiHoc,
      maBaiHoc: itemVideoXemTruoc.item.id,
      maLop: +id,
      loaiTask: LOAI_TASK_VIDEO_XEMTRUOC,
    };
    // console.log(submitHoanThanh);
    RoadmapService.postCheckHoanThanhTaskRoadMap(submitHoanThanh)
      .then(() => {
        // dispatch(getDataRoadMapServAction(id));
        dispatch(getTaskRoadMapServAction(id));
        setCamCo(!camCo);
      })
      .catch((err) => {
        // message.error("đã có lỗi xảy ra");
        console.log(err);
      });
  };
  useEffect(() => {
    if (dataHash !== undefined) {
      if (
        dataTaskRoadMap.get(maBuoiHoc.toString()).danhSachVideoXemTruoc[
        itemVideoXemTruoc?.item?.id
        ] !== undefined
      ) {
        setCheckTaskRoadMap(
          dataTaskRoadMap.get(maBuoiHoc.toString()).danhSachVideoXemTruoc[
            itemVideoXemTruoc?.item?.id
          ].isHoanThanh
        );
      } else {
        setCheckTaskRoadMap(false);
      }
    }
  }, [itemVideoXemTruoc, dataTaskRoadMap]);


  return (
    <div className="heightVideo widthVideo flex space-x-2 justify-between ">
      <div className="flex-grow h-full rounded flex flex-col justify-between">
        <div className="w-full max-h-video-9 h-video-9">
          <ContentVideo />

        </div>
        <div className="flex flex-col justify-between">
          {/* <TabsNewVideoKhoaHocLienQuan /> */}
          {itemVideoXemTruoc.item && (
            <div className="flex justify-between">
              {
                itemVideoXemTruoc.item?.moTa && itemVideoXemTruoc.item?.moTa != "" &&
                <div>
                  <p>
                    <button className="btn btn-sm btn-outline-primary" type="button" data-toggle="collapse" data-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
                      Mô tả
                    </button>
                  </p>
                  <div style={{ minHeight: 120 }}>
                    <div className="collapse width" id="collapseWidthExample">
                      <div className="card card-body" style={{ width: 320 }}>
                        {HtmlParser(itemVideoXemTruoc.item.moTa)}
                      </div>
                    </div>
                  </div>
                </div>

              }

              {checkTaskRoadMap ? (
                <p className="font-medium text-color-blue-white m-0 p-2">
                  <i className="fa fa-check mr-2 text-sm flex-shrink-0 text-green-600"></i>
                  Đã hoàn thành
                </p>
              ) : (
                <div className="p-2">
                  {/* <Checkbox onClick={handleCheckHoanThanh}>Hoàn Thành</Checkbox> */}
                  <button
                    onClick={handleCheckHoanThanh}
                    className="py-2 px-3 text-white rounded-md btn-gradient-cyber"
                  >
                    Hoàn Thành Video
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <MenuVideoXemTruoc
        lopHocActive={lopHocActive}
        maBuoiHoc={maBuoiHoc}
        data={data}
        dataHash={dataHash}
      />
      {/* <SiderKhoaHocLienQuan data={[]} /> */}
    </div>
  );
}
