export const LAY_DANH_SACH_LOP_HOC_THANH_CONG =
  "LAY_DANH_SACH_LOP_HOC_THANH_CONG";

export const LAY_DANH_SACH_KHOA_HOC_THANH_CONG =
  "LAY_DANH_SACH_KHOA_HOC_THANH_CONG";

  export const LAY_DANH_SACH_KHOA_HOC_FREE_THANH_CONG =
  "LAY_DANH_SACH_KHOA_HOC_FREE_THANH_CONG";

  export const LAY_CHI_TIET_KHOA_HOC_FREE_THANH_CONG =
  "LAY_CHI_TIET_KHOA_HOC_FREE_THANH_CONG";

export const LAY_CHI_TIET_KHOA_HOC_THANH_CONG =
  "LAY_CHI_TIET_KHOA_HOC_THANH_CONG";

export const CAP_NHAT_CHI_TIET_KHOA_HOC = "CAP_NHAT_CHI_TIET_KHOA_HOC";

export const LAY_DANH_SACH_BAI_TAP_THANH_CONG =
  "LAY_DANH_SACH_BAI_TAP_THANH_CONG";

export const NOP_BAI_TAP = "NOP_BAI_TAP";
export const NOP_LAI_BAI_TAP = "NOP_LAI_BAI_TAP";
export const NOP_BAI_TAP_THANH_CONG = "NOP_BAI_TAP_THANH_CONG";
export const LAY_DANH_SACH_NOP_BAI_TAP_THANH_CONG =
  "LAY_DANH_SACH_NOP_BAI_TAP_THANH_CONG";

export const LAY_DANH_SACH_LOP_BAI_TAP_THANH_CONG =
  "LAY_DANH_SACH_LOP_BAI_TAP_THANH_CONG";

export const LAY_DANH_SACH_BAI_TAP_NOP_THANH_CONG =
  "LAY_DANH_SACH_BAI_TAP_NOP_THANH_CONG";

export const LAY_DANH_SACH_XEM_LAI_BUOI_HOC_THANH_CONG =
  "LAY_DANH_SACH_XEM_LAI_BUOI_HOC_THANH_CONG";

export const SPINNER_STARTED = "SPINNER_STARTED";
export const SPINNER_ENDED = "SPINNER_ENDED";

export const SET_SOURCE_VIDEO = "SET_SOURCE_VIDEO";
export const SET_DATA_ROADMAP = "SET_DATA_ROADMAP";
export const SET_TASK_ROADMAP = "SET_TASK_ROADMAP";
export const SET_CHUCMUNG_XONGTASK = "SET_CHUCMUNG_XONGTASK";
//  Loại Task
export const LOAI_TASK_TAILIEU_EXTRA = "DanhSachTaiLieuExtra";
export const LOAI_TASK_BAITAP_EXTRA = "DanhSachBaiTapExtra";
export const LOAI_TASK_CAPSTONE = "DanhSachCapstone";
export const LOAI_TASK_TRACNGHIEM = "DanhSachTracNghiem";
export const LOAI_TASK_BAITAP = "DanhSachBaiTap";
export const CHECK_VIDEO_XEMTRUOC = "CHECK_VIDEO_XEMTRUOC";
export const LOAI_TASK_VIDEO_XEMTRUOC = "DanhSachVideoXemTruoc";

export const LAY_DANH_MUC_DANH_GIA = "LAY_DANH_MUC_DANH_GIA";
export const LAY_DANH_SACH_DANH_GIA = "LAY_DANH_SACH_DANH_GIA";

export const LAY_USER_CV = "LAY_USER_CV";
export const CAP_NHAT_CV_LOCAL = "CAP_NHAT_CV_LOCAL";
export const LAY_USER_CV_V2 = "LAY_USER_CV_V2";

export const LAY_CONFIG_THANH_CONG = "LAY_CONFIG_THANH_CONG"
export const LAY_DANH_SACH_BO_DE_THANH_CONG = "LAY_DANH_SACH_BO_DE_THANH_CONG"

//lay voucher
export const LAY_VOUCHER = "LAY_VOUCHER";

//lay data runcode
export const GET_DATA_RUNCODE = "GET_DATA_RUNCODE";
export const RUNCODE_SUBMIT = "RUNCODE_SUBMIT";

//lay template cv
export const LAY_TEMPLATE_CV = "LAY_TEMPLATE_CV";


export const GET_QUIZ_AWS= "GET_QUIZ_AWS";
export const GET_QUIZ= "GET_QUIZ";
export const GET_LAYOUT= "GET_LAYOUT";

//tracking thời gian
export const CAP_NHAT_THOI_GIAN_ONLST= "CAP_NHAT_THOI_GIAN_ONLST";
export const CAP_NHAT_THOI_GIAN_ONLEN= "CAP_NHAT_THOI_GIAN_ONLEN";
export const LAY_THOI_GIAN_ONL= "LAY_THOI_GIAN_ONL";