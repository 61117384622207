import React, { Component } from 'react'
import { Tabs, Result, Tag } from 'antd';
import { connect } from 'react-redux';
import { dateFormat } from '../../../utilities/dateTimeFormat';
import { LocalStorageUtil } from '../../../utilities/localStorage';
import ReactToPrint from 'react-to-print';
import InBangDiem from './InBangDiem';



export default class DiemBaiTap extends Component {

    noiDungDiem = () => {
        const { dsBaiTap, dsLopTaiLieu, dsBaiTapNop } = this.props;
        const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

        let domNoiDung = [];

        let lstbaiTapNop = dsBaiTapNop.filter(n => n.maNguoiDung == nguoiDung.id);
        let sTT = 0;
        let tongDiem = 0;
        lstbaiTapNop.map(item => {
            sTT++;
            if (item.diem != -1)
                tongDiem += item.diem;

            let lopTaiLieu = dsLopTaiLieu.find(n => n.id == item.maBaiTap);

            let tenBaiTap = "";
            //0 tai lieu nguon ngoài, != 0 tai lieu trong nguon
            if (lopTaiLieu)
                if (lopTaiLieu.maBaiTap == 0) {
                    let dUrl = JSON.parse(lopTaiLieu.duongDan);
                    tenBaiTap = dUrl[0];
                } else {
                    let baiTap = dsBaiTap.find(n => n.id == lopTaiLieu.maBaiTap);

                    tenBaiTap = baiTap && baiTap.tenBaiTap;
                }
            domNoiDung.push(<tr>
                <th scope="row">{sTT}</th>
                <td>{tenBaiTap}</td>
                <td>{lopTaiLieu && dateFormat(lopTaiLieu.ngayTao)}</td>
                <td>{item.diem != -1 ? item.diem : <Tag color="red">Chưa chấm</Tag>}</td>
                <td>{item.diem != -1 ? item.diem / 10 : <Tag color="red">Chưa chấm</Tag>}</td>
            </tr>)
        })

        // domNoiDung.push(<tr>
        //     <th colSpan="3">Tổng điểm</th>
        //     <td>{tongDiem}</td>
        //     <td>{(tongDiem / 10).toFixed(1)}</td>
        // </tr>)
        // domNoiDung.push(<tr>
        //     <th colSpan="4">Điểm trung bình</th>
        //     <td>{(tongDiem / sTT / 10).toFixed(1)}</td>
        // </tr>)

        return domNoiDung;
    }

    render() {
        const { dsBaiTap, dsLopTaiLieu, dsBaiTapNop, lopHoc } = this.props;
        let kiemTra = true;

        const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

        let lstbaiTapNop = dsBaiTapNop.filter(n => n.maNguoiDung == nguoiDung.id);
        lstbaiTapNop.map(item => {

            if (item.diem == -1)
                kiemTra = false;
        })

        return (
            <div className="container">
                {/* {
                    new Date() > Date.parse(lopHoc.ngayKetThuc) ?
                        kiemTra ? <ReactToPrint
                            trigger={() => {

                                return <button className="btn btn-primary my-2" ><i className="fa fa-print"></i> In bảng điểm</button>;
                            }}
                            content={() => this.componentRef}
                        />

                            :
                            <h4 className="my-2 text-danger">Hãy hoàn thành hết bài tập để in bảng điểm</h4>
                        : <h4 className="my-2 text-info">Hãy đợi kết thúc khóa học để in bảng điểm</h4>

                }

                <div style={{ display: "none" }}>
                    <InBangDiem dsBaiTapNopHocVien={lstbaiTapNop} nguoiDung={nguoiDung} lopHoc={lopHoc} dsLopBaiTap={dsLopTaiLieu} dsBaiTap={dsBaiTap} ref={el => (this.componentRef = el)} />
                </div> */}
                <table className="table">

                    <tr className="thead-dark">
                        <th scope="col">#</th>
                        <th scope="col">Bài tập</th>
                        <th scope="col">Ngày làm</th>
                        <th scope="col">Điểm</th>
                        <th scope="col">Hệ 10</th>
                    </tr>
                    {this.noiDungDiem()}

                </table>

            </div>
        )
    }
}
