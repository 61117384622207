import React, { useEffect, useState } from "react";

export default function BtnBackToTop({ khoKhanHocVien }) {
  const [showGoTop, setShowGoTop] = useState(false);

  const handleVisibleButton = () => {
    setShowGoTop(window.pageYOffset > 70);
  };

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  }, []);
  if (khoKhanHocVien?.isKhoKhan) {
    // nếu hiện popup khó khăn học viên thì ẩn button back to top
    return <></>;
  }
  return (
    <button
      className={
        showGoTop
          ? " w-14 h-14 flex flex-col justify-center items-center  bg-black bg-opacity-50 fixed right-10  bottom-10 cursor-pointer text-white"
          : "  hidden "
      }
      onClick={handleScrollUp}
    >
      <i className="fa fa-angle-up text-white font-extrabold text-lg"></i>
      <span className="text-xs">TOP</span>
    </button>
  );
}
