import React, { Component, useEffect } from "react";

import { useParams } from "react-router-dom";
import { RoadmapService } from "../../service/roadmapService";

import { useState } from "react";

import Navbar from "../../template/navbar/Navbar";
import UserDashBoard from "../roadmapPage/UserDashboard";
import ThongKeLoTrinh from "../roadmapPage/ThongKeLoTrinh";
import backGrountTemplate from "../../assets/images/background.png";
import ListTaiLieu from "./ListTaiLieu";
import { useDispatch } from "react-redux";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "../../redux/actions/spinnerAction";
import SpinnerComponent from "../../component/Spinner";
import SidebarNew from "../../template/SidebarNew/sidebarNew";
import ModalRoadMap from "../roadmapLoTrinhChiTiet/RoadMapToogleList/CollapseRoadMap/ItemRoadMap/ModalRoadMap";

export default function RoadmapTaiLieuPage() {
  const { id } = useParams();
  const [dataRoadMap, setDataRoadMap] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [contentModal, setContentModal] = useState();
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(set_request_spinner_started());
    RoadmapService.getDetailRoadMap(id)
      .then((res) => {
        dispatch(set_request_spinner_ended());

        setDataRoadMap(res.data.content);
      })
      .catch((err) => {
        dispatch(set_request_spinner_ended());

        console.log(err);
      });
  }, [id]);
  // console.log(dataRoadMap);
  return (
    <>
      <ModalRoadMap
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title={titleModal}
        ContentComponent={contentModal}
        setContentModal={setContentModal}
      />
      <div
        style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
        className="h-max-content min-h-screen w-full  flex bg-cover"
      >
        <div className="w-60">
          {/* <SideBar /> */}
          <SidebarNew />
        </div>
        <div
          style={{ flexGrow: "1" }}
          className="h-max-cotent flex flex-col  min-h-screen "
        >
          <SpinnerComponent />
          <Navbar />
          <div
            // style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
            className=" w-full bg-cover p-3 space-y-2 min-h-screen "
          >
            <UserDashBoard dataRoadMap={dataRoadMap} />

            {/* <ThongKeLoTrinh dataRoadMap={dataRoadMap} /> */}

            {
              dataRoadMap?.thongTinLopHoc?.isKetThuc ? 
              <h1 class="text-center" style={{fontSize:"27px"}}>Thông Báo <br/> Hiện tại bạn đã hết thời gian truy xuất tài nguyên theo quy định </h1>:  <ListTaiLieu
              setTitleModal={setTitleModal}
              setIsModalVisible={setIsModalVisible}
              setContentModal={setContentModal}
              dataVideoRecord={dataRoadMap.danhSachTatCaVideoXemLai}
              dataRoadMap={dataRoadMap.danhSachTaiLieuTheoSkill}
            />
            }

            
          </div>
        </div>
      </div>
    </>
  );
}
