import React from "react";
import { useSelector } from "react-redux";

export default function NewCenter({ data, index, isShowLeft, isShowRight }) {
  let { dataTaskRoadMap } = useSelector((state) => state.roadmapReducer);

  let renderLine = (value = 5) => {
    let lines = [];
    for (let index = 0; index < value; index++) {
      let line = <div className="w-4 h-3 bg-blue-theme bg-opacity-60 "></div>;
      lines.push(line);
    }
    return lines;
  };
  return (
    <div className="w-22 h-22  relative z-10 ">
      <div
        style={{
          backgroundImage:
            "linear-gradient(to left, rgb(34, 125, 249) 0%, rgb(116, 98, 249) 25%, rgb(223, 62, 248) 50%, rgb(116, 98, 249) 75%, rgb(34, 125, 249) 100%)",
        }}
        className=" h-full w-full rounded-full flex items-center justify-center"
      >
        <div className=" h-11/12 w-11/12 rounded-full bg-white flex items-center ">
          <span className="p-1  text-center w-full">{data.tenBuoiHoc}</span>
        </div>
      </div>
      {/* line up */}
      {index !== 0 && (
        <div
          style={{ width: "1px" }}
          className="  bg-opacity-80 left-1/2 -translate-x-1/2 -translate-y-14 -top-40 absolute  transform flex overflow-hidden flex-wrap justify-between h-48 flex-col "
        >
          {renderLine(7)}
        </div>
      )}

      {/* line left */}
      <div
        style={{ height: "1px" }}
        className="w-28   top-1/2 translate-y-1/2 absolute -left-4 transform -translate-x-full flex overflow-hidden flex-wrap justify-between"
      >
        {isShowLeft && renderLine()}
      </div>
      {/* line right */}
      <div
        style={{ height: "1px" }}
        className="w-28  top-1/2 translate-y-1/2 absolute -right-4 transform translate-x-full justify-between flex overflow-hidden flex-wrap"
      >
        {isShowRight ? renderLine() : ""}
      </div>
    </div>
  );
}
