import React, { useEffect } from "react";
import ContentVideo from "./ContentVideo";
import SiderKhoaHocLienQuan from "./Sider";
import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";

export default function ContentKhoaHocLienQuan({ data }) {
  useEffect(() => {
    // console.log("data", data);
  }, []);
  console.log(data)

  let noiDungVideo = useSelector(state => state.roadmapReducer.noiDungVideo);


  return (
    <div style={{ width: "90vw", height: "80vh" }} className="flex  space-x-2">
      <div className="flex-grow h-full bg-gray-100 rounded ">
        <ContentVideo />

        {noiDungVideo && noiDungVideo?.noiDungMoTa && noiDungVideo?.noiDungMoTa != "" &&
          <div className="mt-2">
            <p>
              <button className="btn btn-sm btn-outline-primary" type="button" data-toggle="collapse" data-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
                Mô tả
              </button>
            </p>
            <div style={{ minHeight: 120 }}>
              <div className="collapse width" id="collapseWidthExample">
                <div className="card card-body" style={{ width: 320 }}>
                  {HtmlParser(noiDungVideo.noiDungMoTa)}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <SiderKhoaHocLienQuan data={data} />
    </div>
  );
}


