import React from "react";
import Lottie from "lottie-react";
import videoLottie from "./videoLottie.json";
export default function ViewClickChonVideo() {
  return (
    <div className="w-full h-140 flex flex-col justify-center items-center">
      <div className="w-full h-100 md:w-4/6">
        <Lottie
          loop={true}
          animationData={videoLottie}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <p className="text-gray-900 text-xl text-center w-2/3 mx-auto">
        Bạn vui lòng chọn một trong các video bên dưới
      </p>
    </div>
  );
}
