import React, { Component } from "react";
// import { Player, ControlBar, PlaybackRateMenuButton } from "video-react";
// import HLSSource from "./HLSSource";
import { LocalStorageUtil } from "../../../../../../../../utilities/localStorage";
import { FileSevice } from "../../../../../../../../service/fileService";
import ReactPlayer from "react-player";
import CollapseGhiChu from "../CollapseGhiChu/CollapseGhiChu";
import { RoadmapService } from "../../../../../../../../service/roadmapService";
import { Popover } from "antd";
import HtmlParser from "react-html-parser";

export default class XemTruoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      source: "",
      vimeo: 0,
      ghiChu: null,
    };
    this.nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  }
  //Khởi tạo biến chứa thời gian bắt đầu khi người dùng click vào, cái này không ảnh hưởng tới render nên không cần bỏ vào state
  thoiGianBatDau = "";
  maBaiHoc;

  handlePlayVideo = (item) => {
    this.setState({ ghiChu: item.ghiChu });
    this.maBaiHoc = item.id;

    if (this.nguoiDung.nuocNgoai) {
      FileSevice.layDuongDanVideoDigitalService(item.noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));
    } else {
      FileSevice.layDuongDanVideoService(item.noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));
    }
  };

  handleSubmitTrackingXemVideoXemTruoc() {
    const { maLop, maBuoiHoc } = this.props;
    let thoiGianClickPlay = new Date();
    let dataSubmit = {
      maBuoi: maBuoiHoc,
      maBaiHoc: this.maBaiHoc,
      maLop: +maLop,
      thoiGianBatDau: "",
      thoiGianKetThuc: "",
    };

    if (this.thoiGianBatDau === "") {
      this.thoiGianBatDau = new Date();
    }

    if (this.thoiGianBatDau < thoiGianClickPlay) {
      dataSubmit.thoiGianBatDau = this.thoiGianBatDau;
      dataSubmit.thoiGianKetThuc = thoiGianClickPlay;
      RoadmapService.postTrackingXemVideoXemTruoc(dataSubmit);
      this.thoiGianBatDau = thoiGianClickPlay;
    }
  }

  componentDidMount() {
    const { dsXemLai } = this.props;
    this.maBaiHoc = dsXemLai[0]?.id;
    this.nguoiDung.nuocNgoai &&
      dsXemLai[0]?.noiDung &&
      FileSevice.layDuongDanVideoDigitalService(dsXemLai[0].noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));
    !this.nguoiDung.nuocNgoai &&
      dsXemLai[0]?.noiDung &&
      FileSevice.layDuongDanVideoService(dsXemLai[0].noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));
  }

  componentWillUnmount() {
    this.handleSubmitTrackingXemVideoXemTruoc();
  }
  render() {
    const { dsXemLai } = this.props;
    return (
      <div className="flex h-full space-x-3 w-full">
        <div className="flex-grow flex-col flex items-center justify-center w-full rounded ">
          {this.state.source && (
            <ReactPlayer
              playbackRate={1}
              playing={false}
              url={this.state.source}
              width="100%"
              height="100%"
              controls={true}
              onPlay={() => {
                this.handleSubmitTrackingXemVideoXemTruoc();
              }}
              config={{
                file: {
                  forceHLS: true,
                },
              }}
            />
          )}
          {/* {this.state.ghiChu ? (
            <div className="flex-shrink-0 w-full">
              <CollapseGhiChu data={this.state.ghiChu} />
            </div>
          ) : (
            ""
          )} */}
        </div>
        <div
          className="list-group w-84 overflow-y-scroll bg-gray-200"
          id="list-tab"
          role="tablist"
        >
          <p className=" text-black font-medium px-4 pt-3">
            Danh sách video xem trước
          </p>
          {dsXemLai.map((item) => {
            // console.log(item);
            let extraCss =
              item.id === this.state.activeId ? " bg-blue-theme " : "";
            return (
              <div
                className={
                  " cursor-pointer flex flex-col   justify-center h-max-content items-start  px-1 min-h-16   border-gray-200 rounded-none border-b-1 w-full border-l-0 border-r-0  transform duration-300 card_theme " +
                  extraCss
                }
              >
                <button
                  className=" items-center  flex  px-2  justify-center   p-1 min-h-16 h-max-content   transform duration-300  rounded-lg w-full "
                  id={"lst-video-" + item.id}
                  onClick={() => {
                    this.handlePlayVideo(item);
                    this.handleSubmitTrackingXemVideoXemTruoc();
                  }}
                >
                  <div className=" flex-shrink-0  card_theme bg-gray-400  h-7 rounded-full leading-7 w-7 flex justify-center items-center transform scale-75 relative overflow-hidden mr-2  ">
                    <i className="fa fa-play text-blue-theme text-xs opacity-75"></i>
                  </div>
                  <div className="flex  flex-col w-full justify-start  h-max-content">
                    <span className={"w-full text-left text-sm lg:text-base "}>
                      {item.tieuDe}
                    </span>

                    {item.ghiChu && (
                      <div className="flex  items-center w-full justify-between">
                        <Popover
                          content={
                            <div className="w-96 whitespace-normal">
                              {HtmlParser(item.ghiChu)}
                            </div>
                          }
                          title="Chi chú buổi học"
                          trigger="click"
                        >
                          <p className="p-1 text-xs w-max ml-auto justify-end space-x-3 border-gray-600 border-1 rounded">
                            {" "}
                            <i className="fa fa-book"></i>{" "}
                            <span>Tài nguyên</span>
                          </p>
                        </Popover>
                      </div>
                    )}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
