import React from "react";
import { renderTitleBaiTapNop } from "../../../../../utilities/roadmap";
import ContentBaiTapNop from "./ContentModal/ContentBaiTapNop/ContentBaiTapNop";
import ContentTaiLieu from "./ContentModal/ContentTaiLieu/ContentTaiLieu";
import ContentVideoXemLai from "./ContentModal/ContentVideoXemLai/ContentVideoXemLai";
import OptionItem from "./Option/OptionItem";
import { useParams } from "react-router-dom";
import ContentTracNghiemCuoiKhoa from "./ContentModal/ContentTracNghiemCuoiKhoa/ContentTracNghiemCuoiKhoa_RoadMap";

export default function Left({
  data,
  setIsModalVisible,
  setContentModal,
  setTitleModal,
  isShow,
}) {
  let params = useParams();
  return isShow ? (
    <div className=" w-60 xl:w-80 h-32 card_theme rounded-r-full px-3 flex justify-center items-center relative z-20">
      <div className="flex justify-start space-y-3 flex-col w-max  ">
        {data.taiLieuBaiHoc.length > 0 && (
          <OptionItem
            icon={<i className="fa fa-file text-blue-theme"></i>}
            title={"Tài liệu"}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <ContentTaiLieu
                maBuoiHoc={data.id}
                data={data.taiLieuBaiHoc}
                maLop={params.id}
              />
            }
          />
        )}
        {data.taiLieuBaiTap.length > 0 && (
          <OptionItem
            icon={<i className="fa fa-book-open text-blue-theme"></i>}
            title={renderTitleBaiTapNop(data.taiLieuBaiTap)}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <ContentBaiTapNop
                maBuoiHoc={data.id}
                data={data.taiLieuBaiTap}
                maLop={params.id}
              />
            }
          />
        )}
        {data.videoXemLai.length > 0 && (
          <OptionItem
            icon={<i className="fa fa-film text-blue-theme"></i>}
            title={"Video xem lại"}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <ContentVideoXemLai
                maBuoiHoc={data.id}
                data={data.videoXemLai}
                maLop={params.id}
              />
            }
          />
        )}
        {data?.tracNghiem?.length > 0 && (
          <OptionItem
            icon={<i className="fa fa-laptop-code text-blue-theme"></i>}
            title={"Bài tập trắc nghiệm"}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <ContentTracNghiemCuoiKhoa
                data={data.tracNghiem[0]}
              />
            }
          />
        )}
      </div>
    </div>
  ) : (
    <div className=" w-60 xl:w-80 h-32 "></div>
  );
}
