import React, { Component, useEffect } from "react";

import { useParams } from "react-router-dom";
import { KhoaHocService } from "../../../service/khoaHocService";
import { useState } from "react";

import Navbar from "../../../template/navbar/Navbar";
import backGrountTemplate from "../../../assets/images/background.png";
import ListTaiLieu from "./ListTaiLieu";
import { useDispatch } from "react-redux";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "../../../redux/actions/spinnerAction";
import SpinnerComponent from "../../../component/Spinner";
import SidebarNew from "../../../template/SidebarNew/sidebarNew";
import * as khoaHocActions from "../../../redux/actions/khoaHocAction";

export default function KhoaHocFreePage() {
  const { id } = useParams();
  const [dataRoadMap, setDataRoadMap] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [contentModal, setContentModal] = useState();
  let dispatch = useDispatch();
  useEffect(() => {
   dispatch(khoaHocActions.layChiTietKhoaHocFree(id))
     
  }, [id]);
  // console.log(dataRoadMap);
  return (
    <>
    
      <div
        style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
        className="h-max-content min-h-screen w-full  flex bg-cover"
      >
        <div className="w-60">
          <SidebarNew />
        </div>
        <div
          style={{ flexGrow: "1" }}
          className="h-max-cotent flex flex-col  min-h-screen "
        >
          <SpinnerComponent />
          <Navbar />
          <div
            className=" w-full bg-cover p-3 space-y-2 min-h-screen "
          >


            {
            <ListTaiLieu
            setTitleModal={setTitleModal}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
          />
            }

            
          </div>
        </div>
      </div>
    </>
  );
}
