import React, { Component } from "react";
import { Modal, Input, message } from "antd";
import FacebookLogin from "react-facebook-login";
import history from "../../utilities/history";
import { FACEBOOK_APP_ID } from "../../config/commonConfig";
import { LocalStorageUtil } from "../../utilities/localStorage";
import { NguoiDungSevice } from "../../service/nguoiDungService";
import { DangNhapModel } from "../../model";
import "./DangNhap.scss";
import LoginBackup from "../../component/LoginBackup/LoginBackup";
import LoginEmail from "../../component/LoginBackup/LoginEmail";

export default class DangNhapMail extends Component {
  state = {
    email: "",
    userID: "",
    faceEmail: "",
    name: "",
    isLogin: false,
  };

  componentDidMount = () => {
    this.setState({
      email: "",
      userID: "",
      faceEmail: "",
      name: "",
      isLogin: false,
    });
  };

  responseFacebook = (response) => {
    // console.log(response);
    // NẾU USER ĐÃ ĐĂNG NHẬP SẴN FACEBOOK => LẤY THÔNG TIN FACEBOOK LƯU VÀO STATE
    const { userID, email, name } = response;

    const thongTinDangNhap = new DangNhapModel(userID, "", "");

    NguoiDungSevice.dangNhapFacebookService(thongTinDangNhap)
      .then((res) => {
        switch (res.data.content) {
          case 1:
            this.setState({
              userID,
              isLogin: true,
            });
            break;
          case 0:
            message.info(res.data.message);
            break;
          default:
            // LƯU THÔNG TIN ĐĂNG NHÂP VÀO LOCALSTORAGE
            LocalStorageUtil.setLoginLocalStorage(res.data.content);
            // CHUYỂN HƯỚNG ĐẾN TRANG CHỦ
            history.push("/");
            // window.location.href = '/';
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error("Error !");
          // GỌI HÀM XỬ LÝ ĐĂN NHẬP LỖI
          this.xuLyDangNhapThatBai(err.response);
        }
      });
  };

  onChangeInput = (e) => {
    const { value } = e.target;
    this.setState({ email: value });
  };

  // Đăng nhập sử dụng tài khoản facebook
  onDangNhap = () => {
    const { userID, faceEmail, email } = this.state;
    const thongTinDangNhap = new DangNhapModel(userID, faceEmail, email);

    NguoiDungSevice.dangNhapFacebookService(thongTinDangNhap)
      .then((res) => {
        switch (res.data.content) {
          case 1:
            this.setState({
              userID,
              isLogin: true,
            });
            break;
          case 0:
            message.info(res.data.message);
            break;
          default:
            // LƯU THÔNG TIN ĐĂNG NHÂP VÀO LOCALSTORAGE
            LocalStorageUtil.setLoginLocalStorage(res.data.content);
            // CHUYỂN HƯỚNG ĐẾN TRANG CHỦ
            history.push("/");
            // window.location.href = '/';
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error("Error !");
          // GỌI HÀM XỬ LÝ ĐĂN NHẬP LỖI
          this.xuLyDangNhapThatBai(err.response);
        }
      });
  };

  renderFacebookButton = () => {
    const { name, isLogin } = this.state;

    return (
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        fields="name, email, picture"
        callback={this.responseFacebook}
        cssclassName="btnFacebook"
        textButton={
          <span className="text-xs">
            <i className="fa fa-facebook-f"></i>ĐĂNG NHẬP BẰNG FACEBOOK
          </span>
        }
      />
    );
  };

  render() {
    return (
      <div className="login-page">
        <div className="wrapper">
          <div className="left flex items-center justify-center">
            <img src="https://i.ibb.co/G3xKFwN/team.jpg" alt="img" />
            <div className="left-cyber flex flex-col">
              <span>Hệ thống hỗ trợ học tập tại CyberSoft</span>
              <span style={{ margin: "0 5px" }}> - </span>
              <a target="_blank" href="https://cybersoft.edu.vn">
                {" "}
                https://cybersoft.edu.vn
              </a>
            </div>
          </div>
          <LoginEmail />
        </div>
      </div>
    );
  }
}
