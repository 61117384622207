import moment from "moment";
import React from "react";
import { renderThongBao } from "../../../utilities/roadmap";

export default function UserThongbao({ dataRoadMap }) {
  const renderContent = () => {
    if (dataRoadMap.danhSachThongBaoHocVien?.length == 0) {
      return <p className="p-2">Bạn không có thông báo nào trong hôm nay</p>;
    }

    return dataRoadMap.danhSachThongBaoHocVien?.map((item, index) => {
      return (
        <div key={index} className=" p-2 my-1 card_theme">
          <div className=" text-gray-700 m-0 flex items-center">
            {renderThongBao(item)}
          </div>
        </div>
      );
    });
  };
  return (
    <div className=" p-2 py-1 card_theme  w-96 h-full flex flex-col flex-grow">
      <p className="font-medium ">Thông báo</p>
      <div className=" flex-grow space-y-1  overflow-y-scroll">
        {renderContent()}
      </div>
    </div>
  );
}
