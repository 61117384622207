import React from "react";
import LinkIconComponent from "../Component/LinkIconComponent/LinkIconComponent";
import LinkIconEmailComponent from "../Component/LinkIconEmailComponent/LinkIconEmailComponent";
import { Tag } from "antd";
import { convertHoTenThanhTenHo } from "../../../../utilities/stringFormat";
import moment from "moment";
import "./index.scss";
import * as strUtilities from "../../../../utilities/stringFormat";
import { forLoopMap } from "../../../../utilities/utils";

export default function index(props) {
  const { thongTinMoRong } = props;

  function renderTen(thongTinMoRong) {
    return `${
      thongTinMoRong?.gioiTinh === "male" ? "Mr" : "Mrs"
    }.${convertHoTenThanhTenHo(thongTinMoRong?.hoTen).toUpperCase()}`;
  }

  function renderSkill(danhSachSkill, loaiSkill) {
    let arraySkill = forLoopMap(danhSachSkill, (item, index) => {
      return <li key={`${loaiSkill}_${index}`}>{item}</li>;
    });
    if (danhSachSkill.length === 0) return null
    return (
      <>
      <div className="col-xs-12 col-sm-3">
        <h4 className="skill__sub-title">{loaiSkill}</h4>
        <ul className="skill__items">{arraySkill}</ul>
        </div>
      </>
    );
  }

  function renderButtonLinkOnProject(item, index) {
    return (
      <div className="row project__list-btn" key={`projectButton_${index}`}>
        <div className="col-4">
          {
            item.linkDeploy !== "" ? (<div className="project__btn project__btn-deploy">
            <a href={item.linkDeploy} target="_blank">
              <i className="fa fa-globe"></i>
            </a>
          </div>) : null
          }
          
        </div>
        <div className="col-4">
          {
            item.linkYoutube !== "" ? (<div className="project__btn project__btn-youtube">
            <a href={item.linkYoutube} target="_blank">
             <i className="fa fa-youtube-play"></i>
            </a>
          </div>) : null
          }
        </div>
        <div className="col-4">
          {item.linkGit !== "" ? (<div className="project__btn project__btn-git">
            <a href={item.linkGit} target="_blank">
            <i className="fa fa-github"></i>
            </a>
          </div>) : null}
        </div>
      </div>
    );
  }

  function renderAvatarImage(thongTinMoRong) {
    let isHidden =
      thongTinMoRong?.isHiddenAvatar || thongTinMoRong?.anhAvatar === "";
    if (isHidden) return null;
    return (
      <div className="header__image">
        <img src={strUtilities.convertFileNameToUploadAvatarImage(thongTinMoRong.anhAvatar)} alt="image_avatar" />
      </div>
    );
  }

  function renderFacebookLink(thongTinMoRong) {
    if (thongTinMoRong?.isHiddenFacebookLink) return null;
    return (
      <a
        href={thongTinMoRong?.linkFacebook}
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        <p>{thongTinMoRong?.linkFacebook}</p>
      </a>
    );
  }

  

  function renderHeader(thongTinMoRong) {
    let isHiddenAvatar =
      thongTinMoRong?.isHiddenAvatar || thongTinMoRong.anhAvatar === "";

      if (isHiddenAvatar) {
        return (
          <section className="row header">
          <div className="col-xs-12 col-sm-6">
            <div className="row">
              <div className="col-12">
                <div className="header__info wapper">
                  <h3>
                    <b>{renderTen(thongTinMoRong)}</b>
                  </h3>
                  <h4>{thongTinMoRong?.chucDanh}</h4>
                  <p>{thongTinMoRong?.diaChi}</p>
                  <a href={`mailto:${thongTinMoRong?.email}`}>
                    <p>{thongTinMoRong?.email}</p>
                  </a>
                  <a
                    href={thongTinMoRong?.linkGitHub}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>{thongTinMoRong?.linkGitHub}</p>
                  </a>
                  <a
                    href={thongTinMoRong?.linkLinkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>{thongTinMoRong?.linkLinkedIn}</p>
                  </a>
                  <a href={`tel:+${thongTinMoRong?.soDienThoai}`}>
                    <p>
                      <span>Hotline: </span>
                      {thongTinMoRong?.soDienThoai}
                    </p>
                  </a>
  
                  {renderFacebookLink(thongTinMoRong)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="header__summary">
              <h3>Summary</h3>
              <p>{thongTinMoRong?.gioiThieu}</p>
            </div>
            
          </div>
        </section>
        )
      }

    return (
      <section className="row header">
        <div className="col-xs-12 col-sm-8">
          <div className="row">
            <div className="col-4">{renderAvatarImage(thongTinMoRong)}</div>
            <div className="col-8">
              <div className="header__info wapper">
                <h3>
                  <b>{renderTen(thongTinMoRong)}</b>
                </h3>
                <h4>{thongTinMoRong?.chucDanh}</h4>
                <p>{thongTinMoRong?.diaChi}</p>
                <a href={`mailto:${thongTinMoRong?.email}`}>
                  <p>{thongTinMoRong?.email}</p>
                </a>
                <a
                  href={thongTinMoRong?.linkGitHub}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>{thongTinMoRong?.linkGitHub}</p>
                </a>
                <a href={`tel:+${thongTinMoRong?.soDienThoai}`}>
                  <p>
                    <span>Hotline: </span>
                    {thongTinMoRong?.soDienThoai}
                  </p>
                </a>

                {renderFacebookLink(thongTinMoRong)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4">
          <div className="header__summary">
            <h3>Summary</h3>
            <p>{thongTinMoRong?.gioiThieu}</p>
          </div>
        </div>
      </section>
    );
  }

  function renderEducation(dsEducation) {
    return forLoopMap(dsEducation, (item, index) => {
      return (
        <div className="col-12" key={`educationItem_${index}`}>
          <div className="item">
            <h5 className="sub-title">
              {item.tenTruong} - {item.trinhDo}
            </h5>
            <h6 className="content">
              {item.namHocTu} - {item.namHocDen}
            </h6>
            <p className="education__item-moTa content">{item.moTaNgan}</p>
          </div>
        </div>
      );
    });
  }

  function renderCertification(thongTinMoRong) {
    let isKhongCoChungNhan = thongTinMoRong?.chungNhan?.length === 0

    if (isKhongCoChungNhan) return null

  return  <section className="certification">
              <h3 className="title">Certification</h3>
              <div className="row">
                {renderListCertification(thongTinMoRong?.chungNhan)}
              </div>
            </section>
  }
  
  function renderListCertification(dsCertification) {
    return forLoopMap(dsCertification, (item, index) => {
      return (
        <div className="col-12" key={`certification__${index}`}>
          <div className="item">
            <h5 className="sub-title">{item.tenChungChi}</h5>
            <h5 className="content">
              {item.noiCap} - {moment(item.ngayCapChungChi).format("MM/YYYY")}
            </h5>
            <p className="certification__item-moTa content">{item.moTaNgan}</p>
          </div>
        </div>
      );
    });
  }

  function renderExperience(dsExperience) {
    return forLoopMap(dsExperience, (item, index) => {
      return (
        <div className="col-12" key={`experienceItem_${index}`}>
          <div className="item">
            <h5 className="sub-title">
              {item.tenCongTy} - {item.chucVu}
            </h5>
            <h6 className="content">
              {item.namLamTu} - {item.namLamDen}
            </h6>
            <p className="experience__item-moTa content">{item.moTaNgan}</p>
          </div>
        </div>
      );
    });
  }

  function renderSkillOnProject(dsSkills) {
    return (
      <p className="project_skillOnProject">
        {JSON.parse(dsSkills).join(", ")}
      </p>
    );
  }

  function renderProject(danhSachDuAn) {
    return forLoopMap(danhSachDuAn, (item, index) => {
      return (
        <div
          className="col-xs-12 col-sm-6 wrapper"
          key={`projectItem_${index}`}
        >
          <div className="project__item">
            <h4 className="sub-title">{`${index + 1}. ${item.tieuDe}`}</h4>
            {renderSkillOnProject(item.danhSachSkill)}
            {renderButtonLinkOnProject(item)}
            <p className="project__item-moTa">{item.moTa}</p>
          </div>
        </div>
      );
    });
  }

  return (
    <>
  
      <div className="container basic1">
      
        {renderHeader(thongTinMoRong)}
        <section className="row skills">
          <div className="col-12">
            <h3>Skills</h3>
            <div className="row">
              
                {renderSkill(thongTinMoRong?.lsSkillFE, "Front End")}
           
             
                {renderSkill(thongTinMoRong?.lsSkillBE, "Back End")}
             
             
                {renderSkill(thongTinMoRong?.lsSkillOther, "Other")}
             
             
                {renderSkill(thongTinMoRong?.listSoftSkill, "Soft Skill")}
             
            </div>
          </div>
        </section>
        <section className="project">
          <h3 className="title">Projects</h3>
          <div className="row wrapper">
            {renderProject(thongTinMoRong?.listDuAn)}
          </div>
        </section>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <section className="experience">
              <h3 className="title">Experience</h3>
              <div className="row">
                {renderExperience(thongTinMoRong?.kinhNghiem)}
              </div>
            </section>
          </div>
          <div className="col-xs-12 col-sm-6">
            <section className="education">
              <h3 className="title">Education</h3>
              <div className="row">
                {renderEducation(thongTinMoRong?.truongHoc)}
              </div>
            </section>
            {renderCertification(thongTinMoRong)}
          </div>
        </div>
      </div>
    </>
  );
}
