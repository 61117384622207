import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDataRoadMapServAction } from "../../../redux/actions/roadMapAction";
import backgroundTemplate from "./../../../assets/images/background.png";
import SpinnerComponent from "../../../component/Spinner";
import ChuongHocMobile from "./ChuongHoc/ChuongHocMobile";
import { LocalStorageUtil } from "../../../utilities/localStorage";
import * as action from "./../../../redux/actions/lopHocAction";
import NotFoundViewMobile from "../NotFoundViewMobile/NotFoundViewMobile";
import ThongBaoMobile from "./../thongbaoMobile/ThongBaoMobile";
import NewBottomNavigationMobile from "../newBottomNavigationMobile/NewBottomNavigationMobile";

export default function ChiTietLopMobile() {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  const { id } = useParams();
  let { dataRoadMap } = useSelector((state) => state.roadmapReducer);
  const { dsLopHoc } = useSelector((state) => state.lopHocReducer);
  // console.log(dataRoadMap);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataRoadMapServAction(id));
    dispatch(action.layDanhSachLopHoc(nguoiDung.id));
  }, [id]);
  const trangThai = dsLopHoc.find((item) => item.id == id);
  const renderChuongHoc = () => {
    return dataRoadMap.danhSachBuoiHocTheoSkill?.map((item, index) => {
      return (
        <ChuongHocMobile
          tenChuong={item.tenSkill}
          data={item}
          key={index}
          indexBuoi={index}
        />
      );
    });
  };

  return trangThai?.maTrangThai == 2 ? (
    <div className="h-screen">
      <SpinnerComponent />
      <div className="h-16 flex items-center justify-between px-8 shadow">
        <h3 className="font-bold text-lg mb-0">
          {dataRoadMap?.thongTinLopHoc?.tenLopHoc}
        </h3>
        <ThongBaoMobile thongBao={dataRoadMap?.danhSachThongBaoHocVien}>
          <button className="relative">
            <i className="fa-solid fa-bell text-2xl"></i>
            <div className="rounded-full bg-red-500 text-white absolute bottom-0 w-4 h-4 flex justify-center items-center text-xs -right-2">
              {dataRoadMap?.danhSachThongBaoHocVien?.length}
            </div>
          </button>
        </ThongBaoMobile>
      </div>
      <div
        style={{
          background: `url(${backgroundTemplate})`,
          backgroundSize: "cover",
        }}
        className="p-3"
      >
        <div
          style={{
            background: `url(${backgroundTemplate})`,
            backgroundSize: "cover",
          }}
          className="p-3 h-full overflow-y-scroll pb-12"
        >
          <h4 className="font-bold text-lg">Danh sách các buổi học</h4>
          <div className="space-y-5">{renderChuongHoc()}</div>
        </div>
      </div>
      <NewBottomNavigationMobile />
    </div>
  ) : (
    <div className="h-screen flex justify-center items-center">
      <NotFoundViewMobile />
      <NewBottomNavigationMobile />
    </div>
  );
}
