import { Button, message, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getDataRoadMapServAction,
  getTaskRoadMapServAction,
} from "../../../../../../../redux/actions/roadMapAction";
import { RoadmapService } from "../../../../../../../service/roadmapService";
import { LocalStorageUtil } from "../../../../../../../utilities/localStorage";
import { initBaiTapTracNghiem } from "../../../../../../../utilities/roadmap";
import { LOAI_TASK_TRACNGHIEM } from "../../../../../../../redux/types";

export default function Navigate_Footer_Pratices({
  current,
  total,
  handleClickNextQuestion,
  isDisableBtn,
  listQuestion,
  dataKhoaHoc,
  setScoreQuiz,
}) {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  const { id } = useParams();
  const dispatch = useDispatch();

  const handle_PostKetQua = () => {
    let countCorrected = 0;
    for (let index = 0; index < listQuestion.length; index++) {
      const question = listQuestion[index];
      question.isCorrect && countCorrected++;
    }
    let diemQuizz = countCorrected;
    if (diemQuizz == 0) {
      diemQuizz++;
    }
    let baiTap = initBaiTapTracNghiem;
    baiTap.maBuoiHoc = dataKhoaHoc.maBuoiHoc;
    baiTap.maBaiHoc = dataKhoaHoc.id;
    baiTap.maLop = id * 1;
    // baiTap.maNguoiDung = "63b595e2-8bfd-4cbb-95be-bd3fe427469d";
    baiTap.maNguoiDung = nguoiDung.id;
    baiTap.diem = diemQuizz;

    let submitHoanThanh = {
      maBuoiHoc: dataKhoaHoc.maBuoiHoc,
      maBaiHoc: dataKhoaHoc.id,
      maLop: +id,
      loaiTask: LOAI_TASK_TRACNGHIEM,
    };
    // console.log(submitHoanThanh);
    RoadmapService.postCheckHoanThanhTaskRoadMap(submitHoanThanh)
      .then(() => {
        dispatch(getTaskRoadMapServAction(id));
      })
      .catch((err) => {
        // message.error("đã có lỗi xảy ra");
        console.log(err);
      });
    RoadmapService.postQuiz(baiTap)
      .then((res) => {
        message.success("Nộp bài tập thành công");
        setScoreQuiz(diemQuizz);
        dispatch(getDataRoadMapServAction(id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickNext = () => {
    return !isDisableBtn ? handleClickNextQuestion() : null;
  };
  const percent = Math.floor((current / total) * 100);
  const nextBtnCss = isDisableBtn
    ? " text-gray-600 bg-gray-300 cursor-not-allowed"
    : "  bg-blue-theme text-white ";
  return (
    <div className="  flex items-center h-16 w-full justify-center space-x-10 px-16 border-none rounded-2xl flex-shrink-0 bg-gray-100">
      <div className="flex items-cente space-x-5 justify-center  w-full">
        <Progress
          step={total}
          percent={percent}
          className="w-full"
          showInfo={false}
          strokeWidth={15}
          strokeColor={{
            "0%": "#4A00E0",
            "100%": "#8E2DE2",
          }}
          trailColor={"rgba(68, 66, 178, 0.1)"}
        />
        <span className="font-bold flex-shrink-0 text-color-title">
          {current}/{total} câu
        </span>
      </div>

      <Button
        onClick={() => {
          return current === total ? handle_PostKetQua() : handleClickNext();
        }}
        className={
          "  text-white duration-150 font-bold px-8 rounded  flex items-center h-10 flex-shrink-0 border-none  focus:border-blue-theme hover:border-transparent hover:shadow-lg" +
          nextBtnCss
        }
        // loading={loading}
      >
        {current == total ? "Hoàn thành" : " Câu tiếp theo"}
      </Button>
    </div>
  );
}
