import React from "react";
import { Table, Tooltip } from "antd";
import ReactHtmlParser from "react-html-parser";
import { GetTagStatusBaiTap } from "../../../utilities/roadmap";
import "./table.css";
const columns = [
  {
    title: "Tên Bài tập",
    dataIndex: "name",
    key: "name",
    render: (text) => <a>{text}</a>,
  },

  {
    title: "Trạng thái",
    key: "trangThai",
    dataIndex: "trangThai",
    render: (baiTap) => {
      return GetTagStatusBaiTap(baiTap);
    },
  },
  {
    title: "Loại bài tập",
    dataIndex: "loaiBaiTap",
    key: "loaiBaiTap",
  },
  {
    title: "Điểm",
    dataIndex: "diem",
    key: "diem",
  },

  {
    title: "Nhận xét",
    dataIndex: "nhanXet",
    key: "nhanXet",
    render: (nhanXet) => {
      if (!nhanXet) {
        return "";
      } else {
        return (
          <Tooltip
            // placement={this.state.placement}
            mouseEnterDelay={0}
            mouseLeaveDelay={0.5}
            trigger={["click"]}
            placement="top"
            animation="zoom"
            overlayClassName="  "
            color="white"
            title={
              <p className="text-blue-theme  p-1  text-center">
                {ReactHtmlParser(nhanXet)}
              </p>
            }
            // overlayClassName="bg-red-500"
            onVisibleChange={(visible) => {
              // console.log(visible);
            }}
          >
            <i className="fa fa-comment-dots text-blue-theme text-xl cursor-pointer"></i>
          </Tooltip>
        );
      }
    },
  },
];
export default function TableDiemChungNhan({ data }) {
  let dataSource = data?.map((baiTap, index) => {
    return {
      key: index,
      name: baiTap.tieuDe,
      trangThai: baiTap,
      nhanXet: baiTap.nhanXet ? baiTap.nhanXet : null,
      diem: baiTap.diemBaiTap > 0 ? baiTap.diemBaiTap : "",
      loaiBaiTap: baiTap.loaiBaiTap === "QUIZ" ? "Trắc nghiệm" : "Nộp",
    };
  });
  return (
    <div className="w-full">
      <Table
        pagination={{
          pageSize: dataSource.length > 5 ? 5 : dataSource.length,
          showSizeChanger: false,
        }}
        columns={columns}
        dataSource={dataSource}
        className="w-full text-center rounded-lg shadow-lg overflow-hidden p-2"
      />
    </div>
  );
}
