import { BaseService } from "../../service/baseService";
import { RoadmapService } from "../../service/roadmapService";
import {
  GET_DATA_RUNCODE,
  RUNCODE_SUBMIT,
  SET_DATA_CHUNGNHAN,
  SET_DATA_ROADMAP,
  SET_SOURCE_VIDEO,
  SET_TASK_ROADMAP,
} from "../types";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "./spinnerAction";
import axios from "axios";

export const setNoiDungVideoAction = (noiDung) => {
  return {
    type: "SET_NOIDUNG_VIDEO",
    payload: noiDung,
  };
};

export const setSourceVideoAction = (source) => {
  return {
    type: SET_SOURCE_VIDEO,
    payload: source,
  };
};

export const getDataRoadMapServAction = (id) => {
  return (dispatch) => {
    dispatch(set_request_spinner_started());
    
    RoadmapService.getDetailRoadMap(id)
      .then((res) => {
        dispatch({
          type: SET_DATA_ROADMAP,
          payload: res.data.content,
        });
        dispatch(set_request_spinner_ended());
      })
      .catch((err) => {
        dispatch(set_request_spinner_ended());

        console.log(err);
      });
  };
};

export const getTaskRoadMapServAction = (id) => {
  return (dispatch) => {
    dispatch(set_request_spinner_started());

    RoadmapService.getTaskRoadMap(id)
      .then((res) => {
        dispatch({
          type: SET_TASK_ROADMAP,
          payload: res.data.content,
        });
        dispatch(set_request_spinner_ended());
      })
      .catch((err) => {
        dispatch(set_request_spinner_ended());

        console.log(err);
      });
  };
};

export const getDataRuncodeAction = (maLop, maBuoiHoc, chuongHoc) => {
  return (dispatch) => {
    dispatch(set_request_spinner_started());
    RoadmapService.getDetailRoadMap(maLop).then((res) => {
      dispatch({
        type: GET_DATA_RUNCODE,
        payload: res.data.content,
        maBuoiHoc,
        chuongHoc,
      });
    });
  };
};

export const checkSubmitDataRuncodeAction = (data) => {
  return async (dispatch) => {
    dispatch(set_request_spinner_started());
    try {
      const res = await axios({
        url: "https://runcode.cyberlearn.vn/submit",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data,
      });
      function checkAnswers(arr) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].message !== "Right answer") {
            return false;
          }
        }
        return true;
      }
      const check = checkAnswers(res.data);
      if (check) {
        const getMaNguoiDung = localStorage.getItem("LOGIN_LOCASTORE");
        // console.log(JSON.parse(getMaNguoiDung));
        await RoadmapService.upDiemRuncodeHocVien({
          maNguoiDung: JSON.parse(getMaNguoiDung).id,
          maBuoiHoc: data.infor.maBuoiHoc,
          maBaiHoc: data.infor.maBaiHoc,
          maLop: data.infor.maLop,
          baiLam: data.code,
          thoiGianBatDau: data.infor.thoiGianBatDau,
          thoiGianHoanThanh: data.infor.thoiGianHoanThanh,
          diem: 100,
        });
        dispatch(
          getDataRuncodeAction(
            data.infor.maLop,
            data.infor.maBuoiHoc,
            data.chuongHoc
          )
        );
      }
      dispatch({
        type: RUNCODE_SUBMIT,
        payload: res.data,
      });

      dispatch(set_request_spinner_ended());
    } catch (error) {
      console.log(error);
    }
  };
};
