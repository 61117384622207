import axios from "axios";
import { BaseService } from "./baseService";
const API_URL = "/api/khachhang"; 

const checkTokenService = (token) => {
    return BaseService.get(`${API_URL}/check-token?token=${token}`);
}
const checkTokenService2 = (token) => {
    return BaseService.get(`${API_URL}/check-token2?token=${token}`);
}

const checkCaptchaService = (captcha) => {
    return BaseService.get(`${API_URL}/captcha?captcha=${captcha}`);
}

const capNhatThongTinKhachHangService = (id, model) => {
    return BaseService.put(`${API_URL}/update-info/${id}`, model);
}
const taoThongTinKhachHangService = (model) => {
    return BaseService.post(`${API_URL}/create-info`, model);
}

export const KhachHangService = {
    checkTokenService2,
    checkTokenService,
    checkCaptchaService,
    capNhatThongTinKhachHangService,
    taoThongTinKhachHangService
}