import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import Sidebar from "./sidebarTemplate/SideBarTemplate";
import { LocalStorageUtil } from "../utilities/localStorage";

export default class Layout extends Component {
  render() {
    return (
      <div className="app_container row">
        <div className="app_left col-md-2">
          <Sidebar />
        </div>
        <div className="app_right col-md-10">
          <Navbar />
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export const Template = ({ Component, ...propsRoute }) => {
  const thongTinDangNhap = LocalStorageUtil.getLoginLocalStorage();

  return (
    <Route
      {...propsRoute}
      render={({ ...propsComponent }) => {
        return thongTinDangNhap ? (
          <Layout>
            <Component {...propsComponent} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        );
      }}
    />
  );
};

// export class Template extends Component {

//     // componentDidMount(){

//     // }

//     render (){
//         let { Component, ...propsRoute} = this.props;
//         return (
//             <Route {...propsRoute} render={({ ...propsComponent }) => {
//                 return thongTinDangNhap ? <Layout>
//                     <Component {...propsComponent} />
//                 </Layout> : <Redirect to={{ pathname: '/login' }}/>
//             }} />
//         )
//     }
// }
