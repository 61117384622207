import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import useWindowSize from "../../HOC/useWindowSize";
import { LocalStorageUtil } from "../../utilities/localStorage";
import Upgrade_Notify_Page from "./Upgrade_Notify_Page";

export default function RoadmapResponsive({
  Component,
  MobileComponent = Upgrade_Notify_Page,
}) {
  const { widthWindow, heightWindow } = useWindowSize();

  useEffect(() => {}, []);
  const thongTinDangNhap = LocalStorageUtil.getLoginLocalStorage();
  if (!thongTinDangNhap) {
    return <Redirect to={{ pathname: "/login" }} />;
  }
  if (widthWindow < 992) {
    return <MobileComponent />;
  } else {
    return <Component />;
  }
}
