import React, { Component } from "react";
import "./VideoPage.scss";

import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { Mark_Video } from "../utils/watermark";
import { LocalStorageUtil } from "../../../../../../../utilities/localStorage";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import HLSSource from "../ContentVideoXemLai/xem-lai/HLSSource";

class ContentVideo extends Component {
  componentDidMount() {
    let nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    this.myInterval = Mark_Video(nguoiDung);
  }
  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
  renderIcon = (maLoaiBaiHoc) => {
    switch (maLoaiBaiHoc) {
      case "VIDEO_FPT":
        return <i className="fa fa-film"></i>;
      case "QUIZ":
        return <i className="fa fa-question-circle"></i>;
      default:
        break;
    }
  };
  render() {

    return (
      <div className="w-full max-h-video-9 h-video-9 flex items-center  justify-center rounded bg-gray-200 water-mark-wrapper">
        {this.props.sourceVideo && (
          <Player playsInline fluid={false} width={"100%"} height={"100%"} autoPlay={false}>
            <HLSSource isVideoChild src={this.props.sourceVideo} />
            <ControlBar>
              <PlaybackRateMenuButton
                rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
                order={10}
              />
            </ControlBar>
          </Player>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sourceVideo: state.roadmapReducer.sourceVideo,
  };
}

export default connect(mapStateToProps, null)(ContentVideo);
