import React, { useEffect } from "react";
import Navbar from "../../template/navbar/Navbar";
import SidebarNew from "../../template/SidebarNew/sidebarNew";
import backGrountTemplate from "../../assets/images/background.png";
import ContentResume from "./ContentResume";
import ContentResumeV2 from "./ContentResumeV2";
import { LocalStorageUtil } from "../../utilities/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { layUserCV } from "../../redux/actions/nguoiDungAction";
import { UpdateInfo } from "./UpdateInfo";
import { UpdateInfoV2 } from "./UpdateInfoV2";
import { message } from "antd";
import history from "../../utilities/history";
import * as types  from '../../redux/types'

export default function ResumePage() {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  
  const dispatch = useDispatch();
  const thongTinTaoCV = useSelector(state => state.nguoiDungReducer.thongTinTaoCV);
  const thongTinLocalCV = useSelector(state => state.nguoiDungReducer.thongTinLocalCV);
  const dataCvDaNop =  useSelector(state => state.nguoiDungReducer.dataCvDaNop);

  useEffect(() => {
    // dispatch(layUserCV(nguoiDung.id));
  }, [])

  function renderUpdateInfo() {

    // nếu không có data đổ về thông báo không

    if (thongTinTaoCV == null) 
     { message.error('Lỗi trong quá trình lấy thông tin tạo CV vui lòng liên hệ admin')
       let setTimeOut = setTimeout(history.push('/'), 1000)
  }

    let isChuaNopCv = dataCvDaNop === null

  // console.log(thongTinLocalCV)
    let isChuaTaoCvLocal = Object.keys(thongTinLocalCV).length === 0

    if (isChuaNopCv && isChuaTaoCvLocal)
    {
      setTimeout(history.push('/update-cv'))
      return
    }

    return <ContentResumeV2 />

    
  }

  return (
    <div
      style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
      className="h-max-content min-h-screen w-full  flex"
    >
      <div className="w-60">
        <SidebarNew />
      </div>
      <div
        style={{ flexGrow: "1" }}
        className="h-max-cotent flex flex-col  min-h-screen "
      >
        <Navbar />
        <div className=" w-full bg-cover  space-y-2 chung-nhan h-{100vh}">

          {/* {thongTinTaoCV ?
            thongTinLocalCV == "" ?
            
            // <UpdateInfo nguoiDung={nguoiDung} />
            <UpdateInfoV2 nguoiDung={nguoiDung} />
            :
            // <UpdateInfoV2 nguoiDung={nguoiDung} />
            <ContentResumeV2 />
            :
            <UpdateInfoV2 nguoiDung={nguoiDung} />
          } */}
          
          {
            renderUpdateInfo()
          }
        </div>
      </div>
    </div>
  );
}
