import React, { Component } from "react";
import "./VideoPage.scss";

import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { Mark_Video } from "../utils/watermark";
import { LocalStorageUtil } from "../../../../../../../utilities/localStorage";

class ContentVideo extends Component {
  componentDidMount() {
    let nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    this.myInterval = Mark_Video(nguoiDung);
  }
  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
  renderIcon = (maLoaiBaiHoc) => {
    switch (maLoaiBaiHoc) {
      case "VIDEO_FPT":
        return <i className="fa fa-film"></i>;
      case "QUIZ":
        return <i className="fa fa-question-circle"></i>;
      default:
        break;
    }
  };

  render() {
    return (
      <div className="w-full h-full flex items-center  justify-center rounded bg-gray-200 water-mark-wrapper">
        {this.props.sourceVideo && (
          <ReactPlayer
            playbackRate={1}
            playing={false}
            url={this.props.sourceVideo}
            width="100%"
            height="100%"
            controls={true}
            config={{
              file: {
                forceHLS: true,
              },
            }}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sourceVideo: state.roadmapReducer.sourceVideo,
  };
}

export default connect(mapStateToProps, null)(ContentVideo);
