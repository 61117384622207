import React from "react";
import CycleImg from "./CycleImg";

export default function SkillDown({ data, width }) {
  let bgComponent = data.isActive
    ? " bg-blue-theme text-white"
    : "bg-gray-300 text-dark";
  let renderContent = (array) => {
    if (array.length > 5) {
      return (
        <>
          <p className="p-0 m-0 text-xs xl:text-sm">{array[0].tenBuoiHoc}</p>
          <p className="p-0 m-0 text-xs xl:text-sm">{array[1].tenBuoiHoc}</p>
          <p className="p-0 m-0 text-xs xl:text-sm">...</p>
          <p className="p-0 m-0 text-xs xl:text-sm">
            {array[array.length - 2].tenBuoiHoc}
          </p>
          <p className="p-0 m-0 text-xs xl:text-sm">
            {array[array.length - 1].tenBuoiHoc}
          </p>
        </>
      );
    } else {
      {
        return array?.map((item, index) => {
          return (
            <p key={index} className="p-0 m-0  text-xs xl:text-sm">
              {item.tenBuoiHoc}
            </p>
          );
        });
      }
    }
  };
  return (
    <div
      style={{ width: `${(10 / 100) * 100}%` }}
      className=" h-full flex flex-col   "
    >
      <div className="w-full h-1/2 flex flex-col justify-end items-center p-2 ">
        {renderContent(data.danhSachBuoiHoc)}{" "}
      </div>
      <div
        style={{
          clipPath:
            "polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%)",
        }}
        className={
          "center h-8 w-full   text-center leading-8 text-xs cursor-pointer " +
          bgComponent
        }
        onClick={() => {
          let offset = 300;
          window.scrollTo({
            behavior: "smooth",
            top:
              document
                .getElementById(`skill-${data.tenSkill}`)
                .getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              offset,
          });
        }}
      >
        {data.tenSkill}
      </div>
      <div className="w-full h-1/2  flex flex-col items-center">
        <div className=" w-full flex-grow flex items-center justify-center">
          <div
            style={{ width: "1px" }}
            className={"h-full " + bgComponent}
          ></div>
        </div>

        <CycleImg data={data} width={width} />
      </div>
    </div>
  );
}
