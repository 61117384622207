export class BaiTapNopModel {
    constructor(id, tenBaiTap, biDanh, noiDung, maLopHoc, maBaiTap, maNguoiDung,tenNguoiDung){
        this.id = id;
        this.tenBaiTap = tenBaiTap;
        this.biDanh = biDanh;
        this.noiDung = noiDung;
        this.maLopHoc = maLopHoc;
        this.maBaiTap = maBaiTap;
        this.maNguoiDung = maNguoiDung;
        this.tenNguoiDung = tenNguoiDung;
    }
}