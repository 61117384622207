import React from "react";
import { DOMAIN_API_FILE } from "../../../../../../../config/urlConfig";
import { RoadmapService } from "../../../../../../../service/roadmapService";
import { colors } from "../../../../../constant";

export default function ContentBaiTapTuLuyen({ data, maLop }) {
  // console.log("ContentBaiTapTuLuyen", data);
  function handleSubmitTrackingXemBaiTapExtra(item) {
    let dataSubmit = {
      maBuoi: item.maBuoiHoc,
      maBaiHoc: item.id,
      maLop: +maLop,
    };
    // console.log(dataSubmit);
    RoadmapService.postTrackingXemBaiTapExtra(dataSubmit);
  }
  return (
    <div style={{ width: "600px" }} className=" space-y-5">
      {data.map((item, index) => {
        let color = colors[index % 2];
        return (
          <div>
            <div
              style={{ backgroundColor: color.bg }}
              className="w-full  p-2 px-3  min-h-22 justify-between flex items-center relative rounded-lg shadow-lg"
            >
              <div className="w-max pl-6 h-full flex flex-col  relative ">
                <p className="text-gray-900">{item.tieuDe}</p>
                <button
                  className=" flex items-center h-max-content rounded p-1 border-gray-600 w-max flex-shrink-0 border-1 text-color-content cursor-pointer hover:text-gray-900  px-2 transform duration-300 hover:border-gray-500
                "
                >
                  <a
                    className="flex justify-center items-center space-x-1 text-color-content"
                    href={`${DOMAIN_API_FILE}/${item.noiDung}`}
                    target="_blank"
                    onClick={() => {
                      handleSubmitTrackingXemBaiTapExtra(item);
                    }}
                  >
                    <i className="fa fa-folder-open"></i>
                    <span>Tài nguyên</span>
                    <i className="fa fa-download"></i>
                  </a>
                </button>{" "}
                <div
                  style={{ backgroundColor: color.line }}
                  className="h-4/5 w-1 left-2 top-1/2 transform absolute rounded-lg -translate-y-1/2"
                ></div>
              </div>
              <div className="w-max  h-full flex space-x-5 items-center justify-center  pr-2"></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
