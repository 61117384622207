import React, { useEffect, useState } from 'react';
import MobileQRCode from './MobileQRCode';
import StudentProfile from './StudentProfile';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import { NguoiDungSevice } from '../../service/nguoiDungService';
import { useMediaQuery } from 'react-responsive';
import { decompressFromEncodedURIComponent } from 'lz-string';


const ResponsiveQR = () => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const location = useLocation();
  const [data, setData] = useState()
  const [diem, setDiem] = useState()
  const title = location.pathname === '/infor-profile' ? `${data?.hoTen} - CYBERSOFT` : 'Trang chủ';
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get("code");
  // const decryptCode = () => {
  //   return decodeURIComponent(escape(atob(code)));
  // };
  const decryptCode = () => {
    // Giải mã và giải nén dữ liệu
    return decompressFromEncodedURIComponent(code);
  };
  useEffect(() => {
    document.title = title;
  }, [title]);
  useEffect(() => {
    if (code) {
      var decode = decryptCode()
      NguoiDungSevice.getDataInforProfile(decode).then(res => {
        setData(res.data.content)
      }).catch(error => {
        setData()
        message.info("Sai đường dẫn")
        window.location.href = 'https://cybersoft.edu.vn/';
      })
      NguoiDungSevice.getDataInforProfilePoint(decode).then(res => {
        setDiem(res.data.content)
      }).catch(error => {
        setDiem()
      })

    } else {
      message.info("Sai đường dẫn")
      window.location.href = 'https://cybersoft.edu.vn/';
    }
  }, [code])
  return (
    <>
      {isDesktop && <StudentProfile data={data} diem={diem} />}
      {isMobile && <MobileQRCode data={data} diem={diem}/>}
    </>
  );
};

export default ResponsiveQR;
