import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DeploymentUnitOutlined, GithubOutlined, YoutubeOutlined } from '@ant-design/icons';
import { NguoiDungSevice } from '../../service/nguoiDungService';
import { message, Tabs, Tag } from 'antd';
import backGrountTemplate from "../../assets/images/background.png";
import './style.css'; // Import the new CSS file
import chungNhanTheme from "./../../assets/images/C5.jpg";
import { decompressFromEncodedURIComponent } from "lz-string";
import Certificate from "./Certificate";
import { getDataReportDSBuoiHocTheoSkill } from "../roadmapPage/UserDashboard/ReportBangDiem/report_utils";
import { BangDiem } from "./BangDiem";
const { TabPane } = Tabs;

const StudentProfile = ({ data, diem }) => {
  function encodeObject(obj) {
    // Encode the object as a UTF-8 string
    const encoded = unescape(encodeURIComponent(JSON.stringify(obj)));
    // Encode the UTF-8 string as a base64-encoded string
    return btoa(encoded);
  }


  return (
    <div className="profile-container" style={{ background: `url(${backGrountTemplate})`, minHeight: '100vh' }}>
      <div className="certificate-container">

        <Tabs defaultActiveKey="1" className="w-full felx flex-col flex-grow ">
          <TabPane
            tab={<p className="font-medium text-dark px-1 lg:px-3">Certificate</p>}
            key="1"
            className="w-full h-full "
          >
            {data?.danhSachLopHoc.map((item, idx) => {
              let obj = encodeObject(item);
              if(item.diemCuoiKhoa>=7){
              return (
                <div key={idx} className="certificate-item">
                  <Certificate obj={obj} width={60} />
                </div>
              )
            }
            })}
          </TabPane>
          <TabPane
            tab={<p className="font-medium text-dark px-1 lg:px-3">Transcript</p>}
            key="2"
            className="w-full h-full "
          >
            {diem?.map((item, idx) => {
              let dataReport = getDataReportDSBuoiHocTheoSkill(item.danhSachPoint);
             
                return (
                  <div key={idx} className="certificate-item" style={{paddingRight:'10px'}}>
                    <BangDiem dataReport={dataReport} tenLop={item.tenLop}/>
                  </div>

                )
              
            })}
          </TabPane>
        </Tabs>
      </div>
      <div className="bg-white" style={{ width: '50%', borderRadius: '10px' ,boxShadow:'5px 10px 15px 0px rgba(0, 0, 0, 0.5)'}}>
        <h2 style={{fontSize:'30px',textAlign:'center',padding:'20px 0'}}>Profile {data?.hoTen}</h2>
        <div className="profile-header">
          <div className="profile-image-container">
            <img
              className="profile-image"
              src={`https://ui-avatars.com/api/?name=${data?.hoTen}&background=random&bold=true`}
              alt={data?.hoTen}
              style={{ borderRadius: '10px' }}
            />
          </div>
          <div className="profile-info">
            <div className="profile-category">User Profile</div>
            <h1 className="profile-name">{data?.hoTen}</h1>
            <p className="profile-detail">Email: {data?.email}</p>
            <p className="profile-detail">
              GPA: {(data?.danhSachLopHoc.reduce((acc, lopHoc) => acc + lopHoc.diemCuoiKhoa, 0) / data?.danhSachLopHoc.length).toFixed(1)}/10
            </p>
          </div>
          <img
            src="https://cybersoft.edu.vn/wp-content/uploads/2017/04/MAX-OP1.png"
            alt="Company Logo"
            className="company-logo"
          />
        </div>
        <div className="gr-content">
          <h2 className="text-2xl font-semibold mb-2">Skills</h2>
          {/* <div className="flex flex-wrap gap-2 mb-4">
            {data?.danhSachSkill.map((skill, idx) => (
              <span
                key={idx}
                className="skill-item d-flex align-items-center"
              >
                <span className="mr-1">{skill.tenSkill}</span>
              </span>
            ))}
          </div> */}
          <div className="skills-container">
            {data?.danhSachSkill.map((skill, index) => (
              <span key={index} className="skill-tag">
                {skill.tenSkill}
              </span>
            ))}
          </div>
        </div>
        <div className="gr-content">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Courses</h2>
          <div className="overflow-x-auto">
            <table className="table-course divide-y divide-gray-200 text-lg" >
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                    Course Name
                  </th>
                  <th className="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                    Average Score
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white trTable">
                {data?.danhSachLopHoc.map((classItem, index) => (
                  <tr key={index} className="">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {/* <FaBook className="mr-2 text-gray-400" /> */}
                        {classItem.tenLoTrinh}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {/* <FaStar className="mr-2 text-yellow-400" /> */}
                        {classItem.diemCuoiKhoa.toFixed(1)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="gr-content">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Assignments</h2>
          <div className="space-y-4">
            {data?.danhSachDuAn.map((assignment, index) => {
              if (assignment.diem > 70) {
                return <div
                  key={index}
                  className="item_caps bg-gray-50  rounded-lg p-4 transition duration-300"
                >
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">

                    {assignment.noiDung}
                    <Tag className="ml-2"> Score: {assignment.diem / 10}/10</Tag>
                  </h3>
                  <div className="flex flex-wrap">
                    <a
                      href={assignment.linkGit}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800 transition duration-300 mr-1"
                    >
                      <GithubOutlined style={{ fontSize: '20px', marginRight: '10px' }} /> GitHub
                    </a>
                    <a
                      href={assignment.linkDeploy}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ background: 'rgb(219, 234, 254)' }}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium text-blue-800 transition duration-300 mr-1"
                    >
                      <DeploymentUnitOutlined style={{ fontSize: '20px', marginRight: '10px' }} /> Deploy
                    </a>
                    <a
                      href={assignment.linkYoutube}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ background: 'rgb(254, 226, 226)' }}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium text-red-800 transition duration-300 mr-1"
                    >
                      <YoutubeOutlined style={{ fontSize: '20px', marginRight: '10px' }} /> YouTube
                    </a>
                  </div>
                </div>
              }
            }

            )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default StudentProfile;