import React from "react";
import CycleImg from "./CycleImg";

export default function HeaderItem({ data, width }) {
  let bgComponent = data.isActive
    ? " bg-blue-theme text-white"
    : "bg-gray-300 text-dark";

  return (
    <div
      style={{ width: width }}
      className=" h-full justify-between flex flex-col   space-y-3   items-center"
    >
      <div
        style={{
          clipPath:
            "polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%)",
        }}
        className={
          "center h-8 w-full   text-center leading-8 text-xs cursor-pointer " +
          bgComponent
        }
        onClick={() => {
          let offset = 300;
          window.scrollTo({
            behavior: "smooth",
            top:
              document
                .getElementById(`skill-${data.tenSkill}`)
                .getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              offset,
          });
        }}
      >
        {data.tenSkill}
      </div>
      <div className="w-full h-full  flex flex-col items-center">
        <div className=" w-full flex-grow flex items-center justify-center">
          <div
            style={{ width: "1px" }}
            className={"h-full " + bgComponent}
          ></div>
        </div>
        <CycleImg data={data} width={width} />
      </div>
    </div>
  );
}
