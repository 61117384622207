import * as types from '../types';

const initialState = {
    dsBaiTap: [],
    dataLopBaiTap: [],
    dsBaiTapNop: [],
    lstQuizAws: null,
    lstQuiz: null,
    layoutBoDe: null,
}

export const baiTapReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.GET_QUIZ_AWS:
            return {
                ...state,
                lstQuizAws: action.payload
            }
        case types.GET_QUIZ:
            return {
                ...state,
                lstQuiz: action.payload
            }
        case types.GET_LAYOUT:
            return {
                ...state,
                layoutBoDe: action.payload
            }

        case types.LAY_DANH_SACH_BAI_TAP_THANH_CONG:
            return {
                ...state,
                dsBaiTap: action.payload
            }
        case types.NOP_BAI_TAP_THANH_CONG:
            const { maBaiTap } = action.payload;

            return {
                ...state,
                dsBaiTap: state.dsBaiTap.map(item => {
                    if (item.id === maBaiTap) {
                        return { ...item, baiTapNop: action.payload };
                    }
                    return item;
                })
            }
        case types.LAY_DANH_SACH_LOP_BAI_TAP_THANH_CONG:

            return {
                ...state,
                dataLopBaiTap: action.payload.data ? action.payload.data : [],
            }
        case types.LAY_DANH_SACH_BAI_TAP_NOP_THANH_CONG:

            return {
                ...state,
                dsBaiTapNop: action.payload.data ? action.payload.data : [],
            }
        default:
            return state;
    }
}


export default baiTapReducer;