import React, { useEffect, useState } from 'react'
import Navbar from '../../template/navbar/Navbar'
import SidebarNew from '../../template/SidebarNew/sidebarNew'
import backGrountTemplate from "../../assets/images/background.png";
import { useMeasure } from 'react-use';
import Axios from 'axios';
import HtmlParser from "react-html-parser";
import './list_blog.css';
import { Pagination } from 'antd';
import { useParams } from 'react-router-dom';
import history from '../../utilities/history';


export const ListBlog = () => {

    let [noiDung, setNoiDung] = useState("");
    let param = useParams();

    useEffect(() => {

        Axios({
            method: "GET",
            url: "https://cybersoft.edu.vn/category/bai-viet-nen-doc/page/" + param.page
        }).then(res => {

            let start = res.data.indexOf("api-start");
            let end = res.data.indexOf("api-end");
            let content = res.data.substring(start, end);
            content = content.replace("api-start", "").replaceAll("href=", " target='_blank' href=");

            // console.log(content);
            setNoiDung(JSON.parse(content))


        })

    }, [param])



    /**
     *  lay số trang thông qua đếm chuỗi từ API tra ve
     *  tinh toan load pagination = nhan 10 de có so trang 
     */
    let count = 0;

    if (noiDung != "") {

        count = noiDung.page.split('').filter((value, index, arr) => {
            return arr.slice(index, index + 9).join('') === "cybersoft";
        }).length;


        if (param.page != 1 && param.page != count) {
            count -= 1;
        }
        count = count * 10;

    }

    return (
        <div
            style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
            className="h-max-content min-h-screen w-full  flex"
        >
            <div className="w-60">
                <SidebarNew />
            </div>
            <div
                style={{ flexGrow: "1" }}
                className="h-max-cotent flex flex-col  min-h-screen "
            >
                <Navbar />

                <div className='blog_api flex flex-wrap gap-5 container p-5'>
                    {noiDung != "" && noiDung.data.map(item => {


                        return <div className="card_theme rounded-lg" style={{ width: "30%" }} >

                            {HtmlParser(item.image)}

                            {HtmlParser(item.title)}

                            {HtmlParser(item.content)}

                        </div>
                    })}


                </div>
                <Pagination className='p-5 text-center' total={count} onChange={(page, pageSize) => {

                    history.push("/blog/" + page);
                }} />
            </div>


        </div >
    )
}
