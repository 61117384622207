import React from 'react'

export default function LinkIconComponent(props) {
  const { link, icon  } = props

  return link?.trim() !== "" ?  (<div className="col-4  mb-3">
    <a
              href={link}
              target="_blank"
              rel="nofollow noopener"
              className="link"
            >
          <span className="mt-4" style={{fontSize: "0.6rem"}} >
          <i className={icon} aria-hidden="true">

          </i>
               <span className="text-inherit ml-1">
                <u>
                {props.children}
                </u>
              
                </span> 
            
          </span>
          </a></div>) : null
}

