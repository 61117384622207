import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useMonaco } from "@monaco-editor/react";
import "./selectLanguages.scss";
const { Option } = Select;

export default function SelectLanguages({ setLanguages, lsLang, language }) {
  console.log('language: ', language);
  // const monaco = useMonaco();
  const languagesDefault = [
    { name: "JavaScript", value: "js" },
    { name: "C++", value: "cpp" },
    { name: "Java", value: "java" },
    { name: "Python", value: "py" },
    { name: "C#", value: "cs" },
  ];
  const [languages,setLanguage] = useState([])
  console.log('languages: ', languages);
  useEffect(()=>{
    let newls = lsLang.map((lang)=> {
      //nếu lang._language có trong language thì lang sẽ thay đổi
      return languagesDefault.find(item=>item.value==lang._language)
    })
    setLanguage(newls)
  },[lsLang])

  // console.log(monaco);
  return (
    <>
      {languages.length>0 && <Select
        id="select-language"
        style={{ width: 120 }}
        value={language}
        onSelect={(value) => setLanguages(value)}
      >
        {languages?.map((lang, index) => {
          return (
            <Option key={index} value={lang?.value}>
              {lang.name}
            </Option>
          );
        })}
        {/* <Option value="javascript">JavaScript</Option> */}
        {/* {languages.map((lang) => (
          <Option value={lang}>{lang}</Option>
        ))} */}
      </Select>}
    </>
  );
}
