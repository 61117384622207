import React, { Component } from "react";
import { connect } from "react-redux";
// import "./SideBar.scss";
// import history from "../../utilities/history";
// import * as actions from "../../redux/actions/lopHocAction";
// import * as khoaHocActions from "../../redux/actions/khoaHocAction";
// import * as baiTapActions from "../../redux/actions/baiTapActions";
// import { LocalStorageUtil } from "../../utilities/localStorage";
// import { NavLink } from "react-router-dom";
import SidebarNew from "../SidebarNew/sidebarNew";
import SideBarOld from "../SidebarOld/SidebarOld";

class SideBarTemplate extends Component {
  // state = {
  //   viTri: -1,
  // };

  // componentDidMount = () => {
  //   const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  //   this.props.dispatch(actions.layDanhSachLopHoc(nguoiDung.id));
  // };

  // onGoToKhoaHoc = (index, lopHoc) => {
  //   this.setState({ viTri: index });
  //   const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  //   this.props.dispatch(khoaHocActions.layDanhSachKhoaHoc(lopHoc.id));
  //   this.props.dispatch(
  //     baiTapActions.layDanhSachBaiTap(lopHoc.id, nguoiDung.id)
  //   );

  //   this.props.dispatch(baiTapActions.layDanhSachLopBaiTapAction(lopHoc.id));
  //   this.props.dispatch(baiTapActions.layDanhSachBaiTapNopAction(lopHoc.id));

  //   //lay danh sach xem lai buoi hoc
  //   this.props.dispatch(actions.layDanhSachXemLaiBuoiHocTheoLop(lopHoc.id));
  //   // console.log({ lopHoc });
  //   if (lopHoc.isStart && lopHoc.hasRoadMap) {
  //     history.push(`/course-roadmap/${lopHoc.id}`, { lopHoc });
  //   } else {
  //     history.push("/course", { lopHoc });
  //   }
  // };
  // renderLopHoc = (dsLopHoc) => {
  //   const { viTri } = this.state;
  //   return dsLopHoc
  //     ? dsLopHoc.map((item, index) => {
  //         if (item.isStart && item.hasRoadMap) {
  //           return (
  //             <div className=" w-full ">
  //               <div className="mt-1" key={index}>
  //                 <a
  //                   onClick={() => this.onGoToKhoaHoc(index, item)}
  //                   style={{ color: index === viTri ? "yellow" : "white" }}
  //                 >
  //                   <span className="text-base">
  //                     <i className="fa fa-graduation-cap "></i>Lớp{" "}
  //                     {item.tenLopHoc}
  //                   </span>
  //                 </a>
  //               </div>
  //               <div className=" w-full text-white pl-10 py-3 space-y-2 bg-gray-600">
  //                 <div onClick={() => this.onGoToKhoaHoc(index, item)}>
  //                   <NavLink
  //                     to={`/course-roadmap/${item.id}`}
  //                     className="text-xs"
  //                     activeClassName="text-yellow-300"
  //                   >
  //                     <i className="fa fa-road"></i> Lộ trình học
  //                   </NavLink>
  //                 </div>
  //                 <div className=" cursor-pointer hover:text-yellow-300">
  //                   <NavLink
  //                     to={`/course-roadmap-tai-lieu/${item.id}`}
  //                     className="text-xs"
  //                     activeClassName="text-yellow-300"
  //                   >
  //                     <i className="fa fa-file-alt "></i> Truy xuất nhanh tài liệu
  //                   </NavLink>
  //                 </div>
  //                 <div className=" cursor-pointer hover:text-yellow-300">
  //                   <NavLink
  //                     to={`/course-roadmap-diem/${item.id}`}
  //                     className="text-xs"
  //                     activeClassName="text-yellow-300"
  //                   >
  //                     <i className="fa fa-money-check"></i> Điểm và chứng nhận
  //                   </NavLink>
  //                 </div>
  //                 <div className=" cursor-pointer hover:text-yellow-300">
  //                   <NavLink
  //                     to={`/course-roadmap-thong-bao/${item.id}`}
  //                     className="text-xs"
  //                     activeClassName="text-yellow-300"
  //                   >
  //                     <i className="fa fa-bell"></i> Thông báo
  //                   </NavLink>
  //                 </div>
  //               </div>
  //             </div>
  //           );
  //         } else {
  //           return (
  //             <div className="mt-1" key={index}>
  //               <a
  //                 onClick={() => this.onGoToKhoaHoc(index, item)}
  //                 style={{ color: index === viTri ? "yellow" : "white" }}
  //               >
  //                 <span className="text-base">
  //                   <i className="fa fa-graduation-cap "></i>Lớp{" "}
  //                   {item.tenLopHoc}
  //                 </span>
  //               </a>
  //             </div>
  //           );
  //         }
  //       })
  //     : null;
  // };

  render() {
    const { dsLopHoc } = this.props.lopHocProps;

    // return index == -1 ? <SideBarOld /> : <SidebarNew />;
    return <SideBarOld />;
  }
}

function mapStateToProps(state) {
  return {
    lopHocProps: state.lopHocReducer,
  };
}

export default connect(mapStateToProps, null)(SideBarTemplate);
