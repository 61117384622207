import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Navbar from "../../template/navbar/Navbar";
import backGrountTemplate from "../../assets/images/background.png";
import UserDashBoard from "./UserDashboard";
import ThongKeLoTrinh from "./ThongKeLoTrinh";
import RoadMapToogleList from "./RoadMapToogleList";
import SpinnerComponent from "../../component/Spinner";
import { useDispatch, useSelector } from "react-redux";

import BtnBackToTop from "./BtnBackToTop/BtnBackToTop";
import SkillTimeLine from "./SkillTimeLine/SkillTimeLine";
import { getDataRoadMapServAction } from "../../redux/actions/roadMapAction";
import SidebarNew from "../../template/SidebarNew/sidebarNew";
import PopupKhoKhanHocVien from "../../component/PopupKhoKhanHocVien/PopupKhoKhanHocVien";
import { dataFakeRoadmap } from "../../utilities/roadmap";
import Flash_sale from "../../component/Flash_Sale/Flash_sale";

export default function RoadmapPage() {
  const { id } = useParams();
  let { dataRoadMap } = useSelector((state) => state.roadmapReducer);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataRoadMapServAction(id));
  }, [id]);
  return (
    <div
      style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
      className="h-max-content min-h-screen w-full    bg-cover"
    >
      {/* <BtnBackToTop khoKhanHocVien={dataRoadMap.khoKhanHocVien} /> */}
      <div className="flex h-full w-full">
        <SpinnerComponent />
        <SidebarNew />
        <div
          style={{ flexGrow: "1" }}
          className="flex flex-col  min-h-screen w-full"
        >
          <Navbar />
          <Flash_sale />

          <div className=" w-full p-3 lg:p-5  space-y-2 flex flex-col flex-grow ">
            <UserDashBoard dataRoadMap={dataRoadMap} />
            <ThongKeLoTrinh dataRoadMap={dataRoadMap} />
            {/* <SkillTimeLine dataRoadMap={dataRoadMap} />
          <RoadMapToogleList dataRoadMap={dataRoadMap} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
