import React, {} from "react";
import Lottie from "lottie-react";
import chucMungJson from "./chucMung.json";
import { useDispatch, useSelector } from "react-redux";
export default function ChucMungXongTask() {
  let { titleChucMungXongTask } = useSelector((state) => state.roadmapReducer);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   setTitleChucMung(titleChucMungXongTask);
  // }, [titleChucMungXongTask]);

  return (
    <div
      className="w-full bg-black bg-opacity-25 h-screen fixed"
      style={{ zIndex: "999" }}
    >
      <div className="transform absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/3 w-1/2 h-1/2 ">
        <div className="relative">
          <Lottie
            loop={true}
            animationData={chucMungJson}
            style={{ width: "100%", height: "100%" }}
          />
          <h3 className="text-white text-center transform -translate-y-20 text-2xl">
            {titleChucMungXongTask}
          </h3>
        </div>
        <button
          onClick={() => {
            dispatch({
              type: "SET_CHUCMUNG_XONGTASK",
              payload: false,
            });
          }}
          className="w-8 h-8 bg-white rounded shadow absolute top-20 right-0"
        >
          X
        </button>
      </div>
    </div>
  );
}
