import React from "react";
import XemLaiBuoiHoc from "../../ItemRoadMap/ContentModal/ContentVideoXemLai/xem-lai/XemLaiBuoiHoc";

export default function ViewXemLaiBuoiHoc({ data, maBuoiHoc, maLop }) {
  return (
    <div style={{ width: "90vw", height: "70vh" }} className="flex ">
      <div className=" flex-grow h-full flex items-center">
        <XemLaiBuoiHoc dsXemLai={data} maBuoiHoc={maBuoiHoc} maLop={maLop} />
      </div>
    </div>
  );
}
