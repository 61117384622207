import React, { useState } from 'react';
import { Upload, Button, Icon } from 'antd';
// import * as faceapi from 'face-api.js';
import swal from 'sweetalert';
import './UploadField.css';
import { URL_API_BASE } from '../utilities/UrlConfig';

const URL_UPLOAD = `${URL_API_BASE}/api/file/cmnd`;

export default function UploadCheckFace({ required, onChange, label, value, ...props }) {

    const { name } = props;

    const [listFile, setListFile] = useState([]);
    const [messageError, setMessageError] = useState('');

    const onChangeHandle = ({ file, fileList }) => {
        const temps = [];
        if (file.status !== 'uploading') {
            if (fileList.length > 0) {
                onChange(name, file.response, true);
                setMessageError('');
                temps.push(file);
                // faceapi.bufferToImage(file.originFileObj)
                //     .then(image => faceapi.detectAllFaces(image).withFaceLandmarks().withFaceDescriptors())
                //     .then(arr => {
                //         if(arr.length === 0){
                //             swal("Lỗi!", "Chứng minh không đúng định dạng!", "error");
                //             setMessageError('Chứng minh không đúng định dạng!');
                //         }
                //         else{
                //             onChange(name, file.response, true);
                //             setMessageError('');
                //             temps.push(file);
                //         }
                //     })
                //     .catch(err => {
                //         onChange(name, '', false);
                //         setMessageError('Tệp tin chưa được upload!');
                //         swal("Lỗi!", "Upload tệp tin thất bại!", "error");
                //     });
            }
            else {
                onChange(name, '', false);
                setMessageError('Tệp tin chưa được upload!');
            }
        }
        setListFile(temps);
    }

    return (
        <div className="upload-field">
            <div className="title">
                <label>{label}</label>
                <span>{required ? '*' : ''}</span>
            </div>
            <div className="clearfix">
                <Upload
                    {...props}
                    action={URL_UPLOAD}
                    defaultFileList={listFile}
                    onChange={onChangeHandle}
                >
                    <Button>
                        <Icon type="upload" /> Upload
                    </Button>
                </Upload>
            </div>
            {
                messageError.length > 0 ? <small>{messageError}</small> : null
            }
        </div >
    )
}
