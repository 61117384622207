import React from "react";
import ContentBaiTapTuLuyen from "./ContentModal/ContentBaiTapTuLuyen/ContentBaiTapTuLuyen";
import ContentTaiLieuExtra from "./ContentModal/ContentTaiLieuExtra/ContentTaiLieuExtra";
import ContentTracNghiemExtra from "./ContentModal/ContentTracNghiemExtra/ContentTracNghiemExtra";
import ContentVideoXemTruoc from "./ContentModal/ContentVideoXemTruoc/ContentVideoXemTruoc";
import OptionItem from "./Option/OptionItem";
import { useParams } from "react-router-dom";

export default function Right({
  data,
  setIsModalVisible,
  setContentModal,
  setTitleModal,
  isShow,
}) {
  let params = useParams();

  return isShow ? (
    <div className=" w-60 xl:w-80 h-32 card_theme rounded-l-full px-3 flex justify-center items-center relative z-20">
      <div className="flex justify-start space-y-3 flex-col w-max  ">
        {data.videoExtra.length > 0 && (
          <OptionItem
            icon={<i className="fa fa-video text-blue-theme"></i>}
            title={`Video xem trước `}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <ContentVideoXemTruoc
                maBuoiHoc={data.id}
                data={data.videoExtra}
                maLop={params.id}
              />
            }
          />
        )}
        {data.taiLieuDocThem.length > 0 && (
          <OptionItem
            icon={<i className="fa fa-file-alt text-blue-theme"></i>}
            title={"Tài liệu đọc thêm"}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <ContentTaiLieuExtra
                data={data.taiLieuDocThem}
                maLop={params.id}
              />
            }
          />
        )}
        {data.taiLieuProjectLamThem.length > 0 && (
          <OptionItem
            icon={<i className="fa fa-book text-blue-theme"></i>}
            title={"Bài tập tự luyện"}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <ContentBaiTapTuLuyen
                data={data.taiLieuProjectLamThem}
                maLop={params.id}
              />
            }
          />
        )}
        {data.tracNghiemExtra.length > 0 && (
          <OptionItem
            icon={<i className="fa fa-list-alt text-blue-theme"></i>}
            title={"Bài tập trắc nghiệm"}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <ContentTracNghiemExtra data={data.tracNghiemExtra[0]} />
            }
          />
        )}
      </div>
    </div>
  ) : (
    <div className=" w-60 xl:w-80 h-32 "></div>
  );
}
