import React, { Component, useEffect } from "react";

import { useParams } from "react-router-dom";
import { RoadmapService } from "../../service/roadmapService";

import { useState } from "react";
import SideBar from "../../template/sidebarTemplate/SideBarTemplate";
import Navbar from "../../template/navbar/Navbar";
import UserDashBoard from "../roadmapPage/UserDashboard";
import ThongKeLoTrinh from "../roadmapPage/ThongKeLoTrinh";
import backGrountTemplate from "../../assets/images/background.png";
import ListSuKien from "./ListSuKien/ListSuKien";
import SpinnerComponent from "../../component/Spinner";
import { useDispatch } from "react-redux";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "../../redux/actions/spinnerAction";
import SidebarNew from "../../template/SidebarNew/sidebarNew";

export default function RoadmapThongBaoPage() {
  const { id } = useParams();
  const [dataRoadMap, setDataRoadMap] = useState([]);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(set_request_spinner_started());
    RoadmapService.getDetailRoadMap(id)
      .then((res) => {
        dispatch(set_request_spinner_ended());

        setDataRoadMap(res.data.content);
      })
      .catch((err) => {
        dispatch(set_request_spinner_ended());

        console.log(err);
      });
  }, [id]);
  return (
    <div className="h-max-content min-h-screen w-full  flex">
      <div className="w-60">
        {/* <SideBar /> */}
        <SidebarNew />
      </div>
      <div
        style={{ flexGrow: "1" }}
        className="h-max-cotent flex flex-col  min-h-screen "
      >
        <Navbar />
        <div
          style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
          className=" w-full bg-cover p-3 space-y-2 "
        >
          <SpinnerComponent />
          <UserDashBoard dataRoadMap={dataRoadMap} />
          {/* <ThongKeLoTrinh dataRoadMap={dataRoadMap} /> */}
          <ListSuKien listData={dataRoadMap.thongBao} />
        </div>
      </div>
    </div>
  );
}
