import React from "react";
import { Tabs } from "antd";
import ListDiem from "../ListDiem";
import ThongTinRecord from "../ThongTinRecord/ThongTinRecord";
const { TabPane } = Tabs;

export default function Tab_Diem_RecordXemLai({ dataRoadMap }) {
  return (
    <div className="w-full  flex-grow   space-x-3 flex">
      <div className="w-1/2 flex-grow   space-y-5 flex flex-col">
        <div className="card_theme p-5">
          <h2>Quy định cấp chứng nhận:</h2>
          <p>- Đạt tổng điểm từ 7 trở lên </p>
          <p>
            - Hoàn thành dự án cuối khoá (deadline 1,5 tháng), điểm dự án từ 7
            trở lên
          </p>
          <p className="mb-0">- Bắt buộc hoàn thành bài tập capstone project</p>
        </div>

        <div className="flex-grow space-y-3 card_theme p-3 border-none">
          <Tabs
            defaultActiveKey="1"
            className="w-full felx flex-col flex-grow "
          >
            <TabPane
              tab={<p className="font-medium text-dark px-1 lg:px-3">Điểm</p>}
              key="1"
              className="w-full h-full "
            >
              <ListDiem dataRoadMap={dataRoadMap.danhSachDiemBaiTapTheoSkill} />
            </TabPane>
            {/* 
            <TabPane
              tab={
                <p className="font-medium px-1 lg:px-3 text-dark">
                  Thông tin record
                </p>
              }
              key="2"
              className="h-full "
            >
              <ThongTinRecord
                danhSachTatCaVideoXemLai={dataRoadMap.danhSachTatCaVideoXemLai}
              />
            </TabPane> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
}
