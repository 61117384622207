import React, { useEffect, useState } from "react";
import dataFake from "./../../../../../../dataFake/dataFake.json";
import { DOMAIN_API_FILE } from "../../../../../../config/urlConfig";
import OptionNewItemRoadMap from "../Content/ContentKhoaHocLienQuan/NewKhoaHocLienQuan";
import OptionKhoaHocLienQuan from "./OptionChildItem/OptionNewItemRoadMap";
import moment from "moment/moment";
import { Collapse } from "antd";
import ViewXemLaiBuoiHoc from "../ViewXemLaiBuoiHoc/ViewXemLaiBuoiHoc";
import { useParams } from "react-router-dom";
import dataCyber from "./../../../../../../dataFake/dataBCFE48.json";
import ContentTaiLieu from "../../ItemRoadMap/ContentModal/ContentTaiLieu/ContentTaiLieu";
const { Panel } = Collapse;

export default function NewLeft({
  data,
  dataHash,
  setIsModalVisible,
  setContentModal,
  setTitleModal,
  isShow,
}) {
  let params = useParams();
  // console.log(data);
  // console.log(dataHash);
  // console.log(isShow);
  // render tài liệu của các buổi học
  const renderDataTaiLieuBuoiHoc = (dataFake = []) => {
    // return isShow.taiLieuBaiHoc
    // return dataHoang.content[0].danhSachBaiTap.map((item, index) => {
    //   // console.log(item);
    //   // const data = hashMapLayTaiLieu(item.id);
    //   // const { tieuDe, noiDung } = data;
    //   return (
    //     // <li className="py-1 px-2 hover:bg-gray-200 rounded" key={index}>
    //     //   <a
    //     //     href={`${DOMAIN_API_FILE}/${noiDung}`}
    //     //     target="_blank"
    //     //     className="flex"
    //     //   >
    //     //     <span className="text-blue-theme mr-2">
    //     //       <i className="fa-regular fa-file-lines"></i>
    //     //     </span>
    //     //     <p className="text-blue-theme mb-0">{tieuDe}</p>
    //     //   </a>
    //     // </li>
    //     <div></div>
    //   );
    // });
  };
  return isShow ? (
    <div className="card_theme rounded-3xl p-2 xl:p-5 pl-2 xl:pl-7 flex flex-col justify-center items-start w-72 xl:w-84 h-max-content space-y-2">
      {data.taiLieuBaiHoc.length > 0 && (
        <div className="w-max">
          <div className="w-full">
            {/* <Collapse className="w-full">
            <Panel
              header={
                <div className="hover:bg-gray-200 w-full py-1 rounded">
                  <h2 className="flex items-center opacity-70 mb-0">
                    <span className="p-1 text-xs rounded bg-blue-theme w-6 h-6 flex items-center justify-center text-white mr-2">
                      <i className="fa-regular fa-folder-open"></i>
                    </span>
                    Tài liệu buổi học
                  </h2>
                </div>
              }
              showArrow={false}
              key="1"
            >
              <ul className="space-y-2 mb-0">
                {renderDataTaiLieuBuoiHoc(data.taiLieuBaiHoc)}
              </ul>
            </Panel>
          </Collapse> */}
            <OptionKhoaHocLienQuan
              icon={<i className="fa-regular fa-folder-open"></i>}
              title={`Tài liệu buổi học`}
              
              statusContent={true}
              leftSide={true}
              setTitleModal={setTitleModal}
              setIsModalVisible={setIsModalVisible}
              setContentModal={setContentModal}
              contentModal={
                <ContentTaiLieu
                  maBuoiHoc={data.id}
                  data={data.taiLieuBaiHoc}
                  maLop={params.id}
                />
              }
            />
          </div>
        </div>
      )}

      {/* <div className="w-max"> */}
      {/* <OptionKhoaHocLienQuan
          icon={<i className="fa-solid fa-video"></i>}
          title={"Khoá học liên quan"}
          setTitleModal={setTitleModal}
          setIsModalVisible={setIsModalVisible}
          setContentModal={setContentModal}
          contentModal={
            <OptionNewItemRoadMap
            // dataKhoaHocLienQuan={
            //   data.
            // }
            />
          }
        /> */}
      {/* </div> */}
      {data.videoXemLai.length > 0 && (
        <div>
          <h2 className="flex items-center mb-0 px-2 py-2">
            <span className="p-1 text-xs rounded bg-blue-theme w-6 h-6 flex items-center justify-center text-white mr-2">
              <i className="fa-solid fa-play"></i>
            </span>
            Video xem lại
          </h2>
          <div className="ml-5">
            <ul>
              {data.videoXemLai.map((item, index) => {
                return (
                  <OptionKhoaHocLienQuan
                    key={index}
                    icon={<i className="fa-solid fa-film"></i>}
                    indexVideoXemlai={index}
                    title={`${item.tieuDe} - Hết hạn ${moment(
                      item.ngayXoa
                    ).format("DD/MM/YYYY")}`}
                    setTitleModal={setTitleModal}
                    leftSide={true}
                    setIsModalVisible={setIsModalVisible}
                    setContentModal={setContentModal}
                    contentModal={
                      <ViewXemLaiBuoiHoc
                        maBuoiHoc={item.maBuoiHoc}
                        data={data.videoXemLai}
                        maLop={params.id}
                      />
                    }
                  />
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className=" w-60 xl:w-84 h-32 "></div>
  );
}
