import React, { useState } from 'react';
import { Checkbox } from 'antd';
import './CheckBoxField.css';

export default function CheckBoxField({ required, label, onChange, ...props }) {
    const [message, setMessage] = useState('');

    const onChangeHandle = (e) => {
        onChange(props.name, e.target.checked, true);
    }

    return (
        <div className="checkbox-field">
            <Checkbox onChange={onChangeHandle} checked={props.value}>{label}</Checkbox>
            { message.length > 0 ? <span className="error">{message}</span> : null }
        </div>
    )
}