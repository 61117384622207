import React, { useState, Component, useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../template/navbar/Navbar";
import backGrountTemplate from "../../assets/images/background.png";
import SpinnerComponent from "../../component/Spinner";
import { useDispatch, useSelector } from "react-redux";
import SidebarNew from "../../template/SidebarNew/sidebarNew";
import HTMLparse from "html-react-parser";
import { Collapse, Select } from "antd";
import { baseServiceNew } from "../../service/baseServiceNew";
import ThaoLuanQA from "./thaoLuanQA/ThaoLuanQA";
const { Option } = Select;
const { Panel } = Collapse;

export default function RoadmapQAPage() {
  const { id } = useParams();
  const API_URL = "/api/cau-hoi-thao-luan";
  const [dsCauHoi, setDsCauHoi] = useState([]);
  const [dsCauHoiFiltered, setDsCauHoiFiltered] = useState([]);
  const [dsLoaiCauHoiThaoLuan, setDsLoaiCauHoiThaoLuan] = useState([]);

  useEffect(() => {
    baseServiceNew
      .get(`${API_URL}/lay-cau-hoi-thao-luan-theo-ma-lop/${id}`)
      .then((res) => {
        const { dsCauHoiThaoLuan, lsKhoaHoc } = res.data.content;
        // console.log(res.data.content)
        setDsCauHoi(dsCauHoiThaoLuan);
        setDsLoaiCauHoiThaoLuan(lsKhoaHoc);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [filterId, setFilterId] = useState("all");
  const [filterKhoaHoc, setFilterKhoaHoc] = useState("all");
  const [dataFiltered, setDataFiltered] = useState([]);

  let dataFilterArr = [];

  useEffect(() => {
    if (filterKhoaHoc === "all" && filterId === "all") {
      dataFilterArr = dsCauHoi;
    }
  }, [dataFiltered, filterId]);

  dataFilterArr =
    filterId === "all"
      ? dsCauHoi
      : dsCauHoi?.filter((item) => {
          return item.id === filterId;
        });

  // const { id } = useParams();
  // console.log(id);
  return (
    <div
      style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
      className="h-max-content min-h-screen w-full  flex  bg-cover"
    >
      <SpinnerComponent />
      {/* <SideBar /> */}
      <SidebarNew />
      <div
        style={{ flexGrow: "1" }}
        className="flex flex-col  min-h-screen w-full"
      >
        <Navbar />
        {/* <div className=" w-full p-3 space-y-2  ">
        {dsCauHoiThaoLuan?.map((cauHoi, index) => {
                       return <CauHoi cauHoi= {cauHoi} index={index}  />
                     })}
        </div> */}
        <div className="w-full space-y-5 p-3 overflow-hidden">
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Tìm câu hỏi"
            optionFilterProp="children"
            className="w-full rounded-lg"
            // onChange={onChange}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            onSelect={(option, optionTAg) => {
              setFilterId(option);
            }}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="all">Tất cả</Option>
            {dataFilterArr?.map((item) => {
              return <Option value={item.id}>{item.tieuDe}</Option>;
            })}
          </Select>

          <p className="text-color-content">
            Tất cả câu hỏi ({dataFilterArr.length})
          </p>
          {dataFilterArr.map((item) => {
            return <ThaoLuanQA data={item}></ThaoLuanQA>;
          })}
        </div>
      </div>
    </div>
  );
}

function CauHoi(props) {
  // console.log(props)
  const { cauHoi, index } = props;

  const { khoaHoc, noiDung, tags, tieuDe } = cauHoi;
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  return (
    <Collapse>
      <Panel
        header={
          <div className="d-flex justify-content-between w-100">
            {" "}
            <div className="">
              <p>{`Câu hỏi: ${tieuDe}`}</p>
              <p>{`Khóa học: ${JSON.parse(khoaHoc)?.join(" ,")}`}</p>
              <p>{`Tags: ${JSON.parse(tags)?.join(",")}`}</p>
            </div>
          </div>
        }
        key={index}
      >
        <div className="container">{htmlToReactParser.parse(noiDung)}</div>
      </Panel>
    </Collapse>
  );
}
