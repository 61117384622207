import { DOMAIN_API } from "../config/urlConfig";

export function toAliasString(str) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/\W+/g, ' ');
    str = str.replace(/\s/g, '-');
    return str;
}

export function convertFileNameToUploadAvatarImage(stringImg) {
  if (stringImg == null) return

  if (stringImg.toString().startsWith("https://")) {
      return stringImg
    }
  return `${DOMAIN_API}/avatar-cv/${stringImg}`
}

export const convertToTitleCase = (str) => {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
}

export const boDauTiengViet = (data) => {
    if (!data) return "";

    const replaced = data?.normalize("NFD").replace(/\p{Diacritic}/gu, "");

    return replaced;
  };

  export function tachHoVaTenTuHoTen (hoTen){
    hoTen = boDauTiengViet(hoTen)
    let arrayHoTen = hoTen.split(" ");
    let arrayHo = [...arrayHoTen]
    arrayHo.length = arrayHoTen.length - 1
    return {
        ten: arrayHoTen.at(-1),
        ho: arrayHo.join(" ")
    }
  }

  export function convertHoTenThanhTenHo(hoTen) {
    let arrHoTen = hoTen.split(' ')

    let arrTenHo = [arrHoTen.at(-1)]

    arrHoTen.length = arrHoTen.length - 1

    let tenHo = arrTenHo.concat(arrHoTen).join(' ')

    return tenHo

  }