import React from 'react'
import Header from "./Header/Header";
import Body from './Body/Body';
import { useSelector } from 'react-redux';
import Basic2 from './Basic2'
import Basic1 from './Basic1'
import Default from './Default'
export const TemplateCVPrint = React.forwardRef((props, ref) => {
  const thongTinLocalCV = useSelector(
    (state) => state.nguoiDungReducer.thongTinLocalCV
  );

 
  
  function renderByLayoutTemplate() {
    switch (thongTinLocalCV.layoutTemplate) {

      case "Basic 1":
        return <Basic1 thongTinMoRong={thongTinLocalCV}></Basic1>

      case "Basic 2":
        return <Basic2 thongTinMoRong={thongTinLocalCV}></Basic2>
      default:
        return <Default thongTinMoRong={thongTinLocalCV}></Default>
//         return <>
//          <Header
//  thongTinMoRong = {thongTinLocalCV}
 
//  ></Header>
//  <Body thongTinMoRong = {thongTinLocalCV}></Body>
//         </>
       
    }
  }
    return (

      <div ref={ref}>
{renderByLayoutTemplate()}
      </div>
     

  

     
    );
  });