import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getDataRoadMapServAction } from "../../../redux/actions/roadMapAction";

export default function BuoiHoc({ data, isShow, chuongSo }) {
  // console.log(data);
  const [collapsed, setCollapsed] = useState(false);
  const { id } = useParams();

  return isShow ? (
    <div>
      <div
        className="cursor-pointer"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <div className="flex items-center justify-between py-4 px-4 bg-blue-theme text-white text-sm border-b-1 border-white rounded-t">
          <p className="mb-0">{data.tenBuoiHoc}</p>
          <i
            style={{
              transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
            }}
            className="flex-shrink-0 fa fa-angle-down text-color-title  transition  duration-100 text-base  font-medium"
          ></i>
        </div>
      </div>
      <div className="bg-item-mobile rounded-t-none">
        <AnimateHeight
          id="example-panel"
          duration={500}
          height={collapsed ? 0 : "auto"} // see props documentation below
        >
          <ul className="mb-0 space-y-3 p-3">
            {data.videoExtra.length > 0 && (
              <li className="">
                <Link
                  to={`/video-mobile/${id}/${data.id}/${chuongSo}`}
                  className="text-black no-underline flex items-center text-lg p-2 transition-all duration-300 hover:bg-gray-200 rounded"
                >
                  <i className="fa-solid fa-circle-play mr-2  "></i>
                  <span>Video xem trước</span>
                </Link>
              </li>
            )}
            {/* {data.tracNghiem.length > 0 && (
              <li>
                <Link
                  to="#"
                  className="text-black no-underline flex items-center text-lg p-2 hover:bg-gray-300 rounded"
                >
                  <i className="fa-solid fa-code mr-2"></i>
                  <span>Trắc nghiệm cuối skill</span>
                </Link>
              </li>
            )} */}
            {data.videoXemLai.length > 0 && (
              <li>
                <Link
                  to={`/video-record-mobile/${id}/${data.id}/${chuongSo}`}
                  className="text-black no-underline flex items-center text-lg p-2 hover:bg-gray-300 rounded"
                >
                  <i className="fa-solid fa-video mr-2"></i>
                  <span>Record buổi học</span>
                </Link>
              </li>
            )}
          </ul>
        </AnimateHeight>
      </div>
    </div>
  ) : (
    <div
      className="cursor-pointer"
      onClick={() => {
        setCollapsed(!collapsed);
      }}
    >
      <div className="flex items-center justify-between py-4 px-4 bg-blue-theme text-white text-sm border-b-1 border-white rounded-t">
        <p className="mb-0">{data.tenBuoiHoc}</p>
        <i
          style={{
            transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
          }}
          className="flex-shrink-0 fa fa-angle-down text-color-title  transition  duration-100 text-base  font-medium"
        ></i>
      </div>
    </div>
  );
}
