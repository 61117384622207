import {LocalStorageUtil} from './localStorage'
import {NguoiDungSevice} from '../service/nguoiDungService'        
import dayjs from 'dayjs';

export function forLoopMap(array, inputFunc) {
    let lengthArray = array.length;
    let newArrayMap = [] 
    for(let i = 0; i < lengthArray; i++) {
        newArrayMap.push(inputFunc(array[i], i))
    }

    return newArrayMap
}

export function forLoopFilter(array, checkFunc) {
    let lengthArray = array.length;
    let newArrayMap = [] 
    for(let i = 0; i < lengthArray; i++) {
        if (checkFunc(array[i])) {
            newArrayMap.push(array[i])
        }
    }
    return newArrayMap;
}

export function checkHocVienNuocNgoai(res) {
    let infoNguoiDung = LocalStorageUtil.getLoginLocalStorage();

    NguoiDungSevice.checkHocVienNuocNgoai(res)
    .then((res) => {
    // khac thi moi xu ly
        if (res.data && res.data.content != infoNguoiDung.nuocNgoai) {
            //Convert 0 / 1 to true false
            infoNguoiDung.nuocNgoai = !!res.data.content
            LocalStorageUtil.setLoginLocalStorage(infoNguoiDung)
        }
    })
    .catch((err) => {
        console.log(err)
    })
}
export const removeVietnameseTones = (str) => {
    return str
      .normalize('NFD') // Chuẩn hóa chuỗi về dạng tổ hợp ký tự
      .replace(/[\u0300-\u036f]/g, '') // Xóa các dấu tổ hợp
      .replace(/đ/g, 'd') // Thay thế 'đ' thường bằng 'd'
      .replace(/Đ/g, 'D') // Thay thế 'Đ' hoa bằng 'D'
      .replace(/[^a-zA-Z0-9 ]/g, '') // Xóa các ký tự không hợp lệ (dấu câu)
      .replace(/\s+/g, ' ') // Xóa khoảng trắng thừa
      .trim(); // Xóa khoảng trắng ở đầu và cuối chuỗi
  };
  export const getDayName = (days, idFilter) => {
    let daysDate = [];
    switch (idFilter) {
      case 0: {
        // for (let i = 0; i < days; i++) {
        for (let i = 0; i < 7; i++) {
          let date = new Date();
          date.setDate(date.getDate() - i);
          let tempt1 = date.toISOString().split("T")[0];
          let tempt2 = new Date(tempt1);
          let dayName = tempt2.toLocaleString("vi-VN", { weekday: "short" });
          dayName = dayName.replace("Th", "Thứ");
          daysDate.push(dayName);
        }
        return daysDate.reverse();
      }
      case 1: {
        for (let i = 0; i < days; i++) {
          let date = new Date();
          date.setDate(date.getDate() - i);
          let tempt1 = date.toISOString().split("T")[0];
          let tempt2 = new Date(tempt1);
          let tempt3 = dayjs(tempt2).format("DD/MM");
          daysDate.push(tempt3);
        }
        return daysDate.reverse();
      }
      case 2: {
        for (let i = 0; i < days; i++) {
          let date = new Date();
          date.setDate(date.getDate() - i);
          let tempt1 = date.toISOString().split("T")[0];
          let tempt2 = new Date(tempt1);
          let tempt3 = dayjs(tempt2).format("DD/MM");
          tempt3 = tempt3.split("/");
          tempt3 = tempt3[0] + "/" + tempt3[1];
  
          daysDate.push(tempt3);
        }
        return daysDate.reverse();
      }
  
      default:
        break;
    }
  };
  export let getHinhAnhFB = (id,data) => {

    if (!id) {
      return `https://ui-avatars.com/api/?name=${data?.hoTen}&background=d3d6db&color=ffffff&bold=true`;
    }
    return `https://graph.facebook.com/${id}/picture?type=large`;
  };
  export const getHoVaTen = (fullName) => {
    if(fullName){
      const nameParts = fullName.split(' ');
    if (nameParts.length === 1) {
      return fullName; // Nếu chỉ có 1 từ, trả về luôn tên đó
    }
    return `${nameParts[0]} ${nameParts[nameParts.length - 1]}`;
    }
  };
  export const maskEmail=(email) =>{
    // Tách phần trước và sau dấu @
    if(email){
        const [localPart, domain] = email?.split('@');
    
    // Kiểm tra độ dài của localPart
    const localPartLength = localPart.length;
    
    // Nếu localPart có ít hơn hoặc bằng 5 ký tự, giấu toàn bộ
    if (localPartLength <= 5) {
        return '*'.repeat(localPartLength) + '@' + domain;
    }
    
    // Nếu localPart có nhiều hơn 5 ký tự, giấu 5 ký tự cuối
    const visiblePart = localPart.slice(0, localPartLength - 5);
    const maskedPart = '*'.repeat(5);
    
    // Kết hợp lại và trả về kết quả
    return visiblePart + maskedPart + '@' + domain;
    }
    return email
}