import { StyleSheet } from "@react-pdf/renderer";

export const stylesChungNhan = StyleSheet.create({
  page: {},
  username: {
    fontFamily: "MtdGreatVibes",
    color: "#772c27",
    fontSize: "50px",
    position: "absolute",
    textAlign: "center",
    width: "100%",
    top: "31%",
  },
  tenChungNhan: {
    fontFamily: "iCielNovecentosans",
    color: "#772c27",
    fontSize: "0.7cm",
    fontWeight:"bold",
    position: "absolute",
    textAlign: "center",
    width: "100%",
    top: "49%",
    padding: " 0cm 1.3cm",
    margin:'20px 0'
  },
  diemCuoiKhoa: {
    textAlign: "left",
    marginLeft: "53%",
    fontFamily: "Montserrat",
    color: "#000",
    fontSize: "0.6cm",
    position: "absolute",
    width: "100%",
    bottom: "14%",
  },
  xepLoaiTA: {
    textAlign: "left",
    marginLeft: "69%",
    fontFamily: "Montserrat",
    color: "#000",
    fontSize: "0.6cm",
    position: "absolute",
    width: "100%",
    bottom: "14%",
  },
  duration: {
    textAlign: "left",
    marginLeft: "30%",
    fontFamily: "Montserrat",
    color: "#000",
    fontSize: "0.6cm",
    position: "absolute",
    width: "100%",
    bottom: "15.5%",
  },
  soChungNhan: {
    fontFamily: "Montserrat",
    color: "#000",
    fontSize: "0.45cm",
    position: "absolute",
    textAlign: "left",
    marginLeft: "25%",
    width: "100%",
    bottom: "-22%",
  },
  ngayCap: {
    fontFamily: "Montserrat",
    color: "#000",
    fontSize: "0.45cm",
    position: "absolute",
    textAlign: "left",
    marginLeft: "25%",
    width: "100%",
    bottom: "-26%",
  },
  chuKi: {
    position: "absolute",
    textAlign: "left",
    marginLeft: "59%",
    width: "350px",
    bottom: "-17%",
    objectFit: "contain",
    width: "300px",
    height: "70px",
  },
  // qrcode: {
  //   position: "absolute",
  //   marginLeft: "0",
  //   bottom:"0",
  // },
});
