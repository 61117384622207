export const TRANG_THAI_CV  = {
    DA_TRA_CV : {
        NoiDung: "Đã trả CV",
        TagColor: "red"
    } ,
    CHO_REVIEW :{
        NoiDung: "Đã nộp CV",
        TagColor: "blue"
    } ,
    CV_DA_DUOC_DUYET:{
        NoiDung: "CV đã được duyệt",
        TagColor: "green"
    } 
  }

export const LOAI_UPDATE= { NOP_CV_REVIEW : {
    NoiDung: "Nộp CV",
    TagColor: "geekblue"
} ,
TU_CHOI_CV :{
    NoiDung: "Trả CV",
    TagColor: "red"
} ,
DUYET_CV:{
    NoiDung: "Duyệt CV",
    TagColor: "green"
} 
}