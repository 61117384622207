import React, { useEffect, useState } from "react";
import chungNhanTheme from "./../../../../assets/images/C5.jpg";
import chuKy from "./../../../../assets/images/chuKyNew.png";
import "./ChungNhanQR.css";
import moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import QRCodeWithLogo from "../../../hocvien-profile/QRCodeComponent";
import QRcodeMini from "../../../hocvien-profile/QRcodeMini";
import { removeVietnameseTones } from "../../../../utilities/utils";
import { LocalStorageUtil } from "../../../../utilities/localStorage";
import { compressToEncodedURIComponent } from "lz-string";
export default function ChungNhanQR({ chungNhanId }) {
  const [chungNhan, setChungNhan] = useState();
  const userInfor = LocalStorageUtil.getLoginLocalStorage();
  const encryptCode = (code) => {
    // Nén dữ liệu trước khi mã hóa
    const compressed = compressToEncodedURIComponent(code);
    return compressed;
  };
  function decodeObject(encodedString) {

    let newEncodedString = encodedString.replace(" ", "+");


    // Decode the base64-encoded string to a UTF-8 string
    const decoded = atob(newEncodedString);
    // Decode the UTF-8 string to an object
    return JSON.parse(decodeURIComponent(escape(decoded)));
  }
  useEffect(() => {
    setChungNhan(decodeObject(chungNhanId));
  }, []);

  let xepLoai = "KHÁ";
  let xepLoaiTA = "Good";
  if (chungNhan?.diemCuoiKhoa >= 8) {
    xepLoai = "GIỎI";
    xepLoaiTA = "Very Good";
  }
  if (chungNhan?.diemCuoiKhoa >= 9) {
    xepLoai = "XUẤT SẮC";
    xepLoaiTA = "Excellent";
  }

  return (
    <div className="relative w-full">
      {chungNhan && (
        <>
          <img width="50%" className="mx-auto" src={chungNhanTheme} alt="" />
          <h2 className="view_username absolute">{removeVietnameseTones(chungNhan?.hoTen)}</h2>
          <h3 className="view_tenChungNhan">{chungNhan?.tenLoTrinh}</h3>
          <h3 className="view_duration w-max">{chungNhan?.soThangHoc} months</h3>
          <h3 className="view_diemCuoiKhoa w-max">{chungNhan?.diemCuoiKhoa} / {chungNhan?.isBaoVeCuoiKhoa ? "11" : "10"}</h3>
          {/* <h4 className="view_xepLoai w-max">{xepLoai}</h4> */}
          <h4 className="view_xepLoaiTA w-max">{xepLoaiTA}</h4>
          <h4 className="view_soChungNhan w-max">
            {chungNhan?.fullMaChungNhan}
          </h4>
          <h4 className="view_ngayCap w-max">
            {moment(chungNhan?.ngayCap).format("DD/MM/YYYY")}
          </h4>
          <img className="view_chuKi" src={chuKy} alt="" />
         <div className="qrcode">
         <QRcodeMini width={80} code ={encryptCode(userInfor.id)}/>
         </div>
        </>
      )}
    </div>
  );
}
