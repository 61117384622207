import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const target = new Date(targetDate).getTime();
      const difference = target - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        clearInterval(intervalId);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  const containerStyle = {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  };

  const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px'
  };

  const timeBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };

  const numberStyle = {
    fontSize: '36px',
    fontWeight: 'bold'
  };

  const labelStyle = {
    fontSize: '14px'
  };

  return (
    <div style={containerStyle} className="container">
      <h2 style={titleStyle}>Đếm ngược</h2>
      <div className="row">
        <div className="col-3" style={timeBoxStyle}>
          <span style={numberStyle}>{timeLeft.days}</span>
          <span style={labelStyle}>Ngày</span>
        </div>
        <div className="col-3" style={timeBoxStyle}>
          <span style={numberStyle}>{timeLeft.hours}</span>
          <span style={labelStyle}>Giờ</span>
        </div>
        <div className="col-3" style={timeBoxStyle}>
          <span style={numberStyle}>{timeLeft.minutes}</span>
          <span style={labelStyle}>Phút</span>
        </div>
        <div className="col-3" style={timeBoxStyle}>
          <span style={numberStyle}>{timeLeft.seconds}</span>
          <span style={labelStyle}>Giây</span>
        </div>
      </div>

    </div>
  );
};

export default Countdown;