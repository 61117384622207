import { Button, message, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export default function Navigate_Footer_Pratices({
  current,
  total,
  handleClickNextQuestion,
}) {
  const handleClickNext = (value) => {
    handleClickNextQuestion(value);
  };
  const percent = Math.floor((current / total) * 100);
  const nextBtnCss = "  bg-blue-theme text-white ";
  return (
    <div className="  flex items-center h-16 w-full justify-center space-x-10 px-16 border-none rounded-2xl flex-shrink-0 bg-gray-100">
      <div className="flex items-cente space-x-5 justify-center  w-full">
        <Progress
          step={total}
          percent={percent}
          className="w-full"
          showInfo={false}
          strokeWidth={15}
          strokeColor={{
            "0%": "#4A00E0",
            "100%": "#8E2DE2",
          }}
          trailColor={"rgba(68, 66, 178, 0.1)"}
        />
        <span className="font-bold flex-shrink-0 text-color-title">
          {current}/{total} câu
        </span>
      </div>
      <div className="flex space-x-3">
        <Button
          onClick={() => {
            handleClickNext(-1);
          }}
          className={
            `  text-blue-theme duration-150 font-bold px-8 rounded  flex items-center h-10 flex-shrink-0 border-none  focus:border-blue-theme hover:border-transparent hover:shadow-lg bg-white shadow-lg ${current==1?'hover:border-transparent hover:shadow-lg text-gray-600 bg-gray-300 cursor-not-allowed':''}`
          }
          // loading={loading}
        >
          Quay lại
        </Button>
        <Button
          onClick={() => {
            handleClickNext(+1);
          }}
          className={
            `  text-white duration-150 font-bold px-8 rounded  flex items-center h-10 flex-shrink-0 border-none  focus:border-blue-theme hover:border-transparent hover:shadow-lg ${current==total?'hover:border-transparent hover:shadow-lg text-gray-600 bg-gray-300 cursor-not-allowed':nextBtnCss}`
          }
          // loading={loading}
        >
          Câu tiếp theo
        </Button>

      </div>
    </div>
  );
}
