import React from "react";
import dataXemTruoc from "../../../../../../../dataFake/dataFake.json";
import MenuChildVideoXemTruoc from "./MenuChildVideoXemTruoc";
export default function MenuVideoXemTruoc({
  data,
  dataHash,
  maBuoiHoc,
  lopHocActive,
}) {
  return (
    <MenuChildVideoXemTruoc
      lopHocActive={lopHocActive}
      maBuoiHoc={maBuoiHoc}
      data={data}
      dataHash={dataHash}
    />
  );
}
