import React from "react";
import { Tabs } from "antd";
import "./tabNewVideoKhoaHocLienQuan.css";
import dataTaiLieuBuoiHoc from "./../../../../../../dataFake/dataFake.json";
const { TabPane } = Tabs;
export default function TabsNewVideoKhoaHocLienQuan() {
  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Overview" key="1">
          Content of Tab Pane 2
        </TabPane>
      </Tabs>
    </div>
  );
}
