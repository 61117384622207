import { Menu } from "antd";
import React from "react";
import SidebarItem from "./SidebarItem";

const { SubMenu } = Menu;
export default function Dropdown_LopHetHan({ dsLopHoc,setArrLuuTru }) {
  const renderLopHoc = () => {
    return dsLopHoc
      ?.filter((item) => {
        return item.maTrangThai == 3;
      })
      .map((item, index) => {
        return <SidebarItem key={index} item={item} index={index} setArrLuuTru={setArrLuuTru} />;
      });
  };
  return (
    <div className=" ">
      <Menu
        style={{ width: "100%" }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        className="rounded card-theme"
      >
        <SubMenu
          key="sub4"
          title={
            <span>
              <span className="text-blue-theme">Lớp đã kết thúc</span>
            </span>
          }
        >
          {renderLopHoc()}
        </SubMenu>
      </Menu>
    </div>
  );
}
