import { Divider } from 'antd'
import React from 'react'
import SidebarNew from '../../template/SidebarNew/sidebarNew'
import Navbar from '../../template/navbar/Navbar'
import backGrountTemplate from "../../assets/images/background.png";
import Countdown from './Countdown';


const Challenge = () => {
  return (
    <div
    style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
    className="h-max-content min-h-screen w-full  flex gioiThieu"
  >

    <div className="w-60">
      <SidebarNew />
    </div>
    <div
      style={{ flexGrow: "1" }}
      className="h-max-cotent flex flex-col  min-h-screen "
    >
      <Navbar />

      <div className=' p-3'>



        <div className='h-64 2xl:h-80 flex space-x-3 w-full flex-shrink-0 items-stretch'>
          <div className='info_card card_theme w-2/5 lg:w-2/5 md:w-full    h-full flex flex-col justify-center relative'>

          </div>
          <div className="  info_card card_theme w-3/5 lg:w-3/5 md:w-full  h-full flex flex-col justify-center relative " style={{width:"60%"}} >
           
          </div>

        </div>
        <div>
          <Divider />
          <div className="w-full info_card card_theme">
            <Countdown targetDate="2024-11-11 23:59:00.000" />
          </div>
        </div>


      </div>


    </div >

  </div >
  )
}

export default Challenge