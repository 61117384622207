import React, { Component, useEffect, useReducer, useState } from "react";
import "./resume.css";
import imgHuongDanCvProject from "../../assets/images/huongDanCvProject.png";
import imgHuongDanCvSkill from "../../assets/images/huongDanCvSkill.png";
import imgHuongDanCvSummary from "../../assets/images/huongDanCvSumary.png";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import {
  Modal,
  Popconfirm,
  Tag,
  Tooltip,
  message,
  Checkbox,
  Select,
  notification,
  Table,
  Breadcrumb
} from "antd";
import { DOMAIN_API } from "../../config/urlConfig";
import Loading from "../../assets/loading/Loading";
import { About } from "./NoiDungCV/About";
import { Language } from "./NoiDungCV/Language";
import { Education } from "./NoiDungCV/Education";
import { Expereience } from "./NoiDungCV/Expereience";
import { Certification } from "./NoiDungCV/Certification";
import { Skill } from "./NoiDungCV/Skill";
import { SoftSkill } from "./NoiDungCV/SoftSkill";
import { NgoaiNgu } from "./NoiDungCV/NgoaiNgu";
import { NavLink, withRouter } from "react-router-dom";
import { Skill_V2 } from "./NoiDungCV/Skill_V2";
import { BaseService } from "../../service/baseService";
import { error } from "jquery";
import EditThongTinCoBanModalComponent from "./Components/EditThongTinCoBanModalComponent";
import { boDauTiengViet, convertFileNameToUploadAvatarImage, convertHoTenThanhTenHo } from "../../utilities/stringFormat";
import { NguoiDungSevice } from "../../service/nguoiDungService";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../redux/types";
import moment from "moment";

const { Option } = Select;

const initialState = {
  isEditProfile: false,
  isEditSkill: false,
  thongTinMoRong: {},
  isEditProject: false,
  isAdd: false,
  indexDangChon: 0,
  itemDangChon: {},
  isModalHuongDanVisible: false,
  checkBoxPopupHuongDanchecked: false,
  optionListSoftSkill: [],
  isXemLichSuCapNhat: false,
  indexDuAnDangChon: 0
};

const CONSTANTS = {
  SET_EDIT_PROFILE: "SET_EDIT_PROFILE",
  UPDATE_PROFILE_DATA: "UPDATE_PROFILE_DATA",
  UPDATE_LIST_SKILL: "UPDATE_LIST_SKILL",
  UPDATE_THONG_TIN_MO_RONG: "UPDATE_THONG_TIN_MO_RONG",
  UPDATE_THONG_TIN_ABOUT: "UPDATE_THONG_TIN_ABOUT",
  XOA_PROJECT: "XOA_PROJECT",
  SET_XEM_LICH_SU_CAP_NHAT: "SET_XEM_LICH_SU_CAP_NHAT",
  EDIT_PROJECT: "EDIT_PROJECT",
  SET_EDIT_PROJECT_MODAL: "SET_EDIT_PROJECT_MODAL",
  UPDATE_DU_AN: "UPDATE_DU_AN",
  UPDATE_LIST_NGOAI_NGU: "UPDATE_LIST_NGOAI_NGU",
  UPDATE_LIST_SOFT_SKILL: "UPDATE_LIST_SOFT_SKILL",
  SET_MODAL_HUONG_DAN_VISIBLE: "SET_MODAL_HUONG_DAN_VISIBLE",
  UPDATE_LIST_SKILL_V2: "UPDATE_LIST_SKILL_V2",
  UPDATE_LIST_NGOAI_NGU_V2: "UPDATE_LIST_NGOAI_NGU_V2",
  UPDATE_LIST_SOFT_SKILL_V2: "UPDATE_LIST_SOFT_SKILL_V2",
  SET_CHECKED_POP_UP_HUONG_DAN: "SET_CHECKED_POP_UP_HUONG_DAN",
  SET_OPTIONS_LIST_SOFT_SKILL: "SET_OPTIONS_LIST_SOFT_SKILL",
  UPDATE_LIST_SKILL_FE: "UPDATE_LIST_SKILL_FE",
  UPDATE_LIST_SKILL_BE: "UPDATE_LIST_SKILL_BE",
  UPDATE_LIST_SKILL_OTHER: "UPDATE_LIST_SKILL_OTHER",
  SET_THONG_TIN_CV_DA_NOP: "SET_THONG_TIN_CV_DA_NOP",
};

const ContentResumeV2 = (props) => {
  const dispatch = useDispatch();

  function luuThongTinMoRongXuongLocalStorage(thongTinMoRong) {
    dispatch({
      type: types.CAP_NHAT_CV_LOCAL,
      payload: thongTinMoRong,
    });
  }

  const thongTinLocalCV = useSelector(
    (state) => state.nguoiDungReducer.thongTinLocalCV
  );

  
  const trangThaiCv = useSelector(
    (state) => state.nguoiDungReducer.trangThaiCv
  );

  // dataCvDaDuyet: null,
  //   dataCvDaNop: null,
  //   lichSuUpdate: null,
  //   trangThaiCv: null,
  //   thongTinLocalCV: {}

  function reducer(state, { type, payload }) {
    let newThongTinMoRong = {};
    let hoVaTen = "";
    switch (type) {
      case CONSTANTS.UPDATE_DU_AN:
        // console.log(payload)
        // let newListDuAn = [...thongTinLocalCV.listDuAn];
        // let model = {
        //   tieuDe: payload.project.tieuDe,
        //   linkDeploy: payload.project.linkDeploy,
        //   linkYoutube: payload.project.linkYoutube,
        //   linkGit: payload.project.linkGit,
        //   danhSachSkill: JSON.stringify(payload.project.danhSachSkill),
        //   moTa: payload.project.moTa,
        // };

        // // let index = newListDuAn.findIndex(
        // //   (item) => item.tieuDe === state.itemDangChon.tieuDe
        // // );
        // console.log(newListDuAn)
        // newListDuAn.splice(payload.indexDuAnDangChon, 1, model);

        // newThongTinMoRong = { ...thongTinLocalCV, listDuAn: newListDuAn };
        // luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return {
          ...state,
          isEditProject: false,
        };
      case CONSTANTS.SET_MODAL_HUONG_DAN_VISIBLE:
        return {
          ...state,
          isModalHuongDanVisible: payload,
        };
      case CONSTANTS.SET_THONG_TIN_CV_DA_NOP:
        return {
          ...state,
          thongTinCVDaNop: payload,
        };
      case CONSTANTS.SET_EDIT_PROJECT_MODAL:
        return {
          ...state,
          isEditProject: payload,
        };
      case CONSTANTS.EDIT_PROJECT:
        return {
          ...state,
          isEditProject: true,
          itemDangChon: payload.project,
          indexDuAnDangChon: payload.indexDuAn
        };
      case CONSTANTS.SET_CHECKED_POP_UP_HUONG_DAN:
        return {
          ...state,
          checkBoxPopupHuongDanchecked: payload,
        };

      case CONSTANTS.SET_OPTIONS_LIST_SOFT_SKILL:
        return {
          ...state,
          optionListSoftSkill: payload,
        };

      case CONSTANTS.SET_EDIT_PROFILE:
        return {
          ...state,
          isEditProfile: payload,
        };
      case CONSTANTS.UPDATE_PROFILE_DATA:
        // hoVaTen = tachHoVaTenTuHoTen(payload.hoTen)
        newThongTinMoRong = {
          ...thongTinLocalCV,
          chucDanh: payload.chucDanh,
          diaChi: payload.diaChi,
          anhAvatar: payload.anhAvatar,
          linkFacebook: payload.linkFacebook,
          linkGitHub: payload.linkGitHub,
          linkLinkedIn: payload.linkLinkedIn,
          isHiddenFacebookLink: payload.isHiddenFacebookLink,
          hoTen: payload.hoTen,
          soDienThoai: payload.soDienThoai,
          email: payload.email,
        };
        message.info("Cập nhật thành công");
        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return {
          ...state,
          isEditProfile: false,
          isHiddenFacebookLink: payload.isHiddenFacebookLink,
        };
      case CONSTANTS.UPDATE_LIST_SKILL:
        newThongTinMoRong = {
          ...thongTinLocalCV,
          danhSachSkill: payload.split(","),
        };

        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return state;
      case CONSTANTS.UPDATE_LIST_SKILL_FE:
        newThongTinMoRong = {
          ...thongTinLocalCV,
          lsSkillFE: payload,
        };

        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return state;

      case CONSTANTS.UPDATE_LIST_SKILL_BE:
        newThongTinMoRong = {
          ...thongTinLocalCV,
          lsSkillBE: payload,
        };

        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return state;

      case CONSTANTS.UPDATE_LIST_SKILL_OTHER:
        newThongTinMoRong = {
          ...thongTinLocalCV,
          lsSkillOther: payload,
        };

        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return state;
      case CONSTANTS.UPDATE_LIST_NGOAI_NGU_V2:
        newThongTinMoRong = {
          ...thongTinLocalCV,
          ngoaiNgu: payload,
        };

        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);

        return state;
      case CONSTANTS.UPDATE_LIST_SOFT_SKILL_V2:
        newThongTinMoRong = {
          ...thongTinLocalCV,
          listSoftSkill: payload,
        };

        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return state;
      case CONSTANTS.UPDATE_THONG_TIN_ABOUT:
        newThongTinMoRong = { ...thongTinLocalCV, gioiThieu: payload };

        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return state;
      case CONSTANTS.UPDATE_THONG_TIN_MO_RONG:
        luuThongTinMoRongXuongLocalStorage(payload);
        return {
          ...state,
          thongTinMoRong: payload,
        };

      case CONSTANTS.UPDATE_LIST_NGOAI_NGU:
        newThongTinMoRong = {
          ...thongTinLocalCV,
          ngoaiNgu: payload.split(","),
        };

        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return state;
      case CONSTANTS.UPDATE_LIST_SOFT_SKILL:
        newThongTinMoRong = {
          ...thongTinLocalCV,
          listSoftSkill: payload.split(","),
        };

        luuThongTinMoRongXuongLocalStorage(newThongTinMoRong);
        return state;
      default:
        return state;
    }
    throw Error("Unknown action: " + type);
  }

  useEffect(() => {
    let isMount = true;

    if (isMount) {
      openThongBaoLog(trangThaiCv, thongTinLocalCV);
    }
    return () => {
      isMount = false;
    };
  }, [trangThaiCv]);

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      BaseService.get(`api/config`)
        .then((res) => {
          dispatchLocal({
            type: CONSTANTS.SET_OPTIONS_LIST_SOFT_SKILL,
            payload: JSON.parse(
              res.data.content.find((item) => item.id === "LIST_SOFT_SKILL")
                .noiDung
            ),
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return () => {
      isMount = false;
    };
  }, []);

  // Moi lan thong tin mo rong thay doi thi luu lai xuong local

  const [state, dispatchLocal] = useReducer(reducer, initialState);

  // const layDanhGiaSkill = (point) => {
  //   if (point >= 99) return "Expert"; // Chuyên gia
  //   if (point >= 80) return "Proficient"; // thành thạo
  //   if (point >= 70) return "Advanced"; // Trình độ cao
  //   if (point >= 50) return "Skillful"; // khéo léo
  //   if (point >= 30) return "Beginner"; //mới bắt đầu

  //   return "Novice"; //Người mới
  // };

  // const listTrinhDo = () => {
  //   return [
  //     { value: 10, label: "Novice" },
  //     { value: 40, label: "Beginner" },
  //     { value: 60, label: "Skillful" },
  //     { value: 75, label: "Advanced" },
  //     { value: 85, label: "Proficient" },
  //     { value: 100, label: "Expert" },
  //   ];
  // };

  const randomTag = (data, index, type) => {
    let lstColor = [
      "magenta",
      "red",
      "volcano",
      "orange",
      "gold",
      "lime",
      "green",
      "cyan",
      "blue",
      "geekblue",
      "purple",
    ];
    let random = Math.floor(Math.random() * 11);
    return (
      <Tag
        style={{ marginBottom: "5px" }}
        color={lstColor[0]}
        key={`${type}_${index}`}
      >
        {data}
      </Tag>
    );
  };

  function handleKhongTuDongPopupHuongDanNua() {
    // Kiem tra o local co luu key khong
    let keyLocal = localStorage.getItem("popup_guide");

    if (keyLocal && keyLocal === "done") {
      localStorage.removeItem("popup_guide");
      dispatchLocal({
        type: CONSTANTS.SET_CHECKED_POP_UP_HUONG_DAN,
        payload: false,
      });
      return;
    }

    localStorage.setItem("popup_guide", "done");
    dispatchLocal({
      type: CONSTANTS.SET_CHECKED_POP_UP_HUONG_DAN,
      payload: true,
    });
  }

  useEffect(() => {
    let isMount = true;

    if (isMount) {
      // Check xem o local hv co chon khong hien thi lai huong dan khong
      let keyLocal = localStorage.getItem("popup_guide");

      if (!keyLocal) {
        dispatchLocal({
          type: CONSTANTS.SET_MODAL_HUONG_DAN_VISIBLE,
          payload: true,
        });
      }
    }

    return () => {
      isMount = false;
    };
  }, []);

  function handleXoaDuAn(index) {
    let newThongTinMoRong = { ...thongTinLocalCV };
    let tenDuAnBiXoa = newThongTinMoRong.listDuAn[index].tieuDe;

    newThongTinMoRong.listDuAn?.splice(index, 1);
    message.info(`Xóa dự án ${tenDuAnBiXoa} thành công`);
    dispatch({
      type: types.CAP_NHAT_CV_LOCAL,
      payload: newThongTinMoRong,
    });
  }

  function handleThemDuAnMoi() {
    let arrAddNewDuAn = [...thongTinLocalCV.listDuAn];

    arrAddNewDuAn.unshift({
      tieuDe: "",
      linkDeploy: "",
      linkYoutube: "",
      linkGit: "",
      danhSachSkill: "[]",
      moTa: "",
    });

    let newThongTinMoRongAddDuAn = {
      ...thongTinLocalCV,
      listDuAn: arrAddNewDuAn,
    };

    dispatch({
      type: types.CAP_NHAT_CV_LOCAL,
      payload: newThongTinMoRongAddDuAn,
    });
  }

  return (
    <div className="body p-3">
      <Loading />
      <Breadcrumb>
        <Breadcrumb.Item>
          <NavLink to="/quan-ly-cv">Quản lý CV</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
         Sửa thông tin CV
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex justify-end">
        {/* <ButtonXemLichSuUpdateCvComponent  /> */}

        <button
          className=" px-2 mr-2 py-1 bg-blue-theme text-white rounded shadow hover:shadow-lg transition"
          onClick={() =>
            dispatchLocal({
              type: CONSTANTS.SET_MODAL_HUONG_DAN_VISIBLE,
              payload: true,
            })
          }
        >
          <i className="fa fa-file-export text-white mr-2"></i> Xem hướng dẫn CV
        </button>
        <NavLink to={`/template-cv`}>
          <button className=" px-2 py-1 bg-blue-theme text-white rounded shadow hover:shadow-lg transition">
            <i className="fa fa-file-export text-white mr-2"></i> Chọn Template
          </button>
        </NavLink>
      </div>
      <div className="resume py-5">
        <div className="resume_left">
          <div className="resume_profile">
            <Tooltip title="Avatar sẽ được lấy từ ảnh đại diện facebook của bạn">
              <img
                src={convertFileNameToUploadAvatarImage(thongTinLocalCV?.anhAvatar)}
                style={{ objectFit: "cover" }}
                alt="profile_pic"
              />
            </Tooltip>
            <EditThongTinCoBanModalComponent
              dispatchLocal={dispatchLocal}
              isEditProfile={state.isEditProfile}
              thongTinMoRong={thongTinLocalCV}
              CONSTANTS={CONSTANTS}
            />
          </div>
          <div className="resume_content">
            <Tooltip
              title="Các thông tin chính, nhấn vào Edit nếu bạn muốn thay đổi."
              placement="top"
            >
              <div className="resume_item resume_info">
                <div className="title">
                  <p className="bold">
                    {`${
                      thongTinLocalCV?.gioiTinh === "male" ? "Mr." : "Mrs."
                    }${convertHoTenThanhTenHo(thongTinLocalCV?.hoTen)}`}
                  </p>
                  <p className="regular">{thongTinLocalCV?.chucDanh}</p>
                </div>
                <ul>
                  <li>
                    <div className="icon">
                      <i className="fas fa-map-signs" />
                    </div>
                    <div className="data">{thongTinLocalCV?.diaChi}</div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-mobile-alt" />
                    </div>
                    <div className="data">{thongTinLocalCV?.soDienThoai}</div>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="fas fa-envelope" />
                    </div>
                    <div className="data">{thongTinLocalCV?.email}</div>
                  </li>
                  <li>
                    <a href={thongTinLocalCV?.linkFacebook} target="_blank">
                      <div className="d-flex">
                        <div className="icon">
                          <i className="fab fa-facebook-square" />
                        </div>
                        <div className="data">Liên hệ qua Facebook</div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href={thongTinLocalCV?.linkGitHub} target="_blank">
                      <div className="d-flex">
                        <div className="icon">
                          <i className="fa fa-github" />
                        </div>
                        <div className="data">Xem Profile GitHub</div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href={thongTinLocalCV?.linkLinkedIn} target="_blank">
                      <div className="d-flex">
                        <div className="icon">
                          <i className="fa fa-linkedin" />
                        </div>
                        <div className="data">Xem Profile LinkedIn</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </Tooltip>
            {/* 
            <div className="resume_item resume_skills">
              <div className="title">
                <p className="bold">skill's</p>
              </div>
              <ul>
                {listSkill.map(item => {
                  let random = Math.floor(Math.random() * 101);

                  return <li>
                    <div className="skill_name">{item}</div>
                    <div className="skill_progress">
                      <span style={{ width: random + "%" }} />
                    </div>
                    <div className="skill_per">{layDanhGiaSkill(random)}</div>
                  </li>

                })}

              </ul>
            </div> */}

            {/* <Language thongTinMoRong={thongTinMoRong} layDanhGiaSkill={layDanhGiaSkill} listTrinhDo={listTrinhDo} /> */}
          </div>
        </div>
        <div className="resume_right">
          <About
            thongTinMoRong={thongTinLocalCV}
            dispatchLocal={dispatchLocal}
            CONSTANTS={CONSTANTS}
          />
          {/* <Skill
            thongTinMoRong={thongTinLocalCV}
            dispatchLocal={dispatchLocal}
            CONSTANTS={CONSTANTS}
          /> */}
          <Skill_V2
            danhSachSkill={thongTinLocalCV?.lsSkillBE}
            title="BackEnd Skills"
            handleUpdate={(value) => {

             let newThongTinMoRong = {
                ...thongTinLocalCV,
                lsSkillBE: value,
              };
      
              dispatch({
                type: types.CAP_NHAT_CV_LOCAL,
                payload: newThongTinMoRong
              })
            }}
            listOption={JSON.parse(localStorage.getItem("temp_dsSKill"))}
          />
          <Skill_V2
            danhSachSkill={thongTinLocalCV?.lsSkillFE}
            title="FrontEnd Skills"
            handleUpdate={(value) => {
              dispatchLocal({
                type: CONSTANTS.UPDATE_LIST_SKILL_FE,
                payload: value,
              });
            }}
            listOption={JSON.parse(localStorage.getItem("temp_dsSKill"))}
          />
          <Skill_V2
            danhSachSkill={thongTinLocalCV?.lsSkillOther}
            title="Other Skills"
            handleUpdate={(value) => {
              dispatchLocal({
                type: CONSTANTS.UPDATE_LIST_SKILL_OTHER,
                payload: value,
              });
            }}
            listOption={JSON.parse(localStorage.getItem("temp_dsSKill"))}
          />
          <Skill_V2
            danhSachSkill={thongTinLocalCV?.listSoftSkill}
            title="Soft Skills"
            handleUpdate={(value) => {
              dispatchLocal({
                type: CONSTANTS.UPDATE_LIST_SOFT_SKILL_V2,
                payload: value,
              });
            }}
            listOption={state.optionListSoftSkill}
          />
          <Skill_V2
            danhSachSkill={thongTinLocalCV?.ngoaiNgu}
            title="Languages"
            handleUpdate={(value) => {
              dispatchLocal({
                type: CONSTANTS.UPDATE_LIST_NGOAI_NGU_V2,
                payload: value,
              });
            }}
          />

          {/* <NgoaiNgu
            thongTinMoRong={thongTinLocalCV}
            dispatchLocal={dispatchLocal}
            CONSTANTS={CONSTANTS}
          /> */}
          {thongTinLocalCV?.listDuAn?.length > 0 && (
            <div className="resume_item resume_project">
              <div className="title">
                <p className="bold">Project</p>
                <button className="btn btn-success" onClick={handleThemDuAnMoi}>
                  Add
                </button>
              </div>
              <ul>
                {thongTinLocalCV?.listDuAn?.map((item, index) => {
                  return (
                    <li key={`duAn_${index}`}>
                      <div className="info">
                        <div className="d-flex justify-content-between">
                          <b className="nameTitle">{item.tieuDe}</b>
                          <span>
                            <EditProjectModalComponent
                              dispatchLocal={dispatchLocal}
                              indexDuAnDangChon={state.indexDuAnDangChon}
                              indexDuAn={index}
                              isAdd={state.isAdd}
                              CONSTANTS={CONSTANTS}
                              isEditProject={state.isEditProject}
                              itemDuAn={item}
                              itemDangChon={state.itemDangChon}
                             
                            />
                            {thongTinLocalCV.listDuAn.length == 1 ? (
                              ""
                            ) : (
                              <Popconfirm
                                title="Bạn có chắc muốn xóa dự án này không ?"
                                onConfirm={() => handleXoaDuAn(index)}
                                okText="Đúng"
                                cancelText="Không"
                              >
                                <button className="btn btn-danger ml-2">
                                  Delete
                                </button>
                              </Popconfirm>
                            )}
                          </span>
                        </div>

                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-6">
                              <p className="semi-bold">
                                <span>Skill on Project: </span>
                                {JSON.parse(item.danhSachSkill)?.map(
                                  (valueSkill, index) => {
                                    return randomTag(
                                      valueSkill,
                                      index,
                                      "skillOnProject"
                                    );
                                  }
                                )}
                              </p>
                              {/* <p className="memberTeam">
                          Team size: <span>3</span>
                        </p> */}

                              {item.linkDeploy.trim() !== "" ? (
                                <p className="linkDemo">
                                  <a href={item.linkDeploy} target="_blank">
                                    <button className="btn btn-primary">
                                      Demo Link
                                    </button>
                                  </a>
                                </p>
                              ) : (
                                ""
                              )}

                              {item.linkYoutube.trim() !== "" ? (
                                <p className="linkDemo">
                                  <a href={item.linkYoutube} target="_blank">
                                    <button className="btn btn-danger">
                                      Youtube Link
                                    </button>
                                  </a>
                                </p>
                              ) : (
                                ""
                              )}

                              {item.linkGit.trim() !== "" ? (
                                <p className="linkDemo">
                                  <a href={item.linkGit} target="_blank">
                                    <button className="btn btn-secondary">
                                      Source Code Link
                                    </button>
                                  </a>
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-6">
                              <p className="semi-bold">{item.moTa}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <Education
            thongTinMoRong={thongTinLocalCV}
            CONSTANTS={CONSTANTS}
            dispatchLocal={dispatchLocal}
          />

          <Expereience
            thongTinMoRong={thongTinLocalCV}
            CONSTANTS={CONSTANTS}
            dispatchLocal={dispatchLocal}
          />

          <Certification
            thongTinMoRong={thongTinLocalCV}
            CONSTANTS={CONSTANTS}
            dispatchLocal={dispatchLocal}
          />

          <Modal
            visible={state.isModalHuongDanVisible}
            title={``}
            onCancel={() =>
              dispatchLocal({
                type: CONSTANTS.SET_MODAL_HUONG_DAN_VISIBLE,
                payload: false,
              })
            }
            footer={null}
            width="50vw"
            height="90vh"
            centered
            style={{ top: 10, minWidth: "800px" }}
          >
            <h2 className="text-center text-3xl">
              Hướng dẫn cập nhật thông tin CV
            </h2>

            <ul
              className="list-disc list-outside pl-8"
              style={{ width: "50vw" }}
            >
              {/* <li>Các nội dung của CV các bạn phải điền bằng tiếng anh</li>
              <li>
                Các dự án thì các bạn phải update lại các phần nội dung về tiêu
                đề và mô tả dự án
              </li>
              <li>
                Phần mô tả dự án thì các bạn nêu ra vai`trò của bạn trong team,
                các công việc mà mình đã làm trong dự án, số lượng người tham
                dự.
              </li>
              <li> 
                Trong dự án nếu không hiển thị các link demo / link youtube /
                link git thì các bạn phải bổ sung để đầy đủ thông tin
              </li> */}
              <li className="">
                <img
                  className="d-block w-100"
                  src={imgHuongDanCvSummary}
                  alt="imgHuongDanCvSummary"
                />
              </li>
              <li className="">
                <img
                  className="d-block w-100"
                  src={imgHuongDanCvSkill}
                  alt="imgHuongDanCvSkill"
                />
              </li>
              <li className=" mb-2">
                <img
                  className="d-block w-100"
                  src={imgHuongDanCvProject}
                  alt="imgHuongDanCvProject"
                />
              </li>
            </ul>
            <div className="container">
              <div className="row align-items-center">
                <div className="form-group d-flex align-content-center">
                  <input
                    type="checkbox"
                    className="hover"
                    style={{ width: "20px", height: "20px" }}
                    checked={state.checkBoxPopupHuongDanchecked}
                    id="khongHienThiLaiPopup"
                    defaultValue={false}
                    onClick={handleKhongTuDongPopupHuongDanNua}
                  />
                  <label
                    className="font-bold ml-2 hover"
                    for="khongHienThiLaiPopup"
                  >
                    Không hiển thị lại popup
                  </label>
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      dispatchLocal({
                        type: CONSTANTS.SET_MODAL_HUONG_DAN_VISIBLE,
                        payload: false,
                      })
                    }
                  >
                    Đóng
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

function openThongBaoLog(trangThaiCV, thongTinLocalCV) {
  
  switch (trangThaiCV) {
    case "CHO_REVIEW":
      notification.open({
        message: "Thông báo",
        description:
          "CV của bạn đang được duyệt, bạn vui lòng đợi nhé. Bạn cũng có thể cập nhật CV và update CV đã gửi trong thời gian chờ duyệt",
      });
      break;

    default:
      // if (thongTinLocalCV?.listDuAn?.length < 3) {
      //   notification.open({
      //     message: "Thông báo",
      //     description:
      //       "Bạn đang thiếu dự án, bạn cần cập nhật thêm dự án vào CV để được nộp nhé",
      //   });
      // }
          
         
     
      break;
  }
}

function ButtonXemLichSuUpdateCvComponent(props) {
  const lichSuUpdate = useSelector(
    (state) => state.nguoiDungReducer.lichSuUpdate
  );

  const [isVisibleXemLogModal, setIsVisibleXemLogModal] = useState(false);

  const danhSachLogDuocHienThi = ["NOP_CV_REVIEW","DUYET_CV","TU_CHOI_CV"];

  const logUpdate = lichSuUpdate?.filter((item, key) => {
    return danhSachLogDuocHienThi.includes(item.LoaiLog);
  });

  const cols = [
    {
      title: "Loại log",
      render: (text, record) => {
        return <p>{record.LoaiLog}</p>;
      },
      key: "loaiLog",
      dataIndex: "loaiLog",
    },
    {
      title: "Ngày thực hiện",
      render: (text, record) => {
        return <p>{moment(record.NgayTao).format("DD/MM/yyyy")}</p>;
      },
      key: "ngayThucHien",
      dataIndex: "ngayThucHien",
    },
    {
      title: "Nội dung",
      render: (text, record) => {
        return <p>{record.NoiDung}</p>;
      },
      key: "noiDungLog",
      dataIndex: "noiDungLog",
    },
  ];



  return (
    <>
      lichSuUpdate ? ({" "}
      <button
        className=" px-2 mr-2 py-1 bg-blue-theme text-white rounded shadow hover:shadow-lg transition"
        onClick={() =>
         setIsVisibleXemLogModal(true)
        }
      >
        <i className="fa fa-edit text-white mr-2"></i> Xem lịch sử cập nhật CV
      </button>
      ) : ""
      <Modal
        visible={isVisibleXemLogModal}
        title={`Lịch sử cập nhật CV`}
        onCancel={() =>
          setIsVisibleXemLogModal(false)
        }
        footer={null}
        width="50vw"
        height="90vh"
        centered
        style={{ top: 10, minWidth: "800px" }}
      >
        <Table
          dataSource={logUpdate}
          columns={cols}
          width={'100%'}
        >

        </Table>
      </Modal>
    </>
  );
}

function EditProjectModalComponent(props) {
  const {
    dispatchLocal,
    isEditProject,
    itemDuAn,
    indexDuAn,
    indexDuAnDangChon
  } = props;

  const dispatch = useDispatch();

  const thongTinMoRong = useSelector(state => state.nguoiDungReducer.thongTinLocalCV)

  let danhSachSkillMau = thongTinMoRong.danhSachSkill
  let listDuAn = thongTinMoRong.listDuAn
  let itemDangChon = listDuAn[indexDuAnDangChon]

  const [duAnDangChon, setDuAnDangChon] = useState({});

  const listOptionSkillDuAn = danhSachSkillMau.map((item) => {
    return <Option key={item}>{item}</Option>;
  });

  useEffect(() => {
    let isMount = true;

    if (isMount && itemDangChon) {
      let newDuAnDangChon = {
        ...duAnDangChon,
        tieuDe: itemDangChon.tieuDe,
        linkDeploy: itemDangChon.linkDeploy,
        linkYoutube: itemDangChon.linkYoutube,
        linkGit: itemDangChon.linkGit,
        danhSachSkill: itemDangChon.danhSachSkill
          ? JSON.parse(itemDangChon.danhSachSkill)
          : [],
        moTa: itemDangChon.moTa,
      };
      setDuAnDangChon(newDuAnDangChon);
    }

    return () => {
      isMount = false;
    };
  }, [indexDuAnDangChon]);

  const handleChangeInput = (e) => {
    let newDuAnDangChon = { ...duAnDangChon };
    newDuAnDangChon[e.target.name] = e.target.value;
    setDuAnDangChon(newDuAnDangChon);
  };

  const handleChangeDanhSachSkillOnProject = (value) => {
    let newDuAnDangChon = { ...duAnDangChon, danhSachSkill: value };
    setDuAnDangChon(newDuAnDangChon);
  };

  function handleLuuThongTin() {

    let newModel = {
          tieuDe: boDauTiengViet(duAnDangChon.tieuDe),
          linkDeploy: duAnDangChon.linkDeploy,
          linkYoutube: duAnDangChon.linkYoutube,
          linkGit: duAnDangChon.linkGit,
          danhSachSkill: JSON.stringify(duAnDangChon.danhSachSkill),
          moTa: boDauTiengViet(duAnDangChon.moTa),
        };
    

  let newListDuAn = [...listDuAn]

    newListDuAn.splice(indexDuAnDangChon, 1, newModel)

    let newThongTinMoRong = { ...thongTinMoRong, listDuAn: newListDuAn };

    dispatch({
      type: types.CAP_NHAT_CV_LOCAL,
      payload: newThongTinMoRong,
    });

    dispatchLocal({
      type: CONSTANTS.UPDATE_DU_AN
    })

    
  }

  return (
    <>
      <button
        className="btn btn-primary ml-auto"
        onClick={() =>
          dispatchLocal({ type: CONSTANTS.EDIT_PROJECT, payload: {
            project: itemDuAn,
            indexDuAn: indexDuAn
          } })
        }
      >
        Edit
      </button>
      <Modal
        title={"Edit Project"}
        visible={isEditProject}
        footer={null}
        bodyStyle={{ width: "50vw" }}
        onCancel={() =>
          dispatchLocal({
            type: CONSTANTS.SET_EDIT_PROJECT_MODAL,
            payload: false,
          })
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-12" id="editProjectForm">
              <div className="form-group">
                <label className="font-bold">Project Name: </label>
                <input
                  type="text"
                  className="form-control"
                  name="tieuDe"
                  placeholder="Nhập tên dự án"
                  value={duAnDangChon?.tieuDe ? duAnDangChon?.tieuDe : ""}
                  onChange={(e) => handleChangeInput(e)}
                />
              </div>
              <div className="form-group">
                <label className="font-bold">Deploy Link: </label>
                <input
                  type="text"
                  className="form-control"
                  name="linkDeploy"
                  placeholder="Nhập link deloy du an"
                  value={
                    duAnDangChon?.linkDeploy ? duAnDangChon?.linkDeploy : ""
                  }
                  onChange={(e) => handleChangeInput(e)}
                />
              </div>
              <div className="form-group">
                <label className="font-bold">Youtube Link: </label>
                <input
                  type="text"
                  className="form-control"
                  name="linkYoutube"
                  placeholder="Nhập link Youtube"
                  value={
                    duAnDangChon?.linkYoutube ? duAnDangChon?.linkYoutube : ""
                  }
                  onChange={(e) => handleChangeInput(e)}
                />
              </div>
              <div className="form-group">
                <label className="font-bold">Source Code Link: </label>
                <input
                  type="text"
                  className="form-control"
                  name="linkGit"
                  placeholder=" Nhập link Source Code"
                  value={duAnDangChon?.linkGit ? duAnDangChon?.linkGit : ""}
                  onChange={(e) => handleChangeInput(e)}
                />
              </div>
              <div className="form-group">
                <label className="font-bold">Skills on Project: </label>
                {/* <textarea
                  className="form-control"
                  name="danhSachSkill"
                  rows={6}
                  value={
                    duAnDangChon?.danhSachSkill
                      ? duAnDangChon?.danhSachSkill
                      : ""
                  }
                  placeholder="Mỗi skill cần cách nhau bởi dấu phẩy"
                  onChange={(e) => handleChangeInput(e)}
                /> */}
                <Select
                  mode="tags"
                  value={duAnDangChon?.danhSachSkill}
                  style={{ width: "100%" }}
                  placeholder="Bạn hãy thêm thông tin vào nhé"
                  onChange={(value) =>
                    handleChangeDanhSachSkillOnProject(value)
                  }
                >
                  {listOptionSkillDuAn}
                </Select>
              </div>
              <div className="form-group">
                <label className="font-bold">Project description: </label>
                <textarea
                  className="form-control"
                  name="moTa"
                  rows={6}
                  value={duAnDangChon?.moTa ? duAnDangChon?.moTa : ""}
                  onChange={(e) => handleChangeInput(e)}
                  placeholder="Bạn hãy nhập mô tả cho dự án nhé"
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={() => handleLuuThongTin()}
              >
                Cập nhật
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default ContentResumeV2;
