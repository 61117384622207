import React, { useEffect, useState } from 'react'
import Navbar from '../../template/navbar/Navbar'
import SidebarNew from '../../template/SidebarNew/sidebarNew'
import backGrountTemplate from "../../assets/images/background.png";
import { useMeasure } from 'react-use';
import Axios from 'axios';
import HtmlParser from "react-html-parser";
import './gioithieu.css'
import { Divider, Pagination, Tooltip, Tag, Table, DatePicker, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import history from '../../utilities/history';
import { NguoiDungSevice } from '../../service/nguoiDungService';
import { LocalStorageUtil } from '../../utilities/localStorage';
import LstGioiThieu from './LstGioiThieu';
import moment from 'moment';


const { RangePicker } = DatePicker;

export const ThongKeGioiThieu = () => {

    let [noiDung, setNoiDung] = useState("");
    console.log('noiDung: ', noiDung);
    let [code, setCode] = useState("");
    let [dsGioiThieu, setDsGioiThieu] = useState("");
    console.log('dsGioiThieu: ', dsGioiThieu);
    let param = useParams();
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    //day start,end
    const thisDate = new Date();

    let [ngayBD, setNgayBD] = useState(new Date(thisDate.getFullYear(), thisDate.getMonth(), 1));
    let [ngaykt, setNgayKt] = useState(new Date(thisDate.getFullYear(), thisDate.getMonth() + 1, 0));
    const defaultDateRange = [moment(ngayBD), moment(ngaykt)];
    const splitEmail = (email) => {
        const atIndex = email.indexOf('@');
        if (atIndex === -1) return ''; // Nếu không tìm thấy '@', trả về chuỗi rỗng

        const partBeforeAt = email.slice(0, atIndex);
        const dotIndex = email.indexOf('.', atIndex);

        if (dotIndex !== -1) {
            return email.slice(0, dotIndex);
        } else {
            return partBeforeAt;
        }
    }
    const [cybersoft, setCybersoft] = useState([]);
    const [codezuni, setCodezuni] = useState([]);

    useEffect(() => {
       if(noiDung){
        const filtered = noiDung.filter(item => item.metaData == "Mentor11");
        setCodezuni(filtered);
        const fillCybersoft = noiDung.filter(item => item.metaData == "Cybersoft");
        setCybersoft(fillCybersoft);
       }
    }, [noiDung]);
    const codeRefer = splitEmail(nguoiDung.email)
    const onChange = (dates, dateStrings) => {
        if (dates) {
            const obj = {
                "ngayBatDau": dates[0].toISOString(),
                "ngayKetThuc": dates[1].toISOString(),
                "coderefer": codeRefer
            }
            setNgayBD(dates[0])
            setNgayKt(dates[1])
            NguoiDungSevice.getLstGTThangService(obj).then(res => {
                setDsGioiThieu(res.data.content)
            })
        } else {
            console.log('Clear');
        }
    };
    useEffect(() => {
        const obj = {
            "ngayBatDau": ngayBD,
            "ngayKetThuc": ngaykt,
            "coderefer": codeRefer
        }
        NguoiDungSevice.laySanPhamBanService().then(res => {


            setNoiDung(res.data.content)


        })
        setCode(codeRefer)
        NguoiDungSevice.getLstGTThangService(obj).then(res => {

            setDsGioiThieu(res.data.content)

        })

    }, [])


    const demGioiThieu = (sanPhamId, chot) => {
        let dem = [];

        if (dsGioiThieu)
            dem = dsGioiThieu?.filter(n => n.sanPhamId == sanPhamId);

        if (chot) {
            dem = dem.filter(n => n.daChot == true && n.khaiGiang==true)
        }
        return dem.length
    }
    const demGioiThieuDaNhan = (sanPhamId) => {
        let dem = [];
        if (dsGioiThieu)
            dem = dsGioiThieu?.filter(n => n.sanPhamId == sanPhamId);
        dem = dem.filter(n => n.daChot == true && n.traThuong == true && n.khaiGiang==true)
        return dem.length
    }

    const TongTheoSP = (id) => {
        let tongCong =0
        if (dsGioiThieu) {
            dsGioiThieu.filter(b=>b.sanPhamId==id).map((item, index) => {
                if(item.daChot && item.khaiGiang){
                    tongCong +=(item.hocPhiDong * item.chietKhau / 100)
                }
            })
        }
        return tongCong * 1;

    }

    const thongKeTong = (getTien, chot) => {
        let dem = 0;
        let tongCong = 0;
        if (noiDung) {
            noiDung?.map((item, index) => {
                let demRieng = demGioiThieu(item.id, chot)
                dem += demRieng;
            })
        }
        return dem
    }
    const thongKeTong2 = () => {
        let tongCong = 0;
        if (dsGioiThieu) {
            dsGioiThieu?.map((item, index) => {
                if(item.daChot && item.khaiGiang){
                    tongCong +=(item.hocPhiDong * item.chietKhau / 100)
                }
            })
        }
        return tongCong.toLocaleString()
    }
    const TongNhan = () => {
        let count = 0
        if (dsGioiThieu) {
            dsGioiThieu.map((item, index) => {
                if(item.daChot && item.traThuong && item.khaiGiang){
                    count += item.hocPhiDong * item.chietKhau / 100
                }
            })
        }
        return count * 1
    }
    const CheckDaNhan2 = (a) => {
        console.log('a: ', a);
        let tongCong =0
        if (dsGioiThieu) {
            dsGioiThieu.filter(b=>b.sanPhamId==a.id).map((item, index) => {
                if(item.daChot && item.khaiGiang && item.traThuong){
                    tongCong +=(item.hocPhiDong * item.chietKhau / 100)
                }
            })
        }
        return tongCong * 1;
    }
    const [idSP, setIDSp] = useState();
    const [openDrawerSP, setOpenDrawerSP] = useState(false);

    const showDrawerSP = (id) => {
        setIDSp(id)
        setOpenDrawerSP(true);
    };

    const onCloseSP = () => {
        setOpenDrawerSP(false);
    };
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, rec, idx) => idx + 1
        },
        {
            title: 'Hình ảnh',
            dataIndex: 'hinhAnh',
            key: 'hinhAnh',
            render: (text, rec, idx) => <img width={50} src={text} />
        },
        {
            title: 'Sản phẩm',
            dataIndex: 'tenSanPham',
            key: 'tenSanPham',
            sorter: (a, b) => a.tenSanPham.length - b.tenSanPham.length,
            render: (text, rec, idx) => <p className='p-0 m-0' style={{ cursor: 'pointer' }} onClick={() => { showDrawerSP(rec) }}>{text}</p>
        },
        {
            title: 'Giá sản phẩm',
            dataIndex: 'tongTien',
            key: 'tongTien',
            sorter: (a, b) => a.tongTien - b.tongTien,
            render: (text, rec, idx) => `${text.toLocaleString()}đ`
        },
        // {
        //     title: 'Chiết khấu',
        //     dataIndex: 'phanTramChietKhau',
        //     key: 'phanTramChietKhau',
        //     sorter: (a, b) => a.phanTramChietKhau - b.phanTramChietKhau,
        //     render: (text, rec, idx) => {
        //         if (text == 0) {
        //             return `${(rec.tienThuong * 1).toLocaleString()} VNĐ`
        //         } else return `${text}%`
        //     }
        // },
        // {
        //     title: 'Chiết khấu',
        //     dataIndex:'chietKhau',
        //     key: 'chietKhau',
        //     render: (text,rec, idx) => `${text} %`
        // },
        {
            title: 'Giới thiệu thành công',
            key: 'gttc',
            sorter: (a, b) => demGioiThieu(a.id, true) - demGioiThieu(b.id, true),
            render: (rec, idx) => demGioiThieu(rec.id, true)
        },
        // {
        //     title: 'Tổng thưởng',
        //     key: 'tongThuong',
        //     // sorter: (a, b) => tinhTien(a.id, a.tongTien, a.phanTramChietKhau, a.tienThuong, true) - tinhTien(b.id, b.tongTien, b.phanTramChietKhau, a.tienThuong, true),
        //     render: (rec, idx) => `${(demGioiThieu(rec.id, true) * nguoiDung.chietKhau/100 * rec.tongTien).toLocaleString()} đ`
        // },
        // {
        //     title: 'Tổng thưởng',
        //     key: 'tongThuong',
        //     sorter: (a, b) => tinhTien(a.id, a.tongTien, a.phanTramChietKhau, a.tienThuong, true) - tinhTien(b.id, b.tongTien, b.phanTramChietKhau, a.tienThuong, true),
        //     render: (rec, idx) => `${tinhTien(rec.id, rec.tongTien, rec.phanTramChietKhau, rec.tienThuong, true).toLocaleString()} đ`
        // },
        {
            title: 'Đã nhận',
            key: 'daNhan',
            render: (rec, idx) => CheckDaNhan2(rec).toLocaleString() + 'đ / ' + TongTheoSP(rec.id).toLocaleString() + 'đ'
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (_, rec) => {
                return <div className='d-flex justify-content-between'>
                    <div style={{ position: 'relative' }}>
                        <button className='btn btn-info' onClick={() => {
                            showDrawerSP(rec)
                        }}>Xem</button>
                        {demGioiThieuDaNhan(rec.id) < demGioiThieu(rec.id, true) && <div className='dot'></div>}
                    </div>
                </div>
            }
        },

    ]
    const setRowColors = () => {
        const rows = document.querySelectorAll('.ant-table-row');
        rows.forEach((row, index) => {
            if (index % 2 === 0) {
                row.style.backgroundColor = '#f0f0f0'; // Màu sáng cho hàng chẵn
            } else {
                row.style.backgroundColor = '#d9d9d9'; // Màu tối cho hàng lẻ
            }
        });
    };
    useEffect(() => {
        setRowColors();
    }, [noiDung]);

    // Khi sorting được thực hiện, gọi lại hàm thiết lập màu
    const handleTableChange = (pagination, filters, sorter) => {
        setRowColors();
    };
    return (
        <div
            style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
            className="h-max-content min-h-screen w-full  flex gioiThieu"
        >
            <div className="w-60">
                <SidebarNew />
            </div>
            <div
                style={{ flexGrow: "1" ,width:'calc(100% - 15rem)'}}
                className="h-max-cotent flex flex-col  min-h-screen "
            >
                <Navbar />

                <div className='row p-3'>
                    <RangePicker className='pb-3'
                        defaultValue={defaultDateRange}
                        // locale={customLocale}
                        ranges={{
                            Today: [moment(), moment()],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        onChange={onChange}
                    />
                    <div

                        className="col-12  card_theme  flex justify-around  p-2  "

                    >
                        <div >
                            <span >Đã giới thiệu</span>
                            <div className='font-bold h4'>

                                <i className='fa fa-users rounded-full bg-red-500 p-3 text-white'></i> {thongKeTong(false, false)}
                            </div>
                        </div>

                        <div >
                            <span >Giới thiệu thành công</span>
                            <div className='font-bold h4'>

                                <i className='fa fa-check rounded-full bg-blue-500 p-3 text-white'></i>  {thongKeTong(false, true)}
                            </div>
                        </div>
                        <div>
                            <span >Tổng tiền giới thiệu
                                <Tooltip title="Tổng tiền phải chi thưởng cho những giới thiệu thành công và những lớp đó đã khai giảng!"> <i className="fa-regular fa-circle-question"></i></Tooltip>
                            </span>
                            <div className='font-bold h4'>
                                <i className='fa fa-money rounded-full bg-yellow-600 p-3 text-white'></i>  {thongKeTong2(true, false)} đ
                            </div>
                        </div>
                        <div>
                            <span >Tổng đã nhận</span>
                            <div className='font-bold h4'>
                                <i className='fa fa-usd rounded-full bg-green-600 p-3 text-white'></i>  {TongNhan().toLocaleString()} đ
                            </div>
                        </div>
                    </div>
                    <div

                        className="col-12  card_theme   p-2 mt-3  "

                    >

                        <Divider />
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Cybersoft" key="1">
                                <Table style={{ width: '100%' }} dataSource={cybersoft} columns={columns} onChange={handleTableChange} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Codezuni" key="2">
                                <Table style={{ width: '100%' }} dataSource={codezuni} columns={columns} onChange={handleTableChange} />
                            </Tabs.TabPane>
                        </Tabs>

                        {openDrawerSP && <LstGioiThieu id={idSP} onClose={onCloseSP} openDrawer={openDrawerSP} code={code} ngayBd={ngayBD} ngaykt={ngaykt} />}
                    </div>
                </div>

            </div>


        </div >
    )
}
