import React, { useRef } from "react";
import { DOMAIN_API_FILE } from "../../../../../../../config/urlConfig";
import { RoadmapService } from "../../../../../../../service/roadmapService";
import { initBaiTapNop } from "../../../../../../../utilities/roadmap";
import moment from "moment";
import { LocalStorageUtil } from "../../../../../../../utilities/localStorage";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { getDataRoadMapServAction } from "../../../../../../../redux/actions/roadMapAction";
import { useParams } from "react-router-dom";
import HtmlParser from "react-html-parser";

export default function ItemBaiTapNop({ idKhoaHoc, data, color }) {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  const inputRef = useRef();
  const { id } = useParams();

  let dispatch = useDispatch();
  const handleNopBaiTap = (data) => {
    let baiTap = initBaiTapNop;
    baiTap.maBuoiHoc = data.maBuoiHoc;
    baiTap.maBaiHoc = data.id;
    baiTap.maLop = idKhoaHoc * 1;
    baiTap.maNguoiDung = nguoiDung.id;
    baiTap.noiDung = inputRef.current.value;
    if (!inputRef.current.value) {
      message.error("Nội dung nộp không được để rỗng");
      return;
    }
    RoadmapService.postQuizzWrite(baiTap)
      .then((res) => {
        message.success("Nộp bài tập thành công");
        dispatch(getDataRoadMapServAction(id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let renderContent = () => {
    //đã nộp nhưng chưa có điểm
    if (data.diem == -1) {
      return (
        <>
          <p className="text-red-500 text-center">
            Bài tập của bạn đang được đợi chấm
          </p>
          <div className="flex rounded-lg h-max-content overflow-hidden">
            <input
              ref={inputRef}
              type="text"
              placeholder={data.linkDaNop}
              className=" w-full h-12  px-3"
            />
            <button
              onClick={() => {
                handleNopBaiTap(data);
              }}
              className=" w-24 bg-blue-theme text-white cursor-pointer"
            >
              Cập nhật
            </button>
          </div>
        </>
      );
    } else if (data.diem > 0) {
      // có điểm
      return (
        <button className="round px-4 py-2 bg-blue-theme w-max mx-auto rounded text-white ">
          {data.diem} điểm
        </button>
      );
    } else if (!data.isHetHan && data.diem == 0) {
      //  lần đầu nộp
      return (
        <div className="flex rounded-lg h-max-content overflow-hidden">
          <input
            ref={inputRef}
            type="text"
            placeholder="Link nộp bài tập"
            className=" w-full h-12  px-3"
          />
          <button
            onClick={() => {
              handleNopBaiTap(data);
            }}
            className=" w-24 bg-blue-theme text-white cursor-pointer"
          >
            Nộp bài
          </button>
        </div>
      );
    } else if (data.isHetHan && data.diem == 0) {
      return (
        <button className="round px-4 py-2 bg-red-600 w-22 mx-auto rounded text-white ">
          Hết hạn
        </button>
      );
    }
  };
  return (
    <div
      style={{ backgroundColor: color.bg }}
      className="w-full  p-2 px-3  min-h-22 justify-between flex items-center relative rounded-lg shadow-lg"
    >
      <div className="w-full pl-6 h-full flex flex-col  relative  space-y-3">
        <div className="flex space-x-2 justify-between space-x-5 items-center">
          <p className="text-gray-900">{data.tieuDe}</p>

          <p>
            <span className="text-black">Deadline</span>{" "}
            <span className="text-red-500 font-medium">
              {moment(data.hanNop).format("DD/MM/YYYY")}
            </span>{" "}
          </p>
        </div>
        {data.noiDung && (
          <button
            className=" flex items-center h-max-content rounded p-1 border-gray-600 w-max flex-shrink-0 border-1 text-color-content cursor-pointer hover:text-gray-900  px-2 transform duration-300 hover:border-gray-500
       "
          >
            <a
              className="flex justify-center items-center space-x-1 text-color-content"
              href={`${DOMAIN_API_FILE}/${data.noiDung}`}
              target="_blank"
            >
              <i className="fa fa-folder-open"></i>
              <span>Đề bài</span>
              <i className="fa fa-download"></i>
            </a>
          </button>
        )}
        {data.ghiChu && (
          <div className="p-3 pb-0 w-full  bg-white rounded text-black">
            {HtmlParser(data.ghiChu)}
          </div>
        )}
        {renderContent()}
        <div
          style={{ backgroundColor: color.line }}
          className="h-4/5 w-1 left-2 top-1/2 transform absolute rounded-lg -translate-y-1/2"
        ></div>
      </div>
    </div>
  );
}
