import React from "react";
import Center from "./Center";
import Left from "./Left";
import Right from "./Right";

export default function TracNghiemCuoiKhoa({ data, dataHash }) {
  return (
    <div className="w-full  space-x-12 xl:space-x-36 flex  justify-center items-center transform -translate-y-20 relative z-10">
      <Left data={data} dataHash={dataHash} /> <Center data={data} />
      <Right data={data} />
    </div>
  );
}
