export const cityOptions = [
    { value: '2', label: 'Thành phố Đà Nẵng' },
    { value: '3', label: 'Thành phố Hà Nội' },
    { value: '5', label: 'Thành phố Hồ Chí Minh' }
];

export const introduceOptions = [
    { value: 'BANBE', label: 'Bạn bè' },
    { value: 'FACEBOOK', label: 'Facebook' },
    { value: 'GOOGLE', label: 'Google' },
    { value: 'KHAC', label: 'Khác' }
];

export const industryOptions = [
    { value: 'Tôi học ngành CNTT', label: 'Tôi học ngành CNTT' },
    { value: 'Tôi trái ngành', label: 'Tôi trái ngành (Cố gắng luyện gõ 10 ngón và không nhìn nhé ;) )' },
    { value: 'Tôi chỉ học phổ thông', label: 'Tôi chỉ học phổ thông (Tới lớp 12) (Cố gắng luyện gõ 10 ngón và không nhìn nhé ;) )' }
];

export const cybersoftOptions = [
    { value: "true", label: 'Đồng ý' },
    { value: "false", label: 'Không' }
];