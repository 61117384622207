import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import "./TrangChuPage.scss";
import { LopHocSevice } from "../../service/lopHocService";
import { LocalStorageUtil } from "../../utilities/localStorage";
import * as actions from "../../redux/actions/lopHocAction";

const data = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ],
};

class TrangChuPage extends Component {
  render() {

    const {dsLopHoc} = this.props.lopHocProps

    if (dsLopHoc && dsLopHoc.length > 0) {
      let index = dsLopHoc.findIndex((item) => {
        return item.isStart && item.hasRoadMap;
      });
      if (index !== -1) {
        //   console.log(dsLopHoc[index]);

        this.props.history.push(`/course-roadmap/${dsLopHoc[index].id}`);
      }
    }

  
    return (
      <div className="home">
        <div>
          <h2>Thống kê</h2>
          <Bar
            data={data}
            width={100}
            height={90}
            options={{
              maintainAspectRatio: false,
            }}
          />
        </div>
      </div>
    );
  }



  componentWillMount() {
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    this.props.dispatch(actions.layDanhSachLopHoc(nguoiDung.id));

  }

  componentDidMount = () => {
    LopHocSevice.layDanhSachXemLaiService().then((res) => {
      let data = res.data.content;
      let dNow = new Date();

      data = data.filter((item) => {
        let dateTam = new Date();
        dateTam = new Date(
          new Date(item.ngayTao).setDate(new Date(item.ngayTao).getDate() + 15)
        );

        if (dateTam < dNow) return item;
      });

      if (data.length > 0) {
        let lstData = [];
        data.map((item) => {
          lstData.push(item.id);
        });

        // LopHocSevice.xoaXemLaiService(lstData);
      }
    });
  };
}
function mapStateToProps(state) {
  return {
    lopHocProps: state.lopHocReducer,
  };
}

export default connect(mapStateToProps, null)(TrangChuPage);
