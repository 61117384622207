import React from "react";
import ItemBaiTapNop from "../../../../../roadmapPage/RoadMapToogleList/CollapseRoadMap/ItemRoadMap/ContentModal/ContentBaiTapNop/ItemBaiTapNop";
import dataBaiTapNop from "./../../../../../../dataFake/dataFake.json";
import { colors } from "../../../../constant";
import { useParams } from "react-router-dom";

export default function BaiTapNop({ data, dataHash }) {
  const params = useParams();
  return (
    <div style={{ width: "800px" }} className=" space-y-5">
      {data.map((item, index) => {
        let color = colors[index % 2];
        return (
          <ItemBaiTapNop
            dataHash={dataHash}
            data={item}
            dataRoadMapItem={data}
            idKhoaHoc={params.id}
            color={color}
          />
        );
      })}
    </div>
  );
}
