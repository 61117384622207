import React, { useState } from 'react';
import { Radio } from 'antd';
import './RadioField.css';

export default function RadioField({ options, required, type, label, onChange, ...props }) {

    const [message, setMessage] = useState('');

    const onChangeHandle = (e) => {
        onChange(props.name, e.target.value, true);
    }

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };
    return (
        <div className="radio-field">
            <label className="label">
                {label}
                {required ? <span>*</span> : null}
            </label>
            <Radio.Group onChange={onChangeHandle} {...props} buttonStyle={{background: 'red'}}>
                {
                    options.map((item, index) => {
                        return <Radio style={radioStyle} value={item.value} key={index}>
                            { item.label }
                        </Radio>
                    })
                }
            </Radio.Group>
            {message.length > 0 ? <span className="error">{message}</span> : null}
        </div>
    )
}