import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import ButtonChangeVideo from "../ButtonChangeVideo";
import { useSelector } from "react-redux";

export default function MenuChildVideoXemTruoc({
  data,
  dataHash,
  maBuoiHoc,
  lopHocActive,
}) {
  const [collapsed, setCollapsed] = useState(false);
  // console.log(dataHash);
  // console.log(data);
  // const videoHash = new Map(Object.entries(dataHash.danhSachVideoXemTruoc));
  return (
    <div className="flex flex-col w-80 overflow-y-scroll">
      <div
        className="cursor-pointer"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <div className="flex items-center justify-between py-4 px-4 bg-blue-theme text-white text-sm border-b-1 border-white rounded">
          <p className="mb-0 flex-1">Danh Sách Video Xem Trước</p>
          <i
            style={{
              transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
            }}
            className="flex-shrink-0 fa fa-angle-down text-color-title  transition  duration-100 text-base  font-medium"
          ></i>
        </div>
      </div>
      <div>
        <AnimateHeight
          id="example-panel"
          duration={500}
          height={collapsed ? 0 : "auto"} // see props documentation below
        >
          {data.map((item, index) => {
            // console.log(item);
            return (
              <div key={index} title={item.tenChuong}>
                <ButtonChangeVideo
                  lopHocActive={lopHocActive}
                  checkHoanThanh={
                    dataHash == undefined
                      ? {}
                      : dataHash?.danhSachVideoXemTruoc[item.id]
                  }
                  dataHash={dataHash}
                  dataItem={item}
                  maBuoiHoc={maBuoiHoc}
                />
              </div>
            );
          })}
        </AnimateHeight>
      </div>
    </div>
  );
}
