import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import { LocalStorageUtil } from "../../../../utilities/localStorage";
import { FileSevice } from "../../../../service/fileService";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import { Mark_Video } from "../../../roadmapPage/RoadMapToogleList/CollapseRoadMap/ItemRoadMap/ContentModal/utils/watermark";
import HLSSource from "../VideoXemTruoc/HLSSource";

export default function VideoXemRecordMobile({
  maVideo,
  dsXemLai,
  maLop,
  maBuoiHoc,
}) {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  const [video, setVideo] = useState({
    source: "",
    vimeo: 0,
    youtube: "",
    drive: "",
  });

  useEffect(() => {
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    // console.log(dsXemLai);
    if (dsXemLai) {
      const noiDungVideo = dsXemLai?.videoXemLai?.find(
        (item) => item.id == maVideo
      );
      // console.log(noiDungVideo);
      !nguoiDung.nuocNgoai &&
        noiDungVideo?.noiDung &&
        FileSevice.layDuongDanRecordService(noiDungVideo?.noiDung)
          .then((res) => {
            setVideo({ source: res.data });
          })
          .catch((err) => console.log(err.response));
      // nước ngoài

      nguoiDung.nuocNgoai &&
        noiDungVideo?.noiDung &&
        FileSevice.layDuongDanRecordDigitalService(noiDungVideo?.noiDung)
          .then((res) => {
            setVideo({ source: res.data });
          })
          .catch((err) => console.log(err.response));
    }
    // mark video
    let myInterval = Mark_Video(nguoiDung);

    return () => {
      clearInterval(myInterval);
      // handleSubmitTrackingXemVideoXemLai();
    };
  }, [maVideo]);

  return (
    <div className="flex h-full w-full space-x-3 ">
      <div className="flex-grow h-full flex items-center water-mark-wrapper">
        {nguoiDung.nuocNgoai ? (
          video.source && (
            <Player
              playsInline
              fluid={false}
              width={"100%"}
              height={"100%"}
              autoPlay={false}
            >
              <HLSSource isVideoChild src={video.source} />
              <ControlBar>
                <PlaybackRateMenuButton
                  rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
                  order={10}
                />
              </ControlBar>
            </Player>
          )
        ) : // <Vimeo video={video.vimeo} width="100%" height="500" />
        video.source ? (
          <Player
            playsInline
            fluid={false}
            width={"100%"}
            height={"100%"}
            autoPlay={false}
          >
            <HLSSource isVideoChild src={video.source} />
            <ControlBar>
              <PlaybackRateMenuButton
                rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
                order={10}
              />
            </ControlBar>
          </Player>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
