import axios from "axios";
import { DOMAIN_API, API_URL_DOMAIN_ONLINE } from "../config/urlConfig";
const get = (url) => {
  return axios({
    baseURL: DOMAIN_API,
    url: url,
    method: "GET",
    headers: {
      // 'Authorization': '',
      // 'Content-Type': 'application/json'
    },
  });
};

const API_URL = "/api/file";

const layDuongDanVideoService = (fileName) => {
  return get(`${API_URL}/ftp-video/${fileName}`);
};
//lay duong dan ftp digital
const layDuongDanVideoDigitalService = (fileName) => {
  return get(`${API_URL}/ftp-video-digital/${fileName}`);
};

//duong dan ftp online
const layDuongDanVideoOnlineService = (fileName) => {
  return axios({
    baseURL: API_URL_DOMAIN_ONLINE,
    url: `/api/file/ftp-video/${fileName}`,
    method: "GET",
    headers: {},
  });
};

//lay video record
const layDuongDanRecordService = (fileName) => {
  return get(`${API_URL}/ftp-video-record/${fileName}`);
};
//lay duong dan ftp digital
const layDuongDanRecordDigitalService = (fileName) => {
  return get(`${API_URL}/ftp-video-record-digital/${fileName}`);
};

// lay danh sach cau hinh 
const layDanhSachCauHinhService = () => {
  return get(`/api/config`);
}

export const FileSevice = {
  layDuongDanVideoService,
  layDuongDanVideoOnlineService,
  layDuongDanVideoDigitalService,
  layDuongDanRecordService,
  layDuongDanRecordDigitalService,
  layDanhSachCauHinhService
};
