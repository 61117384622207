import { BaseService } from './baseService';

const API_URL = "/api/nopbaitap"; 

const layDanhSachBaiTapNopService = (classId) => {
    return BaseService.get(`${API_URL}/lay-bai-nop-theo-lop/${classId}`);
}

const updateBaiTapNopService = (id, model) => {
    return BaseService.put(`${API_URL}/${id}`,model);
}

const layDanhSachBaiTapService = (classId, userId) => {
    return BaseService.get(`/api/baitap/${classId}/${userId}`);
}

const nopBaiTapService = (model) => {
    return BaseService.post(API_URL, model);
}

const capNhatBaiTapNopService = (id, model) => {
    return BaseService.put(`${API_URL}/${id}`, model);
}
const layDanhSachLopBaiTapService = (id) => {
    return BaseService.get(`/api/lophoc_tailieu/lay-theo-lop/${id}`);
}

const layQuizAwsService = () => {
    return BaseService.get(`/api/config/get-quiz-aws`);
}
const layQuizService = (id) => {
    return BaseService.get(`/api/boDe/lopHoc?maNhom=${id}`);
}
const layLayoutService = (id) => {
    return BaseService.get(`/api/nhomBoDe/${id}`);
}

const layQuizTestingService = () => {
    return BaseService.get(`/api/config/get-quiz-testing`);
}

export const BaiTapSevice = {
    layQuizTestingService,
    layQuizAwsService,
    layDanhSachBaiTapService,
    nopBaiTapService,
    capNhatBaiTapNopService,
    layDanhSachLopBaiTapService,
    layDanhSachBaiTapNopService,
    updateBaiTapNopService,
    layQuizService,
    layLayoutService
}