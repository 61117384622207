import React from "react";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import HtmlParser from "react-html-parser";
const { Panel } = Collapse;

function CollapseGhiChu({ data }) {
  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <CaretRightOutlined className="text-white" rotate={isActive ? 90 : 0} />
      )}
      className="bg-gray-500 w-full text-white  "
    >
      <Panel
        header={
          <span className=" font-medium  rounded-md  text-white  ">
            Xem ghi chú          </span>
        }
        key="1"
        className="bg-transparent"
      >
        <div className=" flex flex-col  w-full">
          <div className="text-color-blue-white">{HtmlParser(data)}</div>
        </div>
      </Panel>
    </Collapse>
  );
}
export default CollapseGhiChu = React.memo(CollapseGhiChu);
