import axios from "axios";
import { DOMAIN_API } from "../config/urlConfig";
import history from "../utilities/history";

var token = "";

const setToken = () => {
  if (localStorage.getItem("LOGIN_LOCASTORE")) {
    let user = JSON.parse(localStorage.getItem("LOGIN_LOCASTORE"));

    token = user.token;
  }
};

export const get = (url, ...params) => {
  setToken();

  return axios({
    baseURL: DOMAIN_API,
    url: url,
    params: params,
    method: "GET",
    responseType: "json",
    responseEncoding: "utf8",
    headers: {
      apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  }).catch((err) => err.response.status == 401 && history.push("/login"));
};

export const post = (url, data) => {
  setToken();
  return axios({
    baseURL: DOMAIN_API,
    // baseURL: "https://apicrm.cybersoft.edu.vn",

    url: url,
    method: "POST",
    responseType: "json",
    responseEncoding: "utf8",
    headers: {
      apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    // timeout: 1000,
    data: JSON.stringify(data),
  }).catch((err) => err.response.status == 401 && history.push("/login"));
};

export const put = (url, data) => {
  setToken();
  return axios({
    baseURL: DOMAIN_API,
    url: url,
    method: "PUT",
    responseType: "json",
    responseEncoding: "utf8",
    headers: {
      apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    // timeout: 1000,
    data: JSON.stringify(data),
  }).catch((err) => err.response.status == 401 && history.push("/login"));
};

export const remove = (url, ids) => {
  setToken();
  return axios({
    baseURL: "https://apicrm.cybersoft.edu",
    url: url,
    method: "DELETE",
    responseType: "json",
    responseEncoding: "utf8",
    headers: {
      apikey: "UPDy2j7GPQJFweUaCYSECETBERS",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(ids),
    // timeout: 1000
  }).catch((err) => err.response.status == 401 && history.push("/login"));
};

export const baseServiceNew = {
  get,
  post,
  put,
  remove,
};
