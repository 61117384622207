import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import { Collapse } from "antd";

const entities = require("entities");
const { Panel } = Collapse;

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};


const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export default function Practices_ChooseAnswer({
  question,
  handle_CheckSingleChoice,
}) {
  const { quizId } = useParams()
  let [quizDetail, setQuizDetail] = useState(null)

  let cauhoi = question;

  useEffect(() => {

    let checkQuizAWS = localStorage.getItem("quiz")
    if (checkQuizAWS) checkQuizAWS = JSON.parse(checkQuizAWS)

    let quiz = checkQuizAWS.find(n => n.quizId == quizId)
    let dataTemp = [...quiz.userChoice]

    if (quiz) {
      let cauHoi = dataTemp.find(n => n.id == question.id)
      if (cauHoi) {
        setActiveBtnArr(cauHoi.choice);
      }

      setQuizDetail(quiz)

    }

  }, [question?.id]);

  const [activeBtnArr, setActiveBtnArr] = useState([]);

  const handlerActiveBtn = (idBtn) => {
    let index = activeBtnArr.findIndex((index) => {
      return index === idBtn;
    });
    if (index !== -1) {
      activeBtnArr.splice(index, 1);
    } else {
      activeBtnArr.push(idBtn);
    }
  };

  useEffect(() => {

    handle_CheckSingleChoice(question.id, activeBtnArr);

  }, [activeBtnArr]);

  const nextBtnCss =
    activeBtnArr.length == 0
      ? " text-gray-600 bg-gray-400 cursor-not-allowed"
      : "  btn-theme ";


  return (
    <div
      onCopy={(e) => {
        e.preventDefault();
        return false;
      }}
      className="w-full flex-grow flex flex-col  "
    >
      <div className="relative w-full h-full p-5 flex-grow justify-center flex-col space-y-5 ">
        <div className="w-full mx-auto  flex flex-col space-y-5 ">
          <div className="text-lg text-dark font-medium m-0" dangerouslySetInnerHTML={{ __html: question?.tieuDe
              .replaceAll("&bsol;&quot;", "'") }}>
          </div>
          <div className="w-full rounded-xl  overflow-hidden">
            <SyntaxHighlighter
              lineProps={{ style: { wordBreak: 'normal', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' } }}
              wrapLines={true}
              className="p-10"
              language="javascript"
              style={dracula}


            >
              {entities.decodeHTML(question?.inPut
              .replaceAll("&bsol;&quot;", "'"))}
            </SyntaxHighlighter>
          </div>
          <p className="text-lg text-color-title 	">
            <pre>{ReactHtmlParser(cauhoi?.outPut
              .replaceAll("&bsol;&quot;", "'"))}</pre>
          </p>
          <div className="w-full h-max-content grid auto-rows-auto grid-auto-rows gap-5 grid-cols-2">
            {cauhoi?.cauTraLoi.map((item, index) => {
              let indexBtn = activeBtnArr.findIndex((id) => {
                return id === item.ma;
              });
              let activeCss = "";
              if (indexBtn !== -1) {
                activeCss =
                  "bg-blue-600 border-blue-600 border-white text-white";
              } else {
                activeCss = " ";
              }

              let rightCss = ""
              let wrongCss = ""
              if (quizDetail && quizDetail.trangThai == 2) {

                let { userChoice } = quizDetail

                if (cauhoi?.dapAn?.find(n => n == item.ma)) {
                  rightCss = " bg-green-400 border-green-500 "

                } else {
                  let userAnwser = userChoice.find(n => n.id == cauhoi.id);

                  if (userAnwser && userAnwser.choice.find(n => n == item.ma)) {

                    wrongCss = " bg-red-400 border-red-500 "
                  }
                }
              }


              return (
                <div key={item.ma}
                  className={
                    `w-full cursor-pointer  flex  shadow-lg  items-center space-x-5  rounded-lg transition duration-200 card_theme_item text-color-title p-5  py-2  hover:shadow-xl ${rightCss} ${wrongCss} `
                  }
                  onClick={() => {
                    if (quizDetail && quizDetail.trangThai != 0) {
                      return
                    }

                    let index = activeBtnArr.findIndex((id) => {
                      return id === item.ma;
                    });

                    if (index !== -1) {

                      let temp = [...activeBtnArr];
                      temp.splice(index, 1);

                      setActiveBtnArr(temp);

                    } else {

                      setActiveBtnArr([item.ma]);
                    }

                  }}
                >
                  <span
                    className={
                      "h-9 border-2 flex-shrink-0  border-dark w-9 text-center font-bold leading-8  rounded-full " +
                      activeCss
                    }
                  >
                    {alphabet[index]}
                  </span>
                  <p className="text-sm text-color-title m-0" dangerouslySetInnerHTML={{__html:item.noiDung.replaceAll("&bsol;&quot;", "'")}}></p>
                </div>
              );
            })}
          </div>
        </div>

        {quizDetail?.trangThai == 2 && <div className="bg-gray-100 p-5">
          <label className="bg-gray-100 h5 font-bold">
            Giải thích câu hỏi
          </label>
          {ReactHtmlParser(question?.giaiThich?question?.giaiThich
              .replaceAll("&bsol;&quot;", "'"):"")}

        </div>}
        
      </div>
    </div >
  );
}
