import {
  CHECK_VIDEO_XEMTRUOC,
  GET_DATA_RUNCODE,
  RUNCODE_SUBMIT,
  SET_CHUCMUNG_XONGTASK,
  SET_DATA_CHUNGNHAN,
  SET_DATA_ROADMAP,
  SET_SOURCE_VIDEO,
  SET_TASK_ROADMAP,
} from "../types";

let initialState = {
  noiDungVideo:null,
  sourceVideo: "",
  baiHoc: "",
  dataRoadMap: [],
  dataTaskRoadMap: null,
  itemVideoXemTruoc: {},
  chucMungXongTask: false,
  titleChucMungXongTask: "",
  dataRuncode: [],
  testCaseRuncode: [],
};

export const roadmapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NOIDUNG_VIDEO": {
      state.noiDungVideo = action.payload;
      return { ...state };
    }
    case SET_SOURCE_VIDEO: {
      state.sourceVideo = action.payload;
      return { ...state };
    }
    case SET_DATA_ROADMAP: {
      let jsondata = JSON.stringify(action.payload);
      state.dataRoadMap = JSON.parse(jsondata);
      return { ...state };
    }
    case SET_TASK_ROADMAP: {
      // console.log(action.payload);
      // let jsondata = JSON.stringify(action.payload);

      const hashMapData = new Map(Object.entries(action.payload));
      // console.log(hashMapData);
      state.dataTaskRoadMap = hashMapData;
      return { ...state };
    }
    case CHECK_VIDEO_XEMTRUOC: {
      // console.log(action);
      state.itemVideoXemTruoc = action.payload;
      return { ...state };
    }
    case SET_CHUCMUNG_XONGTASK: {
      state.chucMungXongTask = action.payload;
      state.titleChucMungXongTask = action.titleChucMungXongTask;
      return { ...state };
    }
    case GET_DATA_RUNCODE: {
      let jsondata = JSON.stringify(action.payload);
      let layBuoiRunCode = JSON.parse(jsondata).danhSachBuoiHocTheoSkill[
        action.chuongHoc
      ].danhSachBuoiHoc.find((item) => item.id === Number(action.maBuoiHoc));
      // console.log(layBuoiRunCode);
      state.dataRuncode = layBuoiRunCode.runCode;
      // state.dataRuncode = [240, 244, 249, 262];
      return { ...state };
    }
    case RUNCODE_SUBMIT: {
      // console.log(action.payload);
      // console.log(action.payload);
      state.testCaseRuncode = action.payload;
      return { ...state };
    }

    default:
      return { ...state };
  }
};
