import React, { useState } from 'react';
import './TextField.css';

export default function TextField({ required, min, max, compare, pattern, type, label, onChange, ...props }) {

    const { name } = props;
    const [message, setMessage] = useState('');

    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regNumber = new RegExp("^[0-9]*$");
    const regPhone = new RegExp("^[0]+[0-9]{9,10}$");
    const regSalary = new RegExp("^[0-9]{7,9}$");
    const regYear = new RegExp("^[0-9]{4}$");
    const regPattern = pattern ? new RegExp(pattern) : new RegExp("");

    const onChangeHandle = (event) => {
        const value = event.target.value;
        let isValid = validation(value);
        onChange(name, value, isValid)
    }

    const validation = (value) => {
        let isValid = false;
        let errorMessage = '';

        if (required && value.length === 0) {
            errorMessage = `${label} không được bỏ trống!`;
            isValid = false;
        }
        else if (type === 'email' && !regEmail.test(value)) {
            errorMessage = `Email không đúng định dạng!`;
            isValid = false;
        }
        else if (type === 'number' && !regNumber.test(value)) {
            errorMessage = `${label} phải là ký tự số!`;
            isValid = false;
        }
        else if (type === 'birthday' && !regYear.test(value)) {
            errorMessage = `${label} phải là ký tự số và có 4 ký tự!`;
            isValid = false;
        }
        else if (type === 'phone' && !regPhone.test(value)) {
            errorMessage = `${label} không đúng định dạng!`;
            isValid = false;
        }
        else if (type === 'salary' && !regSalary.test(value)) {
            errorMessage = `${label} không đúng định dạng (Chỉ là số vd: 1000000)!`;
            isValid = false;
        }
        else if (min && value.length < min) {
            errorMessage = `${label} ít nhất ${min} ký tự!`;
            isValid = false;
        }
        else if (max && value.length > max) {
            errorMessage = `${label} tối đa ${max} ký tự!`;
            isValid = false;
        }
        else if (pattern && !regPattern.test(value)) {
            errorMessage = `${label} không đúng định dạng!`;
            isValid = false;
        }
        else if (compare && compare !== value) {
            errorMessage = `${label} không khớp!`;
            isValid = false;
        }
        else {
            errorMessage = ``;
            isValid = true;
        }
        setMessage(errorMessage);
        return isValid;
    }

    return (
        <div >
             <label className="label">
                { label } 
                { required ?  <span className='text-danger'>*</span> : null }
            </label>
            <input class="form-control"  type="text" {...props} onChange={onChangeHandle} required />
           
            <div className="bar"></div>
            { message.length > 0 ? <span className="error text-danger">{message}</span> : null }
        </div>
    )
}
