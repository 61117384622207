import React from "react";
import { useSelector } from "react-redux";
import { LOAI_UPDATE, TRANG_THAI_CV } from "../Constant";
import './ThongBaoQuanLyCv.scss'
import { Collapse, Tag } from "antd";
import moment from 'moment';
import ReactHtmlParser from "react-html-parser";
import classNames from "classnames";
const { Panel } = Collapse;



export default function ThongBaoQuanLyCv(props) {

  const DATE_FORMAT = "DD/MM/YYYY HH:mm";

  const lichSuUpdate = useSelector(
    (state) => state.nguoiDungReducer.lichSuUpdate
  );

  function DanhSachLichSuUpdateV2() {
    return lichSuUpdate.map((item, index) => {

      // if (item.LoaiLog === "TU_CHOI_CV") {
      //   return (
      //     <Collapse
      //     bordered={false}
      //     ghost
      //     style={{width:"100%"}}
      //   >
      //     <Panel
      //       key={`lichSuUpdate_${index}`}
      //       className="rounded-xl overflow-hidden"
      //       header={
      //         <div className={classNames({
      //           "text-right": true
      //         })}>
      //         <p> 
      //         <Tag color={LOAI_UPDATE[item.LoaiLog].TagColor}>{LOAI_UPDATE[item.LoaiLog].NoiDung}</Tag>
      //          - {moment(item.NgayTao).format(DATE_FORMAT)} </p>
      //       </div>
      //       }
      //     >
      //       <div className="space-y-2">
      //         <div className="text-right text-green-500">
      //           {ReactHtmlParser(item.NoiDung)}
      //         </div>
      //       </div>
      //     </Panel>
      //   </Collapse>
      //   )
      // }

      if (item.LoaiLog === "TU_CHOI_CV") {
        return (<div className={classNames({
                "text-right": true
              })}>
              <p> 
              <Tag color={LOAI_UPDATE[item.LoaiLog].TagColor}>{LOAI_UPDATE[item.LoaiLog].NoiDung}</Tag>
               - {moment(item.NgayTao).format(DATE_FORMAT)} </p>
               <div className="space-y-2">
              <div className="text-right text-green-500">
                {ReactHtmlParser(item.NoiDung)}
              </div>
            </div>
            </div>
            
        )
      }

      return (
        <div className={classNames({
          "text-left": item.LoaiLog === "NOP_CV_REVIEW",
          "text-right": item.LoaiLog === "DUYET_CV"
        })}>
        <p> 
        <Tag color={LOAI_UPDATE[item.LoaiLog].TagColor}>{LOAI_UPDATE[item.LoaiLog].NoiDung}</Tag>
         - {moment(item.NgayTao).format(DATE_FORMAT)} </p>
      </div>
     

      )
    
   
  })
}

  function renderDanhSachLichSuUpdate() {
    return lichSuUpdate.map((item, index) => {
          return (
            <div className="row" key={`log_update_cv_${index}`}>
              <div className="col-10">
                <span>Loại Log: {item.LoaiLog}</span>
                <span>{item.NoiDung}</span>
              </div>
              <div className="col-2">
                <span>{item.NgayTao}</span>
              </div>
              </div>
          );
        })
  }

  function UpdateCuaHocVien() {

  }

  function UpdateCuaHeThong() {

  }



  return (
    <div className="card_theme text-center quanLyCV__lichSuUpdate">
       
      {lichSuUpdate === null && <p>Chưa có cập nhật nào</p>}
      {lichSuUpdate && DanhSachLichSuUpdateV2()}
    </div>
  );
}
