import React from "react";
import TaiLieuTheoSkill from "./TaiLieuTheoSkill";
import { Tabs } from "antd";
import ListDiem from "../../roadmapDiemChungNhanPage/ListDiem";
import ThongTinRecord from "../../roadmapDiemChungNhanPage/ThongTinRecord/ThongTinRecord";
const { TabPane } = Tabs;
export default function ListTaiLieu({
  dataRoadMap,
  dataVideoRecord,
  setTitleModal,
  setContentModal,
  setIsModalVisible,
}) {
  // console.log(dataRoadMap);
  return (
    <div className=" w-full  space-y-5">
      <div className="flex-grow space-y-3 card_theme p-3 border-none">
        <Tabs defaultActiveKey="1" className="w-full felx flex-col flex-grow ">
          <TabPane
            tab={<p className="font-medium text-dark px-1 lg:px-3">Tài Liệu</p>}
            key="1"
            className="w-full h-full "
          >
            {/* <ListDiem dataRoadMap={dataRoadMap.danhSachDiemBaiTapTheoSkill} /> */}
            {dataRoadMap?.map((item, index) => {
              return <TaiLieuTheoSkill key={index} data={item} />;
            })}
          </TabPane>

          <TabPane
            tab={
              <p className="font-medium px-1 lg:px-3 text-dark">
                Thông tin record
              </p>
            }
            key="2"
            className="h-full "
          >
            <ThongTinRecord
              setTitleModal={setTitleModal}
              setIsModalVisible={setIsModalVisible}
              setContentModal={setContentModal}
              danhSachTatCaVideoXemLai={dataVideoRecord}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
