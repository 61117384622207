import React, { useEffect, useState } from "react";
import dataTaiLieuDocThem from "../../../../../../dataFake/dataFake.json";
import { colors } from "../../../../constant";
import { DOMAIN_API_FILE } from "../../../../../../config/urlConfig";
import { RoadmapService } from "../../../../../../service/roadmapService";
import { useParams } from "react-router-dom";
import {
  LOAI_TASK_BAITAP_EXTRA,
  LOAI_TASK_TAILIEU_EXTRA,
} from "../../../../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { getTaskRoadMapServAction } from "../../../../../../redux/actions/roadMapAction";

export default function BaiTapNopExtra({
  data,
  dataHash,
  lopHocActive,
  idBuoiHoc,
}) {
  // console.log(dataTaiLieuDocThem.dataTaiLieuDocThem);
  // console.log(data);
  const dispatch = useDispatch();
  const { id } = useParams();
  let { dataTaskRoadMap } = useSelector((state) => state.roadmapReducer);

  const [camCo, setCamCo] = useState(false);
  const countTaskTrue = (dataTask, dataRoadMapItem) => {
    let count = 0;
    for (let key in dataRoadMapItem) {
      if (dataTask[dataRoadMapItem[key]?.id].isHoanThanh === true) {
        count++;
      }
    }
    if (count === dataRoadMapItem.length && count !== 0) {
      dispatch({
        type: "SET_CHUCMUNG_XONGTASK",
        payload: true,
        titleChucMungXongTask: " Chúc mừng bạn đã hoàn thành bài tập",
      });
    }
  };

  useEffect(() => {
    if (data && camCo && dataTaskRoadMap.size !== 0) {
      countTaskTrue(
        dataTaskRoadMap.get(idBuoiHoc.toString()).danhSachBaiTapExtra,
        data
      );
    }
  }, [camCo, dataTaskRoadMap]);

  // console.log(dataTaskRoadMap.get("2594"));
  const param = useParams();
  function handleSubmitTrackingXemTaiLieuExtra(item) {
    // let dataSubmit = {
    //   maBuoi: item.maBuoiHoc,
    //   maBaiHoc: item.id,
    //   maLop: +param.id,
    // };
    //Xử lí check hoàn thành khi bấm vô tải tài liệu xem trước về
    let submitHoanThanh = {
      maBuoiHoc: item.maBuoiHoc,
      maBaiHoc: item.id,
      maLop: +param.id,
      loaiTask: LOAI_TASK_BAITAP_EXTRA,
    };
    RoadmapService.postCheckHoanThanhTaskRoadMap(submitHoanThanh)
      .then(() => {
        dispatch(getTaskRoadMapServAction(id));
        setCamCo(!camCo);
      })
      .catch((err) => {
        // message.error("đã có lỗi xảy ra");
        console.log(err);
      });
    // RoadmapService.postTrackingXemTaiLieuExtra(dataSubmit);
  }

  const renderCheckTask = (item) => {
    if (lopHocActive && dataHash !== undefined) {
      if (
        dataTaskRoadMap?.get(item.maBuoiHoc.toString()).danhSachBaiTapExtra[
          item.id
        ]?.isHoanThanh
      ) {
        return (
          <i className="fa-solid ml-auto fa-check p1 w-5 flex items-center justify-center h-5 text-xs rounded-full bg-green-600 text-white"></i>
        );
      } else {
        return (
          <i className="fa-solid ml-auto fa-exclamation p1 w-5 flex items-center justify-center h-5 text-xs rounded-full bg-red-600 text-white"></i>
        );
      }
    } else {
      return "";
    }
  };

  return (
    <div style={{ width: "600px" }} className=" space-y-5">
      {data.map((item, index) => {
        let color = colors[index % 2];
        return (
          <div>
            <div
              style={{ backgroundColor: color.bg }}
              className="w-full  p-2 px-3  min-h-22 justify-between flex items-center relative rounded-lg shadow-lg"
            >
              <div className="w-max pl-6 h-full flex flex-col  relative ">
                <p className="text-gray-900">{item.tieuDe}</p>
                <button
                  className=" flex items-center h-max-content rounded p-1 border-gray-600 w-max flex-shrink-0 border-1 text-color-content cursor-pointer hover:text-gray-900  px-2 transform duration-300 hover:border-gray-500
            "
                >
                  <a
                    className="flex justify-center items-center space-x-1 text-color-content"
                    href={
                      item.noiDung?.slice(0, 5) == "https"
                        ? item.noiDung
                        : `${DOMAIN_API_FILE}/${item?.noiDung}`
                    }
                    target="_blank"
                    onClick={() => {
                      handleSubmitTrackingXemTaiLieuExtra(item);
                    }}
                  >
                    <i className="fa fa-folder-open"></i>
                    <span>Tài nguyên</span>
                    <i className="fa fa-download"></i>
                  </a>
                </button>{" "}
                <div
                  style={{ backgroundColor: color.line }}
                  className="h-4/5 w-1 left-2 top-1/2 transform absolute rounded-lg -translate-y-1/2"
                ></div>
              </div>
              <div className="w-max  h-full flex space-x-5 items-center justify-center  pr-2"></div>
              <div className="checkDone w-1/5">{renderCheckTask(item)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
