import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTaskRoadMapServAction } from "../../../../../redux/actions/roadMapAction";

export default function Center({ data }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  let { dataTaskRoadMap } = useSelector((state) => state.roadmapReducer);

  useEffect(() => {
    dispatch(getTaskRoadMapServAction(id));
  }, [id]);
  // console.log();
  let renderLine = (value = 5) => {
    let lines = [];
    for (let index = 0; index < value; index++) {
      let line = <div className="w-4 h-3 bg-blue-theme bg-opacity-60 "></div>;
      lines.push(line);
    }
    return lines;
  };
  return (
    <div className=" w-22 h-22 flex justify-center items-center relative z-10">
      <div className="w-10 h-10  relative ">
        {/* <div className=" h-full w-full rounded-full card_theme flex items-center"></div> */}
        <div
          style={{
            backgroundImage:
              "linear-gradient(to left, rgb(34, 125, 249) 0%, rgb(116, 98, 249) 25%, rgb(223, 62, 248) 50%, rgb(116, 98, 249) 75%, rgb(34, 125, 249) 100%)",
          }}
          className=" h-full w-full rounded-full flex items-center justify-center"
        >
          <div className=" h-11/12 w-11/12 rounded-full bg-blue-theme flex items-center "></div>
        </div>
        {/* line up */}

        <div
          style={{ width: "1px" }}
          className="  bg-opacity-80 left-1/2 -translate-x-1/2 -translate-y-16 -top-1 absolute  transform flex overflow-hidden flex-wrap justify-between h-14 flex-col "
        >
          {renderLine(3)}
        </div>
        {/* <div
          style={{ width: "1px" }}
          className="  bg-opacity-80 left-1/2 -translate-x-1/2 -translate-y-16 -top-1 absolute  transform flex overflow-hidden flex-wrap justify-between h-14 flex-col "
        >
          {renderLine(5)}
        </div> */}

        {/* line left */}
        <div
          style={{ height: "1px" }}
          className="w-32   bg-opacity-80 top-1/2 translate-y-1/2 absolute -left-4 transform -translate-x-full   justify-between flex overflow-hidden flex-wrap"
        >
          {renderLine()}
        </div>
      </div>
    </div>
  );
}
