import React from "react";
import Item_ListDiem_LoTrinh from "../Item_ListDiem_LoTrinh.js/Item_ListDiem_LoTrinh";
import "./chungNhan.css";
export default function ListDiem({ dataRoadMap }) {
  return (
    <div className=" w-full  space-y-5 chung-nhan">
      {dataRoadMap?.map((item, index) => {
        return <Item_ListDiem_LoTrinh data={item} index={index} />;
      })}
    </div>
  );
}
