import { BaseService } from './baseService';

const API_URL = "/api/khoahoc"; 

const layChiTietKhoaHocService = (courseId) => {
    return BaseService.get(`${API_URL}/${courseId}`);
}

const layDanhSachKhoaHocFreeService = () => {
    return BaseService.get(`${API_URL}/danh-sach-khoa-hoc-free`);
}

const layChiTietKhoaHocFreeService = (courseId) => {
    return BaseService.get(`${API_URL}/free/${courseId}`);
}


export const KhoaHocService = {
    layChiTietKhoaHocService,
    layDanhSachKhoaHocFreeService,
    layChiTietKhoaHocFreeService
}