import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RoadmapService } from "../../service/roadmapService";

export default function PopupKhoKhanHocVien({ khoKhanHocVien }) {
  // console.log("khoKhanHocVien: ", khoKhanHocVien);
  useEffect(() => {
    setContent(khoKhanHocVien?.noiDungTraLoi);
  }, [khoKhanHocVien?.noiDungTraLoi]);

  let params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [content, setContent] = useState("");

  let handleChangeTextarea = (e) => {
    setContent(e.target.value);
  };
  let handleSubmit = () => {
    if (content.trim().length < 3) {
      message.error("Câu trả lời không hợp lệ");
      return;
    }
    let data = {
      id: khoKhanHocVien.id,
      maLop: params.id * 1,
      noiDungKhoKhan: content,
      soThuTuKhoKhan: khoKhanHocVien.thuTuCauHoi,
    };
    RoadmapService.postKhoKhanHocVien(params.id, data)
      .then((res) => {
        // console.log("yes");
        message.success("Câu trả lời của bạn đã được hệ thống ghi nhận");
        // setTimeout(() => {
        setIsOpen(false);
        setIsFinish(true);
        // }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //  khoKhanHocVien?.isKhoKhan
  // return true && !isFinish ? (
  return khoKhanHocVien?.isKhoKhan ? (
    <>
      <div className="bg-white h-max-content w-max fixed bottom-5 right-5 shadow border-red-600 z-30 rounded-md">
        {isOpen ? (
          <div className="p-3">
            <label
              for="message"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Hãy cho chúng tôi biết khó khăn trong học tập của bạn nhé
            </label>
            <div className="space-y-5">
              <p>
                Khảo sát sẽ kết thúc vào ngày{" "}
                <span className="text-black">
                  {moment(khoKhanHocVien?.ngayHetHan).format("DD/MM/YYYY")}
                </span>
              </p>
              <textarea
                value={content}
                onChange={handleChangeTextarea}
                id="message"
                rows={6}
                className=" resize-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Khó khăn của bạn..."
              />
              <button
                onClick={handleSubmit}
                className={`inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white ${
                  content?.trim().length
                    ? "bg-blue-700 cursor-pointer "
                    : "bg-gray-400 cursor-not-allowed"
                }  rounded-lg  `}
              >
                Gửi
                <i className="fa fa-paper-plane ml-2" />
              </button>
            </div>
          </div>
        ) : (
          <p
            onClick={() => {
              setIsOpen(true);
            }}
            className="p-3 m-0 text-blue-theme cursor-pointer "
          >
            Bạn đang gặp khó khăn trong học tập ?
          </p>
        )}
      </div>
    </>
  ) : (
    <></>
  );
}
