import React, { useState, useEffect } from "react";
import Practices_ChooseAnswer from "../Pratices/Practices_ChooseAnser";
import Practices_ChooseAnswerToFill from "../Pratices/Praticees_ChooseAnserToFill";
import Practices_HTML_CSS from "../Pratices/Practices_HTML_CSS";
import Practices_FillCodeToInput from "../Pratices/Practices_FillCodeToInput";
import Practices_MultipleChoice from "../Pratices/Practices_MultipleChoice";
import _ from "lodash";
import Navigate_Footer_Pratices from "./Navigate_Footer_Pratices";
import "./Praticess.css";
import ConTentXemDapAn from "./XemDapAn/ConTentXemDapAn/ConTentXemDapAn";
export default function TracNghiem_Start({ data, forceRerender, indexChuongHoc }) {
  let [currentQuestionIndex, setCurrentQuestsionIndex] = useState(0);
  let [isShowResult, setIsShowResult] = useState(false);
  const [allQuestions, setListQuestion] = useState([]);

  useEffect(() => {
    // console.log("JSON.parse(data.noiDung)", data);
    let listQuestionRaw = JSON.parse(data.noiDung).map((item, index) => {
      // console.log("item off", item);
      return {
        id: item.id,
        noiDung: item,
        isCorrect: false,
        userAnsers: [],
        maLoaiBaiTap: item.maLoaiBaiTap,
      };
    });
    listQuestionRaw = listQuestionRaw || [];
    setListQuestion(listQuestionRaw);
  }, []);
  let handleClickNextQuestion = () => {
    setCurrentQuestsionIndex(currentQuestionIndex + 1);
  };

  let handle_CheckSingleChoice = (id, value, userAnsers) => {
    
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };

    newCurrentQuestion.userAnsers = userAnsers[0]?.cauTraLoi;
    let newAllQuestion = [...allQuestions];

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckMultipleChoice = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    let arrDapAn = [...newCurrentQuestion.noiDung.dapAn];
    if (arrDapAn.sort().toString() == userAnsers.sort().toString()) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];
    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFillInput = (id, userAnsers = []) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };

    let arrDapAn = [...newCurrentQuestion.noiDung.dapAn];
    if (arrDapAn.sort().toString() == userAnsers.sort().toString()) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }

    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;
    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank_CSS = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    newCurrentQuestion.userAnsers = userAnsers;
    let newAllQuestion = [...allQuestions];
    newAllQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let arrRenderQuestion = [];
  arrRenderQuestion = allQuestions.map((question, index) => {
    // console.log(
    //   "🚀 ~ file: TracNghiem_Start.js ~ line 138 ~ arrRenderQuestion=allQuestions.map ~ question",
    //   question.noiDung.inPut
    // );

    let keyIndex = index + data?.id;
    switch (question?.noiDung.maLoaiBaiTap) {
      case "SINGLE":
        return (
          <Practices_ChooseAnswer
            key={keyIndex}
            question={question}
            handle_CheckSingleChoice={handle_CheckSingleChoice}
          />
        );
      case "multiple_choice":
        return (
          <Practices_MultipleChoice
            key={keyIndex}
            handle_CheckMultipleChoice={handle_CheckMultipleChoice}
            question={question}
          />
        );
      case "fill_inblank_css":
        return (
          <Practices_HTML_CSS
            key={keyIndex}
            handle_CheckFinll_IN_Blank_CSS={handle_CheckFinll_IN_Blank_CSS}
            question={question}
          />
        );
      case "fill_inblank":
        return (
          <Practices_ChooseAnswerToFill
            key={keyIndex}
            handle_CheckFinll_IN_Blank={handle_CheckFinll_IN_Blank}
            question={question}
          />
        );
      case "fill_input":
        return (
          <Practices_FillCodeToInput
            key={keyIndex}
            question={allQuestions[currentQuestionIndex]}
            handle_CheckFillInput={handle_CheckFillInput}
          />
        );

      default:
        break;
    }
  });
  let isDisableNextBtn;

  let typeQuestion = allQuestions[currentQuestionIndex]?.noiDung.maLoaiBaiTap;
  if (typeQuestion === "multiple_choice" || typeQuestion === "fill_input") {
    isDisableNextBtn =
      allQuestions[currentQuestionIndex]?.userAnsers?.length === 0;
  }
  if (typeQuestion === "SINGLE") {
    isDisableNextBtn =
      allQuestions[currentQuestionIndex]?.userAnsers == undefined ||  allQuestions[currentQuestionIndex]?.userAnsers === null;
  }
  if (typeQuestion === "fill_inblank_css" || typeQuestion === "fill_inblank") {
    // console.log(allQuestions)
    isDisableNextBtn = !allQuestions[currentQuestionIndex].userAnsers[0];
  }

  return (
    <div className="w-full  flex-grow h-full flex flex-col ">
      {isShowResult ? (
        <ConTentXemDapAn data={data} indexChuongHoc={indexChuongHoc}/>
      ) : (
        <>
          <div className="w-full h-full  flex-grow flex flex-col  p-3 relative overflow-y-scroll">
            <div className="w-full  ">
              {arrRenderQuestion[currentQuestionIndex]}
            </div>
            <div className="h-22 w-full"></div>
          </div>
          <Navigate_Footer_Pratices
            forceRerender={forceRerender}
            current={currentQuestionIndex + 1}
            total={allQuestions.length}
            handleClickNextQuestion={handleClickNextQuestion}
            isDisableBtn={isDisableNextBtn}
            listQuestion={allQuestions}
            dataKhoaHoc={data}
            setIsShowResult={setIsShowResult}
          />{" "}
        </>
      )}
    </div>
  );
}
