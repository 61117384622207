import React, {useEffect,useState} from "react";
import Navbar from "../../template/navbar/Navbar";
import SidebarNew from "../../template/SidebarNew/sidebarNew";
import backGrountTemplate from "../../assets/images/background.png";
import { LocalStorageUtil } from "../../utilities/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, message, Skeleton } from "antd";
import history from "../../utilities/history";
import * as types from "../../redux/types";
import Button from "./Component/Button";
import { NavLink } from "react-router-dom";
import "./QuanLyCv.scss";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { TRANG_THAI_CV, LOAI_UPDATE } from "./Constant";
import ThongBaoQuanLyCv from "./Component/ThongBaoQuanLyCv";
import * as nguoiDungActions from "../../redux/actions/nguoiDungAction";
import Flash_sale from "./Component/Flash_Sale/Flash_sale";

export default function QuanLyCv() {
  const dispatch = useDispatch();
  const thongTinTaoCv = useSelector(
    (state) => state.nguoiDungReducer.thongTinTaoCV
  );
  
  const dataCvDaNop = useSelector(
    (state) => state.nguoiDungReducer.dataCvDaNop
  );
  const lichSuUpdate = useSelector(
    (state) => state.nguoiDungReducer.lichSuUpdate
  );
  const trangThaiCv = useSelector(
    (state) => state.nguoiDungReducer.trangThaiCv
  );
  const tempalteCv = useSelector((state) => state.nguoiDungReducer.tempalteCv);

  const thongTinLocalCV = useSelector(
    (state) => state.nguoiDungReducer.thongTinLocalCV
  );

  const dataCvDaDuyet = useSelector(
    (state) => state.nguoiDungReducer.dataCvDaDuyet
  );
  const soLuotXemPublicCvTrongNgay = useSelector(
    (state) => state.nguoiDungReducer.soLuotXemPublicCvTrongNgay
  );

  useEffect(() => {
    let isMount = true
  
    if (isMount) {
      dispatch(nguoiDungActions.layUserCV_V2())
    }

    
  }, [])
  
  
  const [isClose, setIsClose] = useState(false)


  



  const DATE_FORMAT = "DD/MM/YYYY";

  // console.log({
  //   thongTinTaoCv,
  //   dataCvDaNop,
  //   lichSuUpdate,
  //   trangThaiCv,
  //   tempalteCv,
  //   thongTinLocalCV,
  // });

  // const hasDuAn = thongTinTaoCv?.danhSachDuAn?.length > 0;
  const hasDuAn = true;
  const isChuaNopCv = lichSuUpdate === null;

  const hasLocalCv = Object.keys(thongTinLocalCV).length > 0;

  const soLuongFeedbackDoanhNghiep = 0;

  function renderTrangThaiCv(props) {
    let lastUpdate = new Date;
    if (lichSuUpdate?.length > 0) {
      lastUpdate = lichSuUpdate.slice(-1);
    }

    return (
      <>
        <p>
          <span>
            {" "}
            <b>Trạng thái</b>
          </span>
          : {!hasLocalCv ? "Chưa tạo CV" : trangThaiCv && TRANG_THAI_CV[trangThaiCv].NoiDung}
        </p>
        <p>
          <span>
            <b>Ngày cập nhật</b> :{" "}
          </span>
          {moment(lastUpdate?.NgayTao).format(DATE_FORMAT)}
        </p>
      </>
    );
  }

  function TaoCvButton(props) {
    const { hasLocalCv, hasDuAn } = props;
    let errorString = "Bạn đã tạo CV";

    if (!hasLocalCv && hasDuAn)
      return (
        <NavLink to={`/update-cv`}>
          <Button>Tạo CV</Button>
        </NavLink>
      );

      if (!hasLocalCv && !hasDuAn)
      {
        errorString = "Bạn phải hoàn thành ít nhất một dự án capstone thì mới tạo CV được"
      }

    return (
      <Tooltip placement="top" title={errorString}>
        <span
          className="text-white  transform  rounded-lg p-1 text-sm px-2 shadow-lg transition duration-300"
          disabled
          style={{ backgroundColor: "grey", color: "black" }}
        >
          Tạo CV
        </span>
      </Tooltip>
    );
  }

  function ResetCvButton(props) {
    const { isChuaNopCv } = props;
    let errorString = "Bạn chưa nộp CV";
    function handleTaoLaiCv() {
      history.push("/update-cv");
    }

    if (isChuaNopCv)
      return (
        <Tooltip placement="top" title={errorString}>
          <span
            className="text-white  transform  rounded-lg p-1 text-sm px-2 shadow-lg transition duration-300"
            disabled
             style={{ backgroundColor: "grey", color: "black" }}
          >
            Reset CV
          </span>
        </Tooltip>
      );

    return (
      <Popconfirm
        placement="top"
        title="Dữ liệu CV đã nộp sẽ mất, bạn có chắc muốn tạo lại không ?"
        onConfirm={handleTaoLaiCv}
      >
        <button className="text-white btn-theme transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300">
          Reset CV
        </button>
      </Popconfirm>
    );
  }

  function SuaCvButton(props) {
    const { hasLocalCv } = props;
    let errorString = "Bạn chưa tạo CV";
    if (hasLocalCv)
      return (
        <NavLink to={`/user-cv`}>
          <Button>Sửa CV</Button>
        </NavLink>
      );

    return (
      <Tooltip placement="top" title={errorString}>
        <span
          className="text-white  transform  rounded-lg p-1 text-sm px-2 shadow-lg transition duration-300"
          disabled
          style={{ backgroundColor: "grey", color: "black" }}
        >
          Sửa CV
        </span>
      </Tooltip>
    );
  }

  function XemCvDaNopButton(props) {
    const { isChuaNopCv } = props;
    let errorString = "Bạn chưa nộp CV";
    if (!isChuaNopCv)
      return (
        <NavLink to={`/template-cv`}>
          <Button>Xem CV đã nộp</Button>
        </NavLink>
      );

    return (
      <Tooltip placement="top" title={errorString}>
        <span
          className="text-white  transform  rounded-lg p-1 text-sm px-2 shadow-lg transition duration-300"
          disabled
          style={{ backgroundColor: "grey", color: "black" }}
        >
          Xem CV đã nộp
        </span>
      </Tooltip>
    );
  }

  function XemCvDaDuocDuyetButton(props) {
    const { isChuaNopCv } = props;
    const maCodeCv = useSelector(state => state.nguoiDungReducer.maCodeCv)
    const dataCvDaDuyet = useSelector(
      (state) => state.nguoiDungReducer.dataCvDaDuyet
    );
      let urlString = "cv.itworks.vn"

      let debug = false

      if (debug) {
        urlString = "localhost:3002"
      }

    let errorString = "Bạn chưa nộp CV";
    if (dataCvDaDuyet && maCodeCv )
      return (
        <a href={`https://${urlString}/cv-online?code=${maCodeCv}`} target="_blank" rel="noopener noreferrer">
 <Button>Xem CV đã được duyệt</Button>
        </a>
      );
        if (maCodeCv && !isChuaNopCv) {
          errorString = "Bạn chưa có CV được duyệt"
        }
    return (
      <Tooltip placement="top" title={errorString}>
        <span
          className="text-white  transform  rounded-lg p-1 text-sm px-2 shadow-lg transition duration-300"
          disabled
          style={{ backgroundColor: "grey", color: "black" }}
        >
          Xem CV đã được duyệt
        </span>
      </Tooltip>
    );
  }

  function XemFeedbackDoanhNghiepButton(props) {
    const { isChuaNopCv } = props;
    let errorString = "Bạn chưa nộp CV";

    if (!isChuaNopCv && soLuongFeedbackDoanhNghiep > 0)
      return (
        <NavLink to={`/template-cv`}>
          <Button>Xem feedback của doanh nghiệp</Button>
        </NavLink>
      );

    if (!isChuaNopCv) {
      errorString = "Chưa có feedback nào";
    }

    return (
      <Tooltip placement="top" title={errorString}>
        <span
          className="text-white  transform  rounded-lg p-1 text-sm px-2 shadow-lg transition duration-300"
          disabled
          style={{ backgroundColor: "grey", color: "black" }}
        >
          Xem feedback của doanh nghiệp
        </span>
      </Tooltip>
    );
  }

  function RenderInfoCV(props) {
    const {title, count} = props
    
    return <div className="wrapper">
    <div className="thongke__item card_theme">
        <h5>
          {count} 
          </h5>
          <h4>
          {title}
          </h4>
         
        </div>
        </div>
  }

 
  return (
    <div
      style={{
        background: `url(${backGrountTemplate})`,
        flexGrow: "1",
        backgroundSize: "cover",
      }}
      className="h-max-content min-h-screen w-full  flex"
    >
      <div className="w-60">
        <SidebarNew />
      </div>
      <div
        style={{ flexGrow: "1" }}
        className="h-max-cotent flex flex-col  min-h-screen "
      >
        <Navbar />
        {
          isClose ? null : <div className="w-full h-20">
          <Flash_sale setIsClose={setIsClose} />
          </div>
        }
        
     
        <div className=" w-full bg-cover  space-y-2 quan-ly-cv h-{100vh}">
          <div className="container-fluid">
            <div className="row quanLyCV__header">
              <div className="col-5  p-1">
                
                <div className="card_theme group_button">
                {
                  thongTinTaoCv === null ? <Skeleton active></Skeleton> : <>
                   {renderTrangThaiCv()}
                  <div className="row">
                    <div className="col-4">
                      <TaoCvButton hasLocalCv={hasLocalCv} hasDuAn={hasDuAn} />
                    </div>
                    <div className="col-4">
                      <ResetCvButton isChuaNopCv={isChuaNopCv} />
                    </div>
                    <div className="col-4">
                      <SuaCvButton hasLocalCv={hasLocalCv} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <XemCvDaNopButton isChuaNopCv={isChuaNopCv} />
                    </div>
                    <div className="col-5">
                      <XemCvDaDuocDuyetButton isChuaNopCv={isChuaNopCv}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-7">
                      <XemFeedbackDoanhNghiepButton isChuaNopCv={isChuaNopCv} />
                    </div>
                  </div>
                  </>
                }
                 
                </div>
              </div>
              <div className="col-7  p-1">
                <ThongBaoQuanLyCv />
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 p-1">
                <div className="thong_ke">
                  <div className="row">
                   <RenderInfoCV title="Số lượt public CV được xem trong ngày" count={soLuotXemPublicCvTrongNgay ?? 0} />
                   <RenderInfoCV title="Tổng số lượt public CV được xem" count={isNaN(dataCvDaDuyet?.soLuotXemPublicCv + soLuotXemPublicCvTrongNgay ) ? 0 :dataCvDaDuyet?.soLuotXemPublicCv + soLuotXemPublicCvTrongNgay} />
                   <RenderInfoCV title="Số lượt CV của bạn được doanh nghiệp xem trong ngày" count={0} />
                   <RenderInfoCV title="Tổng số lượt CV của bạn được doanh nghiệp  xem" count={0} />
                   <RenderInfoCV title="Số doanh nghiệp đã xem CV của bạn" count={0} />
                  
                   
                  </div>
                 
                 
                </div>
              </div>
            </div>
          </div>

          <button className="btn-primary"></button>
        </div>
      </div>
    </div>
  );
}
