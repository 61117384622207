import React from "react";
import "./ChungNhanPDF.css";
import bground from "../../../../assets/images/bgChungNhan.jpg";
import chuKi from "../../../../assets/images/chuky.png";
import iCielNovecentosans from "../../../../assets/font/iCielNovecentosans-UltraBold.ttf";
import iCielNovecentosansRegular from "../../../../assets/font/iCielNovecentosans-DemiBold.ttf";
import Lobster from "../../../../assets/font/Lobster-Regular.ttf";
import Neutraface from "../../../../assets/font/Neutraface_Display_Bold.ttf";
import { Document, Page, Text, View, Image, Font } from "@react-pdf/renderer";
import { stylesChungNhan } from "./styleChungNhan";
import { LocalStorageUtil } from "../../../../utilities/localStorage";
import moment from "moment";
Font.register({ family: "iCielNovecentosans", src: iCielNovecentosans });
Font.register({
  family: "iCielNovecentosansRegular",
  src: iCielNovecentosansRegular,
});
Font.register({ family: "Lobster", src: Lobster });
Font.register({
  family: "Neutraface",
  src: Neutraface,
});
export default function ContentChungNhan({ chungNhan }) {
  console.log(chungNhan)
  let userInfor = LocalStorageUtil.getLoginLocalStorage();
  let xepLoai = "KHÁ";
  let xepLoaiTA = "Good";
  if (chungNhan?.diemCuoiKhoa >= 8) {
    xepLoai = "GIỎI";
    xepLoaiTA = "Very Good";
  }
  if (chungNhan?.diemCuoiKhoa >= 9) {
    xepLoai = "XUẤT SẮC";
    xepLoaiTA = "Excellent";
  }
  return (
    <Document>
      <Page size="A4" style={stylesChungNhan.page} orientation="portrait">
        <Image
          style={{
            position: "absolute",
            top: "0%",
            bottom: "0",
            left: "0%",
            right: "0",
          }}
          src={bground}
        />

        <View
          style={{
            position: "absolute",
            top: "20%",
            bottom: "30%",
            left: "0",
            right: "0",
          }}
        >
          <Image style={stylesChungNhan.chuKi} src={chuKi} />
          <Text style={stylesChungNhan.username}>{userInfor?.hoTen}</Text>
          <Text style={stylesChungNhan.tenChungNhan}>
            {chungNhan?.tenLoTrinh}
          </Text>
          <Text style={stylesChungNhan.duration}>
            {chungNhan?.soThangHoc} months
          </Text>
          <Text style={stylesChungNhan.thoiGianDaoTao}>
            {chungNhan?.soThangHoc} tháng
          </Text>
          <Text style={stylesChungNhan.diemCuoiKhoa}>
            {chungNhan?.diemCuoiKhoa} / {chungNhan?.isBaoVeCuoiKhoa ? "11" : "10"}
            {/* {chungNhan?.diemCuoiKhoa} / {chungNhan?.isBaoVeCuoiKhoa ? "11" : "10"} */}
          </Text>
          <Text style={stylesChungNhan.xepLoai}>{xepLoai}</Text>
          <Text style={stylesChungNhan.xepLoaiTA}>{xepLoaiTA}</Text>
          <Text style={stylesChungNhan.soChungNhan}>
            {chungNhan?.fullMaChungNhan}
          </Text>

          <Text style={stylesChungNhan.ngayCap}>
            {moment(chungNhan?.ngayCap).format("DD/MM/YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  );
}
