import { Divider, Progress } from 'antd'
import React, { useEffect } from 'react'
import imgSAA from './SAA.png'
import history from '../../utilities/history'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { useSelector } from 'react-redux'

const QuizEnd = ({ quizDetail, dataQuiz, finishQuiz }) => {
    const { quizId } = useParams()
    const { lstQuiz, layoutBoDe } = useSelector(state => state.baiTapReducer)


    useEffect(() => {
    }, [])

    let listQuiz = dataQuiz.noiDung;


    let domain1 = 0;
    let domain2 = 0;
    let domain3 = 0;
    let domain4 = 0;

    let sumPercent = 0;


    listQuiz?.map(item => {
        let { userChoice } = quizDetail

        let userAnwser = userChoice.find(n => n.id == item.id);

        if (userAnwser && _.isEqual(_.sortBy(userAnwser.choice?.map(String)), _.sortBy(item.dapAn?.map(String)))) {

            sumPercent += 1
            switch (item.tag) {
                case "DMA_1":
                    domain1 += 1
                    break;
                case "DMA_2":
                    domain2 += 1
                    break;
                case "DMA_3":
                    domain3 += 1
                    break;
                case "DMA_4":
                    domain4 += 1
                    break;
                default:
                    break;
            }
        }

    })


    let roundPer = (number, domain = null) => {
        if (domain)
            return Math.ceil((number * 100) / listQuiz.filter(n => n.tag == domain).length)

        return Math.ceil((number * 100) / listQuiz.length)
    }


    return (
        <div className=" p-4 flex justify-center">
            <div className="  bg-white shadow-md rounded-lg p-10">
                <h1 className="text-2xl font-bold mb-2">{dataQuiz.tieuDe} - AWS Certified Solutions Architect Associate - Results</h1>

                <div className="border-t border-gray-300 pt-4">
                    <div className="flex justify-content-around  mb-5">
                        <div className="mx-5">
                            <Progress format={(percent) => <label>{percent} % <br /> correct</label>} type="circle" percent={roundPer(sumPercent)} />

                        </div>

                        <div className="">

                            <p className="text-5xl mb-2">{roundPer(sumPercent)}% <span className='text-sm'>câu đúng ({sumPercent}/{listQuiz.length})</span> </p>
                            <div className="flex items-center text-gray-600 mb-6">
                                <span>{listQuiz?listQuiz.length:0} câu hỏi</span>
                                <span className="mx-2">|</span>
                                <span>130 phút</span>
                                <span className="mx-2">|</span>
                                <span>72% câu đúng để vượt qua</span>
                            </div>

                            <button className="px-4 py-2 bg-blue-500 text-white font-semibold rounded"
                                onClick={() => {
                                    finishQuiz(2)
                                }}
                            >Xem đáp án</button>
                        </div>
                    </div>
                    {layoutBoDe.aws &&<>
                        <h3 className="text-lg font-bold mb-4">Domains</h3>
                    <div className="mb-4">
                        <div className="flex justify-between items-center mb-2">
                            <span>Design High-Performing Architectures ({listQuiz.filter(n => n.tag == "DMA_1").length} questions)</span>
                            <span>{roundPer(domain1, "DMA_1")}%</span>
                        </div>
                        <div className="bg-gray-300 h-4 rounded">
                            <div className="bg-green-500 h-4 rounded"
                                style={{ width: roundPer(domain1, "DMA_1") + "%" }}></div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex justify-between items-center mb-2">
                            <span>Design Secure Architectures ({listQuiz.filter(n => n.tag == "DMA_2").length} questions)</span>
                            <span>{roundPer(domain2, "DMA_2")}%</span>
                        </div>
                        <div className="bg-gray-300 h-4 rounded">
                            <div className="bg-green-500 h-4 rounded"
                                style={{ width: roundPer(domain2, "DMA_2") + "%" }}></div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex justify-between items-center mb-2">
                            <span>Design Resilient Architectures ({listQuiz.filter(n => n.tag == "DMA_3").length} questions)</span>
                            <span>{roundPer(domain3, "DMA_3")}%</span>
                        </div>
                        <div className="bg-gray-300 h-4 rounded">
                            <div className="bg-green-500 h-4 rounded"
                                style={{ width: roundPer(domain3, "DMA_3") + "%" }}></div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex justify-between items-center mb-2">
                            <span>Design Cost-Optimized Architectures ({listQuiz.filter(n => n.tag == "DMA_4").length} questions)</span>
                            <span>{roundPer(domain4, "DMA_4")}%</span>
                        </div>
                        <div className="bg-gray-300 h-4 rounded">
                            <div className="bg-green-500 h-4 rounded"
                                style={{ width: roundPer(domain4, "DMA_4") + "%" }}></div>
                        </div>
                    </div>
                    </>}

                </div>
            </div>
        </div>

    )
}

export default QuizEnd