import React, { Component } from "react";
import { Tag, Input, Button, Collapse, Tooltip, Empty, message } from "antd";

import { Link, NavLink } from "react-router-dom";
import { toAliasString } from "../../../utilities/stringFormat";
import { LocalStorageUtil } from "../../../utilities/localStorage";
import { BaiTapNopModel } from "../../../model";
import * as actions from "../../../redux/actions/baiTapActions";
import { connect } from "react-redux";
import { DOMAIN_API, DOMAIN_API_FILE } from "../../../config/urlConfig";
import Axios from "axios";
import swal from "sweetalert2";
import { dateFormat } from "../../../utilities/dateTimeFormat";
import { BaiTapSevice } from "../../../service/baiTapService";
import * as baiTapActions from "../../../redux/actions/baiTapActions";
import Loading from "../../../assets/loading/Loading";

const FileDownload = require("js-file-download");

var ReactDOMServer = require("react-dom/server");
var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();

const { Panel } = Collapse;
const { Search } = Input;

class BaiTap extends Component {
  state = {
    viTri: -1,
    noiDung: "",
    googleLink: "",
    trangThai: false,
  };
  // shouldComponentUpdate(){
  //     return false;
  // }
  componentDidMount = () => {
    this.setState({ trangThai: false });
  };

  static getDerivedStateFromProps(props, state) {
    const { dsBaiTap } = props.baiTapProps;
    if (dsBaiTap.length > 0) {
      const baiTapNop = dsBaiTap[dsBaiTap.length - 1].baiTapNop;
      return {
        ...state,
        noiDung: baiTapNop ? baiTapNop.noiDung : "",
      };
    }
    return null;
  }

  changeYoutube = () => {
    document.querySelectorAll("oembed[url]").forEach((element) => {
      const anchor = document.createElement("iframe");
      let url = element.getAttribute("url");
      var regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);

      if (match && match[2].length == 11) {
        anchor.setAttribute("src", "//www.youtube.com/embed/" + match[2]);

        anchor.setAttribute("allowfullscreen", element.getAttribute("url"));

        element.appendChild(anchor);
      }
    });
  };

  noiDungBaiTap = () => {
    const { dsLopTaiLieu, dsBaiTap, dsBaiTapNop } = this.props;
    let nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    let lstBuoi = [];
    let soBuoi = 0;

    if (dsLopTaiLieu.length > 0) {
      soBuoi = dsLopTaiLieu.sort((a, b) => a.thuTuBuoi - b.thuTuBuoi)[
        dsLopTaiLieu.length - 1
      ].thuTuBuoi;

      for (let i = soBuoi; i > 0; i--) {
        let lstBaiTap = dsLopTaiLieu.filter((n) => n.thuTuBuoi == i);
        let title =
          lstBaiTap.length > 0
            ? lstBaiTap.sort((a, b) => a.id - b.id)[0].ghiChu
            : "";

        let noiDungTaiLieu = [];
        let noiDungBaiTap = [];

        lstBaiTap.map((item, index) => {
          //load mabaitap ngoai lo tring
          if (item.maBaiTap == 0) {
            //[0] ten file, [1] duong dan
            let dUrl = JSON.parse(item.duongDan);

            if (item.taiLieu) {
              let reactElement = htmlToReactParser.parse(
                item.noiDung.substr(0, 50)
              );

              noiDungTaiLieu.push(
                <div
                  className="tai-lieu-item my-2"
                  style={{
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "17px",
                        maxHeight: 100,
                        minHeight: 100,
                      }}
                    >
                      {index + 1}. {dUrl[0]}{" "}
                    </span>
                    <div className="container">
                      {item.noiDung.length >= 100 ? (
                        <span
                          style={{
                            color: "#5511C6",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            swal.fire(item.title, item.noiDung, "info");
                            this.changeYoutube();
                          }}
                        >
                          {reactElement} <br /> Xem thêm...
                        </span>
                      ) : (
                        reactElement
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="item-action btn-nopbai">
                      {dUrl[1] !== 0 ? (
                        <a
                          href={DOMAIN_API + dUrl[1]}
                          className="btn btn-link btnTaiBaiTap btn-tai-tai-lieu"
                          target="_blank"
                        >
                          <i className="fa fa-download"></i>
                          <span>Tải tài liệu</span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              let reactElement = htmlToReactParser.parse(
                item.noiDung.substr(0, 50)
              );
              let baiTapNop = dsBaiTapNop.find(
                (n) => n.maBaiTap == item.id && n.maNguoiDung == nguoiDung.id
              );

              noiDungBaiTap.push(
                <div className="khoa-hoc-item">
                  <div>
                    <b>
                      {dUrl[0]} - Hạn nộp: {dateFormat(item.ngayHetHan)}
                    </b>

                    <br />

                    <div className="container">
                      {item.noiDung.length >= 50 ? (
                        <span
                          style={{
                            color: "#5511C6",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            swal.fire(item.title, item.noiDung, "info");
                            this.changeYoutube();
                          }}
                        >
                          {" "}
                          {reactElement} <br /> Xem thêm...
                        </span>
                      ) : (
                        reactElement
                      )}
                    </div>

                    <div className="col-12">
                      {
                        //show diem bai tap nop
                        baiTapNop ? (
                          // kiem tra co diem chua
                          baiTapNop.diem == -1 ? (
                            <p className="text-center">
                              {dUrl[1] != 0 ? (
                                <a target="_blank" href={DOMAIN_API + dUrl[1]}>
                                  <button className="btn btn-primary m-3">
                                    Đề bài
                                  </button>
                                </a>
                              ) : (
                                ""
                              )}

                              {!item.hetHan && (
                                <Search
                                  allowClear
                                  enterButton={"Cập nhật"}
                                  size="large"
                                  placeholder={baiTapNop.noiDung}
                                  onSearch={(value) => this.nopBai(value, item)}
                                />
                              )}
                              <h4 className="pt-3">
                                Bạn đã nộp bài hãy đợi mentor chấm điểm !
                                <br />
                                {!item.hetHan &&
                                  "Bạn có thể nộp lại bài tập ở ô trên !"}
                              </h4>
                            </p>
                          ) : (
                            <p>
                              <h3 className="pt-3  text-center">
                                Điểm: {baiTapNop.diem}
                              </h3>
                              {htmlToReactParser.parse(baiTapNop.nhanXet)}
                            </p>
                          )
                        ) : // kiem tra het han chua khi chua nop bai
                        item.hetHan ? (
                          <h3 className="pt-3 text-center">
                            Bạn đã hết hạn nộp bài !
                          </h3>
                        ) : (
                          <p className="text-center">
                            {dUrl[1] != 0 ? (
                              <a target="_blank" href={DOMAIN_API + dUrl[1]}>
                                <button className="btn btn-primary m-3">
                                  Đề bài
                                </button>
                              </a>
                            ) : (
                              ""
                            )}
                            <Search
                              allowClear
                              enterButton={baiTapNop ? "Cập nhật" : "Nộp"}
                              size="large"
                              placeholder={"Nhập link của bạn"}
                              onSearch={(value) => this.nopBai(value, item)}
                            />
                          </p>
                        )
                      }
                    </div>
                  </div>
                </div>
              );
            }
          } else {
            let baiTap = dsBaiTap.find((n) => n.id == item.maBaiTap);
            if (baiTap)
              if (baiTap.taiLieu) {
                let reactElement = htmlToReactParser.parse(baiTap.ghiChu);

                noiDungTaiLieu.push(
                  <div
                    className="tai-lieu-item my-2"
                    style={{
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "17px",
                          maxHeight: 100,
                          minHeight: 100,
                        }}
                      >
                        {index + 1}. {baiTap.tenBaiTap}{" "}
                      </span>
                      <div className="container">
                        {baiTap.ghiChu.length >= 100 ? (
                          <span
                            style={{
                              color: "#5511C6",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              swal.fire(baiTap.title, baiTap.ghiChu, "info");
                            }}
                          >
                            {" "}
                            {reactElement} <br /> Xem thêm...
                          </span>
                        ) : (
                          reactElement
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="item-action btn-nopbai">
                        {baiTap.noiDung.trim() !== "" ? (
                          <a
                            href={`${DOMAIN_API}/files/${baiTap.noiDung}`}
                            className="btn btn-link btnTaiBaiTap btn-tai-tai-lieu"
                            target="_blank"
                            download={baiTap.noiDung}
                          >
                            <i className="fa fa-download"></i>
                            <span>Tải tài liệu</span>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              } else {
                let reactElement = htmlToReactParser.parse(
                  baiTap.ghiChu.substr(0, 50)
                );
                let baiTapNop = dsBaiTapNop.find(
                  (n) => n.maBaiTap == item.id && n.maNguoiDung == nguoiDung.id
                );

                noiDungBaiTap.push(
                  <div className="khoa-hoc-item">
                    <div>
                      <b>
                        {baiTap.tenBaiTap} - Hạn nộp:{" "}
                        {dateFormat(item.ngayHetHan)}
                      </b>

                      <br />

                      <div className="container">
                        {baiTap.ghiChu.length >= 50 ? (
                          <span
                            style={{
                              color: "#5511C6",
                              cursor: "pointer",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              swal.fire(baiTap.title, baiTap.ghiChu, "info");
                            }}
                          >
                            {reactElement} <br /> Xem thêm...
                          </span>
                        ) : (
                          reactElement
                        )}
                      </div>

                      <div className="col-12">
                        {
                          //show diem bai tap nop
                          baiTapNop ? (
                            // kiem tra co diem chua
                            baiTapNop.diem == -1 ? (
                              <p className="text-center">
                                {!item.hetHan && (
                                  <p className="text-center text-info">
                                    <a
                                      target="_blank"
                                      href={
                                        DOMAIN_API + "/files/" + baiTap.noiDung
                                      }
                                    >
                                      <button className="btn btn-primary m-3">
                                        Đề bài
                                      </button>
                                    </a>
                                    <Search
                                      allowClear
                                      enterButton={"Cập nhật"}
                                      size="large"
                                      placeholder={baiTapNop.noiDung}
                                      onSearch={(value) =>
                                        this.nopBai(value, item)
                                      }
                                    />
                                    Bạn có thể nộp lại bài tập ở ô trên !
                                  </p>
                                )}
                                <h4 className="pt-3">
                                  Bạn đã nộp bài hãy đợi mentor chấm điểm !
                                </h4>
                              </p>
                            ) : (
                              <p>
                                <h3 className="pt-3  text-center">
                                  Điểm: {baiTapNop.diem}
                                </h3>
                                {htmlToReactParser.parse(baiTapNop.nhanXet)}
                              </p>
                            )
                          ) : // kiem tra het han chua khi chua nop bai
                          item.hetHan ? (
                            <h3 className="pt-3 text-center">
                              Bạn đã hết hạn nộp bài !
                            </h3>
                          ) : (
                            <p className="text-center">
                              <a
                                target="_blank"
                                href={DOMAIN_API + "/files/" + baiTap.noiDung}
                              >
                                <button className="btn btn-primary m-3">
                                  Đề bài
                                </button>
                              </a>
                              <Search
                                allowClear
                                enterButton={"Nộp"}
                                size="large"
                                placeholder={"Nhập link của bạn"}
                                onSearch={(value) => this.nopBai(value, item)}
                              />
                            </p>
                          )
                        }
                      </div>
                    </div>
                  </div>
                );
              }
          }
        });

        lstBuoi.push(
          <Panel
            header={<b>{title != "" ? title : "Chưa có tiêu đề"}</b>}
            key={i}
          >
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th width="30%">Tài liệu </th>
                  <th>Bài tập </th>
                </tr>
                <tr>
                  <td>{noiDungTaiLieu}</td>
                  <td>{noiDungBaiTap}</td>
                </tr>
              </thead>
            </table>
          </Panel>
        );
      }
    }

    return lstBuoi;
  };
  nopBai = (value, item) => {
    let nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    const { dsBaiTapNop } = this.props;

    //**** ma bai tap nay la ma lophoc_tailieu
    let baiTapNop = dsBaiTapNop.find(
      (n) => n.maBaiTap == item.id && n.maNguoiDung == nguoiDung.id
    );

    let model = {
      noiDung: value,
      diem: -1,
      maLopHoc: item.maLop,
      maBaiTap: item.id,
      maNguoiDung: nguoiDung.id,
      nguoiCham: "",
      nhanXet: "",
    };

    if (value.trim() != "") {
      var div_loading = document.getElementsByClassName("div_loading");
      div_loading[0].removeAttribute("hidden", "");

      if (baiTapNop) {
        baiTapNop.noiDung = value;

        BaiTapSevice.updateBaiTapNopService(baiTapNop.id, baiTapNop)
          .then((res) => {
            this.props.dispatch(
              baiTapActions.layDanhSachBaiTapNopAction(item.maLop)
            );
            this.setState({});
            message.success("Cập nhật thành công !");

            div_loading[0].setAttribute("hidden", "");
          })
          .catch((err) => {
            message.error("Không lưu được !");
            console.log(err);
            div_loading[0].setAttribute("hidden", "");
          });
      } else {
        BaiTapSevice.nopBaiTapService(model)
          .then((res) => {
            this.props.dispatch(
              baiTapActions.layDanhSachBaiTapNopAction(item.maLop)
            );
            this.setState({});
            message.success("Nộp bài thành công !");

            div_loading[0].setAttribute("hidden", "");
          })
          .catch((err) => {
            message.error("Không nộp bài được !");
            console.log(err);
            div_loading[0].setAttribute("hidden", "");
          });
      }
    } else {
      message.error("Hãy nhập nội dung bài tập !");
    }
  };
  render() {
    return (
      <div className="bai-tap">
        <div className="bai-tap-list">
          <Collapse>{this.noiDungBaiTap()}</Collapse>
        </div>
        <Loading />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    khoaHocProps: state.khoaHocReducer,
    baiTapProps: state.baiTapReducer,
  };
}

export default connect(mapStateToProps, null)(BaiTap);
