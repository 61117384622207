import React, { Component } from "react";
import { Menu, Dropdown, Button, Modal } from "antd";
import "./Navbar.scss";
import history from "../../utilities/history";
import { LocalStorageUtil } from "../../utilities/localStorage";
import { DOMAIN_API } from "../../config/urlConfig";
import Axios from "axios";
import QRCodeWithLogo from "../../pages/hocvien-profile/QRCodeComponent";
import { NavLink } from "react-router-dom";

export default class Navbar extends Component {
  state = {
    isModalVisible: false,
  };
  userInfor = LocalStorageUtil.getLoginLocalStorage();

  onLogout = () => {
    LocalStorageUtil.removeLoginLocalStorage();
    LocalStorageUtil.removeCourseLocalStorage();
    history.push("/login");
  };

  downloadQRCode = () => {
    this.setState({ isModalVisible: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalVisible: false });
  };

  render() {
    const menu = (
      <Menu>
        <Menu.Item>
          {/* <a>Thông tin cá nhân</a> */}
          <NavLink to={`/profile/${this.userInfor.id}`}>Thông tin cá nhân</NavLink>
        </Menu.Item>
        <Menu.Item>
          <a>Thống kê</a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={this.downloadQRCode}>
            <i className="fa fa-download" aria-hidden="true"></i> Tải QR
          </a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={this.onLogout}>Thoát</a>
        </Menu.Item>
      </Menu>
    );

    return (
      <nav className="navbar navbar-expand-md bg-light justify-between ">
        <div>
          {this.props.tenLop && (
            <p className="m-0 font-medium text-black">
              Lớp: {this.props.tenLop}
            </p>
          )}
        </div>
        <Dropdown overlay={menu} placement="topRight">
          <div className="dropdown-button">
            <img
              width="30"
              src="https://www.seekpng.com/png/full/428-4287240_no-avatar-user-circle-icon-png.png"
              alt="avatar"
            />
            <b>Cybersoft</b>
          </div>
        </Dropdown>

        {/* Modal cho QR Code */}
        <Modal
        style={{top:'20px'}}
          title="Tải QR Code"
          visible={this.state.isModalVisible}
          onCancel={this.handleCloseModal}
          footer={null}
        >
          <QRCodeWithLogo />
        </Modal>
      </nav>
    );
  }
}
