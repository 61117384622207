import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import "../input/TextField.css";

const dateFormat = 'D/M/YYYY';

export default function DateTime({
    required, onChange, label,
    style, className, mode, value, ...props
}) {

    const { name } = props;
    const [message, setMessage] = useState("");
    const [dateValue, setDateValue] = useState(value ? moment(new Date(value), dateFormat) : null);

    let classNames = className ? `input-group ${className}` : 'input-group';

    const onChangeHandle = (date, dateString) => {
        let isValid = false;
        let errorMessage = '';

        if (required && dateString.length === 0) {
            errorMessage = `${label} không được bỏ trống!`;
            isValid = false;
        } else {
            errorMessage = ``;
            isValid = true;
        }

        setMessage(errorMessage);
        if (mode === "date")
            onChange(name, new Date(date), isValid);
        else {

            const temp = new Date(date);
            const day = temp.getDate() < 10 ? `0${temp.getDate()}` : temp.getDate();
            let month = temp.getMonth() + 1;
            month = month < 10 ? `0${month}` : month;
            const year = temp.getFullYear();
            let tempDate = `${day}-${month}-${year}`;

            onChange(name, tempDate, isValid);
        }
        setDateValue(date);
    };

    return (
        <div >
             <label className="label">
                {label}
                {required ? <span className='text-danger'>*</span> : null}
            </label>
            <DatePicker
                {...props}
                
                onChange={onChangeHandle}
                placeholder={""}
                format={dateFormat}
                value={dateValue}
            />
           
            <div className="bar"></div>
            {message.length > 0 ? <span className="error text-danger">{message}</span> : null}
        </div>
    )
}
