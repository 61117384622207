import React, { useEffect, useState } from "react";
import "./newBottomNavigationMobile.css";
import HouseIcon from "./../../../assets/icon/home-outline.svg";
import VideoIcon from "./../../../assets/icon/videocam-outline.svg";
import UserIcon from "./../../../assets/icon/person-circle-outline.svg";
import { Link, NavLink } from "react-router-dom";
export default function NewBottomNavigationMobile() {
  const [checkRecord, setCheckRecord] = useState(false);
  const [checkDetail, setCheckDetail] = useState(false);
  useEffect(() => {
    const url = window.location.href;
    if (url.includes("video-record-mobile")) {
      setCheckRecord(true);
    }
  }, [checkRecord]);
  useEffect(() => {
    const url = window.location.href;
    if (url.includes("course-detail")) {
      setCheckDetail(true);
    }
  }, [checkDetail]);
  return (
    <div id="newBottomNavigation" className="fixed w-full bottom-0 left-0">
      <nav className="navigation_container">
        <ul className="navigation_list h-full">
          <NavLink
            className="navigation_item"
            activeClassName=" active"
            to="/user-mobile"
          >
            <span className="icon">
              <img src={UserIcon} className="h-7 w-7" alt="#" />
            </span>
            <span className="text-black text">Cá nhân</span>
          </NavLink>
          <NavLink
            className={`${checkDetail && "active"} navigation_item`}
            activeClassName=" active"
            to="/home-mobile"
          >
            <span className="icon">
              <img src={HouseIcon} className="h-7 w-7" alt="" />
            </span>
            <span className="text-black text">Trang chủ</span>
          </NavLink>
          <NavLink
            className={`${checkRecord && "active"} navigation_item`}
            activeClassName="active"
            to="/video-mobile"
          >
            <span className="icon">
              <img src={VideoIcon} className="h-7 w-7" alt="" />
            </span>
            <span className="text-black text">Video</span>
          </NavLink>
          <div className="wrap-circle">
            <div className="highlight_circle" />
          </div>
        </ul>
      </nav>
    </div>
  );
}
