import React from "react";
import { Collapse } from "antd";

import { CaretRightOutlined } from "@ant-design/icons";
import HeaderPanel from "./HeaderPanel";
import { arrBg_LinearGgradient } from "../../roadmapPage/constant";
import Item_List_DiemKhoaHoc from "../Item_List_DiemKhoaHoc/ Item_List_DiemKhoaHoc";
const { Panel } = Collapse;
export default function Item_ListDiem_LoTrinh({ data, index }) {
  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel
        header={<HeaderPanel data={data} />}
        key="1"
        className="bg-transparent "
      >
        <div className=" flex flex-col  space-y-7 w-full">
          <Item_List_DiemKhoaHoc
            bg_color={arrBg_LinearGgradient[index]}
            data={data}
            key={index}
          ></Item_List_DiemKhoaHoc>
        </div>
      </Panel>
    </Collapse>
  );
}
