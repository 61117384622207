import React from "react";
import Lottie from "lottie-react";
import videoLottie from "./videoLottie.json";
import { Link } from "react-router-dom";
import NewBottomNavigationMobile from "../newBottomNavigationMobile/NewBottomNavigationMobile";
export default function ViewVideoButton() {
  return (
    <div>
      <div className="w-full h-140 flex flex-col justify-center items-center">
        <div className="w-full h-100 md:w-4/6">
          <Lottie
            loop={true}
            animationData={videoLottie}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <p className="text-gray-900 text-xl text-center w-2/3 mx-auto">
          Bạn vui lòng quay lại trang chủ chọn lớp để xem video
        </p>
        <Link
          className="btn-gradient-cyber px-3 py-2 rounded text-white cursor-pointer"
          to="/home-mobile"
        >
          Quay lại trang chủ
        </Link>
      </div>
      <NewBottomNavigationMobile />
    </div>
  );
}
