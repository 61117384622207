import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import ButtonVideo from "./ButtonVideo";

export default function SubSider({ course }) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="w-92n ">
      <div
        className="  cursor-pointer px-3 bg-gray-300 pr-7 card_theme border-t-0 border-r-0 border-l-0 rounded-none border-b-1 border-gray-400  text-color-title-theme w-full py-3   whitespace-normal text-lg   h-max-content  hover:text-blue-theme duration-300 min-h-16 flex  justify-between items-center space-x-1 pl-3"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <p className="text-color-title text-base m-0  text-black font-normal ">
          Khoá: {course.tenKhoaHoc}
        </p>

        <i
          style={{
            transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
          }}
          className=" fa fa-angle-down text-color-title  transition  duration-100 text-base flex-shrink-0 font-medium"
        ></i>
      </div>

      <AnimateHeight
        id="example-panel"
        duration={500}
        height={collapsed ? 0 : "auto"} // see props documentation below
      >
        {course.danhSachChuongHocSkill.map((chuongHoc, index) => {
          return (
            <div className="w-full" key={index}>
              <div className="h-12 flex items-center bg-gray-100 px-2">
                <p className=" m-0 text-black">{chuongHoc.tenChuong}</p>
              </div>
              {chuongHoc.danhSachBaiHoc[0].map((lesson) => {
                return <ButtonVideo data={lesson} />;
              })}
            </div>
          );
        })}
      </AnimateHeight>
    </div>
  );
}
