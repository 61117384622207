import React from "react";
import HtmlParser from "react-html-parser";
import { DOMAIN_API_FILE } from "../../../../../../../config/urlConfig";
import { RoadmapService } from "../../../../../../../service/roadmapService";
import { colors } from "../../../../../constant";

export default function ContentTaiLieu({ data, maLop }) {
  // console.log("ghi chú", data);
  function handleSubmitTrackingXemTaiLieu(item) {
    // console.log("item",item)
    let dataSubmit = {
      maBuoi: item.maBuoiHoc,
      maBaiHoc: item.id,
      maLop: +maLop,
    };
    // console.log(dataSubmit);
    RoadmapService.postTrackingXemTaiLieu(dataSubmit);
  }

  return (
    <div style={{ width: "600px" }} className=" space-y-5">
      {data.map((item, index) => {
        let color = colors[index % 2];
        return (
          <div>
            <div
              style={{ backgroundColor: color.bg }}
              className="w-full  p-2 px-3  min-h-22 justify-between flex items-center relative rounded-lg shadow-lg"
            >
              <div className="w-full pl-6 h-full flex flex-col  relative space-y-1 ">
                <p className="text-gray-900">{item.tieuDe}</p>
                {item.noiDung && (
                  <button
                    className=" flex items-center h-max-content rounded p-1 border-gray-600 w-max flex-shrink-0 border-1 text-color-content cursor-pointer hover:text-gray-900  px-2 transform duration-300 hover:border-gray-500
                "
                  >
                    <a
                      className="flex justify-center items-center space-x-1 text-color-content"
                      href={`${DOMAIN_API_FILE}/${item.noiDung}`}
                      target="_blank"
                      onClick={() => {
                        handleSubmitTrackingXemTaiLieu(item);
                      }}
                    >
                      <i className="fa fa-folder-open"></i>
                      <span>Tài nguyên</span>
                      <i className="fa fa-download"></i>
                    </a>
                  </button>
                )}

                {item.ghiChu && (
                  <div className="p-3 pb-0 w-full  bg-white rounded text-black">
                    {HtmlParser(item.ghiChu)}
                  </div>
                )}
                <div
                  style={{ backgroundColor: color.line }}
                  className="h-5/6 w-1 left-2 top-1/2 transform absolute rounded-lg -translate-y-1/2"
                ></div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
