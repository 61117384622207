import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { setNoiDungVideoAction, setSourceVideoAction } from "../../../../../../../redux/actions/roadMapAction";
import { FileSevice } from "../../../../../../../service/fileService";
import { LocalStorageUtil } from "../../../../../../../utilities/localStorage";

function ButtonVideo({ data }) {
  let dispatch = useDispatch();

  let handleChangVideo = (baiHoc) => {
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    if (nguoiDung.nuocNgoai) {
      // dispatch(setSourceVideoAction(baiHoc.vimeo));

      FileSevice.layDuongDanVideoDigitalService(baiHoc.noiDung)
        .then((res) => {
          dispatch(setSourceVideoAction(res.data));

          dispatch(setNoiDungVideoAction(baiHoc));

        })
        .catch((err) => console.log(err.response));
    } else {
      if (baiHoc.online) {
        FileSevice.layDuongDanVideoOnlineService(baiHoc.noiDung)
          .then((res) => {
            dispatch(setSourceVideoAction(res.data));

            dispatch(setNoiDungVideoAction(baiHoc));

          })
          .catch((err) => console.log(err.response));
      } else {
        FileSevice.layDuongDanVideoService(baiHoc.noiDung)
          .then((res) => {
            dispatch(setSourceVideoAction(res.data));

            dispatch(setNoiDungVideoAction(baiHoc));

          })
          .catch((err) => console.log(err.response));
      }
    }
  };


  return (
    <div className="w-full">
      <div
        className={
          "  cursor-pointer flex flex-col   justify-center h-max-content items-start  px-1 min-h-16   border-gray-200 rounded-none border-b-1 w-full border-l-0 border-r-0  transform duration-300 card_theme " +
          "hightLightcss"
        }
        key={data.id + "menuItem"}
        onClick={() => {
          handleChangVideo(data);
        }}
      >
        <button className=" items-center space-x-2  flex  px-2  justify-center   p-1 min-h-16 h-max-content   transform duration-300  rounded-lg w-full ">
          <div className="flex space-x-1  justify-start items-center ">
            <div className=" card_theme bg-gray-400  h-6 rounded-full leading-6 w-6 flex justify-center items-center transform scale-75 relative overflow-hidden  ">
              <i className="fa fa-play text-blue-theme text-xs opacity-75"></i>
            </div>
          </div>
          <div className="flex  flex-col w-full justify-start  h-max-content">
            <span
              className={
                "w-full text-left text-sm " + "color" + "hightLightcss"
              }
            >
              {data.tenBaiHoc}
            </span>
            <div className="flex  items-center w-full justify-between">
              <a
                onClick={() => {
                  // window.open(`https://backend.cyberlearn.vn/${lesson.moTa}`, '_blank').focus();
                }}
                // href={`https://backend.cyberlearn.vn/${lesson.moTa}`}
                target="_blank"
              >
                {/* <button
                  className="flex items-center space-x-1 h-max-content rounded p-1 border-gray-600 w-max flex-shrink-0 border-1 text-color-content cursor-pointer hover:text-gray-900  px-2 transform duration-300 hover:border-gray-500
            "
                >
                  <i className="fa fa-folder-open"></i>
                  <span>Tài nguyên</span>
                  <i className="fa fa-download"></i>
                </button> */}
              </a>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}
export default ButtonVideo = memo(ButtonVideo);
