import React from "react";
import { QRCodeCanvas } from 'qrcode.react';
import { LocalStorageUtil } from "../../utilities/localStorage";
import { DOMAIN_MAIN } from "../../config/urlConfig";
import logo from '../../assets/images/logoCyber.png'; // Đường dẫn đến logo
import { useLocation } from "react-router-dom";
import { compressToEncodedURIComponent } from "lz-string";


const QRCodeWithLogo = () => {
  const location = useLocation();
  const thongTinDangNhap = LocalStorageUtil.getLoginLocalStorage();
  // const encryptCode = (code) => {
  //   return btoa(unescape(encodeURIComponent(code)));
  // };
  const encryptCode = (code) => {
    // Nén dữ liệu trước khi mã hóa
    const compressed = compressToEncodedURIComponent(code);
    return compressed;
  };
  const url = `${DOMAIN_MAIN}/infor-profile?code=${encryptCode(thongTinDangNhap?.id)}`;

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-code-canvas");
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "QRCode.png"; // Tên file tải về
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  return (
    <div>
      <QRCodeCanvas
        id="qr-code-canvas"
        value={url}
        title={"Title for my QR Code"}
        size={500}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
        marginSize={0}
        imageSettings={{
          src: logo,
          x: undefined,
          y: undefined,
          height: 50,
          width: 50,
          opacity: 1,
          excavate: true,
          crossOrigin: "anonymous", // Thêm dòng này
        }}
      />
      {location.pathname!=="/certification" && <button onClick={downloadQRCode} style={{ marginTop: "20px", color:'blue' }}>
        Tải QR Code
      </button>}
    </div>
  );
};

export default QRCodeWithLogo;
