import React, { useEffect, useState } from "react";
import "./ChungNhanPDF.css";
import bground from "../../../../assets/images/C5.jpg";
import chuKi from "../../../../assets/images/chuKyNew.png";
import iCielNovecentosans from "../../../../assets/font/iCielNovecentosans-UltraBold.ttf";
import iCielNovecentosansRegular from "../../../../assets/font/iCielNovecentosans-Normal.ttf";
import MtdGreatVibes from "../../../../assets/font/MTD-GREAT-VIBES.OTF";
import Montserrat from "../../../../assets/font/Montserrat-SemiBold.otf";
import { Document, Page, Text, View, Image, Font } from "@react-pdf/renderer";
import { stylesChungNhan } from "./styleChungNhan";
import { LocalStorageUtil } from "../../../../utilities/localStorage";
import moment from "moment";
import QRCode from "qrcode"; // Thư viện QRCode
import { compressToEncodedURIComponent } from "lz-string";
import { DOMAIN_MAIN } from "../../../../config/urlConfig";
import { removeVietnameseTones } from "../../../../utilities/utils";

// Đăng ký các font chữ
Font.register({ family: "MtdGreatVibes", src: MtdGreatVibes });
Font.register({ family: "Montserrat", src: Montserrat });
Font.register({ family: "iCielNovecentosans", src: iCielNovecentosans });
Font.register({
  family: "iCielNovecentosansRegular",
  src: iCielNovecentosansRegular,
});

// Component PDF với QR Code
export default function ContentChungNhanQR({ chungNhan }) {
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");
  const userInfor = LocalStorageUtil.getLoginLocalStorage(); // Thông tin đăng nhập từ LocalStorage
  
  let xepLoai = "KHÁ";
  let xepLoaiTA = "Good";
  if (chungNhan?.diemCuoiKhoa >= 8) {
    xepLoai = "GIỎI";
    xepLoaiTA = "Very Good";
  }
  if (chungNhan?.diemCuoiKhoa >= 9) {
    xepLoai = "XUẤT SẮC";
    xepLoaiTA = "Excellent";
  }

  // Hàm mã hóa và nén code
  const encryptCode = (code) => {
    return compressToEncodedURIComponent(code);
  };

  // Tạo URL base64 từ QR code và lưu vào state
  useEffect(() => {
    const qrValue = `${DOMAIN_MAIN}/infor-profile?code=${encryptCode(userInfor?.id)}`;
    
    QRCode.toDataURL(qrValue, { width: 100 }, (err, url) => {
      if (!err) {
        setQrCodeDataUrl(url); // Lưu URL base64 vào state
      } else {
        console.error("Error generating QR code", err);
      }
    });
  }, [userInfor]);

  return (
      <Document>
        <Page size="A4" style={stylesChungNhan.page} orientation="landscape">
          <Image
            style={{
              position: "absolute",
              top: "0%",
              bottom: "0",
              left: "0%",
              right: "0",
            }}
            src={bground}
          />

          <View
            style={{
              position: "absolute",
              top: "20%",
              bottom: "30%",
              left: "0",
              right: "0",
            }}
          >
            <Image style={stylesChungNhan.chuKi} src={chuKi} />
            <Text style={stylesChungNhan.username}>{removeVietnameseTones(userInfor?.hoTen)}</Text>
            <Text style={stylesChungNhan.tenChungNhan}>
              {chungNhan?.tenLoTrinh}
            </Text>
            <Text style={stylesChungNhan.duration}>
              {chungNhan?.soThangHoc} months
            </Text>
            <Text style={stylesChungNhan.diemCuoiKhoa}>
              {chungNhan?.diemCuoiKhoa} /{" "}
              {chungNhan?.isBaoVeCuoiKhoa ? "11" : "10"}
            </Text>
            <Text style={stylesChungNhan.xepLoaiTA}>{xepLoaiTA}</Text>
            <Text style={stylesChungNhan.soChungNhan}>
              {chungNhan?.fullMaChungNhan}
            </Text>

            <Text style={stylesChungNhan.ngayCap}>
              {moment(chungNhan?.ngayCap).format("DD/MM/YYYY")}
            </Text>

            {/* Hiển thị mã QR code từ URL base64 */}
            {qrCodeDataUrl && (
              <Image
                src={qrCodeDataUrl}
                style={{ width: 100, height: 100, position: "absolute", left: 83, bottom: '-16%' }}
              />
            )}
          </View>
        </Page>
      </Document>
  );
}
