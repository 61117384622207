import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { urlMainPageOffline } from '../../config/urlConfig';
import { message } from 'antd';
import LoginEmail from '../../component/LoginBackup/LoginEmail';

const PRIVATE_KEY = "4924vyF471fc394";

const jwt = require("jsonwebtoken");
const DangNhapEmail = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get("token");
  
  
    useEffect(() => {
        const checkToken = (token) => {
            try {
              jwt.verify(token, PRIVATE_KEY);
              message.success("Token hợp lệ!");
            } catch {
              message.error(
                "Đã hết hạn đăng nhập, vui lòng liên hệ Cybersoft để lấy link đăng nhập!"
              );
              setTimeout(() => {
                window.location.href = urlMainPageOffline;
              }, 5000);
            }
          };
      
          if (token) {
            checkToken(token);
          } else {
            message.error("Token không tồn tại!");
            setTimeout(() => {
              window.location.href = urlMainPageOffline;
            }, 5000);
          }
    }, [token]);
  
  
    return (
      <div className="login-page">
        <div className="wrapper">
          <div className="left flex items-center justify-center">
            <img src="https://i.ibb.co/G3xKFwN/team.jpg" alt="img" />
            <div className="left-cyber flex flex-col">
              <span>Hệ thống hỗ trợ học tập tại CyberSoft</span>
              <span style={{ margin: "0 5px" }}> - </span>
              <a target="_blank" href="https://cybersoft.edu.vn">
                https://cybersoft.edu.vn
              </a>
            </div>
          </div>
          <LoginEmail />
        </div>
      </div>
    );
    
}

export default DangNhapEmail