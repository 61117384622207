import React from "react";
import ChartLoTrinh from "./ChartLoTrinh";

export default function ThongKeTotal({ dataRoadMap }) {
  return (
    <div
      style={{ maxWidth: "440px " }}
      className="  card_theme   h-full  w-1/2  lg:w-2/5 flex justify-center flex-col py-3 flex-shrink-0"
    >
      <ChartLoTrinh dataRoadMap={dataRoadMap} />
    </div>
  );
}
