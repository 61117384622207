import moment from "moment";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setSourceVideoAction } from "../../../../../redux/actions/roadMapAction";
import ContentCapstone from "../ItemRoadMap/ContentModal/ContentCapstone/ContentCapstone";
import ContentTracNghiemCuoiKhoa from "../ItemRoadMap/ContentModal/ContentTracNghiemCuoiKhoa/ContentTracNghiemCuoiKhoa_RoadMap";
import ModalRoadMap from "../ItemRoadMap/ModalRoadMap";

import OptionItem from "../ItemRoadMap/Option/OptionItem";
import { useParams } from "react-router-dom";

export default function Left({ data, dataHash }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const { id } = useParams();
  const [titleModal, setTitleModal] = useState("");
  const [contentModal, setContentModal] = useState();
  let dispatch = useDispatch();
  // console.log(typeof dataHash);
  // console.log(dataHash.size);
  return (
    <div
      style={{ minHeight: "48px" }}
      className=" w-60 xl:w-80 h-max-content py-3  card_theme rounded-r-full px-3 flex justify-center items-center relative z-0"
    >
      <ModalRoadMap
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title={titleModal}
        ContentComponent={contentModal}
        setContentModal={setContentModal}
        onCloseModal={() => {
          dispatch(setSourceVideoAction(null));
        }}
      />
      <div className="flex justify-start space-y-3 flex-col w-max  ">
        {data.danhSachTracNghiemTheoSkill.length > 0 && (
          <OptionItem
            icon={<i className="fa fa-laptop-code text-blue-theme"></i>}
            title={`Trắc nghiệm cuối skill - ${moment(
              data?.danhSachTracNghiemTheoSkill[0]?.hanNop
            ).format("DD/MM/YYYY")}`}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <ContentTracNghiemCuoiKhoa
                data={data.danhSachTracNghiemTheoSkill[0]}
              />
            }
            key={data?.danhSachTracNghiemTheoSkill[0]?.soLanNop}
          />
        )}{" "}
        {data.danhSachCapstoneTheoSkill.length > 0 && dataHash.size == 0 ? (
          <OptionItem
            icon={<i className="fa fa-bolt text-blue-theme"></i>}
            title={`Bài tập capstone - ${moment(
              data.danhSachCapstoneTheoSkill[0]?.hanNop
            ).format("DD/MM/YYYY")}`}
            setIsModalVisible={setIsModalVisible}
            setContentModal={setContentModal}
            setTitleModal={setTitleModal}
            contentModal={
              <div className="space-y-5">
                {data.danhSachCapstoneTheoSkill.map((item) => {
                  return <ContentCapstone data={item} />;
                })}
              </div>
            }
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
