import React, { useEffect } from "react";
import { Player } from "video-react";
import "./../VideoXemTruoc/xemVideoMobile.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataRoadMapServAction,
  getTaskRoadMapServAction,
} from "../../../../redux/actions/roadMapAction";
import { useState } from "react";
import SpinnerComponent from "../../../../component/Spinner";
import { Checkbox } from "antd";
import VideoXemRecordMobile from "./VideoXemRecordMobile";
import ViewClickChonVideo from "../ViewClickChonVideo";
import NewBottomNavigationMobile from "../../newBottomNavigationMobile/NewBottomNavigationMobile";
export default function XemRecordMobile() {
  const { dataRoadMap } = useSelector((state) => state.roadmapReducer);
  const dispatch = useDispatch();
  const { id, buoiHoc, chuongHoc } = useParams();
  const [maVideoXem, setMaVideoXem] = useState();
  // dataRoadMap?.danhSachBuoiHocTheoSkill[chuongHoc].danhSachBuoiHoc
  //   .videoXemLai[0].id
  // const [videoList, setVideoList] = useState();
  // console.log(dataRoadMap);
  useEffect(() => {
    if (dataRoadMap?.danhSachBuoiHocTheoSkill) {
      setMaVideoXem(
        dataRoadMap?.danhSachBuoiHocTheoSkill[chuongHoc].danhSachBuoiHoc.find(
          (item) => item.id === Number(buoiHoc)
        ).videoXemLai[0].id || 0
      );
    }
  }, []);

  const renderVideoXemTruoc = () => {
    if (dataRoadMap?.danhSachBuoiHocTheoSkill) {
      let videoXemTruoc = "";
      videoXemTruoc = dataRoadMap?.danhSachBuoiHocTheoSkill[
        chuongHoc
      ].danhSachBuoiHoc.find((item) => item.id == Number(buoiHoc));
      return videoXemTruoc.videoXemLai.map((item, index) => {
        return (
          <li key={index}>
            <div
              onClick={() => {
                setMaVideoXem(item.id);
              }}
              // to=""
              className="flex cursor-pointer text-black items-center w-full justify-between gap-5 shadow py-2 px-4 hover:bg-gray-300 rounded"
            >
              <div className="text-lg">{index + 1}</div>
              <div className="flex-1">
                <h4>{item.tieuDe}</h4>
                <p className="mb-0">
                  <i className="fa-solid fa-closed-captioning mr-2"></i>
                  {/* <span>Thời lượng - 10:55 mins</span> */}
                </p>
              </div>
              <div>
                {/* <i className="fa-solid ml-auto fa-check p1 w-5 flex items-center justify-center h-5 text-xs rounded-full bg-green-600 text-white"></i> */}
                {/*
                <i className="fa-solid ml-auto fa-exclamation p1 w-5 flex items-center justify-center h-5 text-xs rounded-full bg-red-600 text-white"></i> */}
              </div>
            </div>
          </li>
        );
      });
    }
  };
  useEffect(() => {
    dispatch(getDataRoadMapServAction(id));

    // dispatch(getTaskRoadMapServAction(id));
  }, [id]);
  // console.log(dataRoadMap?.danhSachBuoiHocTheoSkill);s
  // const newMap = new Map(dataRoadMap?.danhSachBuoiHocTheoSkill);
  // console.log(newMap);
  return (
    <div>
      <SpinnerComponent />
      <div className="h-full py-2 px-8 shadow mb-5">
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-lg mb-0">
            {dataRoadMap?.thongTinLopHoc?.tenLopHoc}
          </h3>
          {/* <div>
            <i className="fa-solid fa-bell text-2xl"></i>
          </div> */}
        </div>
        <h4 className="font-bold text-base mb-0">Video xem trước</h4>
      </div>
      <div>
        <div className="h-140">
          {maVideoXem ? (
            <VideoXemRecordMobile
              maBuoiHoc={buoiHoc}
              dsXemLai={
                dataRoadMap.length !== 0
                  ? dataRoadMap?.danhSachBuoiHocTheoSkill[
                      chuongHoc
                    ].danhSachBuoiHoc.find((item) => item.id == Number(buoiHoc))
                  : []
              }
              maLop={id}
              maVideo={maVideoXem}
            />
          ) : (
            <ViewClickChonVideo />
          )}
        </div>
        <div className="flex justify-between items-center pr-5">
          {/* <h4 className="my-2 px-2 font-bold text-lg">Bài 1: Giới thiệu Css</h4> */}
          {/* <Checkbox /> */}
        </div>
      </div>
      <div className="pb-9">
        <ul className="mx-2 space-y-2 py-5">{renderVideoXemTruoc()}</ul>
      </div>
      <NewBottomNavigationMobile />
    </div>
  );
}
