import { parseInt } from 'lodash';
import React, { Component } from 'react';


export default class LayOutInDangKy extends Component {
    render() {
        const { khachHang } = this.props;

        return (
            <div style={{ padding: 50 }}>
                <table style={{ marginBottom: 30, textAlign: "center", width: "100%" }}>
                    <tr>
                        <td> <img src={""} /></td>
                        <td>  <h1 style={{ fontWeight: 600 }}>Thông tin nhập học</h1></td>
                    </tr>
                </table>



                <span>
                    CyberSoft xin chào bạn, <br />
                    Đầu tiên thay mặt cho trung tâm đào tạo lập trình CyberSoft gởi lời cảm ơn đến các bạn đã chọn CyberSoft để khởi đầu sự nghiệp và nâng cao kỹ năng lập trình của mình.
                    Nhằm đảm bảo lợi ích học tập cao nhất cho các bạn, các bạn vui lòng cung cấp các thông tin dưới đây giúp chúng tôi. CyberSoft sẽ dựa vào những thông tin này để kích hoạt các tài liệu học tập, hỗ trợ tìm kiếm việc làm phù hợp cho bạn các bạn sau khi hoàn thành khóa học.
                    Ngoài ra, cũng thông báo đến bạn rõ các nội quy của TRUNG TÂM ĐÀO TẠO LẬP TRÌNH CYBERSOFT.  <br />
                    Thân mến,  <br />
                    CyberSoft.
                </span>

                <table style={{ marginTop: 30, width: "100%" }}>
                    <tr>
                        <td> <b>Email</b> <br />
                            {khachHang.email} </td>
                        <td>  <b>Tên đầy đủ</b> <br />
                            {khachHang.tenKH}</td>
                    </tr>
                    <tr >
                        <td style={{ paddingTop: 20 }}> <b>Số điện thoại</b> <br />
                            {khachHang.soDienThoai} </td>
                        <td style={{ paddingTop: 20 }}>  <b>Ngày sinh</b> <br />
                            {khachHang.ngaySinh}</td>
                    </tr>

                    <tr >
                        <td style={{ paddingTop: 20 }}> <b>Trường học</b> <br />
                            {khachHang.truongDaVaDangHoc}</td>
                        <td style={{ paddingTop: 20 }}>  <b>Công việc hiện tại</b> <br />
                            {khachHang.congViecHienTai}</td>
                    </tr>
                    <tr >
                        <td style={{ paddingTop: 20 }}> <b>Khoảng lương bạn mong muốn khi học xong khóa</b> <br />
                            {parseInt(khachHang.mucLuongMongMuon).toLocaleString()}</td>
                        <td style={{ paddingTop: 20 }}>  <b>Khoảng lương sau 2 năm bạn mong muốn</b> <br />
                            {parseInt(khachHang.mucLuongMongMuonSau2Nam).toLocaleString()}</td>
                    </tr>

                    <tr >
                        <td style={{ paddingTop: 20 }}>  <b>Bạn học ngành CNTT hay trái ngành?</b> <br />
                            {khachHang.traiNganh}
                        </td>
                        <td style={{ paddingTop: 20 }}>  <b>Bạn đồng ý để Cybersoft hỗ trợ tìm việc cho bạn?</b> <br />
                            {khachHang.hoTroTimViec == "true" ? "Có" : "Không"}</td>
                    </tr>
                    <tr >
                        <td style={{ paddingTop: 20 }}> <b>MẶT TRƯỚC Căn cước công dân hoặc Chứng minh nhân dân của bạn</b> <br />
                            <img style={{ paddingTop: 20 }} width="250" height="100" src={"hide"} />
                        </td>
                        <td style={{ paddingTop: 20 }}>  <b>MẶT SAU Căn cước công dân hoặc Chứng minh nhân dân của bạn</b> <br />
                            <img style={{ paddingTop: 20 }} width="250" height="100" src={"hide"} />
                        </td>
                    </tr>
                </table>

                <div style={{ marginTop: 20 }}>

                    <h4 style={{ fontWeight: 600 }}>TÔI XÁC NHẬN CÁC THÔNG TIN LÀ ĐÚNG SỰ THẬT, ĐÃ ĐỌC VÀ ĐỒNG Ý VỚI CÁC QUY ĐỊNH PHÍA DƯỚI CỦA TRUNG TÂM CYBERSOFT</h4>

                    <span className="d-block font-weight-bold mb-3">Qui định của trung tâm <span className="err">*</span></span>
                    <h5><b>CÁC QUY ĐỊNH VỀ HỌC PHÍ, SẮP LỚP</b></h5>
                    <ol type="number" className="pl-5">
                        <li><b>KHÔNG</b> hoàn lại tiền cọc và tiền học phí hoặc chuyển qua cho người khác</li>
                        <li>Nếu lớp chưa khai giảng, học viên muốn chuyển lớp, học viên cần báo cho CyberSoft trước ngày khai giảng 15 ngày. Học phí sẽ được bảo lưu trong vòng 3 tháng kể từ ngày học viên thông báo cho CyberSoft.</li>
                        <li>	Nếu học viên có nhu cầu bảo lưu/chuyển lớp. CyberSoft hỗ trợ học viên bảo lưu/chuyển lớp <b> 01 (Một) lần</b> trong trường hợp đặc biệt và có đơn xét duyệt. CyberSoft sẽ <b> quyết định việc ghi danh vào các lớp còn trống </b>cho học viên được chấp nhận bảo lưu/chuyển lớp. <b>Học viên được bảo lưu/chuyển lớp sẽ đóng thêm 1 phần phí hỗ trợ bảo lưu/chuyển lớp:</b>
                            <ul type="disc" className="pl-5">
                                <li> Trước 15 ngày so với ngày khai giảng: Miễn phí.</li>
                                <li>	Từ 14 ngày còn lại đến ngày khai giảng và trong 30 ngày đầu kể từ ngày khai giảng: Phí bảo lưu/chuyển lớp là 35% học phí lớp sẽ chuyển qua.</li>

                            </ul>
                            <b>* Đối với khóa học có thời gian nhiều hơn 3 tháng:</b>
                            <ul type="disc" className="pl-5">
                                <li>Từ ngày thứ 31 đến ngày thứ 60 kể từ ngày khai giảng: Phí bảo lưu/chuyển lớp là 55% học phí lớp sẽ chuyển qua.</li>
                                <li>Từ ngày thứ 61 đến ngày thứ 90 kể từ ngày khai giảng: Phí bảo lưu/chuyển lớp là 75% học phí lớp sẽ chuyển qua.</li>
                                <li>Từ ngày thứ 91 trở đi: Không hỗ trợ bảo lưu/chuyển lớp.</li>
                            </ul>

                            <b>* Đối với khoá học từ 3 tháng trở lại, sau 20 ngày kể từ ngày khai giảng sẽ không được bảo lưu. Đối với khóa 1,5 tháng trở lại sẽ không được bảo lưu khi lớp đã khai giảng.</b>
                            <br />
                            <i><b>* Lưu ý:</b> Do hiện tại trung tâm đang rất nhiều học viên đăng ký học, nên các suất học rất quý giá. Phí bảo lưu/chuyển lớp này chỉ là một phần học phí của một suất khóa sau. Mong nhận được sự hợp tác các các bạn.</i>
                        </li>


                        <li> Trường hợp trả góp, đóng nhiều đợt: <b> Học viên cần tự giác hoàn thành đúng kỳ hạn đã thỏa thuận.</b>
                            <ul type="disc" className="pl-5">
                                <li> Trễ 1 - 15 ngày: Phát sinh thêm 1,000,000 VNĐ (một triệu Việt Nam Đồng) </li>
                                <li>	Quá 15 ngày: Không hỗ trợ tiếp tục học tập.</li>
                            </ul>
                        </li>

                        <li>Nếu học viên đóng trễ hạn, học viên sẽ chịu trách nhiệm và để cho trung tâm toàn quyền xử lý.</li>

                        <li>Trong suốt thời gian học tập, trung tâm sẽ phân bổ các giảng viên đảm nhận các phần thuộc thế mạnh của riêng mỗi giảng viên.</li>
                        <li> <b>KHÔNG HOÀN TRẢ </b> bất kì khoản phí nào  đã thanh toán cho trung tâm với bất kì lý do nào sau khi đã làm Form đăng kí ! Tiền học phí không bao gồm chi phí giữ xe  và VAT (nếu muốn xuất hoá đơn đỏ)</li>
                        <li>Khi đăng kí lộ trình FullStack, bạn sẽ học Front-End trước và sau khi kết thúc, trung tâm sẽ gởi lịch học Back-end NodeJS, lộ trình này có thể học tại chi nhánh khác và thời khóa biểu khác (Online Live cho các bạn ngoài HCM) . Học viên lựa chọn và quyết định để được ghi danh vào lớp Back-End. Toàn bộ tài nguyên sẽ hết hạn truy cập theo đúng qui về lớp học (sau 45 ngày kể từ khi kết thúc lớp frontend sẽ hết hạn tài nguyên).</li>
                    </ol>


                    <h5>
                        <b>CÁC QUY ĐỊNH VỀ HỌC TẬP</b></h5>
                    <ol type="number" className="pl-5">
                        <li>
                            CyberSoft có hỗ trợ việc record bài giảng trong một số trường hợp, record chỉ hỗ trợ xem <b>tối đa trong 4 tuần </b> sau mỗi buổi học. Video record sẽ tự động xóa và CyberSoft không hỗ trợ thêm sau đó. Đây chỉ là giá trị cộng thêm nên các bạn cố gắng học tập tham dự đầy đủ các buổi học để nắm vững kiến thức
                        </li>
                        <li>
                            Các video được ghi danh từ hệ thống sẽ được kích hoạt <b> tự động dần theo từng phần trong lộ trình khóa học</b>, trước 7 ngày (1 tuần) cho mỗi phần và chỉ có hạn trong vòng 35 ngày (5 tuần) kể từ ngày được kích hoạt. Sau khi kết thúc khoá học <b> 45 ngày </b>, toàn bộ hệ thống (video, tài liệu học tập,...) đều ngưng truy cập hoàn toàn. Về tài nguyên API trung tâm hỗ trợ tối đa 4 tháng sau khi học xong sẽ ngưng toàn bộ (các bạn cố gắng quay video lại dự án hoặc chụp hình nếu cần để bỏ vào cv xin việc).
                        </li>
                        <li>	Học viên có trách nhiệm <b> bảo mật tài khoản, toàn bộ video, tài liệu</b> mà trung tâm đã cung cấp. Học viên không được cung cấp cho bất kì bên thứ 3 nào mà chưa được sự đồng ý của CyberSoft. Nếu cố tình vi phạm bạn sẽ bị xử phạt 200,000,000 VND (hai trăm triệu Việt Nam Đồng) và CyberSoft sẽ toàn quyền xử lý các vi phạm. Tài khoản đăng nhập hệ thống là tài khoản facebook chính chủ, chỉ xét duyệt và chấp nhận 1 lần duy nhất lúc đăng kí. Bất kì lý do nào liên quan đến việc thay đổi tài khoản đăng nhập sau này đều KHÔNG được hỗ trợ.</li>
                        <li>	Học viên có trách nhiệm chủ động đến lớp học tập chuyên cần. Các video trên hệ thống chỉ mang tính chất hỗ trợ kiến thức.</li>
                        <li>	Chứng nhận chỉ được cấp khi học viên hoàn thành tất cả bài tập với điểm trung bình trên <b> 7 điểm </b> (70 điểm với thang điểm 100)  và đồ án cuối khóa học trên <b>7 điểm</b>. Đối với các học viên được cấp chứng nhận, các bạn được cấp bản mềm dạng pdf trước, bản cứng có mộc đỏ sẽ được cấp theo từng quí (3 tháng/đợt). Học viên sẽ có mặt tại trung tâm theo ngày/giờ thông báo để nhận chứng nhận. Đối học viên học online live chung offline những nơi không có chi nhánh, trung tâm sẽ gởi đến các bạn địa chỉ cư trú tại Việt Nam.</li>

                        <li>	Học viên <b>không được dùng các thiết bị ghi âm, ghi hình lại buổi học trên lớp</b>. Nếu cần tài liệu, chú thích, hỗ trợ thì có thể tự viết lại hoặc nhờ giảng viên hoặc mentor tổng hợp lại và cung cấp sau mỗi buổi học. Nếu CyberSoft phát hiện trường hợp học viên cố tình ghi âm, ghi hình lại buổi học thì CyberSoft sẽ toàn quyền xử lý.
                        </li>
                        <li> <b>KHÔNG</b> được phép hút thuốc trong toà nhà, nếu cố tình vi phạm và xảy ra sự cố, học viên phải chịu hoàn toàn trách nhiệm</li>
                    </ol>
                </div>
            </div>
        )
    }
}
