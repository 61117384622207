import React, { useState } from "react";
import ContentKhoaHocLienQuan from "../ItemRoadMap/ContentModal/ContentKhoaHocLienQuan/ContentKhoaHocLienQuan";
import ModalRoadMap from "../ItemRoadMap/ModalRoadMap";
import OptionItem from "../ItemRoadMap/Option/OptionItem";

export default function Right({ data }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [contentModal, setContentModal] = useState();
  if (data.danhSachKhoaHocBySkill.length == 0) {
    return (
      <div className=" w-60 xl:w-80 h-12  rounded-l-full px-3 flex justify-center items-center"></div>
    );
  }
  return (
    <div className=" w-60 xl:w-80 h-12 card_theme rounded-l-full px-3 flex justify-center items-center">
      <ModalRoadMap
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title={titleModal}
        ContentComponent={contentModal}
        setContentModal={setContentModal}
      />
      <div className="flex justify-start space-y-3 flex-col w-max  ">
        <OptionItem
          title={"Khoá học liên quan"}
          icon={<i className="fa fa-laptop text-blue-theme"></i>}
          setIsModalVisible={setIsModalVisible}
          setContentModal={setContentModal}
          setTitleModal={setTitleModal}
          contentModal={
            <ContentKhoaHocLienQuan data={data.danhSachKhoaHocBySkill} />
          }
        />
      </div>
    </div>
  );
}
