import React from "react";
import HeaderItem from "./HeaderItem";

export default function NavHeaderStiky({ data, width }) {
  return (
    <div className="w-full card_theme flex h-full p-2  xl:p-3 items-center justify-center space-x-1 ">
      {data?.map((item, index) => {
        return <HeaderItem key={index} data={item} width={width} />;
      })}
    </div>
  );
}
