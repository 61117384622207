import { inherits } from "@babel/types";
import React from "react";
import LinkIconComponent from "../Component/LinkIconComponent/LinkIconComponent";
import LinkIconEmailComponent from "../Component/LinkIconEmailComponent/LinkIconEmailComponent";
import { convertHoTenThanhTenHo } from "../../../../utilities/stringFormat";
import './index.scss'
import { Tag } from "antd";
import moment from "moment";
export default function Header({ thongTinMoRong }) {
  let styleCss = {
    linkColor: {
      color: "white",
    },
    headerName: {
      color: "white",
    },
    divTitleHeader: {
      margin: "10px 0 10px 0",
    },
    titleHeader: {
      fontSize: "1.5rem",
      color: "rgb(46, 64, 111)",
    },
    titleProject: {
      fontSize: "1.3rem",
      color: "rgb(46, 64, 111)",
      paddingTop: "10px",
    },
    divProject: {
      boxShadow: " -9px 10px 7px -3px rgba(0,0,0,0.1)",
      padding: "1rem",
      border: "1px solid transparent",
      borderRadius: "10px",
      marginBottom: "2rem",
    },
    tagColor: "magenta",
    linkStyle: {
      color: "black",
    },
    experienceTitle: {
      title: {},
      companyName: {},
      location: {},
      description: {},
    },
    educationTitle: {
      subject: {},
      schoolName: {},
      location: {},
      description: {},
    },
  };

  return (
    <div className="container default">
      <div
        className="row p-6 header"
        style={{ backgroundColor: "#647B71", color: "#fff" }}
      >
        {thongTinMoRong?.isHiddenAvatar ? null : (
          <div className="col-sm-3">
            <p className="avatar-wrapper">
              <img
                className="rounded-full"
                src={thongTinMoRong?.anhAvatar}
                style={{ width: 159 }}
                loading
              />
            </p>
          </div>
        )}
        <div
          className={"header__summary " + (thongTinMoRong.isHiddenAvatar ? "col-sm-12" : "col-sm-9")}
        >
          <h4
          >
            <span
            >
              <strong style={styleCss.headerName}>
                {thongTinMoRong?.gioiTinh === "male" ? "Mr" : "Mrs"}.
                {convertHoTenThanhTenHo(thongTinMoRong?.hoTen).toUpperCase()} -{" "}
                {thongTinMoRong?.chucDanh}
                <br />
              </strong>
            </span>
          </h4>
          <p style={{ textAlign: "justify", lineHeight: "1.15", fontSize: "0.7rem" }}>
            <span >
              {thongTinMoRong?.gioiThieu}
            </span>
          </p>
          <div
            style={{ lineHeight: "1.15", color: "white" }}
            className="container-fluid"
          >
            <div className="row">
              {thongTinMoRong?.linkGitHub &&
              thongTinMoRong.linkGitHub.trim() !== "" ? (
                <LinkIconComponent
                  link={thongTinMoRong?.linkGitHub}
                  styleCss={styleCss.linkColor}
                  icon="fa fa-github"
                >
                  My GitHub
                </LinkIconComponent>
              ) : null}

              {!thongTinMoRong?.isHiddenFacebookLink &&
              thongTinMoRong?.linkFacebook?.trim() !== "" ? (
                <LinkIconComponent
                  link={thongTinMoRong?.linkFacebook}
                  styleCss={styleCss.linkColor}
                  icon="fa fa-facebook"
                >
                  My Facebook
                </LinkIconComponent>
              ) : null}

              {thongTinMoRong?.soDienThoai ? (
                <LinkIconComponent
                  link={thongTinMoRong?.soDienThoai}
                  styleCss={styleCss.linkColor}
                  icon="fa fa-phone"
                >
                  {thongTinMoRong?.soDienThoai}
                </LinkIconComponent>
              ) : null}

              {thongTinMoRong?.email ? (
                <LinkIconEmailComponent
                  thongTinMoRong={thongTinMoRong}
                  linkUrl={thongTinMoRong?.email}
                  styleCss={styleCss.linkColor}
                  icon="fa fa-envelope"
                >
                  Email: {thongTinMoRong?.email}
                </LinkIconEmailComponent>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="row heading heading-work-experience-1">
        <div className="col-sm-12" style={styleCss.divTitleHeader}>
          <div
            style={{
              border: "none",
              borderBottom: "double windowtext 2.25pt",
              padding: "0in 0in 1.0pt 0in",
              marginLeft: "6.65pt",
              marginRight: "0in",
            }}
          >
            <h1
              style={{
                margin: "0.15pt 0in 0.0001pt",
                textAlign: "justify",
                border: "medium none",
                padding: "0in",
                lineHeight: 1,
              }}
            >
             
                <span className="title">
                  <strong>FrontEnd Skills</strong>
                </span>
             
            </h1>
          </div>
        </div>

        <ul className="row mb-2 w-100 text-center">
          {thongTinMoRong?.lsSkillFE
            ? thongTinMoRong.lsSkillFE?.map((item, index) => {
                return (
                  <li
                    className="mt-2 mb-2"
                   
                    key={`skill-template-${index}`}
                  >
                    <Tag color={styleCss.tagColor} className="content">
                      {item}
                    </Tag>
                  </li>
                );
              })
            : null}
        </ul>
      </div>

      <div className="row heading heading-work-experience-1">
        <div className="col-sm-12" style={styleCss.divTitleHeader}>
          <div
            style={{
              border: "none",
              borderBottom: "double windowtext 2.25pt",
              padding: "0in 0in 1.0pt 0in",
              marginLeft: "6.65pt",
              marginRight: "0in",
            }}
          >
            <h1
              style={{
                margin: "0.15pt 0in 0.0001pt",
                textAlign: "justify",
                border: "medium none",
                padding: "0in",
                lineHeight: 1,
              }}
            >
         
                <span className="title">
                  <strong>BackEnd Skills</strong>
                </span>
              
            </h1>
          </div>
        </div>

        <ul className="row mb-2 w-100 text-center">
          {thongTinMoRong?.lsSkillBE
            ? thongTinMoRong.lsSkillBE?.map((item, index) => {
                return (
                  <li
                    className="mt-2 mb-2"
                   
                    key={`skill-template-${index}`}
                  >
                    <Tag color={styleCss.tagColor} className="content">
                      {item}
                    </Tag>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
      <div className="row heading heading-work-experience-1">
        <div className="col-sm-12" style={styleCss.divTitleHeader}>
          <div
            style={{
              border: "none",
              borderBottom: "double windowtext 2.25pt",
              padding: "0in 0in 1.0pt 0in",
              marginLeft: "6.65pt",
              marginRight: "0in",
            }}
          >
            <h1
              style={{
                margin: "0.15pt 0in 0.0001pt",
                textAlign: "justify",
                border: "medium none",
                padding: "0in",
                lineHeight: 1,
              }}
            >
             
                <span className="title">
                  <strong>Other Skills</strong>
                </span>
              
            </h1>
          </div>
        </div>

        <ul className="row mb-2 w-100 text-center">
          {thongTinMoRong?.lsSkillOther
            ? thongTinMoRong.lsSkillOther?.map((item, index) => {
                return (
                  <li
                    className="mt-2 mb-2"
                   
                    key={`skill-template-${index}`}
                  >
                    <Tag color={styleCss.tagColor} className="content">
                      {item}
                    </Tag>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
      <div className="row heading heading-work-experience-1">
        <div className="col-sm-12" style={styleCss.divTitleHeader}>
          <div
            style={{
              border: "none",
              borderBottom: "double windowtext 2.25pt",
              padding: "0in 0in 1.0pt 0in",
              marginLeft: "6.65pt",
              marginRight: "0in",
            }}
          >
            <h1
              style={{
                margin: "0.15pt 0in 0.0001pt",
                textAlign: "justify",
                border: "medium none",
                padding: "0in",
                lineHeight: 1,
              }}
            >
              
                <span className="title">
                  <strong>Soft Skill</strong>
                </span>
             
            </h1>
          </div>
        </div>

        <ul className="row mb-2 w-100 text-center">
          {thongTinMoRong?.listSoftSkill
            ? thongTinMoRong.listSoftSkill?.map((item, index) => {
                return (
                  <li
                    className="mt-2 mb-2"
                   
                    key={`skill-template-${index}`}
                  >
                    <Tag color={styleCss.tagColor} className="content">
                      {item}
                    </Tag>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
      <div className="row heading heading-work-experience-1">
        <div className="col-sm-12" style={styleCss.divTitleHeader}>
          <div
            style={{
              border: "none",
              borderBottom: "double windowtext 2.25pt",
              padding: "0in 0in 1.0pt 0in",
              marginLeft: "6.65pt",
              marginRight: "0in",
            }}
          >
            <h1
              style={{
                margin: "0.15pt 0in 0.0001pt",
                textAlign: "justify",
                border: "medium none",
                padding: "0in",
                lineHeight: 1,
              }}
            >
              
                <span className="title">
                  <strong>Language</strong>
                </span>
              
            </h1>
          </div>
        </div>

        <ul className="row mb-2 w-100 text-center">
          {thongTinMoRong?.ngoaiNgu
            ? thongTinMoRong.ngoaiNgu?.map((item, index) => {
                return (
                  <li
                    className="mt-2 mb-2"
                   
                    key={`skill-template-${index}`}
                  >
                    <Tag color={styleCss.tagColor} className="content">
                      {item}
                    </Tag>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
      <div className="row wrapper">
        <div className="col-sm-12" style={styleCss.divTitleHeader}>
          <div
            style={{
              border: "none",
              borderBottom: "double windowtext 2.25pt",
              padding: "0in 0in 1.0pt 0in",
              marginLeft: "6.65pt",
              marginRight: "0in",
            }}
          >
            <h1
              style={{
                margin: "0.15pt 0in 0.0001pt",
                textAlign: "justify",
                border: "medium none",
                padding: "0in",
                lineHeight: 1,
              }}
            >
              <span className="title">
                <strong>Projects</strong>
              </span>
            </h1>
          </div>
          {thongTinMoRong?.listDuAn?.map((item, index) => {
            return (
              <div style={styleCss.divProject} key={`duAnTemplate_${index}`}>
                <h3 className="sub-title">{item.tieuDe}</h3>
                <p className="content">
                  <span>
                    <p className="content">Skills on Project:</p>
                    <span style={{ color: "#888", lineHeight: "25px" }}>
                      {JSON.parse(item.danhSachSkill).join(", ")}
                    </span>
                  </span>
                </p>
                <p className="content">{item.moTa}</p>
                <div className="row justify-content-between project__listButton">
                  {item?.linkDeploy ? (
                    <LinkIconComponent
                      link={item?.linkDeploy}
                      icon="fa fa-github"
                    >
                      Link Deploy Demo
                    </LinkIconComponent>
                  ) : null}

                  {item?.linkYoutube ? (
                    <LinkIconComponent
                      link={item?.linkYoutube}
                      icon="fa fa-youtube-play"
                    >
                      Link Youtube Demo
                    </LinkIconComponent>
                  ) : null}

                  {item?.linkGit ? (
                    <LinkIconComponent link={item?.linkGit} icon="fa fa-github">
                      Link Source Code
                    </LinkIconComponent>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12" style={styleCss.divTitleHeader}>
          <div
            style={{
              border: "none",
              borderBottom: "double windowtext 2.25pt",
              padding: "0in 0in 1.0pt 0in",
              marginLeft: "6.65pt",
              marginRight: "0in",
            }}
          >
            <h1
              style={{
                margin: "0.15pt 0in 0.0001pt",
                textAlign: "justify",
                border: "medium none",
                padding: "0in",
                lineHeight: 1,
              }}
            >
              <span className="title">
                <strong>Organizational and Committee Experience</strong>
              </span>
            </h1>
          </div>
          <hr />
        </div>
        {thongTinMoRong?.kinhNghiem?.map((item, index) => {
          return (
            <div
              className="col-12 d-flex p-5"
              key={`kinhNghiemTemplate_${index}`}
            >
              <div className="col-sm-5">
                <h3 >
                  <span className="sub-title">{item.chucVu}</span>
                </h3>
                <h4 >
                  <span className="sub-title">{item.tenCongTy}</span>
                </h4>
                <p >
                  <span >
                    <span className="content">
                      {`${item.namLamTu} - ${item.namLamDen}`}
                      <br />
                      {item.diaChi}
                    </span>
                    <br />
                  </span>
                </p>
              </div>
              <div className="col-sm-7">
                <p className="content">{item.moTaNgan}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="col-sm-12" style={styleCss.divTitleHeader}>
          <div
            style={{
              border: "none",
              borderBottom: "double windowtext 2.25pt",
              padding: "0in 0in 1.0pt 0in",
              marginLeft: "6.65pt",
              marginRight: "0in",
            }}
          >
            <h1
              style={{
                margin: "0.15pt 0in 0.0001pt",
                textAlign: "justify",
                border: "medium none",
                padding: "0in",
                lineHeight: 1,
              }}
            >
              <span className="title">
                <strong>Education</strong>
              </span>
            </h1>
          </div>
          <hr />
        </div>
        {thongTinMoRong?.truongHoc?.map((item, index) => {
          return (
            <div
              className="col-12 d-flex p-5"
              key={`truongHocTemplate_${index}`}
            >
              <div className="col-sm-5">
                <h3 className="sub-title" >
                  <span >{item.trinhDo}</span>
                </h3>
                <h4  className="sub-title">
                  <span >{item.tenTruong}</span>
                </h4>
                <p >
                  <span className="content">
                    <span >
                      {`${item.namHocTu} - ${item.namHocDen}`}
                      <br />
                      {item.diaChi}
                    </span>
                    <br />
                  </span>
                </p>
              </div>
              <div className="col-sm-7">
                <p className="content">{item.moTaNgan}</p>
              </div>
            </div>
          );
        })}
      </div>
      {
        thongTinMoRong?.chungNhan.length !== 0 ? ( <div className="row">
        <div className="col-sm-12" style={styleCss.divTitleHeader}>
          <div
            style={{
              border: "none",
              borderBottom: "double windowtext 2.25pt",
              padding: "0in 0in 1.0pt 0in",
              marginLeft: "6.65pt",
              marginRight: "0in",
            }}
          >
            <h1
              style={{
                margin: "0.15pt 0in 0.0001pt",
                textAlign: "justify",
                border: "medium none",
                padding: "0in",
                lineHeight: 1,
              }}
            >
              <span className="title">
                <strong>Certification</strong>
              </span>
            </h1>
          </div>
          <hr />
        </div>
        {thongTinMoRong?.chungNhan?.map((item, index) => {
          return (
            <div className="col-12 d-flex p-5" key={`certification_${index}`}>
              <div className="col-sm-5">
                <h3 className="sub-title" >
                  <span >
                    {item.tenChungChi}
                  </span>
                </h3>
                <h4 className="sub-title">
                  <span >{item.noiCap}</span>
                </h4>
                <p className="content">
                
                    <span >
                      {`${(item.ngayCapChungChi)}`}
                    </span>
                    
               
                </p>
              </div>
              <div className="col-sm-7">
                <p className="content">{item.moTaNgan}</p>
              </div>
            </div>
          );
        })}
      </div>) : null
      }
     
    </div>
  );
}
