export const DOMAIN_API = "https://apicrm.cybersoft.edu.vn";
// export const DOMAIN_API = "https://localhost:5001";


export const DOMAIN_MAIN = "https://login.cybersoft.edu.vn";
// export const DOMAIN_MAIN = "https://localhost:3000";

export const DOMAIN_API_FILE = "https://apicrm.cybersoft.edu.vn/files";

export const urlMainPage = "https://cyberlearn.vn";
export const urlMainPageOffline = "https://cybersoft.edu.vn";

export const API_URL_DOMAIN_ONLINE = "https://backend.cyberlearn.vn";
export const API_URL_VIDEO_NUOC_NGOAI =
  "https://apicrm.cybersoft.edu.fvn/api/file/ftp-video-digital/";

// sendCommand(SecurityInterstitialCommandId.CMD_PROCEED
// command run backtest trên chrome
