import React from "react";

export default function OptionKhoaHocLienQuan({
  icon,
  title,
  setIsModalVisible,
  setContentModal,
  contentModal,
  setTitleModal,
  statusContent,
  leftSide,
  lopHocActive,
  deadline,
  classNameTitle,
  indexVideoXemlai
}) {
  // console.log(leftSide);
  // console.log(lopHocActive);
  const renderTag = () => {
    if (lopHocActive) {
      if (leftSide === undefined && statusContent === 1) {
        // console.log("first");
        return (
          <span className="ml-2 text-white text-xs w-5 h-5 flex justify-center items-center bg-green-600 rounded-full">
            <i class="fa-solid fa-check p-1"></i>
          </span>
        );
      } else if (leftSide === undefined && statusContent === 2) {
        return (
          <span
            className={`progress bg-transparent text-red-600 w-5 h-5 text-xl flex items-center justify-center ml-2 ${classNameTitle}`}
          >
            <i className="fa-solid fa-triangle-exclamation"></i>
          </span>
        );
      } else {
        return "";
      }
    }
  };

  return (
    <h2
      className={`flex mb-0 items-center cursor-pointer py-1 hover:bg-gray-200 rounded ${
        leftSide !== undefined ? "px-2" : ""
      }`}
      onClick={() => {
        sessionStorage.setItem('indexVideoXemLai', indexVideoXemlai)
        setIsModalVisible(true);
        setContentModal(contentModal);
        setTitleModal(title);
      }}
    >
      <span className="p-1 rounded bg-blue-theme w-6 h-6 text-xs xl:text-xs flex items-center justify-center text-white mr-2">
        {icon}
      </span>
      <div className="space-y-1">
        <span
          className={`opacity-70 ${classNameTitle} ${
            statusContent === 1
              ? "text-green-600"
              : statusContent === 2
              ? "text-red-600"
              : ""
          }`}
        >
          {title}
        </span>
        {statusContent === 1 || statusContent === 3 ? "" : deadline}
      </div>
      {renderTag()}
    </h2>
  );
}
