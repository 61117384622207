import React, { useState } from 'react';
import './SelectField.css';

export default function SelectField({ options, required, type, label, onChange, ...props }) {

    const [message, setMessage] = useState('');

    const onChangeHandle = (e) => {
        onChange(props.name, e.target.value, true);
    }
    return (
        <div className="select-field">
            <label className="label">
                { label } 
                { required ?  <span>*</span> : null }
            </label>
            <select className='form-control' value={props.value} onChange={onChangeHandle} defaultValue={5}>
                {
                    options.map((item, index) => <option
                            key={index} 
                            value={item.value}
                        >
                            {item.label}
                        </option>)
                }
            </select>
            { message.length > 0 ? <span className="error">{message}</span> : null }
        </div>
    )
}