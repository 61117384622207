import React, { useEffect, useState, useReducer } from "react";
import { message, Modal, Rate, Tabs, Input, Popconfirm } from "antd";
import {
  layDanhMucDanhGia,
  layDanhSachDanhGia,
  layDanhSachDanhGiaTrongThang,
} from "../../../../redux/actions/nguoiDungAction";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useParams } from "react-router-dom";
import { LocalStorageUtil } from "../../../../utilities/localStorage";
import { NguoiDungSevice } from "../../../../service/nguoiDungService";
import moment from "moment";
const { TabPane } = Tabs;
const { TextArea } = Input;

const desc = ["rất tệ", "tệ", "bình thường", "tốt", "rất tốt"];
const CONSTANTS = {
  TOGGLE_MODAL_NHAN_XET: "TOGGLE_MODAL_NHAN_XET",
  CHON_DANH_GIA: "CHON_DANH_GIA",
  SUA_NHAN_XET: "SUA_NHAN_XET",
  INPUT_NHAN_XET: "INPUT_NHAN_XET",
  LUU_NHAN_XET: "LUU_NHAN_XET",
  SET_IS_DANH_GIA_CHUA_DU: "SET_IS_DANH_GIA_CHUA_DU",
  SET_MODAL_VISIBLE: "SET_MODAL_VISIBLE",
  SET_IS_TUAN_CUOI_CUNG: "SET_IS_TUAN_CUOI_CUNG",
  SET_MODAL_THONG_BAO_VISIBLE: "SET_MODAL_THONG_BAO_VISIBLE",
  DONG_THONG_BAO: "DONG_THONG_BAO",
};
const initialState = {
  isNhanXetModalVisible: false,
  indexDanhMucDangChon: 0,
  mentorIdDangChon: "",
  nhanXetDangChon: "",
  isSuaNhanXet: false,
  isDanhGiaDu: false,
  visible: false,
  isTrongTuanCuoiCungCuaThang: false,
  modalThongBaoVisible: false,
  isDanhGiaChuaDuSoLuong: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CONSTANTS.DONG_THONG_BAO:
      if (state.isDanhGiaChuaDuSoLuong && state.isTrongTuanCuoiCungCuaThang) {
        return {
          ...state,
          visible: true,
          modalThongBaoVisible: false,
        };
      } else {
        return {
          ...state,
          modalThongBaoVisible: false,
        };
      }
    case CONSTANTS.SET_MODAL_THONG_BAO_VISIBLE:
      return {
        ...state,
        modalThongBaoVisible: payload,
      };
    case CONSTANTS.TOGGLE_MODAL_NHAN_XET:
      return {
        ...state,
        isNhanXetModalVisible: !state.isNhanXetModalVisible,
      };
    case CONSTANTS.SET_MODAL_VISIBLE:
      return {
        ...state,
        visible: payload,
      };
    case CONSTANTS.CHON_DANH_GIA:
      return {
        ...state,
        indexDanhMucDangChon: payload.indexDanhMuc,
        mentorIdDangChon: payload.mentorId,
        isSuaNhanXet: false,
        isNhanXetModalVisible: true,
        nhanXetDangChon: "",
      };
    case CONSTANTS.SUA_NHAN_XET:
      return {
        ...state,
        indexDanhMucDangChon: payload.indexDanhMuc,
        mentorIdDangChon: payload.mentorId,
        isNhanXetModalVisible: true,
        nhanXetDangChon: payload.nhanXet,
        isSuaNhanXet: true,
      };
    case CONSTANTS.INPUT_NHAN_XET:
      return {
        ...state,
        nhanXetDangChon: payload,
      };
    case CONSTANTS.LUU_NHAN_XET:
      return {
        ...state,
        indexDanhMucDangChon: 0,
        mentorIdDangChon: "",
        isSuaNhanXet: false,
        isNhanXetModalVisible: false,
        nhanXetDangChon: "",
      };
    case CONSTANTS.SET_IS_TUAN_CUOI_CUNG: {
      return {
        ...state,
        isTrongTuanCuoiCungCuaThang: payload,
      };
    }
    case CONSTANTS.SET_IS_DANH_GIA_CHUA_DU:
      return {
        ...state,
        isDanhGiaChuaDuSoLuong: payload,
      };
    default:
      return state;
  }
};

export default function DanhGiaMentorV2({ dataRoadMap }) {
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const [lstDanhGia, setLstDanhGia] = useState([]);
  const { id } = useParams();
  const PHIEN_BAN_THONG_BAO = "0.0.1";

  const danhMucDanhGia = useSelector(
    (state) => state.nguoiDungReducer.danhMucDanhGia
  );
  const dsDanhGia = useSelector((state) => state.nguoiDungReducer.dsDanhGia);
  const thongTinDangNhap = LocalStorageUtil.getLoginLocalStorage();

  //Debug
  // thongTinDangNhap.id = "26826898-eb07-4880-891a-432335a7f23a";

  const showModal = () => {
    dispatchLocal({ type: CONSTANTS.SET_MODAL_VISIBLE, payload: true });
  };

  const handleOk = () => {
    setTimeout(() => {
      dispatchLocal({ type: CONSTANTS.SET_MODAL_VISIBLE, payload: false });
    }, 3000);
  };

  const handleCancel = () => {
    dispatchLocal({ type: CONSTANTS.SET_MODAL_VISIBLE, payload: false });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(layDanhMucDanhGia());
    dispatch(layDanhSachDanhGiaTrongThang());
  }, []);

  // useEffect(() => {
  //   let isMount = true;

  //   if (isMount) {
  //     // Kiem tra coi co danh gia chua

  //     let soLuongMentorGiangVienTrongLop =
  //       dataRoadMap.thongTinLopHoc?.danhSachMT_GV.length;

  //     let soLuongMentorDaDanhGia = dsDanhGia.filter(
  //       (n) => n.maLop == id && n.maNguoiDanhGia == thongTinDangNhap.id
  //     ).length;

  //     // Kiem tra tuan cuoi cung cua thang

  //     // Ngày cuối cùng của tháng
  //     let ngayCuoiCungCuaThang = moment().endOf("month");

  //     // Check xem là thứ mấy nếu không phải là chủ nhật thì trừ cho tới khi nào ra chủ nhật
  //     while (ngayCuoiCungCuaThang.day() != 0) {
  //       ngayCuoiCungCuaThang.subtract(1, "days");
  //     }

  //     let ngayDauTuan = moment(ngayCuoiCungCuaThang);
  //     ngayDauTuan.subtract(7, "days");

  //     let ngayHomNay = moment();

  //     let khoangCachToiNgayDauTuan = ngayDauTuan.diff(ngayHomNay, "days");

  //     let khoangCachToiNgayCuoiTuan = ngayCuoiCungCuaThang.diff(
  //       ngayHomNay,
  //       "days"
  //     );

  //     // Từ ngày thứ hai đến ngày chủ nhật
  //     let isTrongTuanCuoiCungCuaThang =
  //       khoangCachToiNgayDauTuan <= 0 && khoangCachToiNgayCuoiTuan >= 0;

  //     dispatchLocal({
  //       type: CONSTANTS.SET_IS_TUAN_CUOI_CUNG,
  //       payload: isTrongTuanCuoiCungCuaThang,
  //     });

  //     let isDanhGiaChuaDuSoLuong =
  //       soLuongMentorDaDanhGia < soLuongMentorGiangVienTrongLop;

  //       // Truong hop la mentor hay giang vien thi se khong danh gia ho nen chi can danh gia tong so - 1 la duoc

  //       if (thongTinDangNhap.maNhomQuyen !== "HOCVIEN") {
  //         isDanhGiaChuaDuSoLuong =
  //       soLuongMentorDaDanhGia < soLuongMentorGiangVienTrongLop - 1;
  //       }

  //     // console.log(soLuongMentorDaDanhGia, soLuongMentorGiangVienTrongLop)
  //     dispatchLocal({
  //       type: CONSTANTS.SET_IS_DANH_GIA_CHUA_DU,
  //       payload: isDanhGiaChuaDuSoLuong,
  //     });

  //     let isChuaHienThiThongBao = true;

  //     let luuLocalThongBao = localStorage.getItem("THONG_BAO_TICH_CUC");

  //     // Neu da hien thi popup va phien bao thong bao moi nhat thi khong popup nua
  //     if (luuLocalThongBao && luuLocalThongBao === PHIEN_BAN_THONG_BAO) {
  //       isChuaHienThiThongBao = false;
  //     }

  //     // isChuaHienThiThongBao = !isChuaHienThiThongBao;

  //     // isTrongTuanCuoiCungCuaThang = !isTrongTuanCuoiCungCuaThang
  //     // Neu danh gia chua du mentor - giang vien thi se tiep tuc popup
  //     if (isDanhGiaChuaDuSoLuong && isTrongTuanCuoiCungCuaThang) {
  //       // Chưa hiện thông báo thì popup thông báo thay vì popup đánh giá
  //       // Tắt thông báo theo yêu cầu thầy
  //       isChuaHienThiThongBao = false
  //       if (isChuaHienThiThongBao) {
  //         dispatchLocal({
  //           type: CONSTANTS.SET_MODAL_THONG_BAO_VISIBLE,
  //           payload: true,
  //         });
  //       } else {
  //         dispatchLocal({ type: CONSTANTS.SET_MODAL_VISIBLE, payload: true });
  //       }
  //     }
  //   }
  //   return () => {
  //     isMount = false;
  //   };
  // }, [dataRoadMap.thongTinLopHoc?.danhSachMT_GV.length, dsDanhGia.length]);

  useEffect(() => {
    //loc danh gia theo lop
    const lstDanhGiaTheoLop = dsDanhGia.filter(
      (n) => n.maLop == id && n.maNguoiDanhGia == thongTinDangNhap.id
    );

    if (lstDanhGiaTheoLop.length > 0) {
      let lstTemp = [];
      lstDanhGiaTheoLop.map((item) => {
        let temp = { ...item, noiDungDanhGia: JSON.parse(item.noiDungDanhGia) };
        lstTemp.push(temp);
      });

      setLstDanhGia(lstTemp);
    }
  }, [dsDanhGia.length]);

  function handleLuuNhanXetChiTietDanhGia() {
    // Lưu đánh gía
    let checkDanhGia = lstDanhGia.find(
      (n) => n.mentorId == state.mentorIdDangChon
    );

    if (checkDanhGia) {
      checkDanhGia.noiDungDanhGia[state.indexDanhMucDangChon].NhanXet =
        state.nhanXetDangChon;
    }

    // kiem tra xem danh gia du so luong mentor gv trong lop thi khong confirm khi nhan nut gui
    // if (lstDanhGia.length >= dataRoadMap.thongTinLopHoc?.danhSachMT_GV.length) {
    //   dispatchLocal({ type: CONSTANTS.INPUT_IS_DANH_GIA_DU });
    // }

    // Cập nhật xong rồi thì xử lý reset lại các thông tin
    dispatchLocal({ type: CONSTANTS.LUU_NHAN_XET });
  }

  function handleSuaDanhGia(index, mentorId) {
    let checkDanhGia = lstDanhGia.find((n) => n.mentorId == mentorId);
    let nhanXet = "";
    if (checkDanhGia) {
      // Nếu đã được đánh giá
      nhanXet = checkDanhGia?.noiDungDanhGia[index]?.NhanXet;
    }

    dispatchLocal({
      type: CONSTANTS.SUA_NHAN_XET,
      payload: {
        indexDanhMuc: index,
        mentorId: mentorId,
        nhanXet,
      },
    });
  }

  function changeDanhGia(value, index, mentorId) {
    // console.log(state)
    let nhanXet = "";

    let checkDanhGia = lstDanhGia.find((n) => n.mentorId == mentorId);
    if (checkDanhGia) {
      // Nếu đã được đánh giá
      checkDanhGia.noiDungDanhGia[index].DiemDanhGia = value;

      nhanXet = checkDanhGia.noiDungDanhGia[index].NhanXet;
    } else {
      let noiDungDanhGia = [];

      danhMucDanhGia.map((n) => {
        noiDungDanhGia.push({
          DiemDanhGia: 0,
          NhanXet: "",
        });
      });

      noiDungDanhGia[index].DiemDanhGia = value;

      let model = {
        mentorId,
        maLop: id,
        noiDung: "",
        noiDungDanhGia,
        maNguoiDanhGia: thongTinDangNhap.id,
      };

      setLstDanhGia([...lstDanhGia, model]);

      nhanXet = model.noiDungDanhGia.NhanXet;
    }

    dispatchLocal({
      type: CONSTANTS.CHON_DANH_GIA,
      payload: {
        indexDanhMuc: index,
        mentorId: mentorId,
        nhanXet,
      },
    });
  }

  function handleDongThongBao() {
    // Đóng thông báo thì lưu local để không popup thông báo nữa
    localStorage.setItem("THONG_BAO_TICH_CUC", PHIEN_BAN_THONG_BAO);
    // Lưu xong thì kiểm tra xem nó có cần mở popup đánh giá hay không
    dispatchLocal({ type: CONSTANTS.DONG_THONG_BAO });
  }

  const luuDanhGia = () => {
    if (lstDanhGia.length > 0) {
      lstDanhGia.map((item) => {
        let data = {
          ...item,
          noiDungDanhGia: JSON.stringify(item.noiDungDanhGia),
        };
        NguoiDungSevice.luuDanhGiaServiceV2(data)
          .then((res) => {
            message.success("Gửi thành công !");
            document
              .querySelector("#thongBaoDanhGia")
              .removeAttribute("hidden");
          })
          .catch((err) => message.error("Đã có lỗi xảy ra !"));
      });
    }
  };

  return (
    <>
      <span className=" text-sm" onClick={showModal}>
        Đánh giá
      </span>
      <Modal
        visible={state.isNhanXetModalVisible}
        title={`Đánh giá chi tiết ${
          danhMucDanhGia[state.indexDanhMucDangChon]
        }`}
        onCancel={handleLuuNhanXetChiTietDanhGia}
        footer={null}
        width="90vw"
        height="90vh"
        style={{ top: 10 }}
      >
        <p className="mt-5 text-primary italic">
          Lưu ý: Mọi đánh giá của bạn sẽ được lưu dưới dạng ẩn danh (sẽ không ai
          biết bạn đã đánh giá họ)
        </p>
        <TextArea
          placeholder="Hãy để lại vài dòng suy nghĩ của bạn nhé !"
          autoSize={{ minRows: 3 }}
          onChange={(e) =>
            dispatchLocal({
              type: CONSTANTS.INPUT_NHAN_XET,
              payload: e.target.value,
            })
          }
          defaultValue={""}
          value={state.nhanXetDangChon}
        />
        <div className="flex justify-center pt-5">
          <button
            className="btn btn-primary"
            onClick={handleLuuNhanXetChiTietDanhGia}
          >
            Lưu
          </button>
        </div>
      </Modal>
      <Modal
        visible={state.modalThongBaoVisible}
        title={``}
        onCancel={() => handleDongThongBao()}
        footer={null}
        width="50vw"
        height="90vh"
        centered={true}
        style={{ top: 10, minWidth: "800px" }}
      >
        <h2 className="text-center text-3xl">Thông báo</h2>
        <p>
          Để tri ân những đóng góp của các bạn khi đánh giá Giảng viên - Mentor
          thì Trung tâm sẽ tặng{" "}
          <span className="text-blue-500">điểm tích cực</span> cho các bạn.
        </p>
        <ul className="list-disc list-outside pl-8">
          <li>
            Để nhận được điểm tích cực thì bạn cần phải đánh giá{" "}
            <span className="text-red-500">tất cả</span> Giảng viên - Mentor
            trong lớp.
          </li>
          <li>
            Điểm tích cực nhận được khi đánh giá sẽ không quá{" "}
            <span className="text-red-500">100 điểm / tháng</span> . Hệ thống sẽ
            kiểm tra nếu có bài tập đủ điều kiện để sử dụng <br /> điểm tích cực
            sẽ <span className="text-red-500">tự động</span> hiển thị nút sử
            dụng.
          </li>
          <li>
            {" "}
            Điểm tích cực chỉ được sử dụng trong{" "}
            <span className="text-red-500">lớp đang học</span> và không thể
            chuyển hoặc sử dụng gia hạn bài tập cho lớp khác.
          </li>
          <li>
            {" "}
            Nếu bạn chưa đánh giá
            <span className="text-red-500"> đủ số lượng</span> Giảng viên -
            Mentor thì hệ thống sẽ tự hiển thị mục đánh giá vào{" "}
            <span className="text-red-500">tuần cuối cùng</span> của tháng{" "}
            <br />
            để nhắc nhở bạn đánh giá.
          </li>
          <li>
            {" "}
            Mỗi bài tập chỉ được gia hạn{" "}
            <span className="text-red-500"> 01 (một) lần</span> và trừ{" "}
            <span className="text-red-500">100 điểm tích cực</span> , trường hợp
            gia hạn trùng bài tập thì sẽ{" "}
            <span className="text-red-500">không bị trừ</span> điểm tích cực.
          </li>
          <li>
            {" "}
            Điểm tích cực chỉ dùng để gia hạn bài tập{" "}
            <span className="text-red-500"> tuần / trắc nghiệm</span> (không
            được dùng cho{" "}
            <span className="text-red-500">
              dự án Capstone và dự án cuối khoá
            </span>
            )
          </li>
          <li>
            {" "}
            Bài tập chỉ được gia hạn khi đã{" "}
            <span className="text-red-500">hết hạn 01 (một) ngày</span> . Mỗi
            lần gia hạn chỉ được gia hạn thêm{" "}
            <span className="text-red-500">01 (một) ngày</span> .
          </li>
        </ul>
        <p>
          VD: Bài tập A hết hạn ngày{" "}
          <span className="text-red-500">26/04/2023</span> , ngày{" "}
          <span className="text-red-500">27/04/2023</span> nếu bạn có 100 điểm
          tích cực thì hệ thống sẽ hiện nút "Sử dụng" <br /> để bạn gia hạn bài
          tập. Nếu ngày 27/04/2023 bạn quên không gia hạn thì sẽ không thể sử
          dụng điểm để gia hạn cho bài tập này. Nếu bạn <br /> có gia hạn thì
          hạn nộp sẽ thêm 1 ngày là 27/04/2023. Hết ngày 27/04/2023, bạn sẽ
          không thể gia hạn thêm.
        </p>
      </Modal>
      <Modal
        visible={state.visible}
        title="Bạn hãy đánh giá cho giảng viên - mentor nhé"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="90vw"
        height="90vh"
        style={{ top: 10 }}
      >
        <Tabs style={{ width: 600 }}>
          {dataRoadMap.thongTinLopHoc?.danhSachMT_GV.filter(x => x.mentorId !== thongTinDangNhap.id).map((item) => {
            let danhGiaTheoMentor = lstDanhGia.find(
              (n) => n.mentorId == item.mentorId
            );

            let noiDungDanhGia = [];

            if (danhGiaTheoMentor) {
              //Mentor trong tháng đã được đánh giá
              noiDungDanhGia = danhGiaTheoMentor.noiDungDanhGia;
            }

            // console.log(noiDungDanhGia)
            return (
              <TabPane tab={item.hoTen} key={item.mentorId}>
                <ul className="list-group">
                  {danhMucDanhGia.map((danhMuc, index) => {
                    let danhGiaValue = 0;
                    let nhanXet = "";
                    if (noiDungDanhGia.length > 0) {
                      // Có đánh giá
                      danhGiaValue = noiDungDanhGia[index]?.DiemDanhGia;
                      nhanXet = noiDungDanhGia[index]?.NhanXet;
                    }

                    return (
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <b>{danhMuc}</b>
                        <div>
                          <span className="badge badge-secondary badge-pill">
                            <Rate
                              defaultValue={danhGiaValue}
                              tooltips={desc}
                              onChange={(value) => {
                                changeDanhGia(value, index, item.mentorId);
                              }}
                            />
                          </span>

                          {nhanXet != "" ? (
                            <button
                              className="btn btn-primary ml-2"
                              onClick={() =>
                                handleSuaDanhGia(index, item.mentorId)
                              }
                            >
                              Sửa nhận xét
                            </button>
                          ) : null}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </TabPane>
            );
          })}
        </Tabs>
        <p id="thongBaoDanhGia" hidden className="pt-5">
          <i className="text-danger">
            {" "}
            Cảm ơn bạn đã đánh giá ! Đây sẽ là dữ liệu để trung tâm nhận xét và
            nâng cao chất lượng của đội ngũ.
            <br /> Bạn vẫn có thể gửi lại đánh giá của mình trong tháng !{" "}
          </i>
        </p>
        <div className="flex justify-center pt-5">
          {state?.isDanhGiaDu ? (
            <button className="btn btn-primary" onClick={luuDanhGia}>
              Gửi
            </button>
          ) : (
            <Popconfirm
              title="Bạn chỉ được nhận điểm tích cực chỉ khi đánh giá tất cả Giảng viên - Mentor, bạn có chắc muốn gửi không ?"
              onConfirm={luuDanhGia}
            >
              <button className="btn btn-primary">Gửi</button>
            </Popconfirm>
          )}
        </div>
      </Modal>
    </>
  );
}
