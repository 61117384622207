import { Popover } from "antd";
import React from "react";
import { renderThongBao } from "./../../../utilities/roadmap";

export default function ThongBaoMobile(props) {
  const renderThongBaoHocVien = () => {
    if (props.thongBao?.length == 0) {
      return <p className="p-2">Bạn không có thông báo nào trong hôm nay</p>;
    }
    return (
      <div className="h-52 overflow-y-scroll">
        {props.thongBao?.map((item, index) => {
          return (
            <div key={index} className=" p-2 my-1 card_theme">
              <div className=" text-gray-700 m-0 flex items-center">
                {renderThongBao(item)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div>
      <Popover
        className="outline-none"
        trigger="click"
        title={<p className="text-lg text-black mb-0">Thông báo</p>}
        placement="bottomRight"
        content={renderThongBaoHocVien()}
      >
        {props.children}
      </Popover>
    </div>
  );
}
