import React, { useEffect, useState } from "react";
import "./ChungNhanPDF.css";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import ContentChungNhan from "./ContentChungNhan";
import { useSelector } from "react-redux";
import { Popover, Modal, Tooltip } from "antd";
import ModalShowChungNhan from "./modalShowChungNhan/ModalShowChungNhan";
import ContentModalChungNhan from "./contentModalChungNhan/ContentModalChungNhan";
import { DOMAIN_MAIN } from "../../../../config/urlConfig";
import { useParams } from "react-router-dom";


export default function ChungNhanPDF({ dataRoadMap }) {

  const [openModal, setOpenModal] = useState(false);
  let { id } = useParams();

  let chungNhan = useSelector((state) => {
    return state.roadmapReducer?.dataRoadMap?.thongTinChungNhanHocVien;
  });

  const handleCancel = () => {
    setOpenModal(false);
  };
  function encodeObject(obj) {
    // Encode the object as a UTF-8 string
    const encoded = unescape(encodeURIComponent(JSON.stringify(obj)));
    // Encode the UTF-8 string as a base64-encoded string
    return btoa(encoded);
  }

  // console.log(encodeObject({
  //   "hoTen": "Tiết Trung Bang",
  //   "tenLop": "Front-End 68",
  //   "tenLoTrinh": "PROFESSIONAL FRONT-END JS DEVELOPER",
  //   "diemCuoiKhoa": "9.0",
  //   "isXuatSac": true,
  //   "maChungNhan": "FE",
  //   "isInChungNhan": false,
  //   "maSoChungNhan": 1132,
  //   "soThangHoc": "6",
  //   "ngayCapChungNhan": "2023-01-28T03:00:22",
  //   "fullMaChungNhan": "FE/2023/20231132",
  //   "maThongTinHocTap": 2641
  // }))


  return (
    <>
      <button
        className="text-white btn-theme transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300 mx-2"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {!chungNhan?.fullMaChungNhan ? (
          <Popover
            placement="top"
            content={"Bạn chưa đủ điều kiện để xem chứng nhận"}
            trigger="click"
          >
            Xem chứng nhận
          </Popover>
        ) : (


          <a href={`${DOMAIN_MAIN}/certification/?inf=${encodeObject(
            chungNhan
          )}`}
            className="text-white"
            target="_blank"
          >
            Xem chứng nhận
          </a>

        )
        }
      </button >

      {chungNhan && chungNhan.diemCuoiKhoa >= 9 &&
        <Tooltip title={<span className="text-dark">Chỉ dành cho học viên có tổng điểm cuối khóa trên 9</span>} placement="bottom">
          <a href={`${DOMAIN_MAIN}/honors/?inf=${encodeObject(
            { ...chungNhan, ml: id, ngayHoc: dataRoadMap.thongTinLopHoc.ngayBatDau }
          )}`

          }
            className="text-white btn-danger transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300 mx-2"
            target="_blank"
          >
            Vinh danh <i class="fa-brands fa-hotjar"></i>
          </a>
        </Tooltip>
      }
    </>
  );
}

// <span

//   className="text-white"
//   target="_blank"
//   onClick={() => {
//     setOpenModal(true);
//   }}
// >
//   Xem chứng nhận
// </span>

//  <Modal
//         title="Chứng nhận"
//         visible={openModal}
//         onCancel={handleCancel}
//         footer={null}
//         width="90vw"
//         style={{ top: 10 }}
//       >
//         <div style={{ width: "90vw", paddingBottom: "30px" }}>
//           {
//             <ContentModalChungNhan chungNhan={chungNhan}/>
//           }
//         </div>
//       </Modal> 

//  <PDFDownloadLink
//   document={<ContentChungNhan chungNhan={chungNhan} />}
//   fileName="src/static/Cybersoft.pdf"
// >
//   Xem chứng nhận
// </PDFDownloadLink>

// <>
//   <PDFViewer width="100%" height="100%">
//     <ContentChungNhan chungNhan={chungNhan} />
//   </PDFViewer>
//   <PDFDownloadLink
//     document={<ContentChungNhan chungNhan={chungNhan} />}
//     fileName="Cybersoft.pdf"
//   >
//     {({ blob, url, loading, error }) =>
//       loading ? (
//         "Loading document..."
//       ) : (
//         <p className="hover:text-white p-0 m-0 text-white ">
//           Xem chứng nhận
//         </p>
//       )
//     }
//   </PDFDownloadLink>
// </>