import { message, Tag, Tooltip, Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { layUserCV } from "../../../redux/actions/nguoiDungAction";
import { NguoiDungSevice } from "../../../service/nguoiDungService";
import { LocalStorageUtil } from "../../../utilities/localStorage";

const { Option } = Select;

export const Skill_V2 = ({
  danhSachSkill,
  handleUpdate,
  title,
  listOption = [],
}) => {
  const newOptions = listOption?.map((item) => (
    <Option key={item}>{item}</Option>
  ));


  return (
    <div className="resume_item resume_language">
      <div className="title">
        <p className="bold">{title} </p>
        <Select
          mode="tags"
          value={danhSachSkill}
          style={{ width: "100%" }}
          placeholder="Bạn hãy thêm thông tin vào nhé"
          onChange={handleUpdate}
        >
          {newOptions}
        </Select>
      </div>
    </div>
  );
};
