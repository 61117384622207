import { Modal } from "antd";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { FileSevice } from "../../../service/fileService";

export default function Button_HuongDanHocTap() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [source, setSource] = useState("");
  const showModal = () => {
    setIsModalVisible(true);

    // FileSevice.layDuongDanVideoDigitalService("special.mp4")
    //   .then((res) => {
    //     // dispatch(setSourceVideoAction(res.data));
    //     setSource(res.data);
    //   })
    //   .catch((err) => console.log(err.response));

    FileSevice.layDanhSachCauHinhService().then(res => {
      let link = res.data.content.find(n => n.id == "HD_HOC");
      setSource(link.noiDung);

    })
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    stopVideo();
    setIsModalVisible(false);
  };

  const youtubeLinkToEmbed = (link) => {
    var videoId = link.split("v=")[1];
    var ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition != -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    return videoId;
  }
  const stopVideo = () => {
    var iframe = document.getElementById("huongdan");
    var src = iframe.src;
    iframe.src = src;
  }
  return (
    <>
      <Modal
        title="Video hướng dẫn học tập"
        width="70vw"
        height="90vh"
        style={{ top: 10 }}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div style={{ minWidth: "40vw", minHeight: "40vh" }} className="w-full">
          { }
          {source && <iframe id="huongdan" width="560" height="315" src={`https://www.youtube.com/embed/${youtubeLinkToEmbed(source)}`} frameborder="0" allowfullscreen="true"></iframe>}

          {/* {source && <ReactPlayer
            playbackRate={1}
            playing={false}
            url={source}
            width="100%"
            height="100%"
            controls={true}
            config={{
              file: {
                forceHLS: true,
              },
            }}
          />
          } */}
        </div>
      </Modal>
      <div className=" cursor-pointer hover:text-yellow-300">
        <button
          onClick={showModal}
          className="text-xs text-blue-theme pl-0 focus:outline-none"
        >
          {" "}
          <i className="fa fa-file mr-2"></i> Hướng dẫn học tập
        </button>
      </div>
    </>
  );
}
