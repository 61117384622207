import React, { Component } from 'react';
import { Tag, Input, Button } from 'antd';

import { Link, NavLink } from "react-router-dom";
import { toAliasString } from '../../../utilities/stringFormat';
import { LocalStorageUtil } from '../../../utilities/localStorage';
import { BaiTapNopModel } from '../../../model';
import * as actions from '../../../redux/actions/baiTapActions';
import { connect } from 'react-redux';
import { DOMAIN_API, DOMAIN_API_FILE } from '../../../config/urlConfig';
import Axios from 'axios';
import swal from 'sweetalert2'
const FileDownload = require('js-file-download');

var ReactDOMServer = require('react-dom/server');
var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParser = new HtmlToReactParser();


class BaiTap extends Component {

    state = {
        viTri: -1,
        noiDung: '',
        googleLink: '',
        trangThai: false
    }
    // shouldComponentUpdate(){
    //     return false;
    // }
    componentDidMount = () => {
        this.setState({ trangThai: false });
    }

    static getDerivedStateFromProps(props, state) {
        const { dsBaiTap } = props.baiTapProps;
        if (dsBaiTap.length > 0) {
            const baiTapNop = dsBaiTap[dsBaiTap.length - 1].baiTapNop;
            return {
                ...state,
                noiDung: baiTapNop ? baiTapNop.noiDung : ''
            }
        }
        return null;
    }

    onChange = (e) => {
        const { value } = e.target;
        this.setState({ googleLink: value });
    }

    onMoFormNopBaiTap = (index) => {
        const { noiDung } = this.state;
        if (noiDung.length > 0) {
            this.setState({ viTri: index, trangThai: false });
        }
        else {
            this.setState({ viTri: index, trangThai: true });
        }
    }

    onNopBai = () => {
        const { maLopHoc, khoaHocProps } = this.props;
        const { viTri, googleLink } = this.state;
        const { dsBaiTap } = this.props.khoaHocProps;
        const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  
        let baiTap = dsBaiTap[viTri];
 
        const biDanh = toAliasString(baiTap.tenBaiTap);
        const baiTapNop = new BaiTapNopModel(0, baiTap.tenBaiTap,
            biDanh, googleLink, maLopHoc, baiTap.id, nguoiDung.id, nguoiDung.hoTen ? '' : nguoiDung.hoTen);
   
        this.props.dispatch(actions.nopBaiTapAction(baiTapNop));
        this.setState({ viTri: -1, trangThai: false });


    }

    onNopLaiBai = (baiTapDaNop) => {
        const { maLopHoc, khoaHocProps } = this.props;
        const { viTri, googleLink } = this.state;
        const { dsBaiTap } = this.props.khoaHocProps;
        // console.log("khoaHocProps",khoaHocProps);
        // console.log("dsBaiTap",dsBaiTap);


        const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

        const baiTap = dsBaiTap[viTri];
        const biDanh = toAliasString(baiTap.tenBaiTap);
        const baiTapNop = new BaiTapNopModel(baiTapDaNop.id, baiTap.tenBaiTap,
            biDanh, googleLink, maLopHoc, baiTap.id, nguoiDung.id, nguoiDung.hoTen);
 
        this.props.dispatch(actions.nopLaiBaiTap(baiTapDaNop.id, baiTapNop, nguoiDung.hoTen));
        this.setState({ viTri: -1, trangThai: false });

    }

    onSuaBai = () => {
        this.setState({ trangThai: true });
    }

    onHuySuaBai = () => {
        this.setState({ trangThai: false });
    }

    renderNoiDung = (baiTapNop) => {
        const { trangThai, noiDung } = this.state;

        if (noiDung.length === 0) {
            return <div className="nop-bai">
                <Input onChange={this.onChange} placeholder="Link google drive" />
                <Button onClick={this.onNopBai} type="primary">Gửi</Button>
            </div>
        }
        else if (!trangThai) {
            return <div className="nop-bai">
                <b>Link: </b>
                <span><a href={noiDung} target="_blank">{noiDung}</a></span>
                <Button onClick={this.onSuaBai} type="primary">Sửa</Button>
            </div>
        }
        else if (trangThai) {
            return <div className="nop-bai">
                <b>Link: </b>
                <Input onChange={this.onChange} placeholder="Link google drive" />
                <div className="action">
                    <Button onClick={this.onHuySuaBai}>Đóng</Button>
                    <Button onClick={() => this.onNopLaiBai(baiTapNop)} type="primary">Gửi</Button>
                </div>
            </div>
        }
        else {
            return null;
        }
    }

    renderBaiTap = () => {
        // khoaHocProps: state.khoaHocReducer,
        const { dsBaiTap } = this.props.khoaHocProps;

        const { viTri } = this.state;

        return dsBaiTap.filter(n => n.taiLieu?.toString().toLowerCase() !== 'true').map((item, index) => {

            let lengthGhiChu = item.ghiChu.length;
            let ghiChu = item.ghiChu.length >= 100 ? item.ghiChu.substr(0, 100) + '...' : item.ghiChu;
            item.tenBaiTap = item.tenBaiTap.length >= 50 ? item.tenBaiTap.substr(0, 50) + "..." : item.tenBaiTap;

            let reactElement = htmlToReactParser.parse(ghiChu);

            // let reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

            return <div className="col-4" key={index}> <div className="item  mt-2 ml-2" >
                <div className="bai-tap-item" style={{ flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '17px', maxHeight: 100, minHeight: 100 }}>{index + 1}. {item.tenBaiTap} - (Hạn nộp {Number(item.hanNop) < 0 ? <span>0</span> : <span>{item.hanNop}</span>} ngày)</span>
                        <div className="container" >
                            {reactElement} {lengthGhiChu >= 100 ? <span style={{ color: '#fff', cursor: 'pointer', fontWeight: 'bold' }} onClick={() => {
                                swal.fire(item.title, item.ghiChu, 'info')
                            }}> ... xem thêm</span> : ''}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>


                        <div className="item-action btn-nopbai" >
                            <div>
                                {
                                    Number(item.hanNop) < 0 ? <Tag color="red">Hết hạn nộp</Tag> : <a onClick={() => this.onMoFormNopBaiTap(index)} title="Nộp bài tập">
                                        <i className="fa fa-edit"></i>
                                        <span>Nộp bài</span>
                                    </a>
                                }
                            </div>
                            {
                                //  item.noiDung.trim() !=='' ? <a href={`${DOMAIN_API_FILE}/${item.noiDung}`}><i className="fa fa-download"></i> Tải về</a>:''
                                item.noiDung.trim() !== '' ? <a href={`${DOMAIN_API}/files/${item.noiDung}`} className="btn btn-link btnTaiBaiTap" target="_blank" download={item.noiDung} >
                                    <i className="fa fa-download"></i>
                                    <span>Tải bài tập</span>
                                </a> : ''
                                //   item.noiDung.trim() !=='' ? <button className="btn btn-link btnTaiBaiTap" onClick={()=>{

                                //         Axios(
                                //             {
                                //                 url:`${DOMAIN_API}/api/file/downloadfile`,
                                //                 data:{pathFile:item.noiDung},
                                //                 method:'post',
                                //                 responseType: 'blob', // important
                                //             }
                                //         ).then((response) => {
                                //             console.log(response)
                                //             FileDownload(response.data,item.noiDung);
                                //         })
                                //     }} title="Tải bài tập" download>
                                //         <i className="fa fa-download"></i>
                                //         <span>Tải bài tập</span>
                                //     </button>

                            }
                            {/* <button onClick={()=>{
                                    Axios(
                                        {
                                            url:'https://localhost:5001/api/file/DownloadFile?pathFile=3-sadsada.xlsx',
                                            method:'post'
                                        }
                                    ).then((response) => {
                                        FileDownload(response.data, 'report.xlsx');
                                    })
                                }} title="Tải bài tập" target="_blank" download>
                                    <i className="fa fa-download"></i>
                                    <span>Bài tập</span>
                                </button>: <p></p> */}
                        </div>
                    </div>

                </div>

                {
                    viTri === index ? this.renderNoiDung(item.baiTapNop) : null
                }
            </div>
            </div>
        })
    }

    render() {
        return (
            <div className="bai-tap">
                <div className="bai-tap-list row">
                    {
                        this.renderBaiTap()
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        khoaHocProps: state.khoaHocReducer,
        baiTapProps: state.baiTapReducer
    }
}

export default connect(mapStateToProps, null)(BaiTap);
  // <button onClick={()=>{
                                //     Axios(
                                //         {
                                //             url:'https://localhost:5001/api/file/DownloadFile?pathFile=3-sadsada.xlsx',
                                //             method:'post'
                                //         }
                                //     ).then((response) => {
                                //         FileDownload(response.data, 'report.xlsx');
                                //     })
                                // }} title="Tải bài tập" target="_blank" download>
                                //     <i className="fa fa-download"></i>
                                //     <span>Bài tập</span>
                                // </button>: <p></p>