import Lottie from "lottie-react";
import React from "react";
import notfound from "./68395-data-not-found.json";
export default function NotFoundViewMobile() {
  return (
    <div>
      <div className="w-full md:w-4/6 mx-auto">
        <Lottie
          loop={true}
          animationData={notfound}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <p className="text-gray-900 text-xl text-center w-2/3 mx-auto">
        Hết hạn truy cập tài nguyên, vui lòng quay lại trang chủ và chọn lớp
        khác
      </p>
    </div>
  );
}
