import { BaseService } from "./baseService";


const API_URL = "/api/followUser";

const checkFollow = (userId) => {

  return BaseService.post(`${API_URL}/check-follow/${userId}`);
};
const dsFollow = (userId,trangThai) => {
  return BaseService.get(`${API_URL}/dsFollow/${userId}/${trangThai}`);
};
const follow = (userId,status) => {
  return BaseService.post(`${API_URL}/follow-user/${userId}/${status}`);
};


export const FollowSevice = {
    checkFollow,
    dsFollow,
    follow
};
