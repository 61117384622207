import React from "react";
import { Modal } from "antd";
import "./modal.css";
import EmtyComponent from "./EmtyComponent";
export default function ModalRoadMap({
  ContentComponent,
  title,
  isModalVisible,
  setIsModalVisible,
  setContentModal,
  onCloseModal = () => {},
}) {
  let topPosition =
    title.toString().toLowerCase().includes("video") ||
    title.toString().toLowerCase().includes("trắc nghiệm") ||
    title.toString().toLowerCase().includes("liên quan")
      ? "10px"
      : "";
  return (
    <Modal
      title={<p className="p-0 m-0">{title}</p>}
      className="rounded-xl p-0  felx flex-col items-center "
      visible={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
        setContentModal(EmtyComponent);
        onCloseModal();
      }}
      footer={null}
      style={{ top: topPosition }}
      zIndex={"500"}
    >
      <div className="w-max">{ContentComponent} </div>
    </Modal>
  );
}
