import React, { Component, useEffect } from "react";

import { useParams } from "react-router-dom";

import SideBar from "../../template/sidebarTemplate/SideBarTemplate";
import Navbar from "../../template/navbar/Navbar";
import UserDashBoard from "../roadmapPage/UserDashboard";
import ThongKeLoTrinh from "../roadmapPage/ThongKeLoTrinh";
import backGrountTemplate from "../../assets/images/background.png";
import ListDiem from "./ListDiem";
import { useDispatch, useSelector } from "react-redux";

import SpinnerComponent from "../../component/Spinner";
import { getDataRoadMapServAction } from "../../redux/actions/roadMapAction";
import SidebarNew from "../../template/SidebarNew/sidebarNew";
import Tab_Diem_RecordXemLai from "./Tab_Diem_RecordXemLai/Tab_Diem_RecordXemLai";

export default function RoadmapDiemChungNhanPage() {
  const { id } = useParams();
  let { dataRoadMap } = useSelector((state) => state.roadmapReducer);

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataRoadMapServAction(id));
  }, [id]);
  return (
    <div className="h-max-content min-h-screen w-full  flex">
      <div className="w-60">
        <SidebarNew />
      </div>
      <div
        style={{ flexGrow: "1" }}
        className="h-max-cotent flex flex-col  min-h-screen "
      >
        <Navbar />
        <div
          style={{ background: `url(${backGrountTemplate})`, flexGrow: "1" }}
          className=" w-full bg-cover p-3 space-y-2 chung-nhan "
        >
          <SpinnerComponent />
          <UserDashBoard dataRoadMap={dataRoadMap} />
          {/* <ThongKeLoTrinh dataRoadMap={dataRoadMap} /> */}
          <Tab_Diem_RecordXemLai dataRoadMap={dataRoadMap} />
        </div>
      </div>
    </div>
  );
}
