import axios from 'axios';
import { API_URL_DOMAIN_ONLINE } from "../config/urlConfig";


var token ='';
if(localStorage.getItem('USER_LOGIN')){
    let user = JSON.parse(localStorage.getItem('USER_LOGIN'));
    // token=user.token;
}

export const get = (url, ...params) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        params: params,
        method:'GET',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"UPD124yRTWF124QJFweUaCYSECETBERS",
            'Authorization': 'Bearer '+token,
            'Content-Type': 'application/json'
        }
    })
}

export const post = (url, data) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'POST',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"UPD124yRTWF124QJFweUaCYSECETBERS",
            'Authorization': 'Bearer '+token,

            'Content-Type': 'application/json'
        },
        // timeout: 1000,
        data: JSON.stringify(data)
        
    })
}

export const put = (url, data) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'PUT',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"UPD124yRTWF124QJFweUaCYSECETBERS",
            'Authorization': 'Bearer '+token,

            'Content-Type': 'application/json'
        },
        // timeout: 1000,
        data: JSON.stringify(data)
    })
}

export const remove = (url, ids) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'DELETE',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"UPD124yRTWF124QJFweUaCYSECETBERS",
            'Authorization': 'Bearer '+token,

             'Content-Type': 'application/json'
        },
        data: JSON.stringify(ids)
        // timeout: 1000
    })
}

export const postForm = (url, data) => {
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'POST',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"UPD124yRTWF124QJFweUaCYSECETBERS",
            'Authorization': 'Bearer '+token,

            'Content-Type': 'application/json'
        },
        // timeout: 1000,
        data: data
        
    })
}

export const putForm = (url, data) => {
    // console.log(url,"url","data",data)
    return axios({
        baseURL: API_URL_DOMAIN_ONLINE,
        url: url,
        method:'POST',
        responseType: 'json',
        responseEncoding: 'utf8',
        headers: {
            'apikey':"UPD124yRTWF124QJFweUaCYSECETBERS",
            'Authorization': 'Bearer '+token,

            'Content-Type': 'application/json'
        },
        // timeout: 1000,
        data: data
        
    })
}

export const BaseApiOnline = {
    get,
    post,
    put,
    remove,
    postForm,
    putForm
}