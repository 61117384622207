import React from "react";
import ReactApexChart from "react-apexcharts";

const configChart = {
  series: [],
  options: {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: [],
    // max-width
    responsive: [
      {
        breakpoint: 3000,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1280,
        options: {
          chart: {
            width: 340,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  },
};
export default function ChartLoTrinh({ dataRoadMap }) {
  configChart.series = dataRoadMap?.danhSachBuoiHocTheoSkill?.map((item) => {
    return item.trongSo;
  });

  configChart.options.labels = dataRoadMap?.danhSachBuoiHocTheoSkill?.map(
    (item) => {
      return item.tenSkill;
    }
  );
  return (
    <div className=" flex flex-col w-full items-center space-y-1 justify-center h-full relative">
      <p className=" font-medium px-5">Thống kê lộ trình</p>

      {dataRoadMap?.danhSachBuoiHocTheoSkill?.length > 0 && (
        <ReactApexChart
          options={configChart.options}
          series={configChart.series}
          type="pie"
          width={400}
        />
      )}
    </div>
  );
}
