import React from "react";
import "./Flash_sale.css";
import { useState } from "react";
export default function Flash_sale() {
  const [isOpenFlashSale, setIsOpenFlashSale] = useState(true);
  // const rendererTimer = ({ days, hours, minutes, seconds }) => {
  //   return (
  //     <span>
  //       {/* {days} ngày {hours} giờ {minutes} phút {seconds} giây */}
  //       {days} ngày {hours} giờ {minutes} phút
  //     </span>
  //   );
  // };
  return (
    <div
      className={
        isOpenFlashSale
          ? "w-full h-20   transform duration-500 relative"
          : "h-0  transform duration-100 w-full overflow-hidden"
      }
    >
      {/* // start flash sale */}
      <div className="w-full h-full flex justify-center items-center text-black">
        <div
          style={{
            background:
              "linear-gradient(105.48deg, #f8de8e 1.36%, #ffc40a 100%)",
          }}
          className="w-full h-full relative flex justify-center overflow-hidden"
        >
          <div className=" ">
            <div className="absolute left-1/3 -translate-x-1/2  top-1/2 transform -translate-y-1/2  flash_sale_line_left h-3  w-96 ">
              <div className="w-6 h-60 bg-yellow-500 absolute bottom-48 -left-2"></div>
              <div className="w-3 h-60  bg-yellow-600 absolute bottom-12 -left-10 "></div>
            </div>

            <div className="h-full flex flex-col justify-center space-y-1 lg:space-y-1 bg-transparent relative z-10 ">
              <p className="text-color-title-theme text-center text font-medium text-sm lg:text-base xl:text-lg bg-transparent m-0 p-0">
                NHẬN TIỀN MẶT ĐẾN 800K KHI GIỚI THIỆU HỌC VIÊN MỚI
              </p>
              <div className="flex space-x-5 text-base text-center">
                <span>1-Dành cho khóa Bootcamp</span>
                <span>2-Đóng đợt 1 từ 12 triệu trở lên</span>
                <span>3-Áp dụng cho học viên đăng kí mới từ 10/5 trở đi</span>
                <button>
                  <a
                    target="_blank"
                    className="text-blue-theme  hover:text-red-500  font-medium underline"
                    href="https://www.facebook.com/lophocviet"
                  >
                    Inbox Fan Page hỗ trợ chi tiết
                  </a>
                </button>
              </div>
            </div>
            <div className="z-20 absolute right-1/3 top-1/2 transform -translate-y-1/2 translate-x-1/2   flash_sale_line_right   h-3 w-96">
              <div className="w-3 h-60 bg-yellow-500 absolute top-12 -right-2"></div>
              <div className="w-6 h-60  bg-yellow-600 absolute top-36 -right-12 "></div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}
      <button
        className="absolute right-10 top-1/2 transform -translate-y-1/2"
        onClick={() => {
          setIsOpenFlashSale(false);
        }}
      >
        <i className="fa fa-times text-white text-lg"></i>
      </button>
    </div>
  );
}
