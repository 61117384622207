// import { ConvertNameUser } from "./ConvertNameUser";
export const gerateDataSetChart = (arrData, canvas, userInfo) => {
  const ctx = canvas.getContext("2d");

  const gradient_bg_1 = ctx.createLinearGradient(0, 0, 0, 250);
  gradient_bg_1.addColorStop(0, "rgba(231, 18, 143, 1.0)");
  // A kind of blue for max.
  gradient_bg_1.addColorStop(0.7, "rgba(0, 173, 238, 1.0)");
  gradient_bg_1.addColorStop(1, "#EDD629");
  const arrColor = [
    gradient_bg_1,
    "rgb( 117, 95, 211,0.8)",
    "rgb( 70, 220, 216)",
    "rgb(106, 201, 119,0.9)",
    "rgb(253, 206, 0,0.8)",
  ];


    return [{
      label: "Thời gian online(phút)",
      fill: "start",
      backgroundColor: "transparent",
      borderColor: arrColor[1],
      pointBackgroundColor: arrColor[1],
      lineTension: 0.4,
      cubicInterpolationMode: "monotone",
      pointStyle: "circle",
      pointRadius: 2,
      pointHoverRadius: 6,
      borderWidth: 4,
      data: arrData,
      fontColor: "#ffffff",
    }]
  };

  export const generateOption = (statisArr) => ({
    // devicePixelRatio: 4,
    // fix chữ trên chart bị méo
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2, // Tỷ lệ chiều rộng và chiều cao
  
    title: {
      display: true,
      text: "Thống kê thời gian học",
      fontSize: 20,
    },
    legend: {
      position: "right",
      labels: {
        fontSize: 5,
      },
    },
    layout: {
      padding: 0,
      marggin: 0,
    },
    datasetStrokeWidth: 2,
    pointDotStrokeWidth: 4,
    scales: {
      xAxes: {
        display: true,
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "white",
        },
      },
      yAxes: {
        title: {},
        grid: {
          color: "white",
        },
        ticks: {
          min: 0, 
          beginAtZero: true,
          stepSize: 20, 
          padding: 10,
          fontSize: 14,
          color: "white",
        },
        suggestedMin: 0,
        suggestedMax: Math.max(statisArr[0]) + 20,
      },
    },
    plugins: {
      legend: {
        padding: 0,
        align: "end",
        labels: {
          usePointStyle: true,
          fontSize: 8,
          padding: 5,
          boxWidth: 3,
          pointStyle: "circle",
          color: "white",
        },
      },
    },
    interaction: {
      intersect: false,
    },
    radius: 0,
    tooltips: {
      yAlign: "bottom",
      xAlign: "bottom",
    },
  });
  export const generateOption2 = (statisArr) => ({
    responsive: true,
    maintainAspectRatio: false,
  
    title: {
      display: true,
      text: "THỐNG KÊ THỜI GIAN ONLINE",
      fontSize: 15,
      color: "white", // Đảm bảo tiêu đề cũng có màu trắng
    },
    legend: {
      position: "top",
      align: "end",
      labels: {
        usePointStyle: true,
        fontSize: 8,
        padding: 5,
        boxWidth: 3,
        pointStyle: "circle",
        color: "white",
      },
    },
    layout: {
      padding: 0,
      margin: 0,
    },
    datasetStrokeWidth: 2,
    pointDotStrokeWidth: 4,
    scales: {
      x: {  // Thay đổi từ xAxes thành x
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "white",
        },
      },
      y: {  // Thay đổi từ yAxes thành y
        grid: {
          color: "rgba(255, 255, 255, 0.1)",  // Làm mờ lưới để dễ nhìn hơn
        },
        ticks: {
          beginAtZero: true,
          stepSize: 20,
          padding: 10,
          font: {  // Thay đổi từ fontSize thành font object
            size: 14,
          },
          color: "white",
        },
        suggestedMin: 0,
        suggestedMax: Math.max(...statisArr) + 20,  // Sử dụng spread operator để lấy giá trị max
      },
    },
    plugins: {
      legend: {
        padding: 0,
        labels: {
          padding: 5,
          boxWidth: 3,
          color: "white",
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        position: 'nearest',  // Đảm bảo tooltip ở gần điểm dữ liệu nhất
        yAlign: 'bottom',
        xAlign: 'left',
        caretPadding: 10,
        caretSize: 0,  // Ẩn mũi tên của tooltip
        backgroundColor: 'rgba(0, 0, 0, 0.8)',  // Màu nền tối cho tooltip
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'white',
        borderWidth: 1,
        callbacks: {
          labelPointStyle: function() {
            return {
              pointStyle: 'circle',
              rotation: 0,
            };
          }
        }
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  });
  