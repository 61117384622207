import React from "react";
import XemLaiBuoiHoc from "./xem-lai/XemLaiBuoiHoc";

export default function ContentVideoXemLai({ data, maBuoiHoc,maLop }) {
  return (
    <div style={{ width: "90vw", height: "80vh" }} className="flex ">
      <div className=" flex-grow h-full flex items-center">
        <XemLaiBuoiHoc dsXemLai={data} maBuoiHoc={maBuoiHoc} maLop={maLop} />
      </div>
    </div>
  );
}
