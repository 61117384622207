import React, { Component } from "react";
import { LocalStorageUtil } from "../../../../../../utilities/localStorage";
import HLSSource from "./../../ItemRoadMap/ContentModal/ContentVideoXemLai/xem-lai/HLSSource";
import { RoadmapService } from "../../../../../../service/roadmapService";
import { Mark_Video } from "../../../../../roadmapPage/RoadMapToogleList/CollapseRoadMap/ItemRoadMap/ContentModal/utils/watermark";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import { FileSevice } from "../../../../../../service/fileService";

export default class ViewXemRecordThongTin extends Component {
  state = {
    source: "",
    vimeo: 0,
    youtube: "",
    drive: "",
  };

  //Khởi tạo biến chứa thời gian bắt đầu khi người dùng click vào, cái này không ảnh hưởng tới render nên không cần bỏ vào state
  thoiGianBatDau = "";
  maBaiHoc;

  handlePlayVideo = (item) => {
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    !nguoiDung.nuocNgoai &&
      FileSevice.layDuongDanRecordService(item.noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));
    // nước ngoài

    nguoiDung.nuocNgoai &&
      FileSevice.layDuongDanRecordDigitalService(item.noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));
  };

  handleSubmitTrackingXemVideoXemLai() {
    const { maLop, maBuoiHoc } = this.props;
    let thoiGianClickPlay = new Date();
    let dataSubmit = {
      maBuoi: maBuoiHoc,
      maBaiHoc: this.maBaiHoc,
      maLop: +maLop,
      thoiGianBatDau: "",
      thoiGianKetThuc: "",
    };

    if (this.thoiGianBatDau === "") {
      this.thoiGianBatDau = new Date();
    }

    if (this.thoiGianBatDau < thoiGianClickPlay) {
      dataSubmit.thoiGianBatDau = this.thoiGianBatDau;
      dataSubmit.thoiGianKetThuc = thoiGianClickPlay;
      RoadmapService.postTrackingXemVideoXemLai(dataSubmit);
      this.thoiGianBatDau = thoiGianClickPlay;
    }
  }

  componentDidMount() {
    const { dsXemLai } = this.props;
    this.maBaiHoc = dsXemLai?.id;
    this.handleSubmitTrackingXemVideoXemLai();
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    !nguoiDung.nuocNgoai &&
      dsXemLai?.noiDung &&
      FileSevice.layDuongDanRecordService(dsXemLai?.noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));
    // nước ngoài

    nguoiDung.nuocNgoai &&
      dsXemLai?.noiDung &&
      FileSevice.layDuongDanRecordDigitalService(dsXemLai?.noiDung)
        .then((res) => {
          this.setState({ source: res.data });
        })
        .catch((err) => console.log(err.response));

    // mark video
    this.myInterval = Mark_Video(nguoiDung);
  }
  componentWillUnmount() {
    clearInterval(this.myInterval);
    this.handleSubmitTrackingXemVideoXemLai();
  }
  render() {
    const { dsXemLai } = this.props;
    // console.log(dsXemLai);
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    return (
      <div className="flex h-full w-full space-x-3 ">
        <div className="flex-grow h-full flex items-center water-mark-wrapper">
          {nguoiDung.nuocNgoai ? (
            this.state.source && (
              <Player
                playsInline
                fluid={false}
                width={"100%"}
                height={"100%"}
                autoPlay={false}
              >
                <HLSSource isVideoChild src={this.state.source} />
                <ControlBar>
                  <PlaybackRateMenuButton
                    rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
                    order={10}
                  />
                </ControlBar>
              </Player>
            )
          ) : // <Vimeo video={this.state.vimeo} width="100%" height="500" />
          this.state.source ? (
            <Player
              playsInline
              fluid={false}
              width={"100%"}
              height={"100%"}
              autoPlay={false}
            >
              <HLSSource isVideoChild src={this.state.source} />
              <ControlBar>
                <PlaybackRateMenuButton
                  rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
                  order={10}
                />
              </ControlBar>
            </Player>
          ) : (
            ""
          )}
        </div>
        {/* <div
          className="list-group w-84 flex-shrink-0 overflow-y-scroll "
          id="list-tab"
          role="tablist"
        >
          {dsXemLai.map((item) => {
            let extraCss =
              item.id === this.state.activeId ? " bg-blue-theme " : "";
            return (
              <div
                className={
                  " cursor-pointer flex flex-col bg-blue-theme  justify-center h-max-content items-start  px-1 min-h-16   border-gray-200 border-b-1 w-full border-l-0 border-r-0  transform duration-300 text-white rounded py-2" +
                  extraCss
                }
              >
                <button
                  className=" items-center  flex  px-2  justify-center   p-1 min-h-16 h-max-content   transform duration-300  rounded-lg w-full active:outline-none focus:outline-none"
                  id={"lst-video-" + item.id}
                  onClick={() => {
                    this.handlePlayVideo(item);
                    this.handleSubmitTrackingXemVideoXemLai();
                  }}
                >
                  <div className="bg-blue-theme h-9 rounded-full leading-7 w-9 flex justify-center items-center transform relative overflow-hidden mr-2   ">
                    <i className="fa-solid fa-film text-base text-white"></i>
                  </div>
                  <div className="flex  flex-col w-full justify-start  h-max-content">
                    <span
                      className={"w-full text-left text-sm lg:text-base mb-1"}
                    >
                      {item.tieuDe}
                    </span>
                    <div className=" text-xs w-full text-left ">
                      <p className="m-0 p-0 mb-1">
                        Ngày tạo : {moment(item.ngayTao).format("DD/MM/yyyy")}
                      </p>
                      <p className="m-0 p-0">
                        Ngày xoá : {moment(item.ngayXoa).format("DD/MM/yyyy")}
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            );
          })}
        </div> */}
      </div>
    );
  }
}
