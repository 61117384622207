import {
  message,
  Tooltip,
  Spin,
  Breadcrumb,
  notification,
  Upload,
  Button,
  Icon,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { layUserCV } from "../../redux/actions/nguoiDungAction";
import { NguoiDungSevice } from "../../service/nguoiDungService";
import moment from "moment";
import axios from "axios";
import {
  boDauTiengViet,
  tachHoVaTenTuHoTen,
} from "../../utilities/stringFormat";
import * as types from "../../redux/types";
import history from "../../utilities/history";
import * as nguoiDungActions from "../../redux/actions/nguoiDungAction";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { ListItemAvatar } from "@material-ui/core";
import { API_URL_DOMAIN_ONLINE, DOMAIN_API } from "../../config/urlConfig";
// import dataThongTinMoRong from './dataThongTinMoRong.json';

export const UpdateInfoV2 = ({ nguoiDung }) => {
  const dispatch = useDispatch();
  const [isAnhAvatarError, setIsAnhAvatarError] = useState(false);

  const thongTinTaoCv = useSelector(
    (state) => state.nguoiDungReducer.thongTinTaoCV
  );

  let token = "";

  const setToken = () => {
    if (localStorage.getItem("LOGIN_LOCASTORE")) {
      let user = JSON.parse(localStorage.getItem("LOGIN_LOCASTORE"));

      token = user.token;
    }
  };

  useEffect(() => {
    let isMount = true;

    if (isMount) {
      // Check xem các dự án có đầy đủ thông tin source code / deploy / youtube không
      if (!thongTinTaoCv?.danhSachDuAn) {
        // Nếu undefined thì đẩy về trang quản lý
        window.location.replace("/quan-ly-cv");
      }

      setToken();
    }

    return () => {
      isMount = false;
    };
  }, []);

  // const isLoading = useSelector(state => state.spinner.isLoading)

  //   const [isLoading, setIsLoading] = useState(true);
  const [isCamKet, setIsCamKet] = useState(false);
  const [linkAvatarImage, setLinkAvatarImage] = useState("");
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }


  const handleChangeUploadImage = (info) => {
    if (info.file.status === 'uploading') {
        return;
    }
    if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, imageUrl => {
            // console.log("value",info.file.response)
            setLinkAvatarImage(info.file.response.split("/").at(-1));
        });
        return;
    }
}

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Chỉ được phép tải lên hình JPG/PNG!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Hình tải lên tối đa 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  function getValueFromId(idString) {
    return document.querySelector(`#${idString}`).value;
  }

  // console.log(thongTinTaoCv)
  const handleCheckSquareImageDebounce = _.debounce(
    handleCheckSquareImage,
    500
  );

  function handleCheckSquareImage() {
    let imgUrl = getValueFromId("anhAvatar");

    if (imgUrl.trim() === "") {
      return;
    }

    axios
      .get(imgUrl, { responseType: "blob" })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        const img = new Image();

        img.onload = function () {
          const width = img.width;
          const height = img.height;

          if (width !== height) {
            message.info("Bạn nên sử dụng ảnh vuông để CV được đẹp hơn nhé.");
            setIsAnhAvatarError(false);
          } else {
            setIsAnhAvatarError(false);
          }
        };

        img.onerror = function () {
          setIsAnhAvatarError(true);
          message.error("URL ảnh không hợp lệ hoặc không tải được ảnh.");
        };

        img.src = url;
      })
      .catch((error) => {
        setIsAnhAvatarError(true);
        message.error("URL ảnh không hợp lệ hoặc không tải được ảnh.");
        console.error(error);
      });
  }

  async function checkUrlValid(url) {
    let urlTrim = url.trim();

    if (!urlTrim.startsWith("https://")) {
      message.error(
        "Link vừa nhập không có https://, bạn vui lòng nhập lại link nhé"
      );
      return;
    }

    // if (urlTrim === '') {
    //   return
    // }
    //   try {
    //     const response = await fetch(url);
    //     if (response.ok) {
    //       return
    //     } else {
    //      message.error('Link vừa nhập bị lỗi, bạn vui lòng kiểm tra lại link nhé')
    //     }
    //   } catch (error) {
    //      message.error('Link vừa nhập bị lỗi, bạn vui lòng kiểm tra lại link nhé')
    //   }
  }

  const handelCheckUrlValidDebouce = _.debounce(checkUrlValid, 1000);

  const saveData = () => {
    let chucDanh = document.querySelector("#chucDanh").value;
    let diaChi = document.querySelector("#diaChi").value;
    let gioiThieu = document.querySelector("#gioiThieu").value;
    // let anhAvatar = getValueFromId("anhAvatar");
    let linkFacebook = getValueFromId("linkFacebook");
    let linkGitHub = getValueFromId("linkGitHub");
    let linkLinkedIn = getValueFromId("linkLinkedIn");

    // if (isAnhAvatarError) {
    //   anhAvatar = "";
    // }
    if (
      chucDanh.trim() == "" ||
      diaChi.trim() == "" ||
      gioiThieu.trim() == "" ||
      linkFacebook.trim() == "" ||
      linkGitHub.trim() == "" ||
      linkLinkedIn.trim() == ""
    ) {
      message.error("Bạn hãy điền đầy đủ thông tin ở phần đánh dấu nhé !");
      return;
    }

    // linkLinkedIn = null ? "" : linkLinkedIn

    let thongTinMoRong = {
      chucDanh,
      diaChi: boDauTiengViet(diaChi),
      gioiThieu: boDauTiengViet(gioiThieu),
      anhAvatar: linkAvatarImage,
      linkFacebook,
      linkGitHub,
      linkLinkedIn,
      truongHoc: [],
      kinhNghiem: [],
      ngoaiNgu: ["ENGLISH"],
      chungNhan: [],
    };

    thongTinMoRong.danhSachSkill = [];
    thongTinMoRong.isHiddenFacebookLink = false;

    const listSkillFe = [
      "HTML-CSS",
      "HTML5-CSS3",
      "BOOTSTRAP",
      "JS",
      "SASS",
      "API",
      "REACTJS",
      "TYPESCRIPT",
    ];
    const listSkillBe = [
      "ES6",
      "DOCKER",
      "NESTJS",
      "NODEJS-ORM",
      "EXPRESSJS",
      "MYSQL",
      "JAVA-CORE",
      "DATABASE",
      "COOKIES-SESSION",
      "INJECTION",
      "SPRING",
      "BACKEND FOUNDATION",
      "DESIGN PATTERN",
      "BASIC HIBERNATE",
      "RESTful API",
      "LOGGING SECURITY",
      "ADVANCED HIBERNATE",
    ];

    thongTinMoRong.lsSkillFE = [];
    thongTinMoRong.lsSkillBE = [];
    thongTinMoRong.lsSkillOther = [];

    thongTinTaoCv?.danhSachDuAn?.forEach((item) => {
      let arraySkill = JSON.parse(item.danhSachSkill);
      item.moTa = "";
      thongTinMoRong.danhSachSkill = [
        ...thongTinMoRong.danhSachSkill,
        ...arraySkill,
      ];
    });
    let setSkill = new Set(thongTinMoRong.danhSachSkill);
    thongTinMoRong.danhSachSkill = [...setSkill];

    for (let i = 0; i < thongTinMoRong.danhSachSkill.length; i++) {
      let skillName = thongTinMoRong.danhSachSkill[i];

      if (listSkillFe.includes(skillName)) {
        thongTinMoRong.lsSkillFE.push(skillName);
        continue;
      }

      if (listSkillBe.includes(skillName)) {
        thongTinMoRong.lsSkillBE.push(skillName);
        continue;
      }

      thongTinMoRong.lsSkillOther.push(skillName);
    }

    if (
      thongTinTaoCv.ngayBatDau.toString() !== "0001-01-01T00:00:00" &&
      thongTinTaoCv.ngayKetThuc.toString() !== "0001-01-01T00:00:00"
    ) {
      thongTinMoRong.truongHoc.push({
        tenTruong: "CyberSoft Academy",
        trinhDo: chucDanh,
        namHocTu: moment(thongTinTaoCv?.ngayBatDau)
          .format("MM/yyyy")
          .toString(),
        namHocDen: moment(thongTinTaoCv?.ngayKetThuc)
          .format("MM/yyyy")
          .toString(),
        diaChi: "112 Cao Thang Street, District 3, Ho Chi Minh City",
        moTaNgan: "International Programmer Training Center",
      });

      thongTinMoRong.kinhNghiem.push({
        chucVu: `Intern ${chucDanh}`,
        tenCongTy: "CyberSoft Academy",
        namLamTu: moment(thongTinTaoCv?.ngayBatDau)
          .format("MM/yyyy")
          .toString(),
        namLamDen: moment(thongTinTaoCv?.ngayKetThuc)
          .format("MM/yyyy")
          .toString(),
        diaChi: "112 Cao Thang Street, District 3, Ho Chi Minh City",
        moTaNgan: "International Programmer Training Center",
      });
    }

    thongTinMoRong.gioiTinh = getGioiTinh();
    thongTinMoRong.email = thongTinTaoCv?.emailNguoiDung;
    thongTinMoRong.hoTen = boDauTiengViet(thongTinTaoCv?.hoTen);
    thongTinMoRong.soDienThoai = thongTinTaoCv?.soDienThoai;
    thongTinMoRong.chungNhan = thongTinTaoCv?.danhSachChungChi
      ? thongTinTaoCv?.danhSachChungChi
      : [];
    thongTinMoRong.listDuAn = thongTinTaoCv?.danhSachDuAn?.sort(
      (a, b) => b.danhSachSkill.length - a.danhSachSkill.length
    );

    if (thongTinMoRong.listDuAn.length === 0) {
      thongTinMoRong.listDuAn.push({
        tieuDe: "",
        linkDeploy: "",
        linkYoutube: "",
        linkGit: "",
        danhSachSkill: "[]",
        moTa: "",
      });
    }

    // thongTinMoRong.listDuAn = thongTinTaoCv?.danhSachDuAn
    thongTinMoRong.headerType = "default";
    thongTinMoRong.bodyType = "default";
    thongTinMoRong.layoutTemplate = "Basic 2";
    thongTinMoRong.listSoftSkill = [];
    thongTinMoRong.layoutColor = {
      primaryColor: "default",
      secondaryColor: "default",
      subPrimaryColor: "default",
      subSecondaryColor: "default",
      backGroundColor: "default",
    };

    dispatch({
      type: types.CAP_NHAT_CV_LOCAL,
      payload: thongTinMoRong,
    });

    let redirect = setTimeout(history.push("/user-cv"), 1000);
  };

  function getGioiTinh() {
    var ele = document.getElementsByName("gender");

    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) return ele[i].value;
    }
  }

  return (
    <Spin
      tip="Đang tải dữ liệu bạn vui lòng đợi nhé"
      spinning={thongTinTaoCv === null}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <NavLink to="/quan-ly-cv">Quản lý CV</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Tạo CV</Breadcrumb.Item>
      </Breadcrumb>
      <div className="container">
        <p className="text-center py-5">
          <h1 className=" text-3xl ">Cập nhật thông tin </h1>
          <i>
            Bạn phải cập nhật lại thêm các thông tin dưới đây để xem được CV mẫu
            của bạn. Các thông tin phải điền bằng <b>tiếng Anh.</b>
          </i>
        </p>
        <div className="row">
          <div className="col-9">
            <div className="form-group d-flex align-content-center">
              <input
                type="checkbox"
                className=""
                style={{ width: "20px", height: "20px" }}
                onChange={(e) => {
                  setIsCamKet(e.target.checked);
                }}
                id="hiddenFacebookLink"
                defaultValue={false}
              />
              <label className="font-bold ml-2">
                Tôi ĐỒNG Ý cho CyberSoft chuyển CV/Thông tin cá nhân của tôi đến
                các công ty tuyển dụng đề hỗ trợ tìm việc làm và tôi mong muốn
                nhận được thông báo qua email đã đăng ký của tôi tại CyberSoft
                trong quá trình hỗ trợ tìm việc làm.
              </label>
            </div>
            <div className="form-group">
              <div>
                <label className="font-bold mr-2">Gender : </label>
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  defaultChecked
                />{" "}
                Mr
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  className="ml-2"
                />{" "}
                Mrs
              </div>
            </div>
            {/* <div className="form-group">
              <Tooltip
                title="Bạn nên chọn hình ảnh phù hợp với vị trí ứng tuyển, nhìn thấy khuôn mặt trực diện, nên sử dụng ảnh vuông"
                placement="top"
              >
                {" "}
                <label className="font-bold">Avatar image URL : </label>
                <input
                  type="text"
                  className="form-control"
                  id="anhAvatar"
                  placeholder="Nhập link ảnh"
                  onChange={handleCheckSquareImageDebounce}
                  // defaultValue="https://i0.wp.com/thatnhucuocsong.com.vn/wp-content/uploads/2023/02/Hinh-anh-avatar-Facebook.jpg"
                />
              </Tooltip>
            </div> */}

            <div className="form-group">
              <Upload
                action= {`${DOMAIN_API}/api/file/avatar-cv`}
                listType= "picture-card"
                multiple= "false"
                onChange={handleChangeUploadImage}
                beforeUpload={beforeUpload}
                accept= "image/*"
                showUploadList={false}

              >
                {linkAvatarImage !== "" ? <img src={`${DOMAIN_API}/avatar-cv/${linkAvatarImage}`} alt="avatar" style={{ width: '300px' }} /> : <Button>
                  <Icon type="upload" /> Upload Avatar Image
                </Button>}
               
                
              </Upload>
            </div>

            <div className="form-group">
              <label className="font-bold">
                Facebook Link<span style={{ color: "red" }}>*</span> :{" "}
              </label>
              <input
                type="text"
                className="form-control"
                id="linkFacebook"
                placeholder="https://facebook.com"
                onChange={(e) => handelCheckUrlValidDebouce(e.target.value)}
              />
            </div>

            <div className="form-group d-flex align-content-center">
              <Tooltip
                title="Không hiện link facebook trong CV"
                placement="top"
              >
                <label className="font-bold">Hidden Facebook link: </label>
              </Tooltip>
              <input
                type="checkbox"
                className="ml-2"
                style={{ width: "20px", height: "20px" }}
                id="hiddenFacebookLink"
                defaultValue={false}
              />
            </div>
            <div className="form-group">
              <Tooltip title="" placement="top">
                <label className="font-bold">
                  LinkedIn Link<span style={{ color: "red" }}>*</span> :{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="linkLinkedIn"
                  placeholder="https://www.linkedin.com/"
                  onChange={(e) => handelCheckUrlValidDebouce(e.target.value)}
                />
              </Tooltip>
            </div>

            <div className="form-group">
              <Tooltip
                title="Bạn nên sử dụng github mà thường hay push code lên nhé"
                placement="top"
              >
                <label className="font-bold">
                  Github Link<span style={{ color: "red" }}>*</span> :{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="linkGitHub"
                  placeholder="https://github.com"
                  onChange={(e) => handelCheckUrlValidDebouce(e.target.value)}
                />
              </Tooltip>
            </div>
            <div className="form-group">
              <Tooltip
                title="Chức danh mà bạn muốn ứng tuyển, VD: Front End Developer,..."
                placement="top"
              >
                <label className="font-bold">
                  Title<span style={{ color: "red" }}>*</span> :{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="chucDanh"
                  placeholder="Front-End developer, Back-End developer, Full-Stack developer,..."
                  // defaultValue="Front End Developer"
                />
              </Tooltip>
            </div>
            <div className="form-group">
              <Tooltip
                title="Bạn nên bảo mật địa chỉ đang ở, chỉ cần ghi ngắn gọn quận, thành phố, tỉnh nơi bạn đang sống bằng tiếng Anh"
                placement="top"
              >
                <label className="font-bold">
                  Address<span style={{ color: "red" }}>*</span> :{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="diaChi"
                  // placeholder="District XXX, XXX City"
                  // defaultValue="Phường A, Quận B, Thành phố Hồ Chí Minh"
                />
              </Tooltip>
            </div>

            <div className="form-group">
              <Tooltip
                title="Nêu ra các lợi thế của bản thân, mục tiêu trong tương lai trong một khoảng thời gian cụ thể, có thể chia ra làm mục tiêu ngắn hạn và mục tiêu dài hạn. Nội dung phải viết bằng tiếng Anh bạn nhé."
                placement="top"
              >
                <label className="font-bold">
                  Summary
                  <span style={{ color: "red" }}>*</span> :{" "}
                </label>
                <textarea
                  className="form-control"
                  id="gioiThieu"
                  rows={6}
                  // defaultValue={
                  //   "I love learning new technologies and building valuable application that helps others with their work. Im considered a team-player because I like to help other and tend to work well within the group"
                  // }
                />
              </Tooltip>
            </div>
            <div className="text-center">
              {isCamKet ? (
                <button className="btn btn-primary" onClick={saveData}>
                  Cập nhật
                </button>
              ) : (
                <Tooltip
                  title="Bạn phải đồng ý cam kết mới tiếp tục được"
                  placement="top"
                >
                  <button
                    className="btn btn-primary"
                    disabled
                    onClick={saveData}
                  >
                    Cập nhật
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};
