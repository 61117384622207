import React, { Component } from "react";
import { connect } from "react-redux";
import "./SideBar.scss";
import history from "../../utilities/history";
import * as actions from "../../redux/actions/lopHocAction";
import * as khoaHocActions from "../../redux/actions/khoaHocAction";
import * as baiTapActions from "../../redux/actions/baiTapActions";
import { LocalStorageUtil } from "../../utilities/localStorage";
import { NavLink } from "react-router-dom";
import {checkHocVienNuocNgoai} from '../../utilities/utils'
import publicIp from 'public-ip'
import { Menu } from "antd";
const { SubMenu } = Menu;
class SidebarOld extends Component {
  state = {
    viTri: -1,
  };

  componentDidMount = () => {
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    this.props.dispatch(khoaHocActions.layDanhSachKhoaHocFree());

    this.props.dispatch(actions.layDanhSachLopHoc(nguoiDung.id));
    publicIp.v4()
    .then((res) => {
      checkHocVienNuocNgoai(res)
    })
  
  };

  onGoToKhoaHoc = (index, lopHoc) => {
    this.setState({ viTri: index });
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    this.props.dispatch(khoaHocActions.layDanhSachKhoaHoc(lopHoc.id));
    this.props.dispatch(
      baiTapActions.layDanhSachBaiTap(lopHoc.id, nguoiDung.id)
    );

    this.props.dispatch(baiTapActions.layDanhSachLopBaiTapAction(lopHoc.id));
    this.props.dispatch(baiTapActions.layDanhSachBaiTapNopAction(lopHoc.id));

    //lay danh sach xem lai buoi hoc
    this.props.dispatch(actions.layDanhSachXemLaiBuoiHocTheoLop(lopHoc.id));
    // console.log({ lopHoc });
    if (lopHoc.isStart && lopHoc.hasRoadMap) {
      history.push(`/course-roadmap/${lopHoc.id}`, { lopHoc });
    } else {
      history.push("/course", { lopHoc });
    }
  };

  renderFreeCouseMenu(dsKhoaHocFree) {

    if (dsKhoaHocFree.length === 0) {

      return  <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme">
      Bạn đã hết thời gian truy cập vào tài nguyên này !
    </div>
    }

    return dsKhoaHocFree.map((item,index) => (<div className="mt-1" key={index}>
    <NavLink to={`/course-free/${item.id}`}>
    <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme">
             <i className="fa fa-star"></i> {item.tenKhoaHoc} - <span className="text-red-500">{item.soLuotXem} lượt xem</span>
           </div>
         </NavLink>
  
   </div>))
  }

  renderLopHoc = (dsLopHoc) => {
    const { viTri } = this.state;
    const { dsKhoaHocFree } = this.props.khoaHocProps;

    return dsLopHoc
      ? 
      <>
      {dsLopHoc.map((item, index) => {
          if (item.isStart && item.hasRoadMap) {
            return (
              <div className=" w-full ">
                <div className="mt-1" key={index}>
                  <a
                    onClick={() => this.onGoToKhoaHoc(index, item)}
                    style={{ color: index === viTri ? "yellow" : "white" }}
                  >
                    <span className="text-base">
                      <i className="fa fa-graduation-cap "></i>Lớp{" "}
                      {item.tenLopHoc}
                    </span>
                  </a>
                </div>
                <div className=" w-full text-white pl-10 py-3 space-y-2 hidden ">
                  <div onClick={() => this.onGoToKhoaHoc(index, item)}>
                    <NavLink
                      to={`/course-roadmap/${item.id}`}
                      className="text-xs"
                      activeClassName="text-yellow-300"
                    >
                      <i className="fa fa-road"></i> Lộ trình học
                    </NavLink>
                  </div>
                  <div className=" cursor-pointer hover:text-yellow-300">
                    <NavLink
                      to={`/course-roadmap-tai-lieu/${item.id}`}
                      className="text-xs"
                      activeClassName="text-yellow-300"
                    >
                      <i className="fa fa-file-alt "></i> Truy xuất nhanh tài
                      liệu
                    </NavLink>
                  </div>
                  <div className=" cursor-pointer hover:text-yellow-300">
                    <NavLink
                      to={`/course-roadmap-diem/${item.id}`}
                      className="text-xs"
                      activeClassName="text-yellow-300"
                    >
                      <i className="fa fa-money-check"></i> Điểm và chứng nhận
                    </NavLink>
                  </div>
                  <div className=" cursor-pointer hover:text-yellow-300">
                    <NavLink
                      to={`/course-roadmap-thong-bao/${item.id}`}
                      className="text-xs"
                      activeClassName="text-yellow-300"
                    >
                      <i className="fa fa-bell"></i> Thông báo
                    </NavLink>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="mt-1" key={index}>
                <a
                  onClick={() => this.onGoToKhoaHoc(index, item)}
                  style={{ color: index === viTri ? "yellow" : "white" }}
                >
                  <span className="text-base">
                    <i className="fa fa-graduation-cap "></i>Lớp{" "}
                    {item.tenLopHoc}
                  </span>
                </a>
              </div>
            );
          }
        })}
          <div className=" cursor-pointer hover:text-yellow-300 sidebar-list space-y-2 p-2">
                    <NavLink
                      to={`/quan-ly-cv`}
                      className="text-xs"
                      activeClassName="text-yellow-300"
                    >
                    

                      <span className="text-base">
                    <i className="fa fa-bell "></i>Quản lý CV
                  </span>
                    </NavLink>
                    
                  <Menu
        style={{ width: "100%"}}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        className="rounded card-theme"
      
      >
        <SubMenu
          key="sub4"
          style={{paddingLeft: "10px"}}
          title={
            <div>
              <span  className="text-blue-theme">Chia sẻ miễn phí</span> 
              <span className="ml-2 bg-red-500 text-white p-1 animate-pulsee">New</span>
            </div>
          }
        >
         
       {this.renderFreeCouseMenu(dsKhoaHocFree)}
        </SubMenu>
      </Menu>
      <Menu
              style={{ width: "100%" }}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              className="rounded card-theme"

            >
              <SubMenu
                
                key="sub4"
                className="nhanUuDai"
                style={{ paddingLeft: "10px" }}
                title={
                  <div >
                    <span className="text-blue-theme font-black">Chia sẻ nhận quà!</span>
                    <span className="ml-2 bg-red-500 text-white p-1 animate-pulsee">New</span>
                  </div>
                }
              >

                <NavLink to={`/referral`}>
                  <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme relative">
                    <i className="fa fa-money"></i> Sản phẩm
                    <span className="new_badge">HOT</span>
                  </div>
                </NavLink>
                <NavLink to={`/refer-chart`}>
                  <div className=" w-full pl-3  py-3  card_theme text-xs text-blue-theme relative">
                    <i className="fa fa-bar-chart"></i> Doanh thu
                  </div>
                </NavLink>
              </SubMenu>
            </Menu>
                  </div>

      </>
      
      : null;
  };

  render() {
    const { dsLopHoc } = this.props.lopHocProps;
    return (
      <div className="sidebar">
        <div className="sidebar-header">
          <div className="logo">
            <img src="https://i.imgur.com/lC22izJ.png" />
          </div>
          <span>CYBERSOFT</span>
        </div>
        <div className="line">
          <span></span>
        </div>
        <div className="sidebar-list">{this.renderLopHoc(dsLopHoc)}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lopHocProps: state.lopHocReducer,
    khoaHocProps: state.khoaHocReducer
  };
}

export default connect(mapStateToProps, null)(SidebarOld);
