import React, { useState } from 'react';
import { DeploymentUnitOutlined, GithubOutlined, YoutubeOutlined } from '@ant-design/icons';
import './stylemobile.css'
import chungNhanTheme from "./../../assets/images/C5.jpg";
import Certificate from './Certificate';

const MobileQRCode = ({data}) => {
  function encodeObject(obj) {
    // Encode the object as a UTF-8 string
    const encoded = unescape(encodeURIComponent(JSON.stringify(obj)));
    // Encode the UTF-8 string as a base64-encoded string
    return btoa(encoded);
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [objCer, setobj] = useState();
  const setOpen=(obj) => { 
    setIsModalOpen(true)
    setobj(obj)
   }
  return (
    <div className="profile-container">
      <div className="profile-card">
        <div className="profile-header">
          <div className="profile-avatar">
          <img
           className="w-100"
           src={`https://ui-avatars.com/api/?name=${data?.hoTen}&background=random&bold=true`}
           alt={data?.hoTen}
           style={{borderRadius:'10px'}}
         />
          </div>
          <div className="profile-info">
            <h1 className="profile-name">{data?.hoTen}</h1>
            <p className="profile-email">{data?.email}</p>
            <p className="profile-gpa">GPA: {(data?.danhSachLopHoc.reduce((acc, lopHoc) => acc + lopHoc.diemCuoiKhoa, 0) / data?.danhSachLopHoc.length).toFixed(1)}/10</p>
          </div>
        </div>
        
        <div className="profile-section">
          <h2 className="section-title">Skills</h2>
          <div className="skills-container">
            {data?.danhSachSkill.map((skill, index) => (
              <span key={index} className="skill-tag">
                {skill.tenSkill}
              </span>
            ))}
          </div>
        </div>
        
        <div className="profile-section">
          <h2 className="section-title">Courses</h2>
          {data?.danhSachLopHoc?.map((course, index) => (
            <div key={index} className="course-item">
              <h3 className="course-name">{course.tenLoTrinh}</h3>
              <p className="course-score">Average Score: {course.diemCuoiKhoa.toFixed(1)}</p>
            </div>
          ))}
        </div>
        <div className="profile-section">
          <h3 className="section-title">Certificates</h3>
          {data?.danhSachLopHoc.map((course, index) => {
        let obj = encodeObject(course)
        if(course.diemCuoiKhoa>=7){
          return  <div onClick={() => setOpen(obj)}>
            <Certificate obj={obj} width={25}/>

          </div>
        }
          })}
          {/* <div className="cursor-pointer" onClick={() => setIsModalOpen(true)}>
            <img src={chungNhanTheme} alt="Front-End Developer Certificate" className="w-full h-auto rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300" />
            <p className="text-sm text-gray-600 mt-2">Click to view certificate</p>
          </div> */}
        </div>
        
        <div className="profile-section">
          <h2 className="section-title">Assignments</h2>
          {data?.danhSachDuAn.map((assignment, index) => {
            if(assignment.diem>=70) {
                return <div key={index} className="assignment-item">
                <h3 className="assignment-name">{assignment.noiDung}</h3>
                <p className="assignment-score">Score: {assignment.diem}</p>
                <div className="assignment-links">
                  <a target='_blank' href={assignment.linkGit} className="assignment-link github"><GithubOutlined style={{ fontSize: '20px', marginRight: '5px' }} /> GitHub</a>
                  <a target='_blank' href={assignment.linkDeploy} className="assignment-link deploy"><DeploymentUnitOutlined style={{ fontSize: '20px', marginRight: '5px' }} /> Deploy</a>
                  <a target='_blank' href={assignment.linkYoutube} className="assignment-link youtube"><YoutubeOutlined style={{ fontSize: '20px', marginRight: '5px' }} /> YouTube</a>
                </div>
              </div>
            }
          })}
        </div>
        {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg max-w-3xl max-h-full overflow-auto">
            <div className="flex justify-end">
              <button onClick={() => setIsModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                X
              </button>
            </div>
            <Certificate obj={objCer} width={25}/>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default MobileQRCode;