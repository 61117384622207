import { SPINNER_ENDED, SPINNER_STARTED } from "../types";

export const set_request_spinner_started = () => {
  return {
    type: SPINNER_STARTED,
  };
};

export const set_request_spinner_ended = () => {
  return {
    type: SPINNER_ENDED,
  };
};
