import React from "react";
import { LocalStorageUtil } from "../../../utilities/localStorage";
import backgroundTemplate from "./../..//../assets/images/background.png";
import NewBottomNavigationMobile from "../newBottomNavigationMobile/NewBottomNavigationMobile";

export default function UserMobile({ title }) {
  const user = LocalStorageUtil.getLoginLocalStorage();
  // console.log(user);
  return (
    <div>
      <div className="h-16 flex items-center justify-between px-8 shadow ">
        <h3 className="font-bold text-lg mb-0">Thông tin cá nhân</h3>
        {/* <div>
          <i className="fa-solid fa-bell text-2xl"></i>
        </div> */}
      </div>
      <div
        // style={{
        //   background: `url(${backgroundTemplate})`,
        //   backgroundSize: "cover",
        // }}
        className="p-5 h-full"
      >
        <div className=" rounded-lg relative w-full h-56 text-center flex flex-col justify-center items-center space-y-3">
          {/* <div className="w-20 h-20 rounded-full bg-gray-400 mx-auto flex items-center justify-center">
            <div className="w-16 h-16 bg-red-600 rounded-full">
              <img src="./img/profile.png" className="rounded-full" alt="" />
            </div>
          </div> */}
          <div className="text-xl font-bold">{user.hoTen}</div>
          <div className="text-lg font-bold">{user.email}</div>
          <div className="text-lg font-bold">{user.soDT}</div>
          {/* <div className="bg-gray-700 absolute bottom-0 rounded-b-lg left-0 right-0 h-10 flex items-center"></div> */}
        </div>
        <ul className="space-y-2 mt-3">
          <li className="w-full h-full cursor-pointer bg-blue-500 rounded hover:bg-blue-600 ease-linear duration-300">
            <a
              href="#"
              className="text-white p-2 no-underline leading-none rounded-full flex items-center h-full justify-center"
            >
              <i className="fa-brands fa-facebook-f mr-2"></i>
              <span className="text-xs">Chia sẻ lên Facebook</span>
            </a>
          </li>
          <li className="w-full h-full cursor-pointer bg-yellow-500 rounded hover:bg-yellow-600 ease-linear duration-300">
            <a
              href="#"
              className="text-white no-underline p-2 leading-none rounded-full flex items-center h-full justify-center"
            >
              <i className="fa-solid fa-download mr-2"></i>
              <span className="text-xs">Tải về chứng nhận</span>
            </a>
          </li>
          <li className="w-full h-full cursor-pointer bg-red-500 rounded hover:bg-red-600 ease-linear duration-300">
            <a
              href="#"
              className="text-white no-underline p-2 leading-none rounded-full flex items-center h-full justify-center"
            >
              <i className="fa-solid fa-gift mr-2"></i>
              <span className="text-xs">Nhận voucher</span>
            </a>
          </li>
        </ul>
      </div>
      <NewBottomNavigationMobile />
    </div>
  );
}
