import * as React from "react";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { LocalStorageUtil } from "../../utilities/localStorage";
import { getScoreFromExercise } from "../roadmapPage/UserDashboard/ReportBangDiem/report_utils";

let tableCss = {
  borderCollapse: "separate",
  borderSpacing: "0",
  cellPadding: 15,
};

export const BangDiem = ({ dataReport, tenLop }) => {
    // console.log('dataReport: ', dataReport);
  //
  let user = LocalStorageUtil.getLoginLocalStorage();

  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  const exportPDFWithComponent = () => {
    let element = container.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `CyberSoft ${new Date().getFullYear()}`,
    });
  };

  let renderContentTrBySkill = (skill) => {
    if (skill.arrScore.length == 0) {
      return <></>;
    }
    let contentHTML = [];

    let firstTr = (
      <tr className="border-2 ">
        <td className="w-20" rowSpan={skill.arrScore.length}>
          {skill.tenSkill}
        </td>
        <td>{skill?.arrScore[0]?.tieuDe}</td>
        <td>{skill?.arrScore[0]?.loaiBaiTap}</td>
        <td>{getScoreFromExercise(skill?.arrScore[0])}</td>
      </tr>
    );
    contentHTML.push(firstTr);
    skill.arrScore.forEach((lesson, index) => {
      if (index > 0) {
        contentHTML.push(
          <tr className="border-2 ">
            <td>{lesson?.tieuDe}</td>
            <td>{lesson?.loaiBaiTap}</td>
            <td>{getScoreFromExercise(lesson)}</td>
          </tr>
        );
      }
    });

    return contentHTML;
  };
  return (
    <div className="w-full h-full space-y-2">
      <div className=" flex justify-end">
        {/* <button
          className=" px-2 py-1 bg-blue-theme text-white rounded shadow hover:shadow-lg transition"
          onClick={exportPDFWithComponent}
        >
          <i className="fa fa-file-export text-white mr-2"></i> Export PDF
        </button> */}
      </div>
      <div id="contentReport" style={{ left: "200%" }} className=" ">
        <PDFExport
          ref={pdfExportComponent}
          margin={20}
          fileName={`CyberLearn ${new Date().getFullYear()}`}
          // author="KendoReact Team"
        >
          <div ref={container} className=" ">
            <div className="border-1  border-black">
              <div className="space-y-2  p-3">
                <p style={{}} className=" font-medium  ">
                  Tên lớp học:{tenLop}
                </p>
              </div>
            </div>

            <div className=" my-10">
              <table
                id="report_diem"
                style={tableCss}
                cellPadding={"15px"}
                className="w-full text-left"
              >
                <thead>
                  <tr>
                    <td className="w-1/6">Tên skill</td>
                    <td>Tên bài tập</td>
                    <td>Loại bài tập</td>
                    <td>Điểm</td>
                  </tr>
                </thead>

                <tbody>
                  {dataReport?.map((item) => {
                    return renderContentTrBySkill(item);
                  })}
                  {/* <tr>
                    <td colSpan={4}>
                      Điểm tổng kết : {getScoreReport(dataReport).toFixed(1)}
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </PDFExport>
      </div>
    </div>
  );
};
