import React, { useCallback, useEffect, useRef, useState } from "react";
import Editor from "@monaco-editor/react";
import SelectLanguages from "../SelectLanguages/SelectLanguages";
import SelectThemes from "../SelectThemes/SelectThemes";
import Testcase from "../Testcase/Testcase";
import { useDispatch, useSelector } from "react-redux";
import { checkSubmitDataRuncodeAction } from "../../../../redux/actions/roadMapAction";
import { Tooltip } from "antd";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "../../../../redux/actions/spinnerAction";
import _debounce from "lodash/debounce";

// import * as monaco from "monaco-editor";
export default function RuncodeEditor({
  question,
  idCauHoi,
  disabledButton,
  setDisabledButton,
  increaseQuestionNumber,
  thoiGianBatDau,
  idLop,
  idBuoiHoc,
  idBaiHoc,
  chuongHoc,
  isShowNextQuestion,
}) {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  // let { testCaseRuncode } = useSelector((state) => state.roadmapReducer);
  // console.log(testCaseRuncode);
  const [languages, setLanguages] = useState(question?.init_code[0]?._language);
  const [themes, setThemes] = useState("vs-dark");
  const [defaultCode, setDefaultCode] = useState("");
  const [code, setCode] = useState("");
  // console.log(idCauHoi);
  // console.log(question);
  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (idCauHoi.diem === 100) {
      setDisabledButton(false);
    }
  }, [idCauHoi.diem]);

  // const monaco = useMonaco();
  const setLanguage = (language) => {
    setLanguages(language);
  };
  function handleEditorDidMount(editor) {
    // console.log(editor);
    editorRef.current = editor;
  }
 
  useEffect(() => {
    const renderDefaultCode = () => {
      let language = languages === "javascript" ? "js" : languages;
      let defaultCode = question?.init_code.find(
        (item) => item._language === language
      );
      const formattedValue = defaultCode?._function;
  
      if (isJson(formattedValue)) {
        setDefaultCode(JSON.parse(formattedValue));
        setCode(JSON.parse(formattedValue));
      } else {
        setDefaultCode(defaultCode?._function);
        setCode(defaultCode?._function);
      }
    };
    renderDefaultCode();
  }, [languages, question]);
  
  // Khi người dùng có bài làm trước đó (idCauHoi.baiLamTracNghiem)
  useEffect(() => {
    if (idCauHoi.baiLamTracNghiem) {
      setDefaultCode(JSON.parse(idCauHoi.baiLamTracNghiem));
      setCode(JSON.parse(idCauHoi.baiLamTracNghiem));
    }
  }, [idCauHoi]);
  useEffect(() => {
    if (question) {
      setLanguage(question?.init_code[0]?._language)
    }
  }, [question]);
  

  //này để submit

  const submitAnswer = async () => {
    dispatch(set_request_spinner_started());
    // if (editorRef.current.getValue().includes("return")) {
    const data = {
      code: editorRef.current(),
      language: languages === "javascript" ? "js" : languages,
      question: Number(idCauHoi.noiDung),
      infor: {
        maBuoiHoc: Number(idBuoiHoc),
        maBaiHoc: idBaiHoc,
        loaiBaiTap: "RUN_CODE",
        maLop: idLop,
        baiLam: idCauHoi.baiLamTracNghiem,
        thoiGianBatDau: thoiGianBatDau,
        thoiGianHoanThanh: new Date(),
      },
      chuongHoc,
    };
    dispatch(checkSubmitDataRuncodeAction(data));
    dispatch(set_request_spinner_ended());
    // setDisabledButton(false);
    // } else {
    //   dispatch(set_request_spinner_ended());
    // }
  };

  return (
    <div className="space-y-3">
      <div className="py-2 bg-theme-runcode-problem rounded px-2  flex justify-between items-center">
        <div className="space-x-4">
          <SelectLanguages setLanguages={setLanguage} lsLang={question.init_code} language={languages} />
          <SelectThemes setThemes={setThemes} themes={themes} />
        </div>
        <button
          onClick={() => {
            setCode(defaultCode);
          }}
          className="text-white px-2 py-1 rounded border border-white focus:outline-none"
        >
          Reset Code
        </button>
      </div>
      <div className="space-y-4">
        <Editor
          height="45vh"
          language={languages}
          theme={themes}
          defaultValue={defaultCode}
          value={code}
          onChange={(value) => {
            setCode(value);
          }}
          className="rounded bg-theme-runcode-problem shadow"
          // onMount={handleEditorDidMount}
          editorDidMount={handleEditorDidMount}
          options={{
            fontSize: 14,
            tabSize: 4,
            minimap: {
              enabled: false,
            },
          }}
        />
        <div className="py-2 bg-theme-runcode-problem rounded px-2  flex justify-between items-center">
          {idCauHoi.diem === 100 ? (
            <Tooltip
              placement="topLeft"
              title="Đã hoàn thành nên không thể submit"
            >
              <button
                onClick={submitAnswer}
                className={`text-red-500 px-4 py-1 rounded border border-red-500 focus:outline-none cursor-not-allowed
                `}
                disabled
              >
                Submit
              </button>
            </Tooltip>
          ) : (
            <button
              onClick={_debounce(submitAnswer, 1000)}
              className={`text-red-500 px-4 py-1 rounded border border-red-500 focus:outline-none`}
            >
              Submit
            </button>
          )}
          {isShowNextQuestion && (
            <div>
              {disabledButton ? (
                <Tooltip
                  placement="topLeft"
                  title="Hoàn thành câu này để mở khoá câu tiếp theo"
                >
                  <button
                    disabled={disabledButton}
                    onClick={increaseQuestionNumber}
                    className={`text-white px-4 py-1 rounded border border-white focus:outline-none ${
                      disabledButton ? "cursor-not-allowed" : ""
                    }`}
                  >
                    Câu tiếp theo
                  </button>
                </Tooltip>
              ) : (
                <button
                  disabled={disabledButton}
                  onClick={increaseQuestionNumber}
                  className={`text-white px-4 py-1 rounded border border-white focus:outline-none ${
                    disabledButton ? "cursor-not-allowed" : ""
                  }`}
                >
                  Câu tiếp theo
                </button>
              )}
            </div>
          )}
        </div>
        {question && <Testcase question={question} />}
      </div>
    </div>
  );
}
