const COURSE_LOCASTORE = "COURSE_LOCASTORE";
const LOGIN_LOCASTORE = "LOGIN_LOCASTORE";

// --------- THÔNG TIN ĐĂNG NHẬP ----------
export const setLoginLocalStorage = (info) => {
  localStorage.setItem(LOGIN_LOCASTORE, JSON.stringify(info));
};

export const getLoginLocalStorage = () => {
  if (!localStorage.getItem(LOGIN_LOCASTORE)) return null;
  return JSON.parse(localStorage.getItem(LOGIN_LOCASTORE));
};

export const removeLoginLocalStorage = () => {
  if (localStorage.getItem(LOGIN_LOCASTORE))
    localStorage.removeItem(LOGIN_LOCASTORE);
};

// --------- THÔNG TIN KHÓA HỌC ----------
export const setCourseLocalStorage = (course) => {
  localStorage.setItem(COURSE_LOCASTORE, JSON.stringify(course));
};

export const getCourseLocalStorage = () => {
  if (!localStorage.getItem(COURSE_LOCASTORE)) return null;
  return JSON.parse(localStorage.getItem(COURSE_LOCASTORE));
};

export const removeCourseLocalStorage = () => {
  if (localStorage.getItem(COURSE_LOCASTORE))
    localStorage.removeItem(COURSE_LOCASTORE);
};

export const LocalStorageUtil = {
  setLoginLocalStorage,
  getLoginLocalStorage,
  removeLoginLocalStorage,
  setCourseLocalStorage,
  getCourseLocalStorage,
  removeCourseLocalStorage,
};
