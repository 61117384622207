import React, { useEffect, useReducer, useState, useRef } from "react";
import { Modal, Popconfirm, Tag, Tooltip, message, Select, Breadcrumb } from "antd";
import Navbar from "../../../template/navbar/Navbar";
import SidebarNew from "../../../template/SidebarNew/sidebarNew";
import { NavLink, withRouter } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { TemplateCVPrint } from "./TemplateCVPrint";
import { NguoiDungSevice } from "../../../service/nguoiDungService";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../../redux/types";

const { Option } = Select;

const initialState = {
  thongTinMoRong: {},
  diemCv: {},
  styleCss: {},
  isDaGuiThanhCongCv: false,
};

const CONSTANTS = {
  SET_IS_GUI_THANH_CONG: "SET_IS_GUI_THANH_CONG",
  LIST_DEFAULT_PROJECT: [
    "[CAPSTONE] Dự án cuối khóa Node JS",
"[CAPSTONE] BC-Capstone_Bootstrap",
"[CAPSTONE] JS",
"[CAPSTONE] REACTJS",
"[CAPSTONE] DỰ ÁN CUỐI KHÓA FE",
"[CAPSTONE] DỰ ÁN CUỐI KHÓA NODEJS",
"[CAPSTONE] LAYOUT FE Tổng hợp 21 Buổi",
"[CAPSTONE] PROJECT ANGULAR",
"[CAPSTONE] PROJECT CUỐI KHÓA FE",
"[CAPSTONE] Dự án cuối khóa DS",
"[CAPSTONE] Capstone-express-ORM",
"[CAPSTONE] Project cuối khóa JAVA dự án JIRA",
"[CAPSTONE] React Native Dự án cuối khóa Shopping App",
  ]
};




function reducer(state, { type, payload }) {
  switch (type) {
    case CONSTANTS.SET_IS_GUI_THANH_CONG:
      return {
        ...state,
        isDaGuiThanhCongCv: payload,
      };
    default:
      return state;
  }
  throw Error("Unknown action: " + type);
}

function tinhDiemCV(thongTinMoRong) {
  let diemCv = 100;
  let errorArray = [];

  // Check xem các dự án có đầy đủ thông tin source code / deploy / youtube không
  if (!thongTinMoRong?.listDuAn) {
    // Nếu undefined thì đẩy về trang quản lý 
    window.location.replace('/quan-ly-cv')
  }

  for (let i = 0; i < thongTinMoRong?.listDuAn.length; i++) {
    let duAnCheck = thongTinMoRong.listDuAn[i];

    if (duAnCheck?.linkDeploy?.trim() === "") {
      diemCv -= 35;
      errorArray.push(`${duAnCheck.tieuDe} thiếu link Deploy`);
    }

    if (duAnCheck?.linkGit?.trim() === "") {
      diemCv -= 35;

      errorArray.push(`${duAnCheck.tieuDe} thiếu link Github`);
    }

    if (duAnCheck?.linkYoutube?.trim() === "") {
      diemCv -= 35;

      errorArray.push(`${duAnCheck.tieuDe} thiếu link Youtube`);
    }
    if (CONSTANTS.LIST_DEFAULT_PROJECT.includes(duAnCheck.tieuDe)) {
      diemCv -= 35;
      errorArray.push(`Bạn chưa cập nhật lại tiêu đề cho dự án ${duAnCheck.tieuDe}`)
    }

    if (duAnCheck?.moTa.trim() === "") {
      diemCv -= 35;
      errorArray.push(`Bạn chưa cập nhật lại mô tả cho dự án ${duAnCheck.tieuDe}`)
    }

  }

  if (thongTinMoRong?.listDuAn.length < 2) {
    diemCv -= 35;
      errorArray.push(`Bạn chưa đủ số lượng dự án, bạn cần bổ sung thêm dự án để CV hấp dẫn hơn nhé`)
  }

  
diemCv = diemCv >= 0 ? diemCv : 0
  return {
    diem: diemCv,
    errorArray,
  };
}

export default function TemplateCV() {
  const dispatch = useDispatch();
  const thongTinLocalCV = useSelector(
    (state) => state.nguoiDungReducer.thongTinLocalCV
  );
  //
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const componentRef = useRef();
  // Lấy data từ local storage lên
  const LIST_TEMPLATE = ["Basic 1", "Basic 2"];
  let danhSachOptionTemplate = [];

  for (let i = 0; i < LIST_TEMPLATE.length; i++) {
    danhSachOptionTemplate.push(
      <Option key={LIST_TEMPLATE[i]}>{LIST_TEMPLATE[i]}</Option>
    );
  }

  let diemCv = tinhDiemCV(thongTinLocalCV);

  function handleChangeAnLinkFacebook(value) {
    let newThongTinMoRong = { ...thongTinLocalCV };

    newThongTinMoRong.isHiddenFacebookLink = value;

    dispatch({
      type: types.CAP_NHAT_CV_LOCAL,
      payload: newThongTinMoRong,
    });
  }

  function handleChangeAnAvatar(value) {
    let newThongTinMoRong = { ...thongTinLocalCV };

    newThongTinMoRong.isHiddenAvatar = value;

    dispatch({
      type: types.CAP_NHAT_CV_LOCAL,
      payload: newThongTinMoRong,
    });
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function handleSendCV() {
    let strThongTinMoRong = JSON.stringify(thongTinLocalCV);
    let model = {
      MaNguoiDung: "",
      NoiDungCVReview: strThongTinMoRong,
    };

    NguoiDungSevice.nopUserCV_Service(model).then((res) => {
      message.info("Đã nộp thành công bạn vui lòng đợi thông tin từ admin nhé");
      dispatchLocal({
        type: CONSTANTS.SET_IS_GUI_THANH_CONG,
        payload: true,
      });
    });
  }

  return (
    <>
      <div
        style={{ flexGrow: "1" }}
        className="h-max-content min-h-screen w-full  flex"
      >
        <div className="w-60">
          <SidebarNew />
        </div>
        <div
          style={{ flexGrow: "1" }}
          className="h-max-cotent flex flex-col  min-h-screen "
        >
          <Navbar />
          <div className=" w-full bg-cover  space-y-2 chung-nhan h-{100vh}">
          <Breadcrumb>
        <Breadcrumb.Item>
          <NavLink to="/quan-ly-cv">Quản lý CV</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
         Chọn template
        </Breadcrumb.Item>
      </Breadcrumb>
            <div className="flex justify-content-between">
              <NavLink to={`/user-cv`}>
                <button className=" px-2 py-1 bg-blue-theme text-white rounded shadow hover:shadow-lg transition">
                  <i className="fa fa-file-export text-white mr-2"></i> Edit
                  thông tin
                </button>
              </NavLink>
              {diemCv.diem === 100 ? (
                <button
                  className=" px-2 py-1 bg-blue-theme text-white rounded shadow hover:shadow-lg transition"
                  onClick={handleSendCV}
                  disabled={state.isDaGuiThanhCongCv}
                >
                  Gửi CV cho Admin review
                </button>
              ) : (
                <Tooltip
                  title={
                    diemCv.diem < 70
                      ? "Bạn cần phải bổ sung thông tin còn thiếu mới gửi cho admin được"
                      : "CV của bạn chưa đạt điểm tối đa bạn nên bổ sung đủ thông tin trước khi nộp"
                  }
                >
                  <button
                    className=" px-2 py-1 bg-blue-theme text-white rounded shadow hover:shadow-lg transition"
                    disabled={diemCv.diem < 70 || state.isDaGuiThanhCongCv}
                    onClick={handleSendCV}
                  >
                    Gửi CV cho Admin review
                  </button>
                </Tooltip>
              )}

              {/* <button className="px-2 py-1 bg-blue-theme text-white rounded shadow hover:shadow-lg transition" onClick={handlePrint}>In thử CV</button> */}
            </div>
            <div className="div container">
              <div className="row">
                <div className="col-3">
                  <h5 className="mb-5">Chọn các thông tin của template</h5>
                  <div className="form-group d-flex align-content-center">
                    <label className="font-bold">Ẩn link facebook: </label>
                    <input
                      type="checkbox"
                      className="ml-2"
                      style={{ width: "20px", height: "20px" }}
                      onClick={(e) =>
                        handleChangeAnLinkFacebook(e.target.checked)
                      }
                      checked={thongTinLocalCV?.isHiddenFacebookLink}
                    />
                  </div>
                  <div className="form-group d-flex align-content-center">
                    <label className="font-bold">Ẩn hình avatar: </label>
                    <input
                      type="checkbox"
                      className="ml-2"
                      style={{ width: "20px", height: "20px" }}
                      onClick={(e) => {
                        handleChangeAnAvatar(e.target.checked);
                      }}
                      checked={thongTinLocalCV?.isHiddenAvatar}
                    />
                  </div>
                  <div className="form-group align-content-center">
                    <label className="font-bold">Chọn loại template: </label>
                    <br></br>
                    <Select
                      className="mb-2"
                      size={"default"}
                      value={thongTinLocalCV?.layoutTemplate}
                      onChange={(value) => {
                        let newThongTinLocalCv = {
                          ...thongTinLocalCV,
                          layoutTemplate: value,
                        };

                        dispatch({
                          type: types.CAP_NHAT_CV_LOCAL,
                          payload: newThongTinLocalCv,
                        });
                      }}
                      style={{ width: 200 }}
                    >
                      {danhSachOptionTemplate}
                    </Select>
                  </div>

                  {diemCv?.diem === 100 ? (
                    <Tag color="green">CV của bạn rất tốt</Tag>
                  ) : (
                    <Tag color="red">Điểm CV của bạn là : {diemCv.diem}</Tag>
                  )}

                  {diemCv?.diem === 100 ? null : (
                    <ul>
                      {diemCv?.errorArray?.map((item, index) => {
                        return (
                          <li className="pt-2" key={`errorCv_${index}`}>
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
                <div className="col-9">
                  <TemplateCVPrint ref={componentRef} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
