import React from "react";
import { useParams } from "react-router-dom";

import { colors } from "../../../../../constant";
import ItemBaiTapNop from "./ItemBaiTapNop";

export default function ContentBaiTapNop({ data }) {
  let params = useParams();

  return (
    <div style={{ width: "800px" }} className=" space-y-5">
      {data.map((item, index) => {
        let color = colors[index % 2];
        return (
          <ItemBaiTapNop data={item} idKhoaHoc={params.id} color={color} />
        );
      })}
    </div>
  );
}
