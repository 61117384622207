import { Progress } from "antd";
import { Tooltip, Popover } from "antd";
import React from "react";
import { useMeasure } from "react-use";
import { dateFormat } from "../../../utilities/dateTimeFormat";
import { LocalStorageUtil } from "../../../utilities/localStorage";
import { checkIsHocVienXuatSac } from "../../../utilities/roadmap";
import CauHoiKhoKhanDaHoi from "./cauHoiKhoKhanDaHoi/CauHoiKhoKhanDaHoi";
import ChungNhanPDF from "./ChungNhanPDF/ChungNhanPDF";
import DanhGiaMentor from "./danhGiaMentor/DanhGiaMentor";
import Overlay_ChucMung from "./Overlay_ChucMung/Overlay_ChucMung";
import ReportBangDiem from "./ReportBangDiem/ReportBangDiem";
import DanhGiaMentorV2 from "./danhGiaMentor/DanhGiaMentorV2";
import moment from "moment";
import UserDiemTichCuc from "./userDiemTichCuc/UserDiemTichCuc";
import { getHinhAnhFB } from "../../../utilities/utils";

export default function UserInfor({ dataRoadMap }) {
  let nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  let copyToken = () => {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = dataRoadMap.thongTinLopHoc?.token;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };
  let resultXuatSac = checkIsHocVienXuatSac(
    nguoiDung?.id,
    dataRoadMap?.thongTinLopHoc?.listThuHangHocVienTrongLop
  );

  // get current size
  const [refCurrentEl, { width, height }] = useMeasure();
  return (
    <div
      ref={refCurrentEl}
      style={{ maxWidth: "440px" }}
      className="info_card card_theme w-1/2  lg:w-2/5    h-full flex flex-col justify-center relative  "
    >
      {resultXuatSac && <Overlay_ChucMung height={height} width={width} />}
      <div
        className="w-full h-full relative z-20 p-5 flex-col flex justify-center 
      "
      >
        <div className="w-full  items-center  flex space-x-5  ">
          <div className="flex flex-col justify-center py-3 pt-5">
            <div className="relative w-24 h-24">
              <div className="relative w-24 h-24">
                {/* Vòng tròn progress */}
                <Progress
                  type="circle"
                  strokeColor={{
                    "0%": "#5B86E5",
                    "100%": "#36D1DC",
                  }}
                  percent={70}
                  showInfo={false}
                  trailColor={"rgba(68, 66, 178, 0.1)"}
                  className="w-24 h-24 relative z-10"
                />

                {/* Hình ảnh */}
                <div className="absolute inset-0 flex items-center justify-center z-20">
                  <img
                    src={getHinhAnhFB(nguoiDung.facebookId, nguoiDung)}
                    alt="Avatar"
                    className="w-24 h-24 rounded-full"
                    style={{ scale: "0.9" }}
                  />
                </div>
              </div>
              <div className="w-full h-full absolute left-0 top-0 rounded-full card_theme flex justify-center items-center">
                <i className="fa fa-user text-4xl text-blue-400"></i>
              </div>
            </div>
            <p className="mt-3 font-medium text-blue-theme text-base">
              {nguoiDung.hoTen}
            </p>
          </div>
          <div className="flex h-full justify-center space-y-3 flex-col text-sm">
            <p className="mt-3 font-medium text-blue-theme text-base">
              Lớp: {dataRoadMap?.thongTinLopHoc?.tenLopHoc}
            </p>
            <p>
              {" "}
              <span>Ngày bắt đầu:</span>{" "}
              {dataRoadMap?.thongTinLopHoc?.ngayBatDau &&
                dateFormat(dataRoadMap?.thongTinLopHoc?.ngayBatDau)}
            </p>
            <p>
              <span>Ngày kết thúc:</span>{" "}
              {dataRoadMap?.thongTinLopHoc?.ngayKetThuc &&
                dateFormat(dataRoadMap?.thongTinLopHoc?.ngayKetThuc)}
            </p>
            <UserDiemTichCuc dataRoadMap={dataRoadMap} />
          </div>
        </div>
        {resultXuatSac && (
          <p className="p-0 m-0 text-center font-medium mb-5">
            Chúc mừng bạn đã đạt hạng{" "}
            <span className=" text-xl text-red-600">{resultXuatSac}</span>
          </p>
        )}
        <div className="flex w-full  justify-around   ">
          <button
            className="text-white btn-theme transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300 w-max"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <ReportBangDiem dataRoadMap={dataRoadMap} />
          </button>

          <Tooltip title={<p className="text-dark">Đã copy</p>} trigger="click">
            <button
              className="text-white bg-blue-theme w-24 transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300"
              onClick={(event) => {
                event.stopPropagation();
                copyToken();
              }}
            >
              Token
            </button>
          </Tooltip>

          {moment().isBefore(
            moment(dataRoadMap.thongTinLopHoc?.ngayKetThuc).add(45, "days")
          ) ? (
            <button
              className="text-white btn-theme transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300 w-max"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {/* <DanhGiaMentor dataRoadMap={dataRoadMap} /> */}
              <DanhGiaMentorV2 dataRoadMap={dataRoadMap} />
            </button>
          ) : null}
        </div>
        <div className="flex w-full  justify-around  pt-3 ">
          <ChungNhanPDF dataRoadMap={dataRoadMap} />
          {dataRoadMap?.CauHoiKhoKhanDaHoi?.length > 0 ? (
            <button
              className="text-white btn-theme transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300 w-max"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <CauHoiKhoKhanDaHoi dataRoadMap={dataRoadMap} />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
