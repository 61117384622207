import React, { useEffect, useState, useReducer } from "react";
import {
  message,
  Modal,
  Rate,
  Tabs,
  Input,
  Popconfirm,
  Tooltip,
  Table,
  Button,
} from "antd";
import {
  layDanhMucDanhGia,
  layDanhSachDanhGia,
  layDanhSachDanhGiaTrongThang,
} from "../../../../redux/actions/nguoiDungAction";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { LocalStorageUtil } from "../../../../utilities/localStorage";
import { NguoiDungSevice } from "../../../../service/nguoiDungService";
import { getDataRoadMapServAction } from "../../../../redux/actions/roadMapAction";
import moment from "moment";
const { TabPane } = Tabs;
const { TextArea } = Input;

const desc = ["rất tệ", "tệ", "bình thường", "tốt", "rất tốt"];
const CONSTANTS = {
  INPUT_LOC_BAI_TAP: "INPUT_LOC_BAI_TAP",
  GIA_HAN_BAI_TAP_THANH_CONG: "GIA_HAN_BAI_TAP_THANH_CONG",
  SET_MODAL_VISIBLE: "SET_MODAL_VISIBLE",
};
const initialState = {
  danhSachBaiTapHetHanMotNgay: [],
  visible: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CONSTANTS.INPUT_LOC_BAI_TAP:
      return {
        ...state,
        danhSachBaiTapHetHanMotNgay: payload,
      };
    case CONSTANTS.GIA_HAN_BAI_TAP_THANH_CONG: {
      return {
        ...state,
        danhSachBaiTapHetHanMotNgay: state.danhSachBaiTapHetHanMotNgay.filter(
          (item) => {
            return item.maBaiTap != payload.maBaiTap;
          }
        ),
        visible: false,
      };
    }
    case CONSTANTS.SET_MODAL_VISIBLE:
      return {
        ...state,
        visible: payload,
      };
    default:
      return state;
  }
};

export default function UserDiemTichCuc({ dataRoadMap }) {
  const [state, dispatchLocal] = useReducer(reducer, initialState);

  const { id } = useParams();
  const dispatch = useDispatch();
  const formatDate = "DD/MM/YYYY";

  //Debug
  //   thongTinDangNhap.id = "26826898-eb07-4880-891a-432335a7f23a";

  const showModal = () => {
    dispatchLocal({ type: CONSTANTS.SET_MODAL_VISIBLE, payload: true });
  };

  const handleOk = () => {
    setTimeout(() => {
      dispatchLocal({ type: CONSTANTS.SET_MODAL_VISIBLE, payload: false });
    }, 3000);
  };

  const handleCancel = () => {
    dispatchLocal({ type: CONSTANTS.SET_MODAL_VISIBLE, payload: false });
  };

  const columns = [
    {
      title: "Thứ tự buổi học",
      render: (text, record) => {
        return record.stt;
      },
      key: "stt",
      dataIndex: "stt",
    },
    {
      title: "Tên bài tập",
      render: (text, record) => {
        return record.tieuDe;
      },
      key: "tieuDe",
      dataIndex: "tieuDe",
    },
    {
      title: "Ngày hết hạn",
      render: (text, record) => {
        return record.ngayHetHan;
      },
      key: "ngayHetHan",
      dataIndex: "ngayHetHan",
    },

    {
      title: "Hành động",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Bạn có chắc muốn gia hạn không ?"
            onConfirm={() => handleGiaHanBaiTap(record)}
          >
            <Button
              type="primary"
              style={{
                background: "green",
                color: "white",
                borderColor: "green",
              }}
            >
              Gia hạn bài tập
            </Button>
          </Popconfirm>
        );
      },
      key: "hanhDong",
      align: "center",
    },
  ];

  const handleGiaHanBaiTap = (record) => {
    let data = {
      hanNop: new Date(),
      maBaiHoc: record.maBaiTap,
      maLop: id,
      maBuoiHoc: record.maBuoiHoc,
    };

    NguoiDungSevice.giaHanBaiTapBangDiemTichCuc(data)
      .then((res) => {
        message.success("Gia hạn thành công !");
        dispatchLocal({
          type: CONSTANTS.GIA_HAN_BAI_TAP_THANH_CONG,
          payload: record,
        });
        dispatch(getDataRoadMapServAction(id));
      })
      .catch((err) => message.error("Đã có lỗi xảy ra !"));
  };

  useEffect(() => {
    let isMount = true;
    if (isMount) {
      const { danhSachBuoiHocTheoSkill } = dataRoadMap;

      // Lap qua tung skill
      let dsBaiTapVuaHetHanMotNgay = [];
      let ngayHienTai = moment();
      danhSachBuoiHocTheoSkill?.map((skill, index) => {
        // Chưa active thì bỏ
        if (!skill.isActive) return;
        // Lap qua tung buoi
        skill?.danhSachBuoiHoc?.map((buoiHoc, index) => {
          //Chưa hoc thi bo khong duyet
          if (!buoiHoc.isActive) return;
  
          // Kiểm tra bai tap trac nghiem va bai tap tuan de gia han
          let dsBaiTap = [...buoiHoc.taiLieuBaiTap, ...buoiHoc.tracNghiem];
  
          dsBaiTap.map((baiTap) => {
            // Chưa hết hạn thì không xử lý
            if (!baiTap.isHetHan) return;
  
            let ngayHetHan = moment(baiTap.hanNop).subtract(7, "hours");
  
            let chenhLechNgayHetHan = ngayHetHan.diff(ngayHienTai, "days");
  
            // Nếu mà vừa hết hạn 1 ngày thì thêm vào list
  
            if (chenhLechNgayHetHan === -1 && baiTap.diem == 0) {
              dsBaiTapVuaHetHanMotNgay.push({
                maBuoi: buoiHoc.id,
                maBaiTap: baiTap.id,
                stt: buoiHoc.stt,
                loaiBaiTap: buoiHoc.loaiBaiTap,
                maLop: id,
                tieuDe: baiTap.tieuDe,
                ngayHetHan: moment(baiTap.hanNop)
                  .subtract(7, "hours")
                  .format(formatDate),
              });
  
              dispatchLocal({
                type: CONSTANTS.INPUT_LOC_BAI_TAP,
                payload: dsBaiTapVuaHetHanMotNgay,
              });
            }
          });
        });
      });
  
    }
    
    return () => {
      isMount = false;
    };
  }, [dataRoadMap?.danhSachBuoiHocTheoSkill?.length]);

  return (
    <>
      <Tooltip title="Điểm tích cực dùng để gia hạn bài tập tuần / trắc nghiệm quá hạn một ngày.Bạn hãy đánh giá giảng viên - mentor mỗi tháng để được điểm tích cực nhé">
        <span className="font-medium text-blue-500 ">
          <i className="fa fa-star text-blue-500 mr-1"></i>
          <span className="underline">Điểm tích cực:</span>{" "}
          <span className="text-red-500">{dataRoadMap?.diemTichCuc}</span>
          {dataRoadMap?.diemTichCuc &&
          state.danhSachBaiTapHetHanMotNgay.length > 0 ? (
            <button
              className="text-white btn-theme transform hover:-translate-y-1 rounded-lg p-1 text-sm px-2 shadow-lg hover:shadow-xl transition duration-300 w-max ml-1"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <span className="text-sm2" onClick={showModal}>
                Sử dụng
              </span>
            </button>
          ) : (
            ""
          )}
        </span>
      </Tooltip>

      <Modal
        visible={state.visible}
        title="Danh sách các bài tập vừa hết hạn một ngày "
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="90vw"
        height="90vh"
        style={{ top: 10 }}
      >
        <Table
          bordered
          dataSource={state?.danhSachBaiTapHetHanMotNgay}
          columns={columns}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["3", "5", "10", "15"],
            showSizeChanger: true,
          }}
          className="pr-3"
        ></Table>
      </Modal>
    </>
  );
}
