import React from "react";
import ListHocVienXuatSac from "./ListHocVienXuatSac/ListHocVienXuatSac";
import ThongKeTotal from "./ThongKeTotal";

export default function ThongKeLoTrinh({ dataRoadMap = [] }) {
  return (
    <div className="flex space-x-3  w-full min-h-22   h-max-content flex-grow ">
      <ThongKeTotal dataRoadMap={dataRoadMap} />
      <ListHocVienXuatSac
        listThuHangHocVienTrongLop={
          dataRoadMap?.thongTinLopHoc?.listThuHangHocVienTrongLop || []
        }
      />
    </div>
  );
}
