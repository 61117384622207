import * as types from '../types';
import { LopHocSevice } from '../../service/lopHocService';
import { KhoaHocService } from '../../service/khoaHocService';

//-----------========= LAY DANH SACH KHOA HOC ==========-------------
export const layDanhSachKhoaHoc = (classId) => {
    return dispatch => {
        LopHocSevice.layDanhSachKhoaHocCuaLopService(classId)
        .then(res => dispatch(layDanhSachKhoaHocThanhCong(res.data.content)))
        .catch(err => console.log(err.response));
    }
}

export const layDanhSachKhoaHocThanhCong = (data) =>{
    return {
        type: types.LAY_DANH_SACH_KHOA_HOC_THANH_CONG,
        payload: data
    }
}

//-----------========= LAY DANH SACH KHOA HOC FREE ==========-------------
export const layDanhSachKhoaHocFree = () => {
    return dispatch => {
        KhoaHocService.layDanhSachKhoaHocFreeService()
        .then(res => dispatch(layDanhSachKhoaHocFreeThanhCong(res.data.content)))
        .catch(err => console.log(err.response));
    }
}

export const layDanhSachKhoaHocFreeThanhCong = (data) =>{
    return {
        type: types.LAY_DANH_SACH_KHOA_HOC_FREE_THANH_CONG,
        payload: data
    }
}

//-----------========= LAY CHI TIET KHOA HOC FREE ==========-------------
export const layChiTietKhoaHocFree = (courseId) => {
    return dispatch => {
        KhoaHocService.layChiTietKhoaHocFreeService(courseId)
        .then((res) => {
            dispatch(layChiTietKhoaHocFreeThanhCong(res.data.content))
        }
        )
        .catch(err => console.log(err.response));
    }
}

export const layChiTietKhoaHocFreeThanhCong = (data) =>{
    return {
        type: types.LAY_CHI_TIET_KHOA_HOC_FREE_THANH_CONG,
        payload: data
    }
}


//-----------========= LAY CHI TIET KHOA HOC ==========-------------
export const layChiTietKhoaHoc = (courseId) => {
    return dispatch => {
        KhoaHocService.layChiTietKhoaHocService(courseId)
        .then((res) => {
  
            
            dispatch(layChiTietKhoaHocThanhCong(res.data.content))
        }
        )
        .catch(err => console.log(err.response));
    }
}

export const layChiTietKhoaHocThanhCong = (data) =>{
    return {
        type: types.LAY_CHI_TIET_KHOA_HOC_THANH_CONG,
        payload: data
    }
}

//-----------========= CAP NHAT CHI TIET KHOA HOC ==========-------------
export const capNhatChiTietKhoaHoc = (khoaHoc) =>{
    return {
        type: types.CAP_NHAT_CHI_TIET_KHOA_HOC,
        payload: khoaHoc
    }
}