import React, { useState } from "react";
import { Modal } from "antd";
import { ContentReportBangDiem } from "./ContentReportBangDiem";
import "./style.css";
import { getDataReportFromRoadmap } from "./report_utils";
export default function ReportBangDiem({ dataRoadMap }) {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setTimeout(() => {
      setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  let dataReport = getDataReportFromRoadmap(dataRoadMap);

  return (
    <>
      <span className=" text-sm" onClick={showModal}>
        Xem report
      </span>
      <Modal
        visible={visible}
        title="Report lộ trình học"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width="90vw"
        height="90vh"
        style={{ top: 10 }}
      >
        <div
          style={{ height: "85vh", width: "90vw" }}
          className="  overflow-y-scroll  "
        >
          <ContentReportBangDiem
            dataRoadMap={dataRoadMap}
            dataReport={dataReport}
          />
        </div>
      </Modal>
    </>
  );
}
