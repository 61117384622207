import React, {useState} from 'react'
import {message, Modal, Tooltip, Icon, Button, Upload} from 'antd'
import {boDauTiengViet} from '../../../utilities/stringFormat'
import axios from 'axios';
import _ from 'lodash';
import { DOMAIN_API } from '../../../config/urlConfig';


export default function EditThongTinCoBanModalComponent(props) {
    const { thongTinMoRong, dispatchLocal, isEditProfile, CONSTANTS } = props;

    const [isAnhAvatarError, setIsAnhAvatarError] = useState(false)

    function getValueFromId(idString) {

      if (!document.getElementById(idString)){
        return ""
      }
      return document.getElementById(idString).value;
    }

    const [anhAvatar, setAnhAvatar] = useState(()=>thongTinMoRong?.anhAvatar);
    function getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
  
  
    const handleChangeUploadImage = (info) => {
      if (info.file.status === 'uploading') {
          return;
      }
      if (info.file.status === 'done') {
          getBase64(info.file.originFileObj, imageUrl => {
              // console.log("value",info.file.response)
              setAnhAvatar(info.file.response.split("/").at(-1));
          });
          return;
      }
  }
  
    function beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("Chỉ được phép tải lên hình JPG/PNG!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Hình tải lên tối đa 2MB!");
      }
      return isJpgOrPng && isLt2M;
    }

    function handleLuuThongTin() {

      
      
  
      let chucDanh = getValueFromId("chucDanh");
      let diaChi = getValueFromId("diaChi");
     
      let linkFacebook = getValueFromId("linkFacebook");
      let linkGitHub = getValueFromId("linkGitHub");
      let linkLinkedIn = getValueFromId("linkLinkedIn");
      let hoTen = getValueFromId("hoTen");
      let soDienThoai = getValueFromId("soDienThoai");
      let email = getValueFromId("email");
      let isHiddenFacebookLink =
        document.getElementById("hiddenFacebookLink").checked;

        

  
      if (
        chucDanh.trim() == "" ||
        diaChi.trim() == "" ||
        linkFacebook.trim() == "" ||
        linkGitHub.trim() == "" ||
        linkLinkedIn.trim() == "" ||
        hoTen.trim() == "" ||
        soDienThoai.trim() == "" ||
        email.trim() == ""
      ) {
        message.error("Hãy điền đầy đủ thông tin !");
        return;
      }
  
      // linkOnlineCV = null ? "" : linkOnlineCV;

      chucDanh = boDauTiengViet(chucDanh)
      diaChi = boDauTiengViet(diaChi)
 
      let editProfileModal = {
        chucDanh,
        diaChi,
        anhAvatar,
        linkFacebook,
        linkGitHub,
        linkLinkedIn,
        hoTen,
        soDienThoai,
        email,
        isHiddenFacebookLink,
      };
      
     
  
      dispatchLocal({
        type: CONSTANTS.UPDATE_PROFILE_DATA,
        payload: editProfileModal,
      });
    }
    const handleCheckSquareImageDebounce =  _.debounce(handleCheckSquareImage, 500)
  

  function handleCheckSquareImage() {
    let imgUrl = getValueFromId("anhAvatar");

    if (imgUrl.trim() === '') {
      return
    }

    axios.get(imgUrl, { responseType: 'blob' })
    .then(response => {
      const url = URL.createObjectURL(response.data);
      const img = new Image();
      
      img.onload = function() {
        const width = img.width;
        const height = img.height;

        if (width !== height) {
          message.info("Bạn nên sử dụng ảnh vuông để CV được đẹp hơn nhé.")
          setIsAnhAvatarError(false)
        } else {
          setIsAnhAvatarError(false)
        }
      };
      
      img.onerror = function() {
        setIsAnhAvatarError(true)
        message.error("URL ảnh không hợp lệ hoặc không tải được ảnh.");
      };
      
      img.src = url;
    })
    .catch(error => {
      setIsAnhAvatarError(true)
      message.error("URL ảnh không hợp lệ hoặc không tải được ảnh.");
      console.error(error);
    });
      
  }

  
  async function checkUrlValid(url) {

    let urlTrim = url.trim()
    

    if (!urlTrim.startsWith('https://')) {
      message.error('Link phải bắt đầu bằng https://, bạn vui lòng kiểm tra lại link nhé')
      return
    }
    
    if (urlTrim === '') {
      return 
    }
      // try {
      //   const response = await fetch(url);
      //   if (response.ok) {
      //     return
      //   } else {
      //    message.error('Link vừa nhập bị lỗi, bạn vui lòng kiểm tra lại link nhé')
      //   }
      // } catch (error) {
      //    message.error('Link vừa nhập bị lỗi, bạn vui lòng kiểm tra lại link nhé')
      // }
  }

  const handelCheckUrlValidDebouce = _.debounce(checkUrlValid, 500)
  
    return (
      <div>
        <button
          className="btn btn-primary ml-auto"
          onClick={() =>
            dispatchLocal({ type: CONSTANTS.SET_EDIT_PROFILE, payload: true })
          }
        >
          Edit
        </button>
        <Modal
          title="Edit Profile"
          visible={isEditProfile}
          footer={null}
          bodyStyle={{ width: "50vw" }}
          onCancel={() =>
            dispatchLocal({ type: CONSTANTS.SET_EDIT_PROFILE, payload: false })
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-12" id="editForm">
              <Tooltip title='Để thay đổi tên bạn vui lòng liên hệ trung tâm.'>
                <div className="form-group">
                  <label className="font-bold">Name: </label>
                 
                    <input
                    type="text"
                    className="form-control"
                    id="hoTen"
                    defaultValue={thongTinMoRong?.hoTen}
                    disabled={true}
                  />
                  
                  
                </div>
                </Tooltip>
                <div className="form-group">
                  <label className="font-bold">PhoneNumber: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="soDienThoai"
                    placeholder="Nhập số điện thoại"
                    defaultValue={thongTinMoRong?.soDienThoai}
                  />
                </div>
                <div className="form-group">
                  <label className="font-bold">Email: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Nhập link email"
                    defaultValue={thongTinMoRong?.email}
                  />
                </div>
                {/* <div className="form-group">
                  <label className="font-bold">Image Avatar URL: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="anhAvatar"
                    placeholder="Nhập link ảnh"
                    defaultValue={thongTinMoRong?.anhAvatar}
                    onChange={handleCheckSquareImageDebounce}
                  />
                </div> */}
                <div className="form-group">
              <Upload
                action= {`${DOMAIN_API}/api/file/avatar-cv`}
                listType= "picture-card"
                multiple= "false"
                onChange={handleChangeUploadImage}
                beforeUpload={beforeUpload}
                accept= "image/*"
                showUploadList={false}

              >
                {anhAvatar !== "" ? <img src={`${DOMAIN_API}/avatar-cv/${anhAvatar}`} alt="avatar" /> : <Button>
                  <Icon type="upload" /> Upload Avatar Image
                </Button>}
               
                
              </Upload>
            </div>
                <div className="form-group">
                  <label className="font-bold">Facebook Link: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="linkFacebook"
                    placeholder="Nhập link facebook"
                    defaultValue={thongTinMoRong?.linkFacebook}
                    onChange={(e) => handelCheckUrlValidDebouce(e.target.value)}
                  />
                </div>
                <div className="form-group d-flex align-content-center">
                  <label className="font-bold">Hidden Facebook Link: </label>
                  <input
                    type="checkbox"
                    className="ml-2"
                    style={{ width: "20px", height: "20px" }}
                    id="hiddenFacebookLink"
                    defaultValue={thongTinMoRong?.isHiddenFacebookLink}
                  />
                </div>
                <div className="form-group">
                  <label className="font-bold">Github Link: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="linkGitHub"
                    placeholder="Nhập link GitHub"
                    defaultValue={thongTinMoRong?.linkGitHub}
                    onChange={(e) => handelCheckUrlValidDebouce(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="font-bold">Link LinkedIn: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="linkLinkedIn"
                    placeholder="Nhập link GitHub"
                    defaultValue={thongTinMoRong?.linkLinkedIn}
                    onChange={(e) => handelCheckUrlValidDebouce(e.target.value)}
                  />
                </div>
  
                {/* <div className="form-group">
                  <label className="font-bold">Link CV online: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="linkOnlineCV"
                    placeholder=" Nhập link CV Online"
                    defaultValue={thongTinMoRong?.linkOnlineCV}
                  />
                </div> */}
               
                <div className="form-group">
                  <label className="font-bold">Job Title: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="chucDanh"
                    placeholder="Front-End developer, Back-End developer, Full-Stack developer,..."
                    defaultValue={thongTinMoRong?.chucDanh}
                  />
                </div>
                <div className="form-group">
                  <label className="font-bold">Address: </label>
                  <input
                    type="text"
                    className="form-control"
                    id="diaChi"
                    // placeholder=" phường xxx, quận xxx, thành phố xxx"
                    defaultValue={thongTinMoRong?.diaChi}
                  />
                </div>
  
                <button className="btn btn-primary" onClick={handleLuuThongTin}>
                  Cập nhật
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }