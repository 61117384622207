import { BaseService } from "./baseService";

const API_URL = "/api/lophoc";

const layDanhSachLopHocService = (userId) => {
  return BaseService.get(`${API_URL}/byuser-and-check-road-map/${userId}`);
  //   return BaseService.get(`${API_URL}/byuser/${userId}`);
};

const layDanhSachKhoaHocCuaLopService = (classId) => {
  return BaseService.get(`${API_URL}/course/${classId}`);
};

// const layDanhSachXemLaiTheoLopService = (classId) => {
//   return BaseService.get(`api/xemlaibuoihoc/lay-theo-lop/${classId}`);
// };
//Cập nhật api lấy video xem lại có lọc học viên
const layDanhSachXemLaiTheoLopService = (classId) => {
  return BaseService.get(`api/xemlaibuoihoc/lay-theo-lop-hoc-vien/${classId}`);
};

const layDanhSachXemLaiService = () => {
  return BaseService.get(`api/xemlaibuoihoc`);
};

const xoaXemLaiService = (lstId) => {
  return BaseService.remove(`api/xemlaibuoihoc`, lstId);
};

const layConfigXemLaiBuoiHoc = () => {
  return BaseService.get(`api/xemlaibuoihoc/lay-config-xoa-video`);
};
const layDanhSachBoDeService = (id) => {
  return BaseService.get(`api/nhomBoDe/nguoiDung?maNguoiDung=${id}`);
};


export const LopHocSevice = {
  layDanhSachLopHocService,
  layDanhSachKhoaHocCuaLopService,
  layDanhSachXemLaiTheoLopService,
  layDanhSachXemLaiService,
  xoaXemLaiService,
  layConfigXemLaiBuoiHoc,
  layDanhSachBoDeService
};
