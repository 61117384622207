import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "./../../assets/images/logoCyber.png";
import HomeMobile from "./homeMobile/HomeMobile";
import * as action from "./../../redux/actions/lopHocAction";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LocalStorageUtil } from "../../utilities/localStorage";
import {
  set_request_spinner_ended,
  set_request_spinner_started,
} from "../../redux/actions/spinnerAction";
import NewBottomNavigationMobile from "./newBottomNavigationMobile/NewBottomNavigationMobile";

export default function RoadMapMobile() {
  const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
  const dispatch = useDispatch();
  const [navbar, setNavbar] = useState(false);
  const { dsLopHoc } = useSelector((state) => state.lopHocReducer);
  // useEffect(() => {
  //   dispatch(set_request_spinner_started());
  //   dispatch(action.layDanhSachLopHoc(nguoiDung.id));
  //   dispatch(set_request_spinner_ended());
  // }, []);
  // console.log(dsLopHoc);
  const renderLopDangHoc = () => {
    return dsLopHoc
      ? dsLopHoc
          .filter((item) => {
            return item.maTrangThai == 2;
          })
          .map((item, index) => {
            return (
              <li>
                <Link
                  className="flex items-center p-2 text-base font-normal  rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-900 no-underline"
                  to={`/course-detail/${item.id}`}
                >
                  <svg
                    className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                    ></path>
                  </svg>
                  <span className="ml-3">{item.tenLopHoc}</span>
                </Link>
              </li>
            );
          })
      : null;
  };

  return (
    <div className="w-full h-screen lg:hidden">
      <nav className="w-full bg-white shadow">
        <div className="justify-between mx-auto lg:max-w-7xl md:items-center md:flex ">
          <div className="w-full px-5 ">
            <div className="flex items-center justify-between md:block py-3">
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <div className="md:hidden">
                <h3 className="text-lg font-bold mb-0">
                  <Link className="text-black" to="/home-mobile">
                    Dash Board
                  </Link>
                </h3>
              </div>
              <Link to="/" className="no-underline">
                <img src={logo} width="40" alt="" className="h-full" />
              </Link>
            </div>
          </div>
          <div>
            {/* <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:hidden md:pb-0 md:mt-0 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ul className="items-center justify-center space-y-5 md:flex md:space-x-6 md:space-y-0  absolute top-16 left-0 right-0 bottom-0 bg-white bg-opacity-95 z-40 p-5">
                <li className="text-black cursor-pointer text-xl p-3 hover:bg-gray-300 hover:text-black rounded tran ease-in duration-300">
                  <Link to="#">
                    <i className="text-black fa-solid fa-graduation-cap mr-2"></i>
                    <span className="text-black">FrontEnd 73</span>
                  </Link>
                </li>
                <li className="text-black cursor-pointer text-xl p-3 hover:bg-gray-300 hover:text-black rounded tran ease-in duration-300">
                  <Link to="#">
                    <i className="text-black fa-solid fa-graduation-cap mr-2"></i>
                    <span className="text-black">FrontEnd 74</span>
                  </Link>
                </li>
                <li className="text-black cursor-pointer text-xl p-3 hover:bg-gray-300 hover:text-black rounded tran ease-in duration-300">
                  <Link to="#">
                    <i className="text-black fa-solid fa-graduation-cap mr-2"></i>
                    <span className="text-black">FrontEnd 75</span>
                  </Link>
                </li>
                <li className="text-black cursor-pointer text-xl p-3 hover:bg-gray-300 hover:text-black rounded tran ease-in duration-300">
                  <Link to="#">
                    <i className="text-black fa-solid fa-graduation-cap mr-2"></i>
                    <span className="text-black">FrontEnd 76</span>
                  </Link>
                </li>
              </ul>
            </div> */}
            <Drawer
              title={<div className="font-semibold">Danh sách các lớp học</div>}
              placement="left"
              closable={false}
              className="z-20 card_theme"
              onClose={() => {
                setNavbar(false);
              }}
              visible={navbar}
            >
              <ul className="items-center justify-center space-y-3 md:flex md:space-x-6 md:space-y-0  absolute top-16 left-0 right-0 bottom-0 card_theme bg-opacity-95 z-40 p-3">
                {renderLopDangHoc()}
                {/* <li className="text-black cursor-pointer text-lg hover:shadow text-opacity-90 p-3 hover:bg-gray-300 hover:text-black rounded tran ease-in duration-300">
                  <Link to="#">
                    <i className="text-black fa-solid fa-graduation-cap mr-2"></i>
                    <span className="text-black">FrontEnd 73</span>
                  </Link>
                </li>
                <li className="text-black cursor-pointer text-lg hover:shadow text-opacity-90 p-3 hover:bg-gray-300 hover:text-black rounded tran ease-in duration-300">
                  <Link to="#">
                    <i className="text-black fa-solid fa-graduation-cap mr-2"></i>
                    <span className="text-black">FrontEnd 74</span>
                  </Link>
                </li>
                <li className="text-black cursor-pointer text-lg hover:shadow text-opacity-90 p-3 hover:bg-gray-300 hover:text-black rounded tran ease-in duration-300">
                  <Link to="#">
                    <i className="text-black fa-solid fa-graduation-cap mr-2"></i>
                    <span className="text-black">FrontEnd 75</span>
                  </Link>
                </li>
                <li className="text-black cursor-pointer text-lg hover:shadow text-opacity-90 p-3 hover:bg-gray-300 hover:text-black rounded tran ease-in duration-300">
                  <Link to="#">
                    <i className="text-black fa-solid fa-graduation-cap mr-2"></i>
                    <span className="text-black">FrontEnd 76</span>
                  </Link>
                </li> */}
              </ul>
            </Drawer>
          </div>
        </div>
      </nav>
      {/* Content  */}
      <HomeMobile />
      {/* <ChiTietLopMobile /> */}
      {/* <UserMobile /> */}
      {/* <XemVideoMobile /> */}
      {/* <BottomNavigationMobile /> */}
      <NewBottomNavigationMobile />
    </div>
  );
}
