import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { LocalStorageUtil } from "../../utilities/localStorage";
import * as actions from "../../redux/actions/lopHocAction";
import * as khoaHocActions from "../../redux/actions/khoaHocAction";
import * as baiTapActions from "../../redux/actions/baiTapActions";
import Button_HuongDanHocTap from "./Button_HuongDanHocTap/Button_HuongDanHocTap";
import history from "../../utilities/history";
import { Menu } from "antd";

const { SubMenu } = Menu;

class SidebarItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
    };
  }

  toggleDropdown = (event) => {
    event.preventDefault(); // Ngăn chặn hành vi mặc định của NavLink
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  };
  componentDidMount() {}
  onGoToKhoaHoc = (index, lopHoc) => {
    // this.setState({ viTri: index });
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
    this.props.dispatch(khoaHocActions.layDanhSachKhoaHoc(lopHoc.id));
    this.props.dispatch(
      baiTapActions.layDanhSachBaiTap(lopHoc.id, nguoiDung.id)
    );

    this.props.dispatch(baiTapActions.layDanhSachLopBaiTapAction(lopHoc.id));
    this.props.dispatch(baiTapActions.layDanhSachBaiTapNopAction(lopHoc.id));

    //lay danh sach xem lai buoi hoc
    this.props.dispatch(actions.layDanhSachXemLaiBuoiHocTheoLop(lopHoc.id));
    // console.log({ lopHoc });
    if (lopHoc.isStart && lopHoc.hasRoadMap) {
      history.push(`/course-roadmap/${lopHoc.id}`, { lopHoc });
    } else {
      history.push("/course", { lopHoc });
    }
  };
  luuTru = (id,arr) => {
    this.props.setArrLuuTru(id)
  };
  render() {
    let arrLuuTru = localStorage.getItem("luuTru")?localStorage.getItem("luuTru"):"[]"
    if (arrLuuTru) arrLuuTru = JSON.parse(arrLuuTru)
    const { dsBoDe } = this.props.lopHocProps;
    let { item, index } = this.props;
    const { isDropdownOpen } = this.state;

  let checkBoDe = dsBoDe.some((boDe) => {
    const maLoTrinhArray = JSON.parse(boDe.maLoTrinh);
    return maLoTrinhArray.includes(item.maLoTrinh);
  });
    let idUrl = this.props.match.params.id;
    if (item.isStart && item.hasRoadMap) {
      return (
        <div
          key={index}
          className={`  w-full  card_theme ${
            idUrl == item.id ? " active-item    " : " shadow-none border-none"
          } `}
        >
          <div className="mt-1 normal-case   " key={index}>
            <div style={{display:'flex',justifyContent:"space-around",alignItems:'center'}}>
            <a
              onClick={() => this.onGoToKhoaHoc(index, item)}
              // style={{ color: index === viTri ? "yellow" : "white" }}
            >
              <span className="text-sm text-blue-theme ">
                <i className="fa fa-graduation-cap "></i>Lớp {item.tenLopHoc} 
                
              </span>
            </a>
              <a title="Lưu trữ" style={{fontSize:'10px'}} onClick={() => { 
                // arrLuuTru.push(item.id)
                this.luuTru(item.id,arrLuuTru)
               }}> <i className="fa-solid fa-box-open"></i></a>
            </div>
          </div>
          <div className=" w-full text-blue-theme pl-3 lg:pl-7 py-3 space-y-2 ">
            <div onClick={() => this.onGoToKhoaHoc(index, item)}>
              <NavLink
                to={`/course-roadmap/${item.id}`}
                className="text-xs text-blue-theme p-0"
                activeClassName="text-yellow-300 normal-case "
              >
                <i className="fa fa-home"></i> Dashboard
              </NavLink>
            </div>
            <div className=" cursor-pointer hover:text-yellow-300">
              <NavLink
                to={`/course-detail/${item.id}`}
                className="text-xs text-blue-theme p-0"
                activeClassName="text-yellow-300 normal-case "
              >
                <i className="fa fa-road"></i> Lộ trình học chi tiết
              </NavLink>
            </div>
            <div className=" cursor-pointer hover:text-yellow-300">
              <NavLink
                to={`/course-roadmap-tai-lieu/${item.id}`}
                className="text-xs text-blue-theme p-0"
                activeClassName="text-yellow-300"
              >
                <i className="fa fa-file-alt "></i> Truy xuất nhanh tài liệu
              </NavLink>
            </div>
            <div className=" cursor-pointer hover:text-yellow-300">
              <NavLink
                to={`/course-roadmap-diem/${item.id}`}
                className="text-xs text-blue-theme p-0"
                activeClassName="text-yellow-300"
              >
                <i className="fa fa-money-check"></i> Điểm và chứng nhận
              </NavLink>
            </div>
            <div className=" cursor-pointer hover:text-yellow-300">
              <NavLink
                to={`/course-roadmap-thong-bao/${item.id}`}
                className="text-xs text-blue-theme p-0 "
                activeClassName="text-yellow-300"
              >
                <i className="fa fa-bell"></i> Thông báo
              </NavLink>
            </div>
            {checkBoDe && (
          <div className="relative">
            <a
              className="text-xs text-blue-theme p-0 "
              onClick={this.toggleDropdown}
            >
              {/* <NavLink  className="text-xs text-blue-theme p-0 " to={} onClick={this.toggleDropdown}> */}
              <i className="fa fa-file-alt"></i> Bộ chứng chỉ
              <i className={`ml-2 fa ${isDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
              {/* </NavLink> */}
            </a>

            {/* Dropdown items */}
            {isDropdownOpen && (
            <div className="absolute mt-2 w-40 bg-white shadow-md rounded-md" style={{ zIndex: '10', bottom: '100%' }}>
              {dsBoDe
                .filter(boDe => {
                  const maLoTrinhArray = JSON.parse(boDe.maLoTrinh);
                  return maLoTrinhArray.includes(item.maLoTrinh);
                })
                .map((bode, idx) => (
                  <NavLink
                    to={`/quizizz/${bode.id}`}
                    key={idx}
                    className="block px-4 py-2 text-xs hover:bg-gray-200"
                  >
                    {bode.tieuDe}
                  </NavLink>
                ))}
            </div>
          )}
          </div>
        )}
            <Button_HuongDanHocTap />
          </div>
        </div>
      );
    } else {
      return (
        <div className="mt-1" key={index}>
          <a
            onClick={() => this.onGoToKhoaHoc(index, item)}
            // style={{ color: index === viTri ? "yellow" : "white" }}
          >
            <span className="text-base text-blue-theme">
              <i className="fa fa-graduation-cap "></i>Lớp {item.tenLopHoc}
            </span>
          </a>
        </div>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
    lopHocProps: state.lopHocReducer,
  };
}

export default withRouter(connect(mapStateToProps, null)(SidebarItem));
