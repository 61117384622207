import React, { Component } from "react";
import { Modal, Input, message } from "antd";
import FacebookLogin from "react-facebook-login";
import history from "../../utilities/history";
import { FACEBOOK_APP_ID } from "../../config/commonConfig";
import { LocalStorageUtil } from "../../utilities/localStorage";
import { NguoiDungSevice } from "../../service/nguoiDungService";
import { DangNhapModel } from "../../model";
import "./DangNhap.scss";
import LoginBackup from "../../component/LoginBackup/LoginBackup";
import axios from "axios";

export default class ButtonFacebook extends Component {
  state = {
    email: "",
    userID: "",
    faceEmail: "",
    name: "",
    isLogin: false,
    appId: "",
  };
  componentDidMount() {
    axios
      .get("https://apimentor11.cybersoft.edu.vn/api/cauhinh/get-faceid")
      .then((result) => {
        this.setState({ appId: result.data.content });
      })
      .catch((err) => {});

    this.setState({
      email: "",
      userID: "",
      faceEmail: "",
      name: "",
      isLogin: false,
    });
  }
  responseFacebook = (response) => {
    // console.log(response);
    // NẾU USER ĐÃ ĐĂNG NHẬP SẴN FACEBOOK => LẤY THÔNG TIN FACEBOOK LƯU VÀO STATE
    const { userID, email, name } = response;

    const thongTinDangNhap = new DangNhapModel(userID, "", "");

    NguoiDungSevice.dangNhapFacebookService(thongTinDangNhap)
      .then((res) => {
        switch (res.data.content) {
          case 1:
            this.setState({
              userID,
              isLogin: true,
            });
            break;
          case 0:
            message.info(res.data.message);
            break;
          default:
            // LƯU THÔNG TIN ĐĂNG NHÂP VÀO LOCALSTORAGE
            LocalStorageUtil.setLoginLocalStorage(res.data.content);
            // CHUYỂN HƯỚNG ĐẾN TRANG CHỦ
            history.push("/");
            // window.location.href = '/';
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error("Error !");
          // GỌI HÀM XỬ LÝ ĐĂN NHẬP LỖI
          this.xuLyDangNhapThatBai(err.response);
        }
      });
  };

  onChangeInput = (e) => {
    const { value } = e.target;
    this.setState({ email: value });
  };

  // Đăng nhập sử dụng tài khoản facebook
  onDangNhap = () => {
    const { userID, faceEmail, email } = this.state;
    const thongTinDangNhap = new DangNhapModel(userID, faceEmail, email);

    NguoiDungSevice.dangNhapFacebookService(thongTinDangNhap)
      .then((res) => {
        switch (res.data.content) {
          case 1:
            this.setState({
              userID,
              isLogin: true,
            });
            break;
          case 0:
            message.info(res.data.message);
            break;
          default:
            // LƯU THÔNG TIN ĐĂNG NHÂP VÀO LOCALSTORAGE
            LocalStorageUtil.setLoginLocalStorage(res.data.content);
            // CHUYỂN HƯỚNG ĐẾN TRANG CHỦ
            history.push("/");
            // window.location.href = '/';
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          message.error("Error !");
          // GỌI HÀM XỬ LÝ ĐĂN NHẬP LỖI
          this.xuLyDangNhapThatBai(err.response);
        }
      });
  };

  // // HÀM XỬ LÝ ĐĂN NHẬP LỖI
  // xuLyDangNhapThatBai = (error) => {
  //     const { content, message } = error.data;

  //     confirm({
  //         title: "Lỗi",
  //         content: message ? message : "Đăng nhập thất bại!",
  //         onOk() { }
  //     });

  // }

  render() {
    return (
      <>
        <div className="right ">
          <div style={{ marginBottom: "1.5rem" }}>
            <img src="https://i.imgur.com/lC22izJ.png" />
          </div>
          {/* <h3>Đăng nhập</h3> */}
          {this.state.appId && (
            <FacebookLogin
              appId={this.state.appId}
              fields="name, email, picture"
              callback={this.responseFacebook}
              cssclassName="btnFacebook"
              textButton={
                <span className="text-xs">
                  <i className="fa fa-facebook-f"></i>ĐĂNG NHẬP BẰNG FACEBOOK
                </span>
              }
            />
          )}
          <small className="px-5 w-full">
            <b>Lưu ý</b>: Tài khoản facebook của bạn phải là tài khoản cá nhân
            còn hoạt động, và email khớp với email bạn đã đăng ký khóa học của
            trung tâm!
          </small>
        </div>
        <Modal
          title="Kiểm tra thông tin"
          visible={this.state.isLogin}
          width={500}
          closable={false}
          cancelButtonProps={{ hidden: true }}
          onOk={() => this.onDangNhap()}
        >
          <p>
            Nhập email:{" "}
            <input
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
              className="form-control"
            />
          </p>
        </Modal>
      </>
    );
  }
}
