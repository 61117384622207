import React, { Component } from "react";
import { FileSevice } from "../../../service/fileService";
import VideoPlayer from "../../../component/VideoPlayer";
import {
  Player,
  ControlBar,
  ReplayControl,
  PlaybackRateMenuButton,
} from "video-react";
import HLSSource from "./HLSSource";
import { dateFormat } from "../../../utilities/dateTimeFormat";
import moment from 'moment';
import Vimeo from "@u-wave/react-vimeo";
import { LocalStorageUtil } from "../../../utilities/localStorage";
import { LopHocSevice } from "../../../service/lopHocService";

export default class XemLaiBuoiHoc extends Component {
  state = {
    source: "",
    vimeo: 0,
    youtube: "",
    drive: "",
    dateCheck: "15/12/2022",
    hanNgayMoi: 30,
    hanNgayCu: 14,
  };

  async getConfigData () {
    try {
      let {data} = await LopHocSevice.layConfigXemLaiBuoiHoc();
      const {hanVideoCu, hanVideoMoi, ngayCheck} = data?.content;
      this.setState({
        hanNgayCu: +hanVideoCu,
        hanNgayMoi: +hanVideoMoi,
        dateCheck: ngayCheck
      })
    } catch (error) {
     console.log(error)
    }
  }

  componentDidMount(){
   this.getConfigData();
  }

  getYoutube = (link) => {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = link.match(regExp);

    if (match && match[2].length == 11) {
      return match[2];
    }
  };

  getNgayXoa = (ngayTao) => {

    // Kiểm tra ngayTao có trước ngày kiểm tra không
    let isTruocNgayKiemTra = moment(this.state.dateCheck).isAfter(moment(ngayTao),'day');

    if (isTruocNgayKiemTra) {
      return dateFormat(
        new Date(ngayTao).setDate(
          new Date(ngayTao).getDate() + this.state.hanNgayCu
        )
      )
    }
    else 
    {
      return dateFormat(
        new Date(ngayTao).setDate(
          new Date(ngayTao).getDate() + this.state.hanNgayMoi
        )
      )
    }


   
  }

  render() {
    const { dsXemLai } = this.props;
    const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    return (
      <div className="row">
        <h4 className="text-danger col-md-12">
          {" "}
          Lưu ý: Đúng 2 tuần bắt đầu từ ngày tải lên, video sẽ tự động xóa khỏi
          hệ thống.
        </h4>
        <div className="col-md-3">
          <div className="list-group" id="list-tab" role="tablist">
            {dsXemLai.map((item) => (
              <a
                className="list-group-item list-group-item-action"
                id={"lst-video-" + item.id}
                onClick={() => {
                  // this.setState({
                  //     vimeo: item.vimeo,
                  //     youtube: item.youtube,
                  //     drive: item.drive
                  // })

                  var els = document.getElementsByClassName("active");
                  for (var i = 0; i < els.length; i++) {
                    els[i].classList.remove("active");
                  }

                  document
                    .getElementById("lst-video-" + item.id)
                    .classList.add("active");
                  if (nguoiDung.nuocNgoai) {
                    FileSevice.layDuongDanRecordDigitalService(item.noiDung)
                      .then((res) => {
                        this.setState({ source: res.data });
                      })
                      .catch((err) => console.log(err.response));
                  } else {
                    FileSevice.layDuongDanRecordService(item.noiDung)
                      .then((res) => {
                        this.setState({ source: res.data });
                      })
                      .catch((err) => console.log(err.response));
                  }
                }}
              >
                <i
                  className="fa fa-caret-square-o-right mr-2"
                  aria-hidden="true"
                ></i>
                {item.tieuDe}
                <br /> Ngày up: {dateFormat(item.ngayTao)}
                <br /> Ngày xóa:{" "}
                {this.getNgayXoa(item.ngayTao)}
              </a>
            ))}
          </div>
        </div>
        <div className="col-md-9">
          {
            // nguoiDung.nuocNgoai == true && ( this.state.vimeo != 0 ||  this.state.youtube != "" ||  this.state.drive != "" ) ?
            //     this.state.vimeo != 0 ?
            //         <Vimeo
            //             video={this.state.vimeo}
            //             width="100%"
            //             height="500"
            //         /> :
            //         this.state.youtube != "" ?
            //             <iframe width="100%" height="515"
            //                 src={`https://www.youtube.com/embed/${this.getYoutube(this.state.youtube)}`}>
            //             </iframe>
            //             :
            //             this.state.drive != "" ?
            //                 <div style={{width:'100%',textAlign:'center',fontSize:30}}>
            //                     <a href={this.state.drive} target='_blank'>Xem video</a>
            //                 </div>
            //                 : ""
            //     :

            this.state.source ? (
              <Player playsInline fluid={false} width={"100%"} height={550}>
                <HLSSource
                  isVideoChild
                  src={this.state.source && this.state.source}
                />
                <ControlBar>
                  <PlaybackRateMenuButton
                    rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
                    order={10}
                  />
                </ControlBar>
              </Player>
            ) : (
              ""
            )
          }
        </div>
      </div>
    );
  }
}
