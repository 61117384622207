import * as types from '../types';

const initialState = {
    dsLopHoc: [],
    dsXemLai: [],
    dsConfig: [],
    dsBoDe:[]
}

export const lopHocReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LAY_DANH_SACH_LOP_HOC_THANH_CONG:
            return {
                ...state,
                dsLopHoc: action.payload
            }
        case types.LAY_DANH_SACH_BO_DE_THANH_CONG:
            return {
                ...state,
                dsBoDe: action.payload
            }
        case types.LAY_DANH_SACH_XEM_LAI_BUOI_HOC_THANH_CONG:
            return {
                ...state,
                dsXemLai: action.payload
            }
            case types.LAY_CONFIG_THANH_CONG:
            return {
                ...state,
                dsConfig: action.payload

            }
        default:
            return state;
    }
}


export default lopHocReducer;