import React, { useEffect, useState } from "react";
import { message, Modal, Rate, Tabs, Input } from "antd";
import { layDanhMucDanhGia, layDanhSachDanhGia } from '../../../../redux/actions/nguoiDungAction';
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useParams } from "react-router-dom";
import { LocalStorageUtil } from "../../../../utilities/localStorage";
import { NguoiDungSevice } from '../../../../service/nguoiDungService';

const { TabPane } = Tabs;
const { TextArea } = Input;

const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

export default function DanhGiaMentor({ dataRoadMap }) {
    const [visible, setVisible] = useState(false);
    const [lstDanhGia, setLstDanhGia] = useState([]);
    const { id } = useParams();
    const danhMucDanhGia = useSelector(state => state.nguoiDungReducer.danhMucDanhGia);
    const dsDanhGia = useSelector(state => state.nguoiDungReducer.dsDanhGia);
    const thongTinDangNhap = LocalStorageUtil.getLoginLocalStorage();


    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setTimeout(() => {
            setVisible(false);
        }, 3000);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(layDanhMucDanhGia());
        dispatch(layDanhSachDanhGia());

    }, [])

    useEffect(() => {

        //loc danh gia theo lop
        const lstDanhGiaTheoLop = dsDanhGia.filter(n => n.maLop == id && n.maNguoiDanhGia == thongTinDangNhap.id);
        if (lstDanhGiaTheoLop.length > 0) {

            let lstTemp = [];
            lstDanhGiaTheoLop.map(item => {
                let temp = { ...item, noiDung: JSON.parse(item.noiDung) };
                lstTemp.push(temp);
            })

            setLstDanhGia(lstTemp)

        }

    }, [dsDanhGia])

    const changeDanhGia = (value, index, mentorId) => {

        let checkDanhGia = lstDanhGia.find(n => n.mentorId == mentorId);
        if (checkDanhGia) {
            checkDanhGia.noiDung[index] = value;

        } else {
            let noiDung = [0];

            danhMucDanhGia.map(n => {
                noiDung.push(0);
            })
            noiDung[index] = value;

            let model = {
                mentorId,
                maLop: id,
                noiDung,
                maNguoiDanhGia: thongTinDangNhap.id
            };


            setLstDanhGia([...lstDanhGia, model]);

        }

    };
    const luuDanhGia = () => {
        if (lstDanhGia.length > 0) {

            lstDanhGia.map(item => {
                let data = { ...item, noiDung: JSON.stringify(item.noiDung) };
                NguoiDungSevice.luuDanhGiaService(data).then(res => {
                    message.success("Gửi thành công !");
                    document.querySelector("#thongBaoDanhGia").removeAttribute("hidden");
                }).catch(err => message.error("Đã có lỗi xảy ra !"));
            })
        }

    }


    return (
        <>
            <span className=" text-sm" onClick={showModal}>
                Đánh giá
            </span>
            <Modal
                visible={visible}
                title="Đánh giá"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width="90vw"
                height="90vh"
                style={{ top: 10 }}
            >
                <Tabs style={{width:600}}>
                    {dataRoadMap.thongTinLopHoc?.danhSachMT_GV.map(item => {
                        let danhGiaTheoMentor = lstDanhGia.find(n => n.mentorId == item.mentorId);

                        let noiDung = [];
                        if (danhGiaTheoMentor) {
                            noiDung = danhGiaTheoMentor.noiDung;
                        }
                        return <TabPane tab={item.hoTen} key={item.mentorId} >
                            <ul class="list-group">
                                {danhMucDanhGia.map((danhMuc, index) => {

                                    let danhGiaValue = 0;
                                    if (noiDung.length > 0) {
                                        danhGiaValue = noiDung[index + 1];
                                    }

                                    return <li class="list-group-item d-flex justify-content-between align-items-center">
                                        <b>{danhMuc}</b>
                                        <span class="badge badge-secondary badge-pill"><Rate defaultValue={danhGiaValue} tooltips={desc} onChange={(value) => changeDanhGia(value, index + 1, item.mentorId)} /></span>
                                    </li>
                                })}

                            </ul>
                            <p className="mt-5 text-primary italic">
                                Lưu ý: Mọi đánh giá của bạn sẽ được lưu dưới dạng ẩn danh (sẽ không ai biết bạn đã đánh giá họ)
                            </p>
                            <TextArea
                                placeholder="Hãy để lại vài dòng suy nghĩ của bạn nhé !"
                                autoSize={{ minRows: 3 }}
                                onChange={(e) => changeDanhGia(e.target.value, 0, item.mentorId)}
                                defaultValue={noiDung[0]}
                            />
                        </TabPane>
                    })}

                </Tabs>
                <p id="thongBaoDanhGia" hidden className="pt-5" >
                    <i className="text-danger"> Cảm ơn bạn đã đánh giá ! Đây sẽ là dữ liệu để trung tâm nhận xét và nâng cao chất lượng của đội ngũ.<br /> Bạn vẫn có thể gửi lại đánh giá của mình trong suốt quá trình học ! </i>
                </p>
                <div className="flex justify-center pt-5">

                    <button className="btn btn-primary" onClick={luuDanhGia}>Gửi</button>

                </div>
            </Modal>
        </>
    );
}
