import * as types from '../types';
import { BaiTapSevice } from '../../service/baiTapService';
import { Modal } from 'antd';

const { confirm } = Modal;

// get quiz aws 
export const layQuizAwsAction = (classId) => {
    return dispatch => {
        BaiTapSevice.layQuizAwsService()
        .then(res => { dispatch(layQuizAwsThanhCongAction(res.data.content))})
        .catch(err => console.log(err.response));
    }
}
export const layQuizAwsThanhCongAction = (data) => {
    return {
        type: types.GET_QUIZ_AWS,
        payload: {
            data
        }
    }
}
//v2 ngadev
export const layQuizAction = (classId) => {
    return dispatch => {
        BaiTapSevice.layQuizService(classId)
        .then(res => { 
            console.log(res.data.content)
            dispatch(layQuizThanhCongAction(res.data.content))
        })
        .catch(err => console.log(err.response));
    }
}
export const layQuizThanhCongAction = (data) => {
    return {
        type: types.GET_QUIZ,
        payload: {
            data
        }
    }
}
//lay layout bo de
export const layLayoutAction = (id) => {
    return dispatch => {
        BaiTapSevice.layLayoutService(id)
        .then(res => { dispatch(layLayoutThanhCongAction(res.data.content))})
        .catch(err => console.log(err.response));
    }
}
export const layLayoutThanhCongAction = (data) => {
    return {
        type: types.GET_LAYOUT,
        payload: data
        
    }
}



/*=========== lay bai tap nop theo ma lop ===========*/
export const layDanhSachBaiTapNopAction = (classId) => {
    return dispatch => {
        BaiTapSevice.layDanhSachBaiTapNopService(classId)
        .then(res => { dispatch(layDanhSachBaiTapNopThanhCongAction(res.data.content))})
        .catch(err => console.log(err.response));
    }
}

export const layDanhSachBaiTapNopThanhCongAction = (data) => {
    return {
        type: types.LAY_DANH_SACH_BAI_TAP_NOP_THANH_CONG,
        payload: {
            data
        }
    }
}

/*=========== lay lop bai tap theo ma lop ===========*/
export const layDanhSachLopBaiTapAction = (classId) => {
    return dispatch => {
        BaiTapSevice.layDanhSachLopBaiTapService(classId)
        .then(res => { dispatch(layDanhSachLopBaiTapThanhCongAction(res.data.content))})
        .catch(err => console.log(err.response));
    }
}

export const layDanhSachLopBaiTapThanhCongAction = (data) => {
    return {
        type: types.LAY_DANH_SACH_LOP_BAI_TAP_THANH_CONG,
        payload: {
            data
        }
    }
}

//-----------========= LAY DANH SACH BAI TAP ==========-------------
export const layDanhSachBaiTap = (classId, userId) => {
    return dispatch => {
        BaiTapSevice.layDanhSachBaiTapService(classId, userId)
        .then(res => dispatch(layDanhSachBaiTapThanhCong(res.data.content)))
        .catch(err => console.log(err.response));
    }
}

export const layDanhSachBaiTapThanhCong = (data) =>{
    return {
        type: types.LAY_DANH_SACH_BAI_TAP_THANH_CONG,
        payload: data
    }
}

// lay data LopHoc
export const nopBaiTapAction = (model) => {
    return dispatch => {
        BaiTapSevice.nopBaiTapService(model)
            .then(res => {
                dispatch(nopBaiTapThanhCongAction(res));
                confirm({
                    title: 'Thông báo!',
                    content: 'Nộp bài thành công!',
                    onOk() { }
                });
            })
            .catch(err => {
                console.log(err.response);
                confirm({
                    title: 'Thông báo!',
                    content: 'Nộp bài thất bại!',
                    onOk() { }
                });
            });
    }
}

export const nopLaiBaiTap = (id, model) => {
    return dispatch => {
        BaiTapSevice.capNhatBaiTapNopService(id, model)
            .then(res => {
                dispatch(nopBaiTapThanhCongAction(res));
                confirm({
                    title: 'Thông báo!',
                    content: 'Cập nhật thành công!',
                    onOk() { }
                });
            })
            .catch(err => {
                console.log(err.response);
                confirm({
                    title: 'Thông báo!',
                    content: 'Cập nhật thất bại!',
                    onOk() { }
                });
            });
    }
}

export const nopBaiTapThanhCongAction = (response) => {
    return {
        type: types.NOP_BAI_TAP_THANH_CONG,
        payload: response.data.content
    }
}