import { Alert, Modal, Popconfirm, Result, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { API_URL_DOMAIN_ONLINE } from '../../../../config/urlConfig';
import { layVoucher } from '../../../../redux/actions/nguoiDungAction';
import { NguoiDungSevice } from '../../../../service/nguoiDungService';
import { LocalStorageUtil } from '../../../../utilities/localStorage';
import { dateTimeFormat } from '../../../../utilities/dateTimeFormat';


export const VoucherUser = ({ id }) => {

    let newId = id.replace(" ", "+");
    const decoded = atob(newId);

    let data = JSON.parse(decodeURIComponent(escape(decoded)));
  

    const dispatch = useDispatch();

    let nguoiDung = LocalStorageUtil.getLoginLocalStorage();

    const [visible, setVisible] = useState(false);
    const [viewGift, setViewGift] = useState(false);
    const showModal = () => {
        taoVoucher()
        setVisible(true);
    };

    const handleOk = () => {

        setVisible(false);

    };

    const handleCancel = () => {
        setVisible(false);
    };

    const voucher = useSelector(state => state.nguoiDungReducer.voucher);
    const [voucherUser, setvoucherUser] = useState(null);


    useEffect(() => {

        dispatch(layVoucher());
    }, [])

    const taoVoucher = () => {


        let model = {
            maNguoiDung: nguoiDung.id,
            maVoucher: voucher.id,
            maLop: data.ml,
            hanSuDung: voucher.hanSuDung
        }


        NguoiDungSevice.themVoucherUserService(model).then(res => {

            NguoiDungSevice.layVoucherUserService(nguoiDung.id, data.ml).then(resData => {

                setvoucherUser(resData.data.content);
            }).catch(err => console.log("get", err))

        }).catch(err => console.log("post", err));
    }

    let dFer = new Date("02/01/2023");
    let checkDateLop = new Date(data.ngayHoc);
    return (
        <div>
            {checkDateLop > dFer &&
                <span
                    onClick={showModal}
                    className="giftVoucher flex items-center justify-center rounded-md bg-red-500 px-2 text-white hover:bg-red-600 cursor-pointer w-10 h-10"
                >
                    <i className="fa-solid fa-gift text-xl"></i>
                </span>
            }
            <Modal
                visible={visible}
                title="Gift voucher"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width="90vw"
                height="90vh"
            // style={{ top: 10 }}
            >

                <Result

                    icon={<div className="flex justify-center"><img width="500px" src={`${API_URL_DOMAIN_ONLINE}${voucher?.hinhAnh}`} /></div>}
                    title={"Chúc mừng bạn đã nhận " + voucher?.tenVatPham}
                    subTitle={`Lưu ý: Voucher chỉ dành riêng cho người được vinh danh. Có hạn sử dụng đến ${dateTimeFormat(voucherUser?.ngayHetHan)}`}
                    extra={[
                        voucherUser && <div>
                            {
                                voucherUser.suDung ?
                                    <Alert message="Đã sử dụng" type="info" />
                                    :
                                    voucherUser.hetHan ?
                                        <Alert message="Hết hạn" type="error" />
                                        :

                                        voucherUser.hoTenTang == "" ?
                                            viewGift == false ?
                                                <Tooltip title="Bạn có thể tặng voucher cho người khác đã đăng ký tài khoản trên hệ thống !">
                                                    <button className='btn btn-success' onClick={() => setViewGift(true)} ƒ><i className='fa fa-gift'></i> Tặng voucher </button>
                                                </Tooltip>
                                                :
                                                <>
                                                    <Popconfirm
                                                        title="Bạn có chắc muốn tiếp tục?"
                                                        onConfirm={() => {
                                                            let email = document.querySelector("#txt-email").value;

                                                            NguoiDungSevice.tangVoucherUserService({ email, maVoucher: voucherUser.id }).then(res => {
                                                                document.querySelector("#txt-error").innerHTML = "";

                                                                if (res.data.content == 0) {
                                                                    document.querySelector("#txt-error").innerHTML = res.data.message;
                                                                } else {
                                                                    taoVoucher()
                                                                }
                                                            })
                                                        }}
                                                        onCancel={() => setViewGift(false)}
                                                        okText="Có"
                                                        cancelText="Hủy"
                                                    >
                                                        <button className='btn btn-danger'><i className='fa fa-gift'></i> Đồng ý </button>
                                                    </Popconfirm>
                                                    <input id='txt-email' className='mt-2 form-control' placeholder='Nhập email người nhận' />
                                                    <span className='text-danger' id="txt-error"></span>
                                                </>
                                            : ""

                            }

                            {voucherUser.hoTenTang != "" &&
                                <Alert
                                    message={`Bạn đã tặng voucher này cho ${voucherUser?.hoTenTang} , email: ${voucherUser?.emailTang}`}

                                    type="success"

                                />
                            }

                        </div>
                    ]}
                />

            </Modal>
        </div>
    )
}
