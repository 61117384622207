import React, { useEffect } from "react";
// import { Player } from "video-react";
import "./xemVideoMobile.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataRoadMapServAction,
  getTaskRoadMapServAction,
} from "../../../../redux/actions/roadMapAction";
import backgroundTemplate from "./../../../../assets/images/background.png";
import { useState } from "react";
import SpinnerComponent from "../../../../component/Spinner";
import VideoXemTruocMobile from "./VideoXemTruocMobile";
import ViewClickChonVideo from "../ViewClickChonVideo";
import { RoadmapService } from "../../../../service/roadmapService";
import { LOAI_TASK_VIDEO_XEMTRUOC } from "../../../../redux/types";
import NewBottomNavigationMobile from "../../newBottomNavigationMobile/NewBottomNavigationMobile";
export default function XemVideoMobile() {
  const dispatch = useDispatch();
  const { id, buoiHoc, chuongHoc } = useParams();
  // console.log(id, buoiHoc, chuongHoc);
  const [maVideoXem, setMaVideoXem] = useState();
  // console.log(maVideoXem);
  const { dataRoadMap, dataTaskRoadMap } = useSelector(
    (state) => state.roadmapReducer
  );
  useEffect(() => {
    dispatch(getTaskRoadMapServAction(id));
    dispatch(getDataRoadMapServAction(id));
  }, [id, dispatch]);

  const handleCheckHoanThanh = () => {
    let submitHoanThanh = {
      maBuoiHoc: buoiHoc,
      maBaiHoc: maVideoXem || 0,
      maLop: +id,
      loaiTask: LOAI_TASK_VIDEO_XEMTRUOC,
    };
    // console.log(submitHoanThanh);
    RoadmapService.postCheckHoanThanhTaskRoadMap(submitHoanThanh)
      .then(() => {
        // dispatch(getDataRoadMapServAction(id));
        dispatch(getTaskRoadMapServAction(id));
      })
      .catch((err) => {
        // message.error("đã có lỗi xảy ra");
        console.log(err);
      });
  };

  // console.log(dataTaskRoadMap?.size);
  // console.log(dataRoadMap);
  const renderVideoXemTruoc = () => {
    if (dataRoadMap?.danhSachBuoiHocTheoSkill) {
      let videoXemTruoc = "";
      videoXemTruoc = dataRoadMap?.danhSachBuoiHocTheoSkill[
        chuongHoc
      ].danhSachBuoiHoc.find((item) => item.id === Number(buoiHoc));
      return videoXemTruoc?.videoExtra?.map((item, index) => {
        // console.log(videoXemTruoc);
        // console.log(item);
        // console.log(
        //   dataTaskRoadMap?.get(buoiHoc).danhSachVideoXemTruoc[item.id]
        // );
        return (
          <li key={index}>
            <div
              onClick={() => {
                setMaVideoXem(item.id);
              }}
              // to=""
              className="flex cursor-pointer text-black items-center w-full justify-between gap-5 shadow py-2 px-4 bg-item-mobile hover:bg-gray-300 rounded"
            >
              <div className="text-lg">{index + 1}</div>
              <div className="flex-1">
                <h4>{item.tieuDe}</h4>
                <p className="mb-0">
                  <i className="fa-solid fa-closed-captioning mr-2"></i>
                  {/* <span>Thời lượng - 10:55 mins</span> */}
                </p>
              </div>
              <div>
                {dataTaskRoadMap?.size !== 0 &&
                dataTaskRoadMap?.get(buoiHoc).danhSachVideoXemTruoc[item.id]
                  ?.isHoanThanh ? (
                  <i className="fa-solid ml-auto fa-check p1 w-5 flex items-center justify-center h-5 text-xs rounded-full bg-green-600 text-white"></i>
                ) : dataTaskRoadMap?.size === 0 &&
                  !dataTaskRoadMap?.get(toString(buoiHoc))
                    ?.danhSachVideoXemTruoc[item?.id].isHoanThanh ? (
                  ""
                ) : (
                  <i className="fa-solid ml-auto fa-exclamation p1 w-5 flex items-center justify-center h-5 text-xs rounded-full bg-red-600 text-white"></i>
                )}
              </div>
            </div>
          </li>
        );
      });
    }
  };

  const renderButtonHoanThanh = () => {
    // console.log(maVideoXem);
    // console.log(dataTaskRoadMap);
    if (maVideoXem && dataTaskRoadMap.size !== 0) {
      if (
        dataTaskRoadMap?.get(buoiHoc).danhSachVideoXemTruoc[maVideoXem]
          ?.isHoanThanh
      ) {
        return (
          <p className="font-medium text-color-blue-white m-0 p-2">
            <i className="fa fa-check mr-2 text-sm flex-shrink-0 text-green-600"></i>
            Đã hoàn thành
          </p>
        );
      } else {
        return (
          <button
            onClick={handleCheckHoanThanh}
            className="py-2 px-3 text-white rounded-md btn-gradient-cyber"
          >
            Hoàn Thành Video
          </button>
        );
      }
    }
  };

  return (
    <div>
      <SpinnerComponent />
      <div className="h-full py-2 px-8 shadow mb-5">
        <div className="flex items-center justify-between">
          <h3 className="font-bold text-lg mb-0">
            {dataRoadMap?.thongTinLopHoc?.tenLopHoc}
          </h3>
          {/* <div>
            <i className="fa-solid fa-bell text-2xl"></i>
          </div> */}
        </div>
        <h4 className="font-bold text-base mb-0">Video xem trước</h4>
      </div>
      <div>
        <div className="h-140">
          {maVideoXem ? (
            <VideoXemTruocMobile
              maBuoiHoc={buoiHoc}
              dsXemLai={
                dataRoadMap.length !== 0
                  ? dataRoadMap?.danhSachBuoiHocTheoSkill[
                      chuongHoc
                    ].danhSachBuoiHoc.find(
                      (item) => item.id === Number(buoiHoc)
                    )
                  : []
              }
              maLop={id}
              maVideo={maVideoXem}
            />
          ) : (
            <ViewClickChonVideo />
          )}
        </div>
        <div className="flex justify-between items-center pr-5">
          {/* <h4 className="my-2 px-2 font-bold text-lg">Bài 1: Giới thiệu Css</h4> */}
          {/* <Checkbox /> */}
        </div>
      </div>
      <div
        style={{
          background: `url(${backgroundTemplate})`,
          backgroundSize: "cover",
        }}
        className="pb-9"
      >
        <div className="p-2 flex justify-end items-center">
          {renderButtonHoanThanh()}
        </div>
        <ul className="mx-2 space-y-2 pt-3 pb-5">{renderVideoXemTruoc()}</ul>
      </div>
      <NewBottomNavigationMobile />
    </div>
  );
}
