import React, { useState, useEffect } from "react";
import { Tabs, Modal, message } from "antd";
import HLSSource from "../../../roadmapLoTrinhChiTiet/RoadMapToogleList/CollapseRoadMap/ItemRoadMap/ContentModal/ContentVideoXemLai/xem-lai/HLSSource";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import { FileSevice } from "../../../../service/fileService";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BaseService } from "../../../../service/baseService";
import { Mark_Video } from "../../../roadmapLoTrinhChiTiet/RoadMapToogleList/CollapseRoadMap/ItemRoadMap/ContentModal/utils/watermark";
import { LocalStorageUtil } from "../../../../utilities/localStorage";
import Flash_sale from "../../../../features/quan-ly-cv/Component/Flash_Sale/Flash_sale";
const { TabPane } = Tabs;
export default function ListTaiLieu({
  title,
  dsVideo,
  setTitleModal,
  setIsModalVisible,
  setContentModal,
}) {
  const { id } = useParams();
  const [source, setSource] = useState("");
  const [titleVideo, setTitleVideo] = useState("");
  const [visible, setVisible] = useState(false)
 const [isDisableButton, setIsDisableButton] = useState(false);
  const { chiTietKhoaHocFree, dsKhoaHocFree } = useSelector(
    (state) => state.khoaHocReducer
  );

  const [isClose, setIsClose] = useState(false);

  const danhSachChuong =
    chiTietKhoaHocFree?.thongTinChuongHoc?.at(0)?.thongTinBaiHoc;

    useEffect(() => {
     let isMount = true;
 let myInterval;
     if (isMount && visible) {
      const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
      myInterval = Mark_Video(nguoiDung);
     }

     if (visible === false) {
      clearInterval(myInterval);
     }
    
      return () => {
        clearInterval(myInterval);
      }
    }, [visible])
    

  const handleDisableButton = () => {
    setIsDisableButton(true);
    setTimeout(() => {
      setIsDisableButton(false);
    }, 2000); // Thời gian 2 giây (2000 milliseconds)
  };

  function renderTitle() {
    return dsKhoaHocFree?.find((item) => {
      return item.id === Number(id);
    })?.tenKhoaHoc;
  }

  function handleGetVideoTrongNuoc(item) {
    handleDisableButton()
    setTitleVideo(item?.tenBaiHoc)
    
    FileSevice.layDuongDanVideoService(item?.noiDung).then((res) => {
      BaseService.post("/api/khoahoc/free/bai-hoc-offline",item.id)
      setVisible(true)
      setSource(res.data);
    });
  }

  function handleGetVideoNuocNgoai(item) {
    handleDisableButton()
    setTitleVideo(item?.tenBaiHoc)
    FileSevice.layDuongDanVideoDigitalService(item?.noiDung).then((res) => {
      BaseService.post("/api/khoahoc/free/bai-hoc-offline",item.id)
      setVisible(true)
      setSource(res.data);
    });
  }



  function renderListButtonChanel(dsVideo) {
    return (
      dsVideo &&
      dsVideo.map((item, index) => (
        <div
          className=" w-full rounded card_theme p-3 space-x-5 flex items-center justify-between"
          key={index}
        >
          <p className="text-base m-0">{item.tenBaiHoc} - <span className="text-red-500">{item.soLuotXem} lượt xem</span></p>
          <div>
            <button
              className="btn btn-primary mr-2"
              onClick={() => handleGetVideoTrongNuoc(item)}
              disabled={isDisableButton}
            >
              <i class="fa fa-play-circle" aria-hidden="true"></i>
              <span> Server 1</span>
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleGetVideoNuocNgoai(item)}
              disabled={isDisableButton}
            >
              <i class="fa fa-play-circle" aria-hidden="true"></i>
              <span> Server 2</span>
            </button>
          </div>
        </div>
      ))
    );
  }
  return (
    <div className=" w-full  space-y-5">

      {
        !isClose && <div className="w-full h-20">
        <Flash_sale setIsClose={setIsClose}/>
              </div>

      }
      
      
      <div className="flex-grow space-y-3 card_theme p-3 border-none">
        <Tabs defaultActiveKey="1" className="w-full felx flex-col flex-grow ">
  
          <TabPane
            tab={
              <p className="font-medium px-1 lg:px-3 text-dark">
                {renderTitle()}
              </p>
            }
            key="2"
            className="h-full "
          >
            {renderListButtonChanel(danhSachChuong)}

            <Modal
          title={titleVideo}
          visible={visible}
          onCancel={() => {
            setVisible(false)
            setTitleVideo("")
            setSource("")
          }}
          footer={null}
          centered
        >
{source !== "" ? (
  <div style={{ height: "80vh", width: "90vw" }} >
    <div className="flex-grow h-full flex items-center water-mark-wrapper">
<Player 

                      playsInline
                      fluid={false}
                      width={"100%"}
                      height={"100%"}
                      autoPlay={false}
                    >
                      <HLSSource isVideoChild src={source} />
                      <ControlBar>
                        <PlaybackRateMenuButton
                          rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
                          order={10}
                        />
                      </ControlBar>
                    </Player>
    </div>
 
  </div>
                   
                  ) : null}
        </Modal>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

// export default class ViewXemRecordThongTin extends Component {
//   state = {
//     source: "",
//     vimeo: 0,
//     youtube: "",
//     drive: "",
//   };

//   //Khởi tạo biến chứa thời gian bắt đầu khi người dùng click vào, cái này không ảnh hưởng tới render nên không cần bỏ vào state
//   thoiGianBatDau = "";
//   maBaiHoc;

//   handlePlayVideo = (item) => {
//     const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

//     !nguoiDung.nuocNgoai &&
//       FileSevice.layDuongDanRecordService(item.noiDung)
//         .then((res) => {
//           this.setState({ source: res.data });
//         })
//         .catch((err) => console.log(err.response));
//     // nước ngoài

//     nguoiDung.nuocNgoai &&
//       FileSevice.layDuongDanRecordDigitalService(item.noiDung)
//         .then((res) => {
//           this.setState({ source: res.data });
//         })
//         .catch((err) => console.log(err.response));
//   };

//   handleSubmitTrackingXemVideoXemLai() {
//     const { maLop, maBuoiHoc } = this.props;
//     let thoiGianClickPlay = new Date();
//     let dataSubmit = {
//       maBuoi: maBuoiHoc,
//       maBaiHoc: this.maBaiHoc,
//       maLop: +maLop,
//       thoiGianBatDau: "",
//       thoiGianKetThuc: "",
//     };

//     if (this.thoiGianBatDau === "") {
//       this.thoiGianBatDau = new Date();
//     }

//     if (this.thoiGianBatDau < thoiGianClickPlay) {
//       dataSubmit.thoiGianBatDau = this.thoiGianBatDau;
//       dataSubmit.thoiGianKetThuc = thoiGianClickPlay;
//       RoadmapService.postTrackingXemVideoXemLai(dataSubmit);
//       this.thoiGianBatDau = thoiGianClickPlay;
//     }
//   }

//   componentDidMount() {
//     const { dsXemLai } = this.props;
//     this.maBaiHoc = dsXemLai?.id;
//     this.handleSubmitTrackingXemVideoXemLai();
//     const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
//     !nguoiDung.nuocNgoai &&
//       dsXemLai?.noiDung &&
//       FileSevice.layDuongDanRecordService(dsXemLai?.noiDung)
//         .then((res) => {
//           this.setState({ source: res.data });
//         })
//         .catch((err) => console.log(err.response));
//     // nước ngoài

//     nguoiDung.nuocNgoai &&
//       dsXemLai?.noiDung &&
//       FileSevice.layDuongDanRecordDigitalService(dsXemLai?.noiDung)
//         .then((res) => {
//           this.setState({ source: res.data });
//         })
//         .catch((err) => console.log(err.response));

//     // mark video
//     this.myInterval = Mark_Video(nguoiDung);
//   }
//   componentWillUnmount() {
//     clearInterval(this.myInterval);
//     this.handleSubmitTrackingXemVideoXemLai();
//   }
//   render() {
//     const { dsXemLai } = this.props;
//     // console.log(dsXemLai);
//     const nguoiDung = LocalStorageUtil.getLoginLocalStorage();
//     return (
//       <div className="flex h-full w-full space-x-3 ">
//         <div className="flex-grow h-full flex items-center water-mark-wrapper">
//           {nguoiDung.nuocNgoai ? (
//             this.state.source && (
//               <Player
//                 playsInline
//                 fluid={false}
//                 width={"100%"}
//                 height={"100%"}
//                 autoPlay={false}
//               >
//                 <HLSSource isVideoChild src={this.state.source} />
//                 <ControlBar>
//                   <PlaybackRateMenuButton
//                     rates={[2, 1.5, 1.25, 1, 0.75, 0.5]}
//                     order={10}
//                   />
//                 </ControlBar>
//               </Player>
//             )
//           ) : // <Vimeo video={this.state.vimeo} width="100%" height="500" />
//           this.state.source ? (

//           ) : (
//             ""
//           )}
//         </div>
//         {/* <div
//           className="list-group w-84 flex-shrink-0 overflow-y-scroll "
//           id="list-tab"
//           role="tablist"
//         >
//           {dsXemLai.map((item) => {
//             let extraCss =
//               item.id === this.state.activeId ? " bg-blue-theme " : "";
//             return (
//               <div
//                 className={
//                   " cursor-pointer flex flex-col bg-blue-theme  justify-center h-max-content items-start  px-1 min-h-16   border-gray-200 border-b-1 w-full border-l-0 border-r-0  transform duration-300 text-white rounded py-2" +
//                   extraCss
//                 }
//               >
//                 <button
//                   className=" items-center  flex  px-2  justify-center   p-1 min-h-16 h-max-content   transform duration-300  rounded-lg w-full active:outline-none focus:outline-none"
//                   id={"lst-video-" + item.id}
//                   onClick={() => {
//                     this.handlePlayVideo(item);
//                     this.handleSubmitTrackingXemVideoXemLai();
//                   }}
//                 >
//                   <div className="bg-blue-theme h-9 rounded-full leading-7 w-9 flex justify-center items-center transform relative overflow-hidden mr-2   ">
//                     <i className="fa-solid fa-film text-base text-white"></i>
//                   </div>
//                   <div className="flex  flex-col w-full justify-start  h-max-content">
//                     <span
//                       className={"w-full text-left text-sm lg:text-base mb-1"}
//                     >
//                       {item.tieuDe}
//                     </span>
//                     <div className=" text-xs w-full text-left ">
//                       <p className="m-0 p-0 mb-1">
//                         Ngày tạo : {moment(item.ngayTao).format("DD/MM/yyyy")}
//                       </p>
//                       <p className="m-0 p-0">
//                         Ngày xoá : {moment(item.ngayXoa).format("DD/MM/yyyy")}
//                       </p>
//                     </div>
//                   </div>
//                 </button>
//               </div>
//             );
//           })}
//         </div> */}
//       </div>
//     );
//   }
// }
